<div id="common-container">
  <div class="main-container">
    <div class="left-container">
      <div class="imoz">
        <p>{{ inquiry?.username }}님,</p>
        <div class="flx">
          <p><span>소중한 문의</span> 주셔서 <br /></p>
          <div class="flex-imoz">
            <p>감사합니다.</p>
            <img src="assets/imgs/imoz.png"
              alt="" />
          </div>
        </div>
      </div>

      <p class="text">담당자가 확인 후 빠른 시일 내에 연락드리겠습니다.</p>
      <div class="sub-wrap">
        <button type="button"
          class="sub-btn"
          (click)="goList()">
          문의내역 보러 가기
        </button>
      </div>
    </div>

    <div class="right-container">
      <h4>다음은 어떻게 진행되나요?</h4>

      <div class="text-wrap">
        <div class="content">
          <div class="arrow">
            <div class="num-icon">
              <img src="assets/imgs/num01.png"
                alt="" />
            </div>
            <div class="arrow-icon"></div>
          </div>

          <div class="desc">
            <p class="title">목표에 대해 논의</p>
            <p class="con">
              문의 확인 후 목표와 계획을<Br /> 명확히 이해하기 위해 통화를
              준비합니다.
            </p>
          </div>
        </div>

        <div class="content">
          <div class="arrow">
            <div class="num-icon">
              <img src="assets/imgs/num02.png"
                alt="" />
            </div>
            <div class="arrow-icon"></div>
          </div>
          <div class="desc">
            <p class="title">미팅 진행</p>
            <p class="con">
              일정 및 예산 견적과 함께<br />
              스타터팩이 포함된 견적서를 작성합니다.
            </p>
          </div>
        </div>

        <div class="content">
          <div class="arrow">
            <div class="num-icon">
              <img src="assets/imgs/num03.png"
                alt="" />
            </div>
          </div>
          <div class="desc">
            <p class="title">프로젝트 시작</p>
            <p class="con">
              계약이 성사되면<Br />
              본격적인 프로젝트 설계를 시작합니다.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="home-button">
    <button type="button"
      (click)="goHome()">홈으로 가기</button>
  </div>
</div>

<div #script=""
  style.display="none">
  <ng-content></ng-content>
</div>