<div class="image-popup">
  <!-- <div class="header-area"> -->
  <button type="button" class="close-btn" (click)="closeDialog()"></button>
  <!-- flex 자리 배치를 위한 빈 div -->
  <div></div>
  <!-- </div> -->

  <div class="swiper-outer">
    <div class="swiper-container admin-images-swiper">
      <div class="swiper-wrapper">
        <div *ngFor="let item of renewalData" class="swiper-slide">
          <div class="swiper-zoom-container">
            <img [src]="item.image" alt="관리자 페이지 예시 이미지" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-btns">
    <div class="swiper-button swiper-button-prev"></div>
    <div class="swiper-button swiper-button-next"></div>
  </div>
  <!-- flex 자리 배치를 위한 빈 div -->
  <div></div>
</div>
