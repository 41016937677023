import { sequence, style } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { CursorError } from '@angular/compiler/src/ml_parser/lexer';
import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-design',
  templateUrl: './design.component.html',
  styleUrls: ['./design.component.scss'],
})
export class DesignComponent implements OnInit, OnDestroy {
  constructor(
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  ngOnInit(): void {}

  ngAfterContentInit(): void {
    let script = this._renderer2.createElement('script');
    script.type = 'text/javascript';
    script.text = `var _nasa={};if (!wcs_add) var wcs_add = {};
                   wcs_add["wa"] = "s_52ea45ec186a";
                   if (!_nasa) var _nasa = {};
  if (window.wcs) {
    wcs.inflow("startapp.co.kr");
    wcs_do(_nasa);
  }
    `;
    script.onload = () => {};
    this._renderer2.appendChild(this._document.body, script);
    //Called after ngOnInit when the component's or directive's content has been initialized.
    //Add 'implements AfterContentInit' to the class.
    this.progress();
    // this.stickyBtnActive();
    // this.stickyBtnRelative();
    // this.footerOpen();

    // 새로고침 시 스크롤 초기화
    window.onload = function () {
      setTimeout(function () {
        scrollTo(0, 0);
      }, 10);
    };

    // jQuery start
    $(function () {
      // 텍스트애니메이션01 main video <p> : span으로 쪼개서 담아주기
      let txtBox = [];
      let temp = [];
      function textAni() {
        let txtLine = [];
        $('.main-text p').each(function (index, item) {
          let txt = $(item).text();
          let tmp = { index: index, txt: txt };
          txtLine.push(tmp);
        });
        let splitTxt = [];
        txtLine.forEach((txt) => {
          splitTxt.push(txt.txt.split(' '));
        });
        $(splitTxt).each(function (index, item) {
          let txt = [];
          item.forEach((e) => {
            txt.push('<span>' + e + '</span>&nbsp;');
          });
          txtBox.push({ index: index, txt: txt });
        });
        for (var i = 0; i < txtBox.length; i++) {
          $('.main-text p').eq(i).html(txtBox[i].txt);
          $('.main-text p span').css({
            display: 'inline-block',
            opacity: 0,
            position: 'relative',
            top: '10px',
            left: '5px',
            fontSize: '1.17rem',
            lineHeight: '1.722rem',
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
          });
          $('.main-text p')
            .eq(i)
            .find('span')
            .each(function (i, item) {
              temp.push(item);
            });
        }
      }
      textAni();

      // 텍스트애니메이션01 실행
      setTimeout(() => {
        for (let index = 0; index < temp.length; index++) {
          const ele = temp[index];
          $('.main-text p')
            .eq(0)
            .animate({ opacity: 1 })
            .find($(ele))
            .delay(50 * index)
            .animate({ top: 0, opacity: 1, left: 0 }, 500);
        }
      }, 200);

      // 텍스트애니메이션02 : section-title <p>
      let txtBox2 = [];
      let temp2 = [];
      function textAni2() {
        let txtLine2 = [];
        $('.section-title p').each(function (index, item) {
          let txt = $(item).text();
          let tmp = { index: index, txt: txt };
          txtLine2.push(tmp);
        });
        let splitTxt2 = [];
        txtLine2.forEach((txt) => {
          splitTxt2.push(txt.txt.split(' '));
        });

        $(splitTxt2).each(function (index, item) {
          let txt2 = [];
          item.forEach((e) => {
            txt2.push('<span>' + e + '</span>&nbsp;');
          });
          txtBox2.push({ index: index, txt: txt2 });
        });

        for (var i = 0; i < txtBox2.length; i++) {
          $('.section-title p').eq(i).html(txtBox2[i].txt);
          $('.section-title p span').css({
            opacity: 0,
            position: 'relative',
            top: '10px',
            left: '5px',
            fontSize: '1.166rem',
            lineHeight: '1.778rem',
            wordBreak: 'keep-all',
            display: 'inline-block',
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
          });
          $('.section-title p')
            .eq(i)
            .find('span')
            .each(function (i, item) {
              temp2.push(item);
            });
        }
      }
      textAni2();

      let gifChange1 = false;
      let gifChange2 = false;
      let gifChange3 = false;

      // scroll event start
      $(window).scroll(function (e) {
        let winWidth = $(window).width();
        let sTop = $(window).scrollTop();
        let sBottom = sTop + $(window).height();

        let $text = $('.text-wrap li'),
          content = $('.content'),
          $title = $('.strategy .section-title h2 .num');
        // strategy 섹션 스크롤 이벤트
        let sectionTop = $('.strategy').offset().top;
        if (winWidth > 1023) {
          if (sTop > sectionTop - 1000) {
            $('.img-wrap').addClass('active');
          } else {
            $('.img-wrap').removeClass('active');
          }
          if (sTop > sectionTop - 100) {
            $('.section-title').addClass('active');
            $('.text-wrap').addClass('active');
            $('header-share').css('display', 'none');
          } else {
            $('.section-title').removeClass('active');
            $('.text-wrap').removeClass('active');
            $('header-share').css('display', 'block');
          }
        } else if (winWidth <= 1023) {
          if (sTop > sectionTop - 300) {
            $('.text-wrap').addClass('active');
            // $('.text').addClass('active');
          }

          if (sTop > sectionTop + 340) {
            $('.section-title').addClass('active');
            $('.img-wrap').addClass('active');
            $('.text-wrap').addClass('active');
            $('header-share').css('display', 'none');
          } else {
            $('.section-title').removeClass('active');
            $('.img-wrap').removeClass('active');
            $('.text-wrap').removeClass('active');
            $('header-share').css('display', 'block');
          }
        }
        for (var i = 0; i < content.length; i++) {
          let contentTop = content.eq(i).offset().top,
            contentBot = contentTop + content.eq(i).outerHeight();
          let txtTop = $text.eq(i).offset().top,
            txtBot = txtTop + $text.eq(i).outerHeight();
          let sequence = ['01', '02', '03'];

          // 모바일
          if (winWidth <= 1023) {
            if (i === 0) {
              // 첫번째 li에 대한 처리
              if (sTop > sectionTop + 200 && !$text.eq(2).hasClass('active')) {
                $text.eq(i).addClass('active');
              } else if ($text.eq(2).hasClass('active')) {
                $text.eq(i).removeClass('active');
              }
            } else if (
              i === 2 &&
              sTop >= contentTop - 320 &&
              txtBot <= contentBot
            ) {
              // 세번째 li에 대한 처리

              $text.removeClass('active');
              $text.eq(i).addClass('active');
            } else if (
              i !== 2 &&
              sTop >= contentTop - 300 &&
              txtBot <= contentBot
            ) {
              // 두번째 li에 대한 처리
              $text.removeClass('active');
              $text.eq(i).addClass('active');
            } else if (i !== 0 && i !== 2) {
              // 나머지 li에 대한 처리
              $text.eq(i).removeClass('active');
            }
          } else {
            // PC에서 첫 번째 li에 대한 처리
            if (i == 0) {
              if (sTop > sectionTop - 500) {
                $text.eq(i).css({
                  opacity: 1,
                  top: '8.334rem',
                  transform: 'translateY(0)',
                  transition: '0.3s ease',
                });
              }
              if (sTop >= contentTop - 240) {
                $title.text(sequence[i]);
                $text.eq(i).css({
                  top: '50%',
                  transform: 'translateY(-50%)',
                  transition: '0.3s ease 0.1s',
                });
              }
              if (txtBot - 240 >= contentBot) {
                $text.eq(i).css({
                  opacity: 0,
                  transform: 'translateY(0%)',
                  transition: '0.3s ease 0.1s',
                });
              }
            } else {
              // 나머지 li에 대한 처리
              if (sTop >= contentTop - 520 && txtBot <= contentBot) {
                $text.removeClass('active');
                $text.eq(i).addClass('active');
                $title.text(sequence[i]);
              } else {
                $text.eq(i).removeClass('active');
              }
            }
          }
        }

        // 텍스트애니메이션02 실행
        let $section = $('.section');
        for (var i = 0; i < $section.length; i++) {
          let sectionTop = $section.eq(i).offset().top;
          for (let index = 0; index < temp2.length; index++) {
            const ele = temp2[index];
            if (i == 0 && sBottom > sectionTop) {
              if (String($(ele).text()) == '사용자가') {
                $(ele).css({ fontWeight: '800' });
              } else if (String($(ele).text()) == '보다') {
                $(ele).css({ fontWeight: '800' });
              } else if (String($(ele).text()) == '쉽게') {
                $(ele).css({ fontWeight: '800' });
              } else if (String($(ele).text()) == '이해하고') {
                $(ele).css({ fontWeight: '800' });
              } else if (String($(ele).text()) == '사용할수') {
                $(ele).css({ fontWeight: '800' });
              } else if (String($(ele).text()) == '있게') {
                $(ele).css({ fontWeight: '800' });
              } else if (String($(ele).text()) == '돕는') {
                $(ele).css({ fontWeight: '800' });
              } else if (String($(ele).text()) == 'UI가') {
                $(ele).css({ fontWeight: '800' });
              } else if (String($(ele).text()) == '있습니다.') {
                $(ele).css({ fontWeight: '800' });
              }
              $('.section-title p')
                .eq(0)
                .find($(ele))
                .delay(50 * index)
                .animate({ opacity: 1, top: 0, left: 0 }, 500);
            } else if (i == 1 && sBottom + 200 > sectionTop) {
              if (String($(ele).text()) == '비즈니스') {
                $(ele).css({ fontWeight: '800' });
              } else if (String($(ele).text()) == '목표를') {
                $(ele).css({ fontWeight: '800' });
              } else if (String($(ele).text()) == '정의하고,') {
                $(ele).css({ fontWeight: '800' });
              } else if (String($(ele).text()) == '사용자의') {
                $(ele).css({ fontWeight: '800' });
              } else if (String($(ele).text()) == '요구를') {
                $(ele).css({ fontWeight: '800' });
              } else if (String($(ele).text()) == '이해하고,') {
                $(ele).css({ fontWeight: '800' });
              } else if (String($(ele).text()) == '사용자가') {
                $(ele).css({ fontWeight: '800' });
              } else if (String($(ele).text()) == '수용할수') {
                $(ele).css({ fontWeight: '800' });
              } else if (String($(ele).text()) == '있는') {
                $(ele).css({ fontWeight: '800' });
              } else if (String($(ele).text()) == '의미있는') {
                $(ele).css({ fontWeight: '800' });
              } else if (String($(ele).text()) == '환경을') {
                $(ele).css({ fontWeight: '800' });
              }
              $('.section-title p')
                .eq(1)
                .find($(ele))
                .delay(30 * index)
                .animate({ opacity: 1, top: 0, left: 0 }, 500);
            } else if (i == 2 && sBottom + 500 > sectionTop) {
              if (String($(ele).text()) == '원칙,') {
                $(ele).css({ fontWeight: '800' });
              } else if (String($(ele).text()) == '지침,') {
                $(ele).css({ fontWeight: '800' });
              } else if (String($(ele).text()) == '표준') {
                $(ele).css({ fontWeight: '800' });
              }
              $('.section-title p')
                .eq(2)
                .find($(ele))
                .delay(30 * index)
                .animate({ opacity: 1, top: 0, left: 0 }, 500);
            }
          }
        }
      }); // scroll event end
      $(window).trigger('scroll');

      // scrollTop btn
      $('footer-share')
        .find('.top_btn')
        .on('click', function (e) {
          $('html,body').animate({ scrollTop: 0 }, 500);
          return false;
        });
    }); // jQuery end
  }

  ngOnDestroy(): void {
    $(window).scroll().off();
  }

  progress() {
    const container = document.getElementById('scroll');
    const progress = document.getElementById('progress');
    window.onscroll = function () {
      var containerHeight = container.offsetHeight - window.innerHeight,
        containerPos = container.getBoundingClientRect(),
        diff = containerHeight + containerPos.top,
        progressPercentage = (diff / containerHeight) * 100,
        cssWidth = Math.floor(100 - progressPercentage);
      progress.style.width = 1 + cssWidth + '%';
    };
  }

  stickyBtnActive() {
    const stickyBtn = document.querySelector<HTMLElement>('sticky-button p');
    window.addEventListener('scroll', function () {
      let scrollY = window.scrollY;
      if (scrollY > 10) {
        stickyBtn.classList.add('text-ac');
      }
    });
  }

  stickyBtnRelative() {
    $(window).scroll(function () {
      const stickyBtn = $('sticky-button');
      let btmLineTop = $('.bottom-line').offset().top;
      let btmLineHeight = $('.bottom-line').outerHeight();
      let trigger = btmLineTop + btmLineHeight;
      let docHeight = window.scrollY + window.innerHeight;
      if (trigger <= docHeight) {
        stickyBtn.css({
          position: 'absolute',
          zIndex: '100',
        });
      } else {
        stickyBtn.css({
          position: 'fixed',
        });
      }
    });
  }

  // footerOpen() {
  //   const footer = document.querySelector<HTMLElement>('footer-share footer');
  //   const design = document.querySelector<HTMLElement>('#footer-design');
  //   const footerInner = document.querySelector<HTMLElement>('.footer-inner');
  //   const textWrap = document.querySelector<HTMLElement>(
  //     '.strategy .text-wrap'
  //   );

  //   footer.style.position = 'static';
  //   let designHeight = design.clientHeight;
  //   design.style.marginTop = `${-designHeight}px`;
  //   textWrap.style.transition = '0.5s ease 0s';

  //   window.addEventListener('scroll', () => {
  //     let $btmLine = $('.bottom-line');
  //     let $btmLineTop = $btmLine.offset().top;
  //     let $btmLineHeight = $btmLine.outerHeight();
  //     let point = $btmLineTop + $btmLineHeight;
  //     let trigger = window.scrollY + window.innerHeight;
  //     // footer 열릴 때 strategy의 마지막 텍스트가 가려져서 같이 올려줘야함
  //     if (point <= trigger + 10) {
  //       textWrap.style.top = `${-designHeight}px`;
  //       design.classList.add('active');
  //       design.style.marginTop = '0';
  //       setTimeout(() => {
  //         footerInner.style.zIndex = '100';
  //       }, 900);
  //     } else {
  //       textWrap.style.top = '0';
  //     }
  //   });
  // }
}
