<main id="main"
  (scroll)="getScrollHeight($event)">
  <section id="top-wrap"
    *ngIf="solutionAppData">
    <div class="main-img">
      <img [src]="solutionAppData.topArea.mainImgPc"
        (load)="mainImage.style.opacity = '1'"
        #mainImage
        class="main-img-pc image"
        alt="메인 이미지" />
      <img [src]="solutionAppData.topArea.mainImgMb"
        (load)="mainImage1.style.opacity = '1'"
        #mainImage1
        class="main-img-mb image"
        alt="메인 이미지" />
    </div>
    <div class="top-text-area">
      <div class="top-text-inner">
        <p *ngIf="solutionAppData.includePg"
          class="pg-badge"
          [style]="'--main:' + solutionAppData.mainColor">
          PG 결제 연동
        </p>
        <h3>{{ solutionAppData.topArea.title }}</h3>
        <p class="text-detail animation">앱 개발 어려워서, 비용이 많이 들까봐 걱정하셨나요?<br />
          이제 걱정마세요. 기간은 1개월! 비용은 3백만원!<br />
          비즈니스를 시작하는 모든 사람에 적합한 “스타터팩”</p>
        <div class="top-btns">
          <button type="button"
            class="preview-btn"
            (click)="openApp()">
            앱 미리보기
          </button>
          <button type="button"
            class="start-btn"
            [style]="'--main:' + solutionAppData.mainColor"
            (click)="solutionStart()">
            스타터팩 문의하기
          </button>
        </div>
      </div>
    </div>
  </section>

  <section id="icons-wrap"
    *ngIf="solutionAppData">
    <div *ngIf="solutionAppData.appCategoryId !== 'lQgvG6lz6sT9zuSwraUR'"
      class="container-inner not-mall">
      <h3>이렇게나 <span>쉬운 앱</span> 제작</h3>
      <p class="icons-text-detail">
        스타터팩만 선택하면 앱 등록 후 바로! <span>우리 브랜드만의 앱</span>을
        사용할 수 있어요.
      </p>
      <div class="slide-wrap">
        <div class="swiper-container icon-wrap">
          <div class="swiper-wrapper">
            <div class="swiper-slide icon01"></div>
            <div class="swiper-slide icon02"></div>
            <div class="swiper-slide icon03"></div>
            <div class="swiper-slide icon04"></div>
            <div class="swiper-slide icon05"></div>
            <div class="swiper-slide icon06"></div>
            <div class="swiper-slide icon07"></div>
            <div class="swiper-slide icon08"></div>
            <div class="swiper-slide icon09"></div>
            <div class="swiper-slide icon10"></div>
            <div class="swiper-slide icon11"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div *ngIf="solutionAppData.appCategoryId == 'lQgvG6lz6sT9zuSwraUR'"
      class="container-inner mall-article">
      <div class="text-area">
        <h3>이렇게나 <span>쉬운 앱</span> 제작</h3>
        <p class="icons-text-detail">
          스타터팩만 선택하면 앱 등록 후 바로! <span>우리 브랜드만의 앱</span>을
          사용할 수 있어요.
        </p>
      </div>

      <div class="slide-wrap">
        <div class="swiper-container design-wrap">
          <div class="swiper-wrapper">
            <div class="swiper-slide icon01"></div>
            <div class="swiper-slide icon02"></div>
            <div class="swiper-slide icon03"></div>
            <div class="swiper-slide icon04"></div>
          </div>
        </div>
      </div>

      <div class="design-btn-wrap">
        <button type="button"
          [style]="'--main:' + solutionAppData.mainColor"
          (click)="goDesignTemplate()">
          디자인 보러가기
        </button>
      </div>
    </div> -->
  </section>
  <section id="intro-wrap"
    *ngIf="solutionAppData">
    <div class="container-inner">
      <img [src]="solutionAppData.detailImages.pc"
        class="detail-img-pc"
        alt="스타터팩 상세 소개" />
      <img [src]="solutionAppData.detailImages.tablet"
        class="detail-img-tb"
        alt="스타터팩 상세 소개" />
      <img [src]="solutionAppData.detailImages.mobile"
        class="detail-img-mb"
        alt="스타터팩 상세 소개" />

      <div class="admin-slide-wrap">
        <h3>
          편리한&nbsp;<br *ngIf="windowWidth > 1200" /><span [style]="'--main:' + solutionAppData.mainColor">관리자 페이지</span>
        </h3>
        <p class="admin-text-detail">
          복잡하기만 한 관리자 페이지는 그만!<br />보기 편하고 사용은 더 편한
          좋은 <span>스타트앱의 관리자 페이지</span>
        </p>
        <div class="swiper-outer">
          <div class="swiper-container admin-swiper">
            <div class="swiper-wrapper">
              <div *ngFor="let item of solutionAppData.adminSlides; let i = index"
                class="swiper-slide"
                (click)="imgDetailPopup(i)">
                <img [src]="item.image"
                  alt="관리자 페이지 예시 이미지" />
              </div>
            </div>
          </div>
          <div class="swiper-button swiper-button-prev"></div>
          <div class="swiper-button swiper-button-next"></div>
        </div>

        <div class="admin-slide-footer">
          <div>
            <p class="admin-feature-title">
              {{ solutionAppData.adminSlides[activeIndex].title }}
            </p>
            <p class="admin-feature-desc">
              {{ solutionAppData.adminSlides[activeIndex].desc }}
            </p>
          </div>
          <!-- <button type="button"
            [style]="'--main:' + solutionAppData.mainColor"
            (click)="openAdmin()">
            관리자 페이지 체험하기
          </button> -->
        </div>
      </div>
    </div>
  </section>

  <section id="purchase-type-wrap"
    *ngIf="solutionAppData"
    class="navy-bg">
    <div class="container-inner">
      <div class="purchase-top">
        <div class="purchase-title">
          <h3>
            구매후 바로 런칭이 가능한<span> 스타터팩</span>,&nbsp;<br *ngIf="windowWidth > 767" />
          </h3>
          <p class="purchase-desc">
            스타트앱의 완성형 스타터팩이라면 부담스런 앱 구축 비용, 아낄 수
            있어요.
          </p>
        </div>
        <!-- 4.12 연희 삭제 -->
        <!-- <mat-button-toggle-group
          name="purchase"
          aria-label="purchase type"
          [(ngModel)]="purchaseType"
        >
          <mat-button-toggle value="subscribe">구독형</mat-button-toggle>
          <mat-button-toggle value="ownership">소유형</mat-button-toggle>
        </mat-button-toggle-group> -->
      </div>

      <!-- 구독형인 경우 -->
      <!-- *ngIf="purchaseType === 'subscribe'" -->
      <ng-container>
        <!-- <ng-container *ngFor="let product of productTypesData[0]?.products">
          <div class="price-wrap subscribe-wrap"
            *ngIf="product.title == '스타터팩구독'">
            <p class="product-desc">
              {{ product.desc }}
            </p>
            <h4 class="product-title">{{ product.title }}</h4>
            <ul class="price-list">
              <li *ngFor="let item of product.items"
                [class]="item.id"
                [ngClass]="{ 'same-box': item.id !== 'one-year' }">
                <p class="price-label"
                  [ngStyle]="{
                    'font-weight': item.id === 'one-year' ? '700' : '400'
                  }">
                  {{ item.month }}개월
                </p>

                <div class="price-box">
                  <ng-container *ngFor="let box of item.kinds">
                    <div [ngClass]="
                        box.id === 'single' ? 'top-area' : 'bottom-area'
                      ">
                      <div class="box-inner">
                        <div class="left-price">
                          <div *ngIf="box.discountRate"
                            class="badge">
                            {{ box.discountRate }}%
                          </div>
                          <p class="month-price"
                            [ngStyle]="{
                              'font-weight':
                                item.id === 'one-year' ? '900' : '700'
                            }">
                            {{ box.monthPrice | number : "." }}원<span class="month-text">/월</span>
                          </p>
                          <p class="total-price">
                            {{ box.totalPrice | number : "." }}원
                          </p>
                        </div>
                        <div class="icons">
                          <img *ngIf="box.id === 'both'"
                            src="assets/imgs/detailRenewal/ios-icon.png"
                            alt="iOS 아이콘" />
                          <img [src]="
                              item.id === 'one-year' && box.id === 'single'
                                ? 'assets/imgs/detailRenewal/android-icon-blue.png'
                                : 'assets/imgs/detailRenewal/android-icon.png'
                            "
                            alt="안드로이드 아이콘" />
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </li>
            </ul>
          </div>
        </ng-container>

        <p class="guide-text">
          표시된 가격은 부가세 별도로 선택한 요금제 금액이 한번에 결제됩니다.
        </p>
        <button type="button"
          class="view-btn"
          (click)="openPopup('subscribe')">
          상품 자세히 보기
        </button> -->
        <!-- </ng-container> -->

        <!-- 소유형인 경우 -->
        <!-- <ng-container *ngIf="purchaseType === 'ownership'"> -->
        <ng-container *ngFor="let product of productTypesData[1]?.products">
          <div class="price-wrap ownership-wrap">
            <p class="product-desc">
              {{ product.desc }}
            </p>
            <h4 class="product-title">{{ product.title }}</h4>

            <ul class="price-list">
              <li *ngFor="let item of product.items"
                [ngClass]="item.id + '-item'">
                <p>{{ item.totalPrice | number : "." }}원</p>
                <div class="icons">
                  <img *ngIf="item.id === 'both'"
                    src="assets/imgs/detailRenewal/ios-icon.png"
                    alt="iOS 아이콘" />
                  <img [src]="
                      item.id === 'single'
                        ? 'assets/imgs/detailRenewal/android-icon-blue.png'
                        : 'assets/imgs/detailRenewal/android-icon.png'
                    "
                    alt="안드로이드 아이콘" />
                </div>
              </li>
            </ul>
          </div>
        </ng-container>
      </ng-container>

      <p class="guide-text">표시된 가격은 부가세 별도입니다.</p>

      <!-- <p class="guide-text">
        {{
        purchaseType === "subscribe"
        ? "표시된 가격은 부가세 별도로 선택한 요금제 금액이 한번에 결제됩니다."
        : "표시된 가격은 부가세 별도입니다."
        }}
      </p> -->

      <button type="button"
        class="view-btn"
        (click)="openPopup('ownership')">
        상품 자세히 보기
      </button>
    </div>
  </section>

  <section id="function-list-wrap"
    *ngIf="functionListData"
    class="navy-bg">
    <div class="container-inner">
      <h3>
        기능 고민 하지 마세요.<br />앱 전문가 스타트앱이
        <span>필요한 기능은 모두</span> 담았어요.
      </h3>
      <p class="sub-title">쇼핑몰 스타터팩기능 한눈에 보기</p>

      <mat-accordion>
        <mat-expansion-panel *ngFor="let panel of functionListData">
          <mat-expansion-panel-header>
            <mat-panel-title>{{ panel.title }}</mat-panel-title>
          </mat-expansion-panel-header>
          <div *ngFor="let item of panel.functionList"
            class="panel-content">
            <p class="content-title">{{ item.title }}</p>
            <p class="content-desc">{{ item.desc }}</p>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </section>

  <section id="feature-wrap"
    class="navy-bg">
    <div class="container-inner">
      <h3>스타터팩<span>특징</span></h3>
      <ul class="feature-list">
        <li>
          <p class="invent">코드 관리가 쉬워요!</p>
          <p class="feature-title">하이브리드 방식 개발</p>
          <p class="feature-desc">
            구글 안드로이드와 애플 iOS를 한번에 관리할 수 있는 하이브리드 개발
            방식으로 쉽고 빠르게 코드 변경 및 적용이 가능해요.
          </p>
        </li>
        <li>
          <p class="invent">앱을 성장시키는</p>
          <p class="feature-title">간편하지만 강력한 관리 시스템</p>
          <p class="feature-desc">
            스타트앱의 관리자 페이지는 복잡하지 않아요. 필요한 기능만 담은
            직관적인 디자인으로 쉽고 편리하게 앱을 관리할 수 있어요. 꾸준한
            데이터와 컨텐츠 관리로 사용성을 높이고 사용자를 확보하세요.
          </p>
        </li>
        <li>
          <p class="invent">앱 이용률을 높여주는</p>
          <p class="feature-title">푸시 기능</p>
          <p class="feature-desc">
            좋은 푸시 메시지는 앱의 이용률을 높이는 가장 효과적인 전략이에요!
            사용자와 관리자 양측에 푸시 기능을 제공하여 긍정적인 커뮤니케이션이
            가능해요
          </p>
        </li>
      </ul>
    </div>
  </section>

  <section id="review-wrap"
    *ngIf="reviewData">
    <div class="container-inner">
      <h3>고객 후기</h3>

      <div class="swiper-container review-swiper">
        <div class="swiper-wrapper">
          <ng-container *ngFor="let item of reviewData">
            <div class="swiper-slide">
              <div class="slide-inner">
                <div class="name-area">
                  <div *ngIf="item.avatar"
                    class="avatar">
                    <img [src]="item.avatar"
                      alt="" />
                  </div>
                  <p class="name">{{ item.name }}</p>
                  <span>&nbsp;・&nbsp;</span>
                  <p class="app-name">{{ item.appName }}</p>
                </div>
                <div class="rating">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <p class="content">" {{ item.content }} "</p>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </section>

  <section id="qna-wrap"
    *ngIf="qnaData"
    class="sky-bg">
    <div class="container-inner">
      <h3>자주 묻는 질문</h3>

      <mat-accordion class="white-accordion">
        <mat-expansion-panel *ngFor="let panel of qnaData">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="title-wrap">
                <p>Q.</p>
                <p>
                  {{ panel.question }}
                </p>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="panel-content">
            <p>A.</p>
            <p class="content-desc">{{ panel.answer }}</p>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </section>

  <footer-share id="footerWrap"></footer-share>
  <footer-share class="mobile-footer"></footer-share>

  <!-- <ng-container *ngIf="windowWidth <= 1200 || isShowBtn"> -->
  <div class="fixed-btn-area"
    *ngIf="solutionAppData"
    [ngClass]="{ show: isShowBtn }">
    <div class="container-inner">
      <button type="button"
        (click)="solutionStart()"
        [style]="'--main:' + solutionAppData.mainColor">
        스타터팩 문의하기
      </button>
    </div>
  </div>
  <!-- </ng-container> -->
</main>