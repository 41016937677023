<main id="main"
  style="background: #f3f5f9">
  <section id="form-section"
    class="bg-w">
    <div class="w1200 top">
      <h2>
        누구나, 쉽고 간편하게,<br />원하는 스타터팩을 <span>선택</span>하면
        <span>앱 만들기 끝!</span>
      </h2>
      <p>
        앱 전문가 스타트앱이 제공하는 다양한 스타터팩중 마음에 드는 상품을
        선택만 하세요.
      </p>
    </div>
  </section>
  <section id="form-section"
    class="sticky">
    <div class="w1200 cate">
      <div class="category"
        [ngClass]="{ open: categoryView }">
        <ng-container *ngIf="dataService.solutionAppCategories">
          <div class="category-item">
            <p class="cate-tit">스타터팩상품</p>

            <mat-button-toggle-group name="solution"
              aria-label="solution type"
              [(ngModel)]="solutionType"
              (change)="changeFilter()"
              class="toggle-category">
              <mat-button-toggle value="all">
                <p>스타터팩전체</p>
              </mat-button-toggle>
              <mat-button-toggle value="{{ item.id }}"
                *ngFor="let item of dataService.solutionAppCategories">
                <p>{{ item.title }}</p>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <!-- <div class="category-item">
            <p class="cate-tit">PG 결제</p>

            <mat-button-toggle-group name="pg"
              aria-label="pg type"
              [(ngModel)]="includePG"
              (change)="changeFilter()"
              class="toggle-category one">
              <mat-button-toggle [value]="'all'">
                <p>전체</p>
              </mat-button-toggle>
              <mat-button-toggle [value]="'pg'">
                <p>기능 있음</p>
              </mat-button-toggle>
              <mat-button-toggle [value]="'none'">
                <p>기능 없음</p>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div> -->
        </ng-container>
        <button type="button"
          class="cate-button"
          (click)="viewChange()">
          <div class="img-wrap">
            <img src="./assets/imgs/solution-cate-arrow.png"
              alt="" />
          </div>
        </button>
      </div>
    </div>
  </section>
  <section id="form-section category-list"
    class="category-list">
    <div class="w1200"
      *ngIf="selectApps$ | async as apps">
      <div class="product-list">
        <div class="product"
          *ngFor="let item of apps; let i = index">
          <!-- 슬라이드 -->
          <div class="slide-wrap">
            <div class="swiper-container product-swiper{{ i }}">
              <div class="swiper-wrapper">
                <div class="swiper-slide"
                  *ngFor="let image of item.mainImage">
                  <img src="{{ image }}"
                    (click)="pdDetail(item.id)"
                    (load)="listImage.style.opacity = '1'"
                    #listImage
                    class="image"
                    alt="상품이미지" />
                </div>
              </div>
            </div>
          </div>

          <!-- 호버 시 나오는 설명, 버튼 -->
          <div class="hover-before">
            <div class="inner">
              <p class="tit">
                {{ dataService.getCategoryTitleById(item.appCategoryId) }}
              </p>
              <p class="type">
                {{ item.title }}
              </p>
            </div>
            <!-- 슬라이드 이동 -->
            <div class="slide-change">
              <div class="swiper-button-prev button-prev{{ i }}"></div>
              <div class="swiper-button-next button-next{{ i }}"></div>
            </div>
            <!-- 하단 버튼 -->
            <div class="product-btns">
              <button type="button"
                class="button"
                (click)="openApp(item)">
                앱 미리보기
              </button>

              <button type="button"
                class="button"
                (click)="pdDetail(item.id)">
                스타터팩 상세 보러가기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <footer-share id="footerWrap"></footer-share>
  <footer-share class="mobile-footer"></footer-share>
</main>