<div class="pop-wrap">
  <div class="swiper-container">
    <div class="swiper-wrapper">
      <div class="swiper-slide slide1"
        (click)="goshopping()">
        <img src="assets/imgs/pop-img1.png"
          alt="쇼핑몰 50% 지금이 어느때인데 비싸게 쇼핑몰 만드니? 딱 절반만 내고 쇼핑몰 차리세요"
          title="쇼핑앱 바로 구매하기" />
        <div>
          <!-- <button>바로가기</button> -->
        </div>
      </div>
      <div class="swiper-slide slide2"
        (click)="gostore()">
        <img src="assets/imgs/pop-img2.png"
          alt="스타트앱 스토어 open 쉽고 빠르고 저렴하게 만드는 앱. 앱 만드는데, 클릭 한번이면 끝난다고? 기본 플랫폼으로 간단하게 앱을 만들어보세요"
          title="바로 시작하기" />
        <div>
          <!-- <button>바로가기</button> -->
        </div>
      </div>
      <div class="swiper-slide slide3"
        (click)="gocommunity()">
        <img src="assets/imgs/pop-img3.png"
          alt="스타트앱 스토어 OPEN EVENT 커뮤니티 앱 3백만원으로 끝내기 5000000원 할인 30000000원"
          title="바로 구매하기" />
        <div>
          <!-- <button>바로가기</button> -->
        </div>
      </div>
    </div>
    <div class="pop-pagination-wrap">
      <div class="swiper-pagination"></div>
    </div>
  </div>
  <div class="bottom-box">
    <p class="store-checkbox">
      <label for="today"
        [ngClass]="{ checked: todayClose }">오늘 하루 읽지 않음</label>
      <input type="checkbox"
        name="today"
        id="today"
        [(ngModel)]="todayClose" />
    </p>
    <button (click)="close()">닫기</button>
  </div>
</div>