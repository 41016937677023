<div class="wrap">
  <div class="back"></div>
  <img class="mocup"
    src="assets/imgs/221123_S-Tore_mock-up.png"
    alt="핸드폰 목업" />

  <button (click)="close()">
    <img src="assets/imgs/close-icon.png"
      alt="" />
    <span class="close-txt">닫기</span>
  </button>
  <div class="inner">
    <iframe id="site"
      src="https://startapp-store-mall.web.app"
      frameborder="0"
      (load)="onMyFrameLoad(this)"></iframe>
  </div>
</div>
<div class="load-wrap"
  [ngClass]="{ active: loading }">
  <p class="loading-icon">
    <img src="assets/imgs/loading.gif"
      alt="" />
  </p>

  <p class="logo-icon">
    <img src="assets/imgs/logo.png"
      alt="" />
  </p>
</div>