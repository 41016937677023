<div class="wrap">
  <div class="back"></div>

  <button (click)="close()">
    <img src="assets/imgs/close-icon.png"
      alt="" />
    <span class="close-txt">닫기</span>
  </button>

  <div class="inner">
    <iframe id="site"
      src="{{adminUrl}}"
      frameborder="0"
      (load)="onMyFrameLoad(this)"></iframe>
  </div>
</div>
<!-- <div class="load-wrap" [ngClass]="{ active: loading }">
  <p class="loading-icon">
    <img src="assets/imgs/loading.gif" alt="" />
  </p>

  <p class="logo-icon">
    <img src="assets/imgs/logo.png" alt="" />
  </p>
</div> -->