<main id="main">
  <!-- 메인 -->
  <section class="page-main">
    <div class="inner w1200">
      <p class="top-info">앱 런칭 아직도 긴 시간 큰 금액 쓰세요?</p>
      <h2>
        스타트앱의 스타터팩이면<br />
        <p class="big-txt">
          단 몇 주만에
          <span>나만의 <br class="max-view767" />
            앱 서비스</span>
          완성!
        </p>
      </h2>
    </div>

    <div class="po">
      <div class="frame">
        <img src="./assets/imgs/sol-mo-frame.png"
          #frameImage
          class="image"
          (load)="frameImage.style.opacity = '1'"
          alt="메인 슬라이드 모바일" />
      </div>

      <div class="main-img">
        <div class="swiper-outer">
          <div class="swiper-container main-swiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <!--   (load)="image.style.opacity = '1'" -->
                <img src="./assets/imgs/sol-main01.png"
                  #image
                  class="image"
                  alt="mall" />
              </div>
              <div class="swiper-slide">
                <img src="./assets/imgs/sol-main02.png"
                  alt="커뮤니티" />
              </div>
              <div class="swiper-slide">
                <img src="./assets/imgs/sol-main03.png"
                  alt="예약앱" />
              </div>
              <div class="swiper-slide">
                <img src="./assets/imgs/sol-main04.png"
                  alt="유저 목록" />
              </div>
            </div>
          </div>
          <div class="swiper-button swiper-button-prev"></div>
          <div class="swiper-button swiper-button-next"></div>
        </div>
      </div>
    </div>
  </section>

  <!-- 파란 배경 :: 스타트앱 스타터팩이란 -->
  <section class="blue-que-box">
    <div class="inner w1200">
      <h3>
        지금껏 경험 못 했던 쉽고<br class="max-view767" />
        간편한 앱 제작.<br />
        <p class="big-txt">스타터팩이란?</p>
      </h3>
      <p class="txt-info">
        스타트앱은 '<span>누구나 쉽고 간단하게 앱 런칭을 할 수 없을까?</span>'하는 의문에서 스타터팩* 서비스를 시작했어요.<br />
        스타트앱의 풍부한 앱 제작 경험을 담아 앱별로 필요한 기능을 모두 담은
        <span>완성형 스타터팩.</span><br />
        기능, 기간, 비용 고민할 필요 없이 원하는 스타터팩을
        <span>선택만 하면 앱 런칭 끝!</span>
        쉽고 간편한 앱 제작, 이제 스타터팩으로 시작하세요.
      </p>

      <p class="info-gray">
        * 스타터팩 : 특정 상황에 대한 해결책으로 사용자 요구에 따라 프로그램과
        관련된 문제들을 처리해주는 소프트웨어나 기술 등을 의미합니다.
      </p>
    </div>
  </section>

  <!-- 하얀 배경 숫자 -->
  <section class="solution-step-box">
    <div class="inner w1200">
      <h3>
        앱 서비스의 새로운 경험.<br />
        <p class="big-txt">
          <span>스타트앱의 스타터팩</span>은<br class="max-view1200" />
          이렇게나 달라요!
        </p>
      </h3>
      <!-- 1 -->
      <div class="sol-step step01">
        <div class="left">
          <div class="step-num">
            <img src="./assets/imgs/sol-step1.png"
              alt="1" />
          </div>
          <h4 class="step-tit">
            앱 런칭 기간은 <span>짧게</span>,<br class="max-view767" />
            비용은 <span>적게</span>.
          </h4>
          <p class="con-txt">
            앱 제작부터 스토어 등록까지는 통상
            <span>5개월 이상의 기간</span>과 <br />
            <span>수천만원의 비용</span>이 소모됩니다. 하지만 스타트앱 앱
            스타터팩이라면<br />
            다양한 스타터팩 중 선택만 하면 끝이니까
            <span>앱 제작 기간은 1/5로,<br />
              앱 제작 비용은 비교도 할 수 없게</span>
            줄어들어요!
          </p>
        </div>

        <!-- 차트 -->
        <div class="right">
          <div class="chart-tit">앱 런칭 기간 ・ 비용 비교</div>
          <!-- <div class="chart-info">
            * 설치형 상품, 안드로이드 기준, 12개월 계약 시
          </div> -->

          <div class="vertical-chart-box">
            <div class="chart-box">
              <ul class="axis_y">
                <li class="item"></li>
                <li class="item"></li>
                <li class="item"></li>
                <li class="item"></li>
                <li class="item"></li>
                <li class="item"></li>
                <li class="item"></li>
              </ul>
              <ul class="axis_x">
                <li class="item">
                  <div class="text_box">
                    <span class="x-text blue">
                      런칭기간 <span>한달</span> 이내 <br />
                      구매비용 <span>3백만원</span>*
                    </span>
                  </div>
                  <div class="graph-img">
                    <img src="./assets/imgs/chart-left-blue.png"
                      alt="런칭기간 한달 이내 월 요금 2만원*" />
                  </div>
                </li>
                <li class="item">
                  <div class="text_box">
                    <span class="x-text">
                      런칭 기간 <span>5개월</span> 이상<br />
                      개발 비용 <span>3천~5천</span>만원
                    </span>
                  </div>
                  <div class="graph-img">
                    <img src="./assets/imgs/chart-right-gray.png"
                      alt="런칭 기간 5개월 이상 개발 비용 3천~5천만원" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- 2 -->
      <div class="sol-step step02">
        <div class="top-flex">
          <div class="step-num">
            <img src="./assets/imgs/sol-step2.png"
              alt="2" />
          </div>
          <div>
            <h4 class="step-tit">
              우리 앱에 <span>어떤 기능</span>이<br class="max-view767" />
              필요하지?<br class="max-view1200-none" />
              고민하지 마세요.
            </h4>
            <p class="con-txt">
              앱 전문가 스타트앱이 앱 특성에 맞게 군더더기는 덜어내고
              <span>필요한 기능은 모두</span> 담았어요.
            </p>
          </div>
        </div>

        <div class="content-mini-list">
          <div class="content-mini-box box-2"
            (click)="appSolution('pakroRE2x6P8H7LusGxc')">
            <div class="text">
              <p class="title-info">간편하고 편리한</p>
              <p class="title-bold">예약 스타터팩</p>
            </div>
          </div>
          <div class="content-mini-box box-1"
            (click)="appSolution('PkUd3YtocUKE9NWCHCeD')">
            <div class="text">
              <p class="title-info">활발히 소통하고 이어지는</p>
              <p class="title-bold">커뮤니티 스타터팩</p>
            </div>
          </div>
          <div class="content-mini-box"
            (click)="appSolution('lQgvG6lz6sT9zuSwraUR')">
            <div class="text">
              <p class="title-info">당신의 성공 스토리가 시작 될</p>
              <p class="title-bold">쇼핑몰 스타터팩</p>
            </div>
          </div>
          <div class="content-mini-box"
            (click)="appSolution('FkyLv4TU4Acgm8G7IUvV')">
            <div class="text">
              <p class="title-info">우리만의 이야기를 나눌</p>
              <p class="title-bold">채팅 스타터팩</p>
            </div>
          </div>
          <div class="content-mini-box">
            <div class="text-btn">
              <p>
                <span>쇼핑몰, 커뮤니티, 예약, 채팅</span> 앱 등,<br />
                스타트앱의 다채로운 <span>스타터팩</span> 중<br />
                <span>선택</span>만 하세요.
              </p>

              <button class="full-blk-btn"
                (click)="appSolution()"
                fill="default">
                스타터팩제품 보기
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- 3 -->
      <div class="sol-step step03">
        <div class="container-inner">
          <div class="step-num">
            <img src="./assets/imgs/sol-step3.png"
              alt="3" />
          </div>
          <div>
            <div>
              <h4 class="step-tit">
                복잡하고 비싼 앱 개발이 걱정이라면? <br class="max-view1200" />
                <span>3백만 원</span>으로 나만의 앱을 만들 수 있습니다.
              </h4>
              <div class="toggle-flex">
                <p class="con-txt">
                  3백만원*부터 시작하는 <span>스타터팩구매</span>
                  <span>상황에 맞게 상품을 선택</span>하고, <br />
                  <span>부담없는 가격에 나만의 앱</span>을 런칭할 수 있어요.
                </p>

                <!-- 4.12 연희 삭제 -->
                <!-- <mat-button-toggle-group
                  name="purchase"
                  aria-label="purchase type"
                  [(ngModel)]="purchaseType"
                >
                  <mat-button-toggle value="subscribe"
                    >구독형</mat-button-toggle
                  >
                  <mat-button-toggle value="ownership"
                    >소유형</mat-button-toggle
                  >
                </mat-button-toggle-group> -->
              </div>
            </div>
          </div>

          <!-- 구독형인 경우 -->
          <!--  *ngIf="purchaseType === 'subscribe'" -->
          <ng-container>
            <!-- <ng-container *ngFor="let product of productTypesData[0]?.products">
              <div class="price-wrap subscribe-wrap"
                *ngIf="product.title == '스타터팩구독'">


                <p class="product-desc min-view1200"
                  *ngIf="product.title == '스타터팩구독'">
                  <span>월 사용료</span>만 내고 사용해요.
                </p>

                <p class="product-desc max-view1200">
                  {{ product.desc }}
                </p>

                <h4 class="product-title">{{ product.title }}</h4>
                <ul class="price-list">
                  <li *ngFor="let item of product.items"
                    [class]="item.id"
                    [ngClass]="{ 'same-box': item.id !== 'one-year' }">
                    <p class="price-label"
                      [ngStyle]="{
                        'font-weight': item.id === 'one-year' ? '700' : '400'
                      }">
                      {{ item.month }}개월
                    </p>

                    <div class="price-box">
                      <ng-container *ngFor="let box of item.kinds">
                        <div [ngClass]="
                            box.id === 'single' ? 'top-area' : 'bottom-area'
                          ">
                          <div class="box-inner">
                            <div class="left-price">
                              <div *ngIf="box.discountRate"
                                class="badge">
                                {{ box.discountRate }}%
                              </div>
                              <p class="month-price"
                                [ngStyle]="{
                                  'font-weight':
                                    item.id === 'one-year' ? '900' : '700'
                                }">
                                {{ box.monthPrice | number : "." }}원~<span class="month-text">
                                  /월
                                </span>
                              </p>
                              <p class="total-price">
                                {{ box.totalPrice | number : "." }}원~
                              </p>
                            </div>
                            <div class="icons">
                              <img *ngIf="box.id === 'both'"
                                [src]="
                                  item.month == '12'
                                    ? 'assets/imgs/detailRenewal/ios-icon.png'
                                    : 'assets/imgs/detailRenewal/ios-icon-line.png'
                                "
                                alt="iOS 아이콘" />

                              <img [src]="
                                  item.month == '12'
                                    ? 'assets/imgs/detailRenewal/android-icon.png'
                                    : 'assets/imgs/detailRenewal/android-icon-line.png'
                                "
                                alt="안드로이드 아이콘" />
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </li>
                </ul>
              </div>
            </ng-container> -->
            <!-- </ng-container> -->

            <!-- <p class="guide-text">
              표시된 가격은 부가세 별도로 선택한 요금제 금액이 한번에
              결제됩니다.
            </p>

            <button type="button"
              class="view-btn"
              (click)="openPopup('subscribe')">
              상품 자세히 보기
            </button> -->

            <!-- 소유형인 경우 -->
            <!-- <ng-container *ngIf="purchaseType === 'ownership'"> -->
            <ng-container *ngFor="let product of productTypesData[1]?.products">
              <div class="price-wrap ownership-wrap">
                <p class="product-desc">
                  {{ product.desc }}
                </p>
                <h4 class="product-title">{{ product.title }}</h4>

                <ul class="price-list">
                  <li *ngFor="let item of product.items"
                    [ngClass]="item.id + '-item'">
                    <p class="price-p">
                      {{ item.totalPrice | number : "." }}원~
                    </p>
                    <div
                      class="icons"
                      [ngClass]="{ bothItem: item.id === 'both' }"
                    >
                      <div class="yr-wrap">
                        <img [src]="
                            item.id === 'single'
                              ? 'assets/imgs/detailRenewal/android-icon-blue-re.png'
                              : 'assets/imgs/detailRenewal/android-icon-re.png'
                          "
                          alt="안드로이드 아이콘" />
                        <p>AOS(안드로이드)</p>
                      </div>

                      <div class="yr-wrap mt-8">
                        <img *ngIf="item.id === 'both'"
                          src="assets/imgs/detailRenewal/ios-icon-apple.png"
                          alt="iOS 아이콘" />
                        <p *ngIf="item.id === 'both'">IOS(애플)</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </ng-container>
          </ng-container>

          <p class="guide-text">표시된 가격은 부가세 별도입니다.</p>

          <button type="button"
            class="view-btn"
            (click)="openPopup('ownership')">
            상품 자세히 보기
          </button>
        </div>
      </div>

      <!-- 4 -->
      <div class="sol-step step04">
        <div class="left">
          <div class="step-num">
            <img src="./assets/imgs/sol-step4.png"
              alt="4" />
          </div>
          <h4 class="step-tit">
            개발자, 디자이너 없이<br />
            <span>혼자서도 앱을 운영</span>할 수 있어요.
          </h4>
          <p class="con-txt">
            앱 특성에 맞게 제작된 스타트앱의 완성형 스타터팩이니까<br />
            코딩, 포토샵 몰라도 <span>누구나 앱을 운영</span>할 수 있어요.<br />
            스타트앱이 <span>지속적인 점검과 업데이트</span>를 하니 물론
            유지보수도<br />
            걱정할 것 없어요.
            <span>비싼 앱 구축, 운영 비용 아끼고</span> 브랜딩에만 집중하세요!
          </p>
        </div>

        <div class="right">
          <div class="swiper-outer">
            <div class="swiper-container step04-swiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="swiper-img-wrap">
                    <img src="./assets/imgs/sol-step04-01.png"
                      alt="" />
                  </div>
                  <p>
                    앱 구축 ・ 운영<br />
                    비용 절감
                  </p>
                </div>
                <div class="swiper-slide">
                  <div class="swiper-img-wrap">
                    <img src="./assets/imgs/sol-step04-03.png"
                      alt="" />
                  </div>
                  <p>
                    누구나 가능한<br />
                    앱 운영
                  </p>
                </div>
                <div class="swiper-slide">
                  <div class="swiper-img-wrap">
                    <img src="./assets/imgs/sol-step04-02.png"
                      alt="" />
                  </div>
                  <p>
                    지속적인<br />
                    점검 ・ 업데이트
                  </p>
                </div>
              </div>
            </div>
            <div class="swiper-button swiper-button-prev"></div>
            <div class="swiper-button swiper-button-next"></div>
          </div>
        </div>
      </div>

      <!-- 5 -->
      <div class="sol-step step05 admin-slide-wrap">
        <div class="top-flex">
          <div class="step-num">
            <img src="./assets/imgs/sol-step5.png"
              alt="5" />
          </div>
          <div>
            <h4 class="step-tit">
              관리자 페이지, 왜 배워야 쓸 수 있죠?<br />
              너무나도
              <span>쉬운 관리자 페이지</span>
            </h4>
            <p class="con-txt">
              복잡하기만 한 관리자 페이지는 그만! 보기 편하고 사용은 더 편한
              <span>스타트앱의 관리자 페이지</span>로 앱을 더욱
              <span>효율적으로</span>
              운영하세요.
            </p>
          </div>
        </div>

        <div class="swiper-outer">
          <div class="swiper-container admin-swiper">
            <div class="swiper-wrapper">
              <div *ngFor="let item of renewalData.adminHomeSlides; let i = index"
                class="swiper-slide"
                (click)="imgDetailPopup(i)">
                <img [src]="item.image"
                  alt="관리자 페이지 예시 이미지" />
              </div>
            </div>
          </div>
          <div class="swiper-button swiper-button-prev"></div>
          <div class="swiper-button swiper-button-next"></div>
        </div>

        <!-- <div class="admin-slide-footer">
          <button type="button"
            (click)="openAdmin()">
            관리자 페이지 체험하기
          </button>
        </div> -->
      </div>
    </div>
  </section>

  <!-- 자주 묻는 질문 -->
  <section id="qna-wrap">
    <div class="container-inner">
      <h3>자주 묻는 질문</h3>

      <mat-accordion class="white-accordion">
        <mat-expansion-panel *ngFor="let panel of qnaData">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="title-wrap">
                <p>Q.</p>
                <p>
                  {{ panel.question }}
                </p>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="panel-content">
            <p>A.</p>
            <p class="content-desc">{{ panel.answer }}</p>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </section>

  <!-- 고객 후기 -->
  <section id="review-wrap">
    <div class="container-inner">
      <h3>고객 후기</h3>

      <div class="swiper-container reivew-swiper">
        <div class="swiper-wrapper">
          <ng-container *ngFor="let item of reviewData">
            <div class="swiper-slide">
              <div class="slide-inner">
                <div class="name-area">
                  <p class="name">{{ item.name }}</p>
                  <span>&nbsp;・&nbsp;</span>
                  <p class="app-name">{{ item.appName }}</p>
                </div>
                <div class="rating">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <p class="content">" {{ item.content }} "</p>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </section>

  <!-- 포트폴리오 -->
  <section class="back-blk">
    <div class="inner w1200">
      <div class="inner-text w1200">
        <h3>
          5년간 <span>400건 이상</span> 앱 제작<br />
          다양한 분야의 멋진 브랜드들이 <br class="max-view1200" />
          <span>스타트앱과 함께</span> 하고 있습니다.
        </h3>

        <p>
          풍부한 앱 제작 경험을 바탕으로 탄생한 스타트앱 스타터팩,<br />
          이제 그 멋진 경험을 함께 하세요!
        </p>
      </div>

      <div class="sol-mockup w1200">
        <div class="icon hanra">
          <img src="./assets/imgs/sol-portfolio-hanra.png"
            alt="한라 이미지" />
        </div>
        <div class="icon heart">
          <img src="./assets/imgs/sol-portfolio-heart.png"
            alt="한라 이미지" />
        </div>
        <ul class="portfolio-list">
          <li class="default-li"
            (click)="goHanra()">
            <img class="mo-mockup"
              src="./assets/imgs/sol-mockup.png"
              alt="프레임" />
            <div class="port-inner">
              <img src="./assets/imgs/sol-portfolio01.png"
                alt="한라 이미지" />
              <div class="hover-blk">
                <p class="tit">한라</p>
                <p class="info">
                  영상으로 공부하는<br />
                  학습 플랫폼
                </p>

                <button>자세히 보기 ></button>
              </div>
            </div>

            <div class="btminfo">
              <p class="tit">한라</p>
              <p class="info">영상으로 공부하는 학습 플랫폼</p>
            </div>
          </li>
          <li class="mini-li"
            (click)="goTherapeople()">
            <img class="mo-mockup"
              src="./assets/imgs/sol-mockup.png"
              alt="프레임" />
            <div class="port-inner">
              <img src="./assets/imgs/sol-portfolio02.png"
                alt="테라피플 이미지" />
              <div class="hover-blk">
                <p class="tit">테라피플</p>
                <p class="info">
                  전문 치료사와 1:1<br />
                  상담 서비스
                </p>

                <button>자세히 보기 ></button>
              </div>
            </div>
            <div class="btminfo">
              <p class="tit">테라피플</p>
              <p class="info">전문 치료사와 1:1 상담 서비스</p>
            </div>
          </li>
          <li class="mini-li row"
            (click)="goBuoost()">
            <img class="mo-mockup"
              src="./assets/imgs/sol-mockup.png"
              alt="프레임" />
            <div class="port-inner">
              <img src="./assets/imgs/sol-portfolio03.png"
                alt="Buoost 이미지" />
              <div class="hover-blk">
                <p class="tit">Buoost</p>
                <p class="info">영양상태 기반 알맞는 건강식품 추천 쇼핑몰 앱</p>

                <button>자세히 보기 ></button>
              </div>
            </div>

            <div class="btminfo">
              <p class="tit">Buoost</p>
              <p class="info">영양상태 기반 알맞는 건강식품 추천 쇼핑몰 앱</p>
            </div>
          </li>

          <li class="default-li row"
            (click)="goDoor()">
            <img class="mo-mockup"
              src="./assets/imgs/sol-mockup.png"
              alt="프레임" />
            <div class="port-inner">
              <img src="./assets/imgs/sol-portfolio04.png"
                alt="도어 이미지" />
              <div class="hover-blk">
                <p class="tit">도어</p>
                <p class="info">위치기반 익명채팅 서비스</p>

                <button>자세히 보기 ></button>
              </div>
            </div>
            <div class="btminfo">
              <p class="tit">도어</p>
              <p class="info">위치기반 익명채팅 서비스</p>
            </div>
          </li>

          <li class="default-li"
            (click)="goVanilla()">
            <img class="mo-mockup"
              src="./assets/imgs/sol-mockup.png"
              alt="프레임" />
            <div class="port-inner">
              <img src="./assets/imgs/sol-portfolio05.png"
                alt="바닐라 치과 이미지" />
              <div class="hover-blk">
                <p class="tit">바닐라 치과</p>
                <p class="info">
                  치과 진료예약 관리 및<br />
                  고객관리 앱
                </p>

                <button>자세히 보기 ></button>
              </div>
            </div>

            <div class="btminfo">
              <p class="tit">바닐라 치과</p>
              <p class="info">치과 진료예약 관리 및 고객관리 앱</p>
            </div>
          </li>

          <li class="mini-li"
            (click)="goGanpyeonganpan()">
            <img class="mo-mockup"
              src="./assets/imgs/sol-mockup.png"
              alt="프레임" />
            <div class="port-inner">
              <img src="./assets/imgs/sol-portfolio06.png"
                alt="간편간판 이미지" />
              <div class="hover-blk">
                <p class="tit">간편간판</p>
                <p class="info">O2O 간판 서비스</p>

                <button>자세히 보기 ></button>
              </div>
            </div>

            <div class="btminfo">
              <p class="tit">간편간판</p>
              <p class="info">O2O 간판 서비스</p>
            </div>
          </li>

          <li class="mini-li"
            (click)="goHeartFriend()">
            <img class="mo-mockup"
              src="./assets/imgs/sol-mockup.png"
              alt="프레임" />
            <div class="port-inner">
              <img src="./assets/imgs/sol-portfolio07.png"
                alt="마음벗 이미지" />
              <div class="hover-blk">
                <p class="tit">마음벗</p>
                <p class="info">자살 예방 서비스</p>

                <button>자세히 보기 ></button>
              </div>
            </div>
            <div class="btminfo">
              <p class="tit">마음벗</p>
              <p class="info">자살 예방 서비스</p>
            </div>
          </li>

          <li class="mini-li row"
            (click)="goCarryIn()">
            <img class="mo-mockup"
              src="./assets/imgs/sol-mockup.png"
              alt="프레임" />
            <div class="port-inner">
              <img src="./assets/imgs/sol-portfolio08.png"
                alt="Carry In 이미지" />
              <div class="hover-blk">
                <p class="tit">Carry In</p>
                <p class="info">캐나다, 한국 커뮤니티 서비스</p>

                <button>자세히 보기 ></button>
              </div>
            </div>
            <div class="btminfo">
              <p class="tit">Carry In</p>
              <p class="info">캐나다, 한국 커뮤니티 서비스</p>
            </div>
          </li>

          <li class="default-li"
            (click)="goMobilityF()">
            <img class="mo-mockup"
              src="./assets/imgs/sol-mockup.png"
              alt="프레임" />
            <div class="port-inner">
              <img src="./assets/imgs/sol-portfolio09.png"
                alt="경기도 이동편의시설 점검 시스템 이미지" />
              <div class="hover-blk">
                <p class="tit">
                  경기도<br />
                  이동편의시설<br />
                  점검 시스템
                </p>
                <p class="info">
                  경기도이동편의시설<br />
                  기술지원센터 직원용<br />
                  현장점검 프로그램
                </p>

                <button>자세히 보기 ></button>
              </div>
            </div>
            <div class="btminfo">
              <p class="tit">경기도 이동편의시설 점검 시스템</p>
              <p class="info">
                경기도이동편의시설 기술지원센터 직원용 현장점검 프로그램
              </p>
            </div>
          </li>
        </ul>
      </div>

      <div class="viewbtn">
        <button type="button"
          (click)="goPortfolio()">고객 사례 더보기</button>
      </div>
    </div>
  </section>
</main>
<footer-share id="footerWrap"></footer-share>
<footer-share class="mobile-footer"></footer-share>