import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swiper from 'swiper';
import SwiperCore, { Navigation, Pagination } from 'swiper/core';
SwiperCore.use([Navigation, Pagination]);

@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.scss'],
})
export class PopUpComponent implements OnInit {
  todayClose: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<PopUpComponent>,
    private router: Router
  ) {}

  ngOnInit(): void {
    const swiper = new Swiper('.swiper-container', {
      // loop: true,
      autoplay: {
        delay: 5000,
      },
      pagination: {
        el: '.swiper-pagination',
      },
    });
  }

  close() {
    if (this.todayClose) {
      this.dialogRef.close('todayClose');
    } else {
      this.dialogRef.close();
    }
  }

  goshopping() {
    this.dialogRef.close();
    this.router.navigateByUrl('/store-detail/shopping').then(() => {
      // //window.location.reload();
    });
  }
  gostore() {
    this.dialogRef.close();
    this.router.navigateByUrl('/s-tore').then(() => {
      // //window.location.reload();
    });
  }
  gocommunity() {
    this.dialogRef.close();
    this.router.navigateByUrl('store-detail/community').then(() => {
      // //window.location.reload();
    });
  }
}
