<main id="main">
    <div class="wrap">
        <section class="complete w1180 cf"
            *ngIf="checkFooter()">
            <h2 class="title">
                구매하기
                <p>
                    구매를 요청해주셔서 감사합니다.
                    <span class="block">담당자가 확인 후 바로
                        <span class="bold">전화로 연락</span> 드리도록
                        하겠습니다.</span>
                </p>
            </h2>
            <div class="next-process">
                <p class="how">다음은 어떻게 진행 되나요?</p>
                <ul>
                    <li class="text">
                        <h3>구매요청확인</h3>
                        <p>
                            전문상담팀이 요청하신 구매 내용을 확인하고 담당자가 배정됩니다.
                        </p>
                    </li>
                    <li class="icon">
                        <img src="assets/imgs/inquiry_next_icon.png"
                            alt="화살표아이콘" />
                    </li>
                    <li class="text deep-bg">
                        <h3>전화상담 및 결제</h3>
                        <p>담당자와 통화후 결제를 해주시면 고객님의 앱을 준비해드립니다.</p>
                    </li>
                    <li class="icon">
                        <img src="assets/imgs/inquiry_next_icon.png"
                            alt="화살표아이콘" />
                    </li>
                    <li class="text">
                        <h3>앱 전달</h3>
                        <p>
                            준비된 앱을 소스코드와 관련 문서를 전달 드립니다.
                            <span class="block">본격적인 앱 비즈니스를 시작하세요.</span>
                        </p>
                    </li>
                </ul>
            </div>
            <button class="go-home">
                <a [routerLink]="['/home']">홈으로 돌아가기</a>
            </button>
        </section>
    </div>
    <footer-share [ngClass]="{ 'not-active': !checkFooter() }"></footer-share>
</main>