import { Directive, HostListener } from '@angular/core';
import { FileUploadService } from './service/file-upload.service';

@Directive({
  selector: '[appDnd]',
})
export class DndDirective {
  constructor(private fileUploadService: FileUploadService) {}

  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    this.fileUploadService.fileover = true;
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    this.fileUploadService.fileover = false;
  }

  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    this.fileUploadService.fileover = false;
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.fileUploadService.uploadFile(files[0]);
    }
  }
}
