import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DesignComponent } from './pages/design/design.component';
import { DevelopmentComponent } from './pages/development/development.component';
import { HomeComponent } from './pages/home/home.component';
import { InquiryCompleteComponent } from './pages/inquiry-complete/inquiry-complete.component';
import { InquiryComponent } from './pages/inquiry/inquiry.component';
import { MarketingComponent } from './pages/marketing/marketing.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { OrderCompleteComponent } from './pages/order-complete/order-complete.component';
import { PlanComponent } from './pages/plan/plan.component';
import { PortfolioDetailComponent } from './pages/portfolio-detail/portfolio-detail.component';
import { PortfolioMainComponent } from './pages/portfolio-main/portfolio-main.component';
import { PortfolioComponent } from './pages/portfolio/portfolio.component';
import { ServiceInfoComponent } from './pages/service-info/service-info.component';
import { ServiceMenuComponent } from './pages/service-menu/service-menu.component';
import { SimpleEstimateComponent } from './pages/simple-estimate/simple-estimate.component';
import { SolutionCompleteComponent } from './pages/solution-complete/solution-complete.component';
import { SolutionDetailRenewalDesignTemplateComponent } from './pages/solution-detail-renewal-design-template/solution-detail-renewal-design-template.component';
import { SolutionDetailRenewalComponent } from './pages/solution-detail-renewal/solution-detail-renewal.component';
import { SolutionGuideComponent } from './pages/solution-guide/solution-guide.component';
import { SolutionHomeComponent } from './pages/solution-home/solution-home.component';
import { SolutionPaymentCompletedComponent } from './pages/solution-payment-completed/solution-payment-completed.component';
import { SolutionPaymentComponent } from './pages/solution-payment/solution-payment.component';
import { SolutionProductCategoryComponent } from './pages/solution-product-category/solution-product-category.component';
import { StoreComponent } from './pages/store/store.component';
import { SolutionWebCategoryComponent } from './pages/solution-web-category/solution-web-category.component';
import { WebPreviewComponent } from './pages/web-preview/web-preview.component';
import { InquiryPageComponent } from './pages/inquiry-page/inquiry-page.component';

import { InquiryListPopComponent } from './pages/inquiry-list-pop/inquiry-list-pop.component';
import { AppPortfolioListComponent } from './pages/app-portfolio-list/app-portfolio-list.component';
import { AppPortfolioDetailComponent } from './pages/app-portfolio-detail/app-portfolio-detail.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },

  // {
  //   path: 'home',
  //   component: HomeComponent,
  // },
  {
    path: 'home',
    component: ServiceInfoComponent,
  },
  {
    path: 'service-menu',
    component: ServiceMenuComponent,
  },
  {
    path: 'plan',
    component: PlanComponent,
  },
  {
    path: 'design',
    component: DesignComponent,
  },
  {
    path: 'marketing',
    component: MarketingComponent,
  },
  // {
  //   path: 'simple-estimate',
  //   component: SimpleEstimateComponent,
  // },
  // {
  //   path: 'simple-estimate/:id',
  //   component: SimpleEstimateComponent,
  // },

  // {
  //   path: 'inquiry',
  //   component: InquiryComponent,
  // },

  {
    path: 'inquiry-complete',
    component: InquiryCompleteComponent,
  },
  {
    path: 'order-complete/:params',
    component: OrderCompleteComponent,
  },
  {
    path: 'solution-complete/:params',
    component: SolutionCompleteComponent,
  },
  {
    path: 'portfolio',
    component: AppPortfolioListComponent,
  },
  {
    path: 'app-portfolio-detail',
    component: AppPortfolioDetailComponent,
  },
  {
    path: 'app-portfolio-detail/:params',
    component: AppPortfolioDetailComponent,
  },
  {
    path: 'portfolio-detail/:params',
    component: PortfolioDetailComponent,
  },
  {
    path: 'portfolio-hanra',
    component: PortfolioMainComponent,
  },
  {
    path: 'development',
    component: DevelopmentComponent,
  },

  //TODO 나중에 둘중 하나만 살려주세요.
  {
    // path: 'solution-detail-renewal/:params',
    path: 'solution-detail-renewal',
    component: SolutionDetailRenewalComponent,
  },
  {
    // path: 'solution-detail-renewal/:params',
    path: 'solution-detail-renewal/:params',
    component: SolutionDetailRenewalComponent,
  },

  {
    path: 'solution-guide',
    component: SolutionGuideComponent,
  },
  {
    path: 'solution-payment',
    component: SolutionPaymentComponent,
  },
  {
    path: 'solution-payment-completed',
    component: SolutionPaymentCompletedComponent,
  },
  {
    path: 'solution-product-category',
    component: SolutionProductCategoryComponent,
  },
  {
    path: 'solution-web-category',
    component: SolutionWebCategoryComponent,
  },
  {
    path: 'solution-product-category/:params',
    component: SolutionProductCategoryComponent,
  },
  {
    path: 'solution-web-category/:params',
    component: SolutionWebCategoryComponent,
  },
  {
    path: 'solution-home',
    component: SolutionHomeComponent,
  },
  {
    path: 'design-template',
    component: SolutionDetailRenewalDesignTemplateComponent,
  },
  {
    path: 'web-preview',
    component: WebPreviewComponent,
  },
  {
    path: 'web-preview:id',
    component: WebPreviewComponent,
  },
  {
    path: 'inquiry',
    component: InquiryComponent,
  },
  {
    path: 'inquiry-list-pop',
    component: InquiryListPopComponent,
  },
  {
    //notfoundPage
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      useHash: true,
      // onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
