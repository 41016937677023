<!-- <header-share></header-share> -->

<main id="main">
  <section class="top-box">
    <h2>포트폴리오</h2>
    <p>
      우리의 포트폴리오는 고객을 위해 구현한 설계 및 개발 스타터팩을
      설명합니다.<br />
      웹 및 모바일 개발 마케팅까지 접근하는 방법에 대한 정확한 통찰력으로
      가득합니다.
    </p>
  </section>

  <div class="all-wrap web-wrap">
    <section class="main-pf">
      <div class="img-box">
        <a (click)="goHanra()">
          <img src="assets/imgs/portfolio-imgs/pf-main.png"
            alt="한라 앱 이미지"
            class="main-img opacity-on hover-off"
            data-img-speed="10000" />
          <img src="assets/imgs/portfolio-imgs/pf-main-hover.png"
            alt="한라 앱 이미지"
            class="main-img on-hover" />
        </a>
      </div>
      <div class="title-area">
        <h3 class="hidden">메인 포트폴리오</h3>
        <h4>한라</h4>
        <p>
          우리는 한라 앱에서 제공하는 다양한 서비스를 사용자가 쉽고<br />
          편하게 사용할 수 있도록 최적화된 플로우와 화면을 구현하고<br />
          연구 개발 하였습니다.
        </p>
        <a (click)="goHanra()">프로젝트 자세히 보기 ></a>
      </div>
      <img src="assets/imgs/portfolio-imgs/pf-icon-hanra.png"
        alt="한라 앱 아이콘"
        class="layer hanra-icon"
        data-speed="2" />
    </section>

    <section class="pf-list"
      *ngIf="mainArray && mainIcon">
      <h3 class="hidden">포트폴리오 리스트</h3>
      <ul class="list-wrap">
        <li *ngFor="let pf of mainArray"
          class="list-item opacity-on"
          [attr.data-img-speed]="pf.time">
          <a class="go-detail"
            (click)="goPfDetail(pf.id)"></a>
          <img src="assets/imgs/portfolio-imgs/pf-mockup.png"
            alt="목업 이미지"
            class="mockup-img" />
          <div class="inner-area">
            <img [src]="pf.appImg[0]"
              [alt]="pf.title + '이미지'" />
            <div class="hover-box">
              <h4>{{ pf.title }}</h4>
              <p>{{ pf.subInfo }}</p>
              <a (click)="goPfDetail(pf.id)">자세히 보기 ></a>
            </div>
          </div>
          <div class="m-box">
            <h4>{{ pf.title }}</h4>
            <p>{{ pf.subInfo }}</p>
          </div>
        </li>
      </ul>

      <div class="icon-wrap">
        <img *ngFor="let icon of mainIcon"
          [src]="icon.img"
          alt="앱 아이콘 이미지"
          class="layer"
          [attr.data-speed]="icon.time" />
      </div>
    </section>

    <!-- 메인 픽 x 포폴리스트 -->
    <ng-container *ngIf="pfListArray && allPfList">
      <section class="pf-list pf-item-list"
        *ngFor="let pfList of allPfList; let i = index">
        <ul class="list-wrap"
          [ngStyle]="{
            'min-height':
              i == allPfList.length - 1 ? getHeight(pfList) : '1630px'
          }">
          <li *ngFor="let pf of pfList"
            class="list-item opacity-on pf-other"
            [attr.data-img-speed]="pf.time">
            <a class="go-detail"
              (click)="goPfDetail(pf.id)"></a>
            <img src="assets/imgs/portfolio-imgs/pf-mockup.png"
              alt="목업 이미지"
              class="mockup-img" />
            <div class="inner-area">
              <img [src]="pf.appImg[0]"
                [alt]="pf.title + '이미지'" />
              <div class="hover-box">
                <h4>{{ pf.title }}</h4>
                <p>{{ pf.subInfo }}</p>
                <a (click)="goPfDetail(pf.id)">자세히 보기 ></a>
              </div>
            </div>
            <div class="m-box">
              <h4>{{ pf.title }}</h4>
              <p>{{ pf.subInfo }}</p>
            </div>
          </li>
        </ul>

        <div class="icon-wrap other-icon">
          <!-- <img
            *ngFor="let icon of appIcon2"
            [src]="icon.img"
            alt="앱 아이콘 이미지"
            class="layer"
            [attr.data-speed]="icon.time"
          /> -->
          <img *ngIf="pfList[0]"
            [src]="pfList[0].icon ? pfList[0].icon : pfList[1].icon"
            alt="앱 아이콘 이미지"
            class="layer"
            [attr.data-speed]="-3" />
          <img *ngIf="pfList[5]"
            [src]="pfList[5].icon ? pfList[5].icon : pfList[4].icon"
            alt="앱 아이콘 이미지"
            class="layer"
            [attr.data-speed]="4" />
          <img *ngIf="pfList[6] || pfList[7]"
            [src]="pfList[6].icon ? pfList[6].icon : pfList[7].icon"
            alt="앱 아이콘 이미지"
            class="layer"
            [attr.data-speed]="-2" />
        </div>
      </section>
    </ng-container>

    <!-- <section class="pf-list pf-item-list">
      <ul class="list-wrap">
        <li
          *ngFor="let pf of portfolio3"
          class="list-item opacity-on pf-other"
          [attr.data-img-speed]="pf.time"
        >
          <a class="go-detail" [routerLink]="/#/portfolio-detail/{{ pf.url }}"></a>
          <img
            src="assets/imgs/portfolio-imgs/pf-mockup.png"
            alt="목업 이미지"
            class="mockup-img"
          />
          <div class="inner-area">
            <img [src]="pf.img" [alt]="pf.title + '이미지'" />
            <div class="hover-box">
              <h4>{{ pf.title }}</h4>
              <p>{{ pf.con }}</p>
              <a [routerLink]="/#/portfolio-detail/{{ pf.url }}">자세히 보기 ></a>
            </div>
          </div>
          <div class="m-box">
            <h4>{{ pf.title }}</h4>
            <p>{{ pf.con }}</p>
          </div>
        </li>
      </ul>

      <div class="icon-wrap other-icon">
        <img
          *ngFor="let icon of appIcon3"
          [src]="icon.img"
          alt="앱 아이콘 이미지"
          class="layer"
          [attr.data-speed]="icon.time"
        />
      </div>
    </section>
    <section class="pf-list pf-item-list">
      <ul class="list-wrap">
        <li
          *ngFor="let pf of portfolio4"
          class="list-item opacity-on pf-other"
          [attr.data-img-speed]="pf.time"
        >
          <a class="go-detail" [routerLink]="/#/portfolio-detail/{{ pf.url }}"></a>
          <img
            src="assets/imgs/portfolio-imgs/pf-mockup.png"
            alt="목업 이미지"
            class="mockup-img"
          />
          <div class="inner-area">
            <img [src]="pf.img" [alt]="pf.title + '이미지'" />
            <div class="hover-box">
              <h4>{{ pf.title }}</h4>
              <p>{{ pf.con }}</p>
              <a [routerLink]="/#/portfolio-detail/{{ pf.url }}">자세히 보기 ></a>
            </div>
          </div>
          <div class="m-box">
            <h4>{{ pf.title }}</h4>
            <p>{{ pf.con }}</p>
          </div>
        </li>
      </ul>

      <div class="icon-wrap other-icon">
        <img
          *ngFor="let icon of appIcon4"
          [src]="icon.img"
          alt="앱 아이콘 이미지"
          class="layer"
          [attr.data-speed]="icon.time"
        />
      </div>
    </section> -->
  </div>

  <div class="all-wrap mobile-wrap">
    <section class="main-pf">
      <div class="img-box">
        <a (click)="goHanra()">
          <img src="assets/imgs/portfolio-imgs/pf-main.png"
            alt="한라 앱 이미지"
            class="main-img opacity-on hover-off"
            data-img-speed="10000" />
          <img src="assets/imgs/portfolio-imgs/pf-main-hover.png"
            alt="한라 앱 이미지"
            class="main-img on-hover" />
        </a>
      </div>
      <div class="title-area">
        <h3 class="hidden">메인 포트폴리오</h3>
        <h4>한라</h4>
        <p>
          우리는 한라 앱에서 제공하는 다양한 서비스를 사용자가 쉽고<br />
          편하게 사용할 수 있도록 최적화된 플로우와 화면을 구현하고<br />
          연구 개발 하였습니다.
        </p>
        <a (click)="goHanra()">프로젝트 자세히 보기 ></a>
      </div>
      <img src="assets/imgs/portfolio-imgs/pf-icon-hanra.png"
        alt="한라 앱 아이콘"
        class="layer hanra-icon"
        data-speed="2" />
    </section>

    <section class="pf-list"
      *ngIf="AllArr">
      <h3 class="hidden">포트폴리오 리스트</h3>
      <ul class="list-wrap">
        <li *ngFor="let pf of AllArr"
          class="list-item opacity-on"
          [attr.data-img-speed]="pf.time">
          <a class="go-detail"
            (click)="goPfDetail(pf.id)"></a>
          <img src="assets/imgs/portfolio-imgs/pf-mockup.png"
            alt="목업 이미지"
            class="mockup-img" />
          <div class="inner-area">
            <img [src]="pf.appImg[0]"
              [alt]="pf.title + '이미지'" />
            <div class="hover-box">
              <h4>{{ pf.title }}</h4>
              <p>{{ pf.subInfo }}</p>
              <a (click)="goPfDetail(pf.id)">자세히 보기 ></a>
            </div>
          </div>
          <div class="m-box">
            <h4>{{ pf.title }}</h4>
            <p>{{ pf.subInfo }}</p>
          </div>
        </li>
      </ul>

      <!-- <div class="icon-wrap">
        <img
          *ngFor="let icon of appIcon"
          [src]="icon.img"
          alt="앱 아이콘 이미지"
          class="layer"
          [attr.data-speed]="icon.time"
        />
      </div> -->
    </section>
  </div>
  <!-- <sticky-button></sticky-button> -->
</main>
<footer-share id="footerWrap"></footer-share>
<footer-share class="mobile-footer"></footer-share>
<!-- <span class="footer-line"></span>
<footer-share> </footer-share> -->
