import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
@Component({
  selector: 'app-service-menu',
  templateUrl: './service-menu.component.html',
  styleUrls: ['./service-menu.component.scss'],
})
export class ServiceMenuComponent implements OnInit, OnDestroy {
  myDevice = navigator.platform;
  constructor(
    private router: Router,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  ngOnInit(): void {
    //   let script2 = this._renderer2.createElement('script');
    //   script2.type = 'text/javascript';
    //   script2.text = `var _nasa={};
    //   if (!wcs_add) var wcs_add = {};
    // wcs_add["wa"] = "s_52ea45ec186a";
    // if (!_nasa) var _nasa = {};
    // if (window.wcs) {
    //   wcs.inflow("startapp.co.kr");
    //   wcs_do(_nasa);}
    //   `;
    //   this._renderer2.appendChild(this._document.body, script2);
    ///메뉴 메뉴 들어와서
    $(window).scroll().off();
    setTimeout(() => {
      $(function () {
        function mainEvent() {
          $('.s-menu1').hover(
            function () {
              $(this).find($('.top_txt')).stop().animate({ opacity: 1 }, 500);
              $(this)
                .siblings()
                .find($('.top_txt'))
                .stop()
                .animate({ opacity: 0.3 });
              $(this).find($('.info-text')).addClass('active');
              $(this).find($('button')).addClass('active-btn');
              $('.img-box1')
                .addClass('width-up')
                .find('img')
                // .stop()
                // .animate({ opacity: 0 }, 400)
                .addClass('off-img');
              // .queue(function () {
              setTimeout(() => {
                let video = $('.img-box1').find($('video')).get(0);
                video.muted = true;
                video.play();
                video.loop = true;
              }, 300);

              // });
            },
            function () {
              $(this).find($('.top_txt')).stop().animate({ opacity: 0.3 }, 500);
              $(this)
                .siblings()
                .find($('.top_txt'))
                .stop()
                .animate({ opacity: 0.3 });
              $(this).find($('.info-text')).removeClass('active');
              $(this).find($('button')).removeClass('active-btn');

              $('.img-box1')
                .removeClass('width-up')
                .find('img')
                // .stop()
                // .animate({ opacity: 1 }, 0);
                .removeClass('off-img');
              let video = $('.img-box1').find($('video')).get(0);
              video.muted = true;
              video.pause();
            }
          );

          ///
          $('.s-menu2').hover(
            function () {
              $(this).find($('.top_txt')).stop().animate({ opacity: 1 }, 500);
              $(this)
                .siblings()
                .find($('.top_txt'))
                .stop()
                .animate({ opacity: 0.3 });
              $(this).find($('.info-text')).addClass('active');
              $(this).find($('button')).addClass('active-btn');
              $('.img-box2')
                .addClass('width-up')
                .find('img')
                // .stop()
                // .animate({ opacity: 0 }, 400)
                .addClass('off-img');
              setTimeout(() => {
                let video = $('.img-box2').find($('video')).get(0);
                video.muted = true;
                video.play();
                video.loop = true;
              }, 400);
            },
            function () {
              $(this).find($('.top_txt')).stop().animate({ opacity: 0.3 }, 500);
              $(this)
                .siblings()
                .find($('.top_txt'))
                .stop()
                .animate({ opacity: 0.3 });
              $(this).find($('.info-text')).removeClass('active');
              $(this).find($('button')).removeClass('active-btn');

              $('.img-box2')
                .removeClass('width-up')
                .find('img')
                // .stop()
                // .animate({ opacity: 1 }, 0);
                .removeClass('off-img');
              let video = $('.img-box2').find($('video')).get(0);
              video.muted = true;
              video.pause();
            }
          );
          ///

          $('.s-menu3').hover(
            function () {
              $(this).find($('.top_txt')).stop().animate({ opacity: 1 }, 500);
              $(this)
                .siblings()
                .find($('.top_txt'))
                .stop()
                .animate({ opacity: 0.3 });
              $(this).find($('.info-text')).addClass('active');
              $(this).find($('button')).addClass('active-btn');
              $('.img-box3')
                .addClass('width-up')
                .find('img')
                // .stop()
                // .animate({ opacity: 0 }, 400)
                .addClass('off-img');
              setTimeout(() => {
                let video = $('.img-box3').find($('video')).get(0);
                video.muted = true;
                video.play();
                video.loop = true;
              }, 300);
            },
            function () {
              $(this).find($('.top_txt')).stop().animate({ opacity: 0.3 }, 500);
              $(this)
                .siblings()
                .find($('.top_txt'))
                .stop()
                .animate({ opacity: 0.3 });
              $(this).find($('.info-text')).removeClass('active');
              $(this).find($('button')).removeClass('active-btn');

              $('.img-box3')
                .removeClass('width-up')
                .find('img')
                // .stop()
                // .animate({ opacity: 1 }, 0);
                .removeClass('off-img');
              let video = $('.img-box3').find($('video')).get(0);
              video.muted = true;
              video.pause();
            }
          );

          ///
          $('.s-menu4').hover(
            function () {
              $(this).find($('.top_txt')).stop().animate({ opacity: 1 }, 500);
              $(this)
                .siblings()
                .find($('.top_txt'))
                .stop()
                .animate({ opacity: 0.3 });
              $(this).find($('.info-text')).addClass('active');
              $(this).find($('button')).addClass('active-btn');
              $('.img-box4')
                .addClass('width-up')
                .find('img')
                // .stop()
                // .animate({ opacity: 0 }, 400)
                .addClass('off-img');
              setTimeout(() => {
                let video = $('.img-box4').find($('video')).get(0);
                video.muted = true;
                video.play();
                video.loop = true;
              }, 300);
            },
            function () {
              $(this).find($('.top_txt')).stop().animate({ opacity: 0.3 }, 500);
              $(this)
                .siblings()
                .find($('.top_txt'))
                .stop()
                .animate({ opacity: 0.3 });
              $(this).find($('.info-text')).removeClass('active');
              $(this).find($('button')).removeClass('active-btn');

              $('.img-box4')
                .removeClass('width-up')
                .find('img')
                // .stop()
                // .animate({ opacity: 1 }, 0);
                .removeClass('off-img');
              let video = $('.img-box4').find($('video')).get(0);
              video.muted = true;
              video.pause();
            }
          );
          ///

          $('.main-wrap').mouseleave(function () {
            $(this).find($('.top_txt')).stop().animate({ opacity: 1 }, 500);
            let video = $('.img-box1').find($('video')).get(0);
            // video.muted = true;
            video.pause();
            let video2 = $('.img-box2').find($('video')).get(0);
            // video2.muted = true;
            video2.pause();
            let video3 = $('.img-box3').find($('video')).get(0);
            // video3.muted = true;
            video3.pause();
            let video4 = $('.img-box4').find($('video')).get(0);
            // video4.muted = true;
            video4.pause();
          });
        }

        mainEventOff();

        function mainEventOff() {
          $('.s-menu1').hover(
            function (e) {
              $('.s-menu1').off(e);
              e.preventDefault();
              e.stopPropagation();
            },
            function (e) {
              $('.s-menu1').off(e);
              e.preventDefault();
              e.stopPropagation();
            }
          );
          $('.s-menu2').hover(
            function (e) {
              $('.s-menu2').off(e);
              e.preventDefault();
              e.stopPropagation();
            },
            function (e) {
              $('.s-menu2').off(e);
              e.preventDefault();
              e.stopPropagation();
            }
          );
          $('.s-menu3').hover(
            function (e) {
              $('.s-menu3').off(e);
              e.preventDefault();
              e.stopPropagation();
            },
            function (e) {
              $('.s-menu3').off(e);
              e.preventDefault();
              e.stopPropagation();
            }
          );
          $('.s-menu4').hover(
            function (e) {
              $('.s-menu4').off(e);
              e.preventDefault();
              e.stopPropagation();
            },
            function (e) {
              $('.s-menu4').off(e);
              e.preventDefault();
              e.stopPropagation();
            }
          );
        }

        let windowWidth = $(window).width();
        if (windowWidth > 1023) {
          mainEvent();
        }

        let that = this;
        let filter = 'win16|win32|win64|mac|macintel';

        if (that.myDevice == filter) {
          $(window).resize(function (e) {
            let windowWidth = $(window).width();

            if (windowWidth > 1023) {
              mainEvent();
            } else {
              //강제 새로고침
              // location.reload();
            }
          });
        }
      });
    }, 400);
  }

  ngOnDestroy(): void {
    $(window).scroll().off();
  }

  goplan() {
    this.router.navigateByUrl('/plan').then(() => {
      // //window.location.reload();
    });
  }
  godesign() {
    this.router.navigateByUrl('/design').then(() => {
      // //window.location.reload();
    });
  }
  godevelopment() {
    this.router.navigateByUrl('/development').then(() => {
      // //window.location.reload();
    });
  }
  gomarketing() {
    this.router.navigateByUrl('/marketing').then(() => {
      window.location.reload();
    });
  }
}
