import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-solution-ownership-tablet',
  templateUrl: './solution-ownership-tablet.component.html',
  styleUrls: ['./solution-ownership-tablet.component.scss'],
})
export class SolutionOwnershipTabletComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
