import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swiper from 'swiper';
import { solutionRenewalData } from '../../data/solutionRenewalData';
@Component({
  selector: 'app-solution-detail-renewal-image-popup',
  templateUrl: './solution-detail-renewal-image-popup.component.html',
  styleUrls: ['./solution-detail-renewal-image-popup.component.scss'],
})
export class SolutionDetailRenewalImagePopupComponent implements OnInit {
  renewalData: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data, private dialog: MatDialog) {
    this.renewalData = data.list;
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const swiper = new Swiper('.admin-images-swiper', {
      slidesPerView: 1,
      initialSlide: this.data.i ? this.data.i : 0,
      loop: true,
      speed: 400,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        1201: {
          allowTouchMove: false,
        },
        280: {
          zoom: {
            maxRatio: 5,
          },
          allowTouchMove: true,
        },
      },
    });

    setTimeout(() => {
      swiper.update();
    }, 10);
  }

  closeDialog() {
    this.dialog.closeAll();
  }
}
