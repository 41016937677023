<main id="main"
  style="background: #f3f5f9">
  <section id="form-section"
    class="bg-w">
    <div class="w1200 top">
      <h2>포트폴리오</h2>
      <p>
        우리의 포트폴리오는 고객을 위해 구현한 설계 및 개발 스타터팩을
        설명합니다.<br />
        웹 및 모바일 개발 마케팅까지 접근하는 방법에 대한 정확한 통찰력으로
        가득합니다.
      </p>
    </div>
  </section>
  <!-- <section id="form-section"
    class="sticky">
    <div class="w1200 cate">
      <div class="category"
        [ngClass]="{ open: categoryView }">
        <ng-container *ngIf="appCategories">
          <div class="category-item">
            <p class="cate-tit">카테고리</p>

            <mat-button-toggle-group name="solution"
              aria-label="solution type"
              [(ngModel)]="category"
              (change)="changeFilter()"
              class="toggle-category">
              <mat-button-toggle value="all">
                <p>전체</p>
              </mat-button-toggle>
              <mat-button-toggle value="{{ item.id }}"
                *ngFor="let item of appCategories">
                <p>{{ item.title }}</p>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div> -->
  <!-- <div class="category-item">
                        <p class="cate-tit">PG 결제</p>

                        <mat-button-toggle-group name="pg"
                            aria-label="pg type"
                            [(ngModel)]="includePG"
                            (change)="changeFilter()"
                            class="toggle-category one">
                            <mat-button-toggle [value]="'all'">
                                <p>전체</p>
                            </mat-button-toggle>
                            <mat-button-toggle [value]="'pg'">
                                <p>기능 있음</p>
                            </mat-button-toggle>
                            <mat-button-toggle [value]="'none'">
                                <p>기능 없음</p>
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div> -->
  <!-- </ng-container> -->
  <!-- <button type="button" class="cate-button" (click)="viewChange()">
          <div class="img-wrap">
            <img src="./assets/imgs/solution-cate-arrow.png" alt="" />
          </div>
        </button> -->
  <!-- </div>
    </div>
  </section> -->
  <section id="form-section category-list"
    class="category-list">
    <div class="w1200"
      *ngIf="selectApps$ | async as apps">
      <div class="product-list">
        <div class="product"
          *ngFor="let item of apps; let i = index"
          (click)="pdDetail(item.id)">
          <!-- 슬라이드 -->
          <div class="slide-wrap">
            <img src="{{ item.mainImage }}"
              (load)="listImage.style.opacity = '1'"
              #listImage
              class="image"
              alt="상품이미지" />
          </div>

          <!-- 호버 시 나오는 설명, 버튼 -->
          <div class="hover-before">
            <div class="inner">
              <p class="type">
                {{ getCategoryTitleById(item.categoryId) }}
              </p>
              <p class="tit">
                {{ item.title }}
              </p>
              <p class="tag">
                <span *ngFor="let function of item.functions">
                  #{{ function }}</span>
              </p>
            </div>

            <!-- 슬라이드 이동 -->
            <!-- <div class="slide-change">
                            <div class="swiper-button-prev button-prev{{ i }}"></div>
                            <div class="swiper-button-next button-next{{ i }}"></div>
                        </div> -->
            <!-- 하단 버튼 -->
            <!-- <div class="product-btns">
                            <button type="button"
                                class="button"
                                (click)="openApp(item)">
                                앱 미리보기
                            </button>

                            <button type="button"
                                class="button"
                                (click)="pdDetail(item.id)">
                                상품 상세 보러가기
                            </button>
                        </div> -->
          </div>
        </div>
      </div>
    </div>
  </section>
  <footer-share id="footerWrap"></footer-share>
  <footer-share class="mobile-footer"></footer-share>
</main>