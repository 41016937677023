import {
  Component,
  OnInit,
  AfterViewInit,
  HostListener,
  Renderer2,
  Inject,
} from '@angular/core';
import { productTypesData } from '../../data/solutionRenewalData';
import Swiper from 'swiper';
import { MatDialog } from '@angular/material/dialog';
import { SolutionDetailRenewalPopupComponent } from '../solution-detail-renewal-popup/solution-detail-renewal-popup.component';
import * as $ from 'jquery';
import { Router, ActivatedRoute } from '@angular/router';
import { SolutionDetailRenewalImagePopupComponent } from '../solution-detail-renewal-image-popup/solution-detail-renewal-image-popup.component';
import { DbService } from 'src/app/service/db.service';
import { DataService } from 'src/app/service/data.service';
import { SolutionQna } from 'src/app/models/solutionQna.models';
import { SolutionReview } from 'src/app/models/solutionReview.models';
import {
  AppFunctionList,
  SolutionApp,
} from 'src/app/models/solutionApp.models';
import { SolutionPrice } from 'src/app/models/solutionPrice.models';
import { StoreAppPopComponent } from '../store-app-pop/store-app-pop.component';
import { AdminSamplePopComponent } from '../admin-sample-pop/admin-sample-pop.component';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-solution-detail-renewal',
  templateUrl: './solution-detail-renewal.component.html',
  styleUrls: ['./solution-detail-renewal.component.scss'],
})
export class SolutionDetailRenewalComponent implements OnInit, AfterViewInit {
  purchaseType = 'subscribe';
  renewalData: any;
  productTypesData: any;
  solutionAppData: SolutionApp;
  solutionPrice: SolutionPrice[];
  functionListData: AppFunctionList[];
  reviewData: SolutionReview[];
  qnaData: SolutionQna[];
  windowWidth: number = window.innerWidth;
  isShowBtn = false;
  activeIndex = 0;

  slideResize: boolean = false;
  myDevice = navigator.platform;
  testSolutionAppId = 'ckYy49RXdEodTiMZ4f8a';

  @HostListener('window:resize', ['$event'])
  getWindowWidth(e) {
    const width = e.target.innerWidth;
    this.windowWidth = width;
  }
  @HostListener('window:scroll', ['$event'])
  getScrollHeight(e) {
    const startBtn = document.querySelector<HTMLElement>('.start-btn');
    const btnH = startBtn.getBoundingClientRect().height;
    const btnY = startBtn.getBoundingClientRect().y;

    if (btnH + btnY - 130 < 0) {
      this.isShowBtn = true;
    } else {
      this.isShowBtn = false;
    }

    if (this.windowWidth <= 1200) {
      const fixedArea = document.querySelector<HTMLElement>('.fixed-btn-area');
      const areaH = fixedArea.getBoundingClientRect().height;
      const scrollT = $(window).scrollTop();
      const triggerPoint = $('main').height() - $(window).height();

      if (triggerPoint - areaH - 10 <= scrollT) {
        fixedArea.style.position = 'static';
      } else {
        fixedArea.style.position = 'fixed';
      }
    }
  }

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private db: DbService,
    private dataService: DataService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.productTypesData = productTypesData;
    this.route.queryParams.subscribe({
      next: async ({ id }) => {
        try {
          if (id) {
            await this.getInitData(id);
            this.loadDomAnimation();
            setTimeout(() => this.setSwiper(), 1000);
          }
        } catch (error) {
          console.error('Error handling the categoryId:', error);
          // Handle any errors here
        }
      },
      error: (err) => {
        // Error handling here
        console.error('Error in subscription:', err);
      },
      complete: () => {
        // Handle completion here
        console.log('Completed');
      },
    });
  }

  ngOnInit(): void {
    //   let script2 = this._renderer2.createElement('script');
    //   script2.type = 'text/javascript';
    //   script2.text = `var _nasa={};
    //   if (!wcs_add) var wcs_add = {};
    // wcs_add["wa"] = "s_52ea45ec186a";
    // if (!_nasa) var _nasa = {};
    // if (window.wcs) {
    //   wcs.inflow("startapp.co.kr");
    //   wcs_do(_nasa);}
    //   `;
    //   this._renderer2.appendChild(this._document.body, script2);
    // this.setIitem();
  }

  // setIitem() {
  //   this.db.updateAt('solutionApps/2fPTTYsM8X5XqGRIi8YZ', {
  //     mainColor: '896EE3',
  //   });
  // }

  getInitData(id: string) {
    return new Promise(async (resolve) => {
      await this.dataService.inIt();
      this.solutionAppData = await this.dataService.getSolutionAppById(id);
      this.solutionPrice = this.dataService.setSolutionPrice(
        this.solutionAppData
      );

      this.functionListData =
        await this.dataService.getSolutionAppFunctionListByAppId(id);
      this.qnaData = this.dataService.qnaData;
      this.reviewData = this.dataService.reviewData;
      resolve(true);
    });
  }

  setSwiper() {
    const iconSwiper = new Swiper('.icon-wrap', {
      autoplay: true,
      allowTouchMove: false,
      centeredSlides: true,
      loop: true,
      slidesPerView: 7,
      slidesPerGroup: 1,
      slidesPerColumn: 1,
      breakpoints: {
        1201: {
          // slidesPerView: 7,
          slidesPerGroup: 1,
          slidesPerColumn: 1,
          spaceBetween: 60,
        },
        768: {
          // slidesPerView: 6,
          spaceBetween: 40,
        },
        280: {
          // slidesPerView: 3,
          spaceBetween: 20,
        },
      },
    });

    const designSwiper = new Swiper('.design-wrap', {
      autoplay: false,
      allowTouchMove: false,
      loop: false,
      slidesPerView: 4,
      slidesPerGroup: 1,
      spaceBetween: 80,
      breakpoints: {
        280: {
          autoplay: {
            delay: 3000,
          },
          loop: true,
          slidesPerView: 2,
          spaceBetween: 28,
        },
        480: {
          autoplay: {
            delay: 3000,
          },
          loop: true,
          slidesPerView: 3,
          spaceBetween: 60,
        },
        768: {
          autoplay: {
            delay: 3000,
          },
          loop: true,
          slidesPerView: 5,
          spaceBetween: 60,
        },
        1201: {
          autoplay: {
            delay: 3000000000,
          },
          loop: false,
          slidesPerView: 4,
          spaceBetween: 80,
        },
      },
      on: {
        init: (e) => {},
        breakpoint: (e) => {
          if (e.currentBreakpoint === '1201') {
            setTimeout(() => {
              e.autoplay.stop();
            }, 10);
          } else {
            setTimeout(() => {
              e.autoplay.start();
            }, 10);
          }
        },
      },
    });

    const adminSwiper = new Swiper('.admin-swiper', {
      slidesPerView: 1,
      autoplay: {
        delay: 5000,
      },
      loop: true,
      speed: 400,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        1201: {
          allowTouchMove: false,
        },
        280: {
          allowTouchMove: true,
        },
      },
      on: {
        slideChange: (e) => {
          this.activeIndex = e.realIndex;
        },
      },
    });

    const reviewSwiper = new Swiper('.review-swiper', {
      autoplay: {
        delay: 0,
        // pauseOnMouseEnter: true,
      },
      allowTouchMove: false,
      centeredSlides: true,
      // direction: 'vertical',
      loop: true,
      ///TODO 0224 가영 오류수정
      breakpoints: {
        1201: {
          direction: 'vertical',
          slidesPerView: 2,
          speed: 5000,
          spaceBetween: 100,
          autoplay: {
            delay: 0,
          },
          loop: true,
        },
        768: {
          direction: 'horizontal',
          slidesPerView: 1.5,
          speed: 7000,
          spaceBetween: 24,
          autoplay: {
            delay: 0,
          },
          loop: true,
        },
        280: {
          direction: 'horizontal',
          slidesPerView: 1.1,
          speed: 8000,
          spaceBetween: 20,
          autoplay: {
            delay: 0,
          },
          loop: true,
        },
      },
      ///TODO 0224 가영 오류수정
      on: {
        init: (e) => {},
        breakpoint: (e) => {
          e.autoplay.start();
        },
      },
    });

    setTimeout(() => {
      let footer = document.getElementById('footer');
      if (footer) {
        footer.classList.add('white');
      }
    }, 200);

    setTimeout(() => {
      iconSwiper?.update();
      designSwiper?.update();
      adminSwiper?.update();
      reviewSwiper?.update();
    }, 500);
  }

  ngAfterViewInit(): void {}

  openPopup(popupType) {
    this.dialog.open(SolutionDetailRenewalPopupComponent, {
      panelClass: 'solution-popup',
      data: {
        // type: this.purchaseType,
        type: popupType,
      },
    });
  }

  solutionStart() {
    // this.router.navigate(['/solution-payment'], {
    //   queryParams: { id: this.solutionAppData.id },
    // });
    this.router.navigate(['/inquiry'], {
      queryParams: { id: this.solutionAppData.id, type: 'app' },
    });
  }

  // 관리자페이지 디테일 이미지 모달
  imgDetailPopup(i: number) {
    const dialog = this.dialog.open(SolutionDetailRenewalImagePopupComponent, {
      panelClass: 'solution-image-popup',
      data: {
        list: this.solutionAppData.adminSlides,
        i,
      },
    });
  }

  // 디자인 보러가기
  goDesignTemplate() {
    this.router.navigate(['design-template']);
  }

  loadDomAnimation() {
    window.onload = function () {
      setTimeout(function () {
        scrollTo(0, 0);
      }, 10);
    };

    let that = this;
    let filter = 'win16|win32|win64|mac|macintel';

    $(function () {
      if (that.myDevice == filter) {
        $(window).trigger('resize');
        // 창크기 조절 시 자동 (새로고침)resize 발생
        window.onresize = function () {
          // location.reload();
        };
      }

      // 메인 영역 애니메이션
      const $list = $('.top-text-inner');
      $list.addClass('active');
    });
  }
  getClass(item) {
    switch (item.title) {
      case '3개월':
        return 'quarter-year';
      case '6개월':
        return 'half-year';
      case '12개월':
        return 'one-year';
      default:
        break;
    }
  }

  getType(price) {
    switch (price.type) {
      case '안드로이드':
        return 'single';
      default:
        return 'both';
    }
  }

  openApp() {
    this.dialog.open(StoreAppPopComponent, {
      panelClass: 'app-pop-modal',
      data: {
        appUrl: this.solutionAppData.appPreviewUrl,
      },
    });
  }

  openAdmin() {
    this.dialog.open(AdminSamplePopComponent, {
      panelClass: 'admin-sample-modal',
      data: {
        // storeId: this.storeId,
        adminUrl: this.solutionAppData.adminPreviewUrl,
      },
    });
  }
}
