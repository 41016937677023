import { Injectable } from '@angular/core';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  public fileover;

  _fileArr: Array<{
    name: string;
    url: string;
    progress: number;
    totalBytes: number;
  }> = [];

  constructor() {}

  get fileArr() {
    return this._fileArr;
  }

  set fileArr(arr) {
    this._fileArr = arr;
  }

  uploadFile(file) {
    this.selectFile(file);
  }

  ///첨부파일 만들기
  async selectFile(file) {
    let fileName = file.name;
    let tmp = { name: fileName, url: '', progress: 0, totalBytes: 0 };

    this._fileArr.push(tmp);

    let index = this._fileArr.length - 1;

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      const dataUrlImage: any = reader.result;
      const storageRef = firebase.default.storage().ref();
      const fileName = `${new Date().getTime()}_${file.name}`;
      const upRef = 'fileStorage/' + fileName;
      const uploadTask = storageRef
        .child(upRef)
        .putString(dataUrlImage, 'data_url');
      uploadTask.on(
        firebase.default.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {
          if (this._fileArr[index]) {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this._fileArr[index].totalBytes = snapshot.totalBytes;
            this._fileArr[index].progress = progress;
          }
        },
        (error) => {},
        () => {
          let url = `https://storage.googleapis.com/startapp-new.appspot.com/${upRef}`;
          this.fileArr[index].url = url;
        }
      );
    };
  }
}
