import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DbService } from './db.service';
import { TrackingData } from '../models/trackingData.model';
import { IpService } from './ip.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  constructor(
    private db: DbService,
    private ipService: IpService,
    private deviceService: DeviceDetectorService
  ) {}

  recordVisit(data: TrackingData) {
    return this.db.updateAt(`trackingDatas/${data.id}`, data);
  }

  async saveTrackingData(
    id: string,
    type: 'visit' | 'inquiry' | 'tryCall' | 'inquiry-mobile',
    inquiryId?: string
  ) {
    const ipdata = await this.ipService.getIp();
    const referrer = decodeURIComponent(document.referrer);
    const utmParams = this.parseUtmParams(window.location.search);
    const deviceInfo = this.deviceService.getDeviceInfo();
    const trackingData: TrackingData = {
      id,
      ip: ipdata && ipdata.ip ? ipdata.ip : '',
      referrer,
      inquiryId: inquiryId ? inquiryId : '',
      type,
      deviceInfo: deviceInfo ? deviceInfo : '',
      utmSource: utmParams['utm_source'] ? utmParams['utm_source'] : '',
      utmMedium: utmParams['utm_medium'] ? utmParams['utm_medium'] : '',
      utmCampaign: utmParams['utm_campaign'] ? utmParams['utm_campaign'] : '',
      utmTerm: utmParams['utm_term'] ? utmParams['utm_term'] : '',
      utmContent: utmParams['utm_content'] ? utmParams['utm_content'] : '',
      dateCreated: new Date().toISOString(), // 방문 시간 기록
      date: new Date(),
    };
    return this.recordVisit(trackingData);
  }

  private parseUtmParams(search: string): any {
    const params = new URLSearchParams(search);
    return {
      utm_source: decodeURIComponent(params.get('utm_source')),
      utm_medium: decodeURIComponent(params.get('utm_medium')),
      utm_campaign: decodeURIComponent(params.get('utm_campaign')),
      utm_term: decodeURIComponent(params.get('utm_term')),
      utm_content: decodeURIComponent(params.get('utm_content')),
    };
  }
}
