import { DOCUMENT } from '@angular/common';
import {
  Component,
  HostListener,
  Inject,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-solution-guide',
  templateUrl: './solution-guide.component.html',
  styleUrls: ['./solution-guide.component.scss'],
})
export class SolutionGuideComponent implements OnInit {
  purchaseType = 'subscribe';

  // 가로 값
  windowWidth: number = window.innerWidth;
  @HostListener('window:resize', ['$event'])
  getWindowWidth(e) {
    const width = e.target.innerWidth;
    this.windowWidth = width;
  }

  constructor(
    private router: Router,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  ngOnInit(): void {
    //   let script2 = this._renderer2.createElement('script');
    //   script2.type = 'text/javascript';
    //   script2.text = `var _nasa={};
    //   if (!wcs_add) var wcs_add = {};
    // wcs_add["wa"] = "s_52ea45ec186a";
    // if (!_nasa) var _nasa = {};
    // if (window.wcs) {
    //   wcs.inflow("startapp.co.kr");
    //   wcs_do(_nasa);}
    //   `;
    //   this._renderer2.appendChild(this._document.body, script2);
  }

  //  스타터팩제품 보기
  pdList() {
    this.router.navigateByUrl('/solution-product-category').then(() => {
      //window.location.reload();
    });
  }
}
