<main id="main">
  <div class="all-wrap"
    class="w1200">
    <section id="form-section">
      <div class="top">
        <h2>
          월 사용료만 내면 되는 <span>간편한 스타터팩구독</span>,<br />
          소스 코드를 소유할 수 있는 <span>스타터팩</span> 중 선택만 하세요!
        </h2>
        <p>
          스타트앱의 완성형 스타터팩이라면 부담스런 앱 구축 비용, 아낄 수
          있어요.
        </p>
      </div>
    </section>
    <section id="form-section">
      <mat-button-toggle-group name="purchase"
        class="toggle-segment"
        aria-label="purchase type"
        [(ngModel)]="purchaseType">
        <mat-button-toggle value="subscribe">
          <div>
            <div class="type-icon">
              <svg width="52px"
                height="52px"
                viewBox="0 0 52 52">
                <g stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd">
                  <g transform="translate(-56.000000, -479.000000)">
                    <g transform="translate(56.000000, 479.000000)">
                      <rect x="0"
                        y="0"
                        width="52"
                        height="52"></rect>
                      <path
                        d="M24.7063996,8.03147311 L27.9021345,11.3761468 L34.473033,11.3761468 L34.952708,21.4440412 L17.5356852,21.9704611 L17.0353741,8.58805608 L24.7063996,8.03147311 Z"
                        class="stroke-3E3F44 fill"
                        stroke-width="2"></path>
                      <rect class="stroke-3E3F44"
                        stroke-width="2"
                        x="8"
                        y="37"
                        width="9"
                        height="7"
                        rx="2"></rect>
                      <rect class="stroke-3E3F44"
                        stroke-width="2"
                        x="35"
                        y="37"
                        width="9"
                        height="7"
                        rx="2"></rect>
                      <rect class="stroke-3E3F44"
                        stroke-width="2"
                        x="21.5"
                        y="37"
                        width="9"
                        height="7"
                        rx="2"></rect>
                      <line x1="26"
                        y1="37.5"
                        x2="26"
                        y2="21.2689949"
                        class="stroke-3E3F44"
                        stroke-width="2"></line>
                      <line x1="12.5"
                        y1="37"
                        x2="12.5"
                        y2="29"
                        class="stroke-3E3F44"
                        stroke-width="2"></line>
                      <line x1="39.5"
                        y1="37"
                        x2="39.5"
                        y2="29"
                        class="stroke-3E3F44"
                        stroke-width="2"></line>
                      <line x1="12.5"
                        y1="29"
                        x2="39.5"
                        y2="29"
                        class="stroke-3E3F44"
                        stroke-width="2"
                        stroke-linecap="square"></line>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <h5><span>스타터팩</span> 구독</h5>
            <p class="type-info">
              월 45,000원*의 사용료로 이제 앱도 구독하세요!
            </p>
            <p class="mini-info">*안드로이드 기준, 12개월 계약 시</p>
          </div>

          <button type="button"
            class="seg-btn">
            {{ purchaseType === "subscribe" ? "선택 중" : "선택 하기" }}
          </button>
        </mat-button-toggle>
        <mat-button-toggle value="ownership">
          <div>
            <div class="type-icon">
              <svg width="52px"
                height="52px"
                viewBox="0 0 52 52">
                <g stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd">
                  <g transform="translate(-426.000000, -479.000000)">
                    <g transform="translate(426.000000, 479.000000)">
                      <rect x="0"
                        y="0"
                        width="52"
                        height="52"></rect>
                      <path
                        d="M24.7063996,9.03147311 L27.9021345,12.3761468 L34.473033,12.3761468 L34.952708,22.4440412 L17.5356852,22.9704611 L17.0353741,9.58805608 L24.7063996,9.03147311 Z"
                        class="stroke-3E3F44 fill"
                        stroke-width="2"></path>
                      <g transform="translate(38.000000, 29.000000) rotate(20.000000) translate(-38.000000, -29.000000) translate(30.000000, 14.000000)"
                        class="stroke-3E3F44"
                        stroke-width="2">
                        <path
                          d="M18.1004493,8.68341122 L11.6108511,11.8140797 C11.6242901,11.7322678 11.6260936,11.6489335 11.6162067,11.5666055 C11.5005709,10.0342802 10.3203876,8.80338179 8.80924042,8.63901748 L5.0587585,8.22917411 C2.85731661,8.02249249 0.90192658,5.84156736 -1.3857403,6.00917837 L-3,6.17975691 L-3,15.8912046 L1.33154662,17.6100244 C2.016834,17.8825073 2.74882769,18.0146281 3.48502921,17.998716 L9.64606201,17.879728 C10.65331,17.8559801 11.6359665,17.5603955 12.4922633,17.0235862 L20.0444748,12.2794613 C20.5042328,11.9873931 20.8299942,11.5221806 20.9497307,10.9866859 C21.0694672,10.4511912 20.9733151,9.88952741 20.6825344,9.42588273 C20.1271442,8.55953921 19.0235882,8.24221471 18.1004493,8.68341122 Z"
                          fill-rule="nonzero"
                          transform="translate(9.000000, 12.000000) rotate(-90.000000) translate(-9.000000, -12.000000) ">
                        </path>
                        <line x1="11.7119383"
                          y1="13.1525313"
                          x2="7.53130321"
                          y2="13.1525313"
                          stroke-linecap="round"
                          fill-rule="nonzero"
                          transform="translate(9.621621, 13.152531) rotate(-90.000000) translate(-9.621621, -13.152531) ">
                        </line>
                        <rect class="fill-3E3F44"
                          x="1"
                          y="24"
                          width="14"
                          height="5"
                          rx="1"></rect>
                      </g>
                      <g transform="translate(14.000000, 29.000000) scale(-1, 1) rotate(20.000000) translate(-14.000000, -29.000000) translate(6.000000, 14.000000)"
                        class="stroke-3E3F44"
                        stroke-width="2">
                        <path
                          d="M18.1004493,8.68341122 L11.6108511,11.8140797 C11.6242901,11.7322678 11.6260936,11.6489335 11.6162067,11.5666055 C11.5005709,10.0342802 10.3203876,8.80338179 8.80924042,8.63901748 L5.0587585,8.22917411 C2.85731661,8.02249249 0.90192658,5.84156736 -1.3857403,6.00917837 L-3,6.17975691 L-3,15.8912046 L1.33154662,17.6100244 C2.016834,17.8825073 2.74882769,18.0146281 3.48502921,17.998716 L9.64606201,17.879728 C10.65331,17.8559801 11.6359665,17.5603955 12.4922633,17.0235862 L20.0444748,12.2794613 C20.5042328,11.9873931 20.8299942,11.5221806 20.9497307,10.9866859 C21.0694672,10.4511912 20.9733151,9.88952741 20.6825344,9.42588273 C20.1271442,8.55953921 19.0235882,8.24221471 18.1004493,8.68341122 Z"
                          fill-rule="nonzero"
                          transform="translate(9.000000, 12.000000) rotate(-90.000000) translate(-9.000000, -12.000000) ">
                        </path>
                        <line x1="11.7119383"
                          y1="13.1525313"
                          x2="7.53130321"
                          y2="13.1525313"
                          stroke-linecap="round"
                          fill-rule="nonzero"
                          transform="translate(9.621621, 13.152531) rotate(-90.000000) translate(-9.621621, -13.152531) ">
                        </line>
                        <rect class="fill-3E3F44"
                          x="1"
                          y="24"
                          width="14"
                          height="5"
                          rx="1"></rect>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <h5><span>소스코드</span> 구매</h5>
            <p class="type-info">
              짧은 세팅 기간, 저렴한 비용으로<br />
              앱 전문가가 만든 소스코드를 소유
            </p>
          </div>
          <button type="button"
            class="seg-btn">
            {{ purchaseType === "ownership" ? "선택 중" : "선택 하기" }}
          </button>
        </mat-button-toggle>
      </mat-button-toggle-group>

      <!-- 구독형 스타터팩 -->
      <div class="subscribe"
        *ngIf="purchaseType === 'subscribe'">
        <!-- PC -->
        <div class="table-wrap"
          *ngIf="windowWidth > 1200">
          <table>
            <thead>
              <tr>
                <th rowspan="2"
                  class="th">상품명</th>
                <!-- <th colspan="2"
                  class="p-th">
                  <div>
                    <p class="product-title">스타터팩구독</p>
                    <p class="item-title">설치형 상품</p>
                  </div>
                </th> -->
                <!-- <th colspan="2" class="p-th">
                  <div>
                    <p class="product-title">스타터팩구독</p>
                    <p class="item-title">스타터팩구독</p>
                  </div>
                </th> -->
                <th class="p-th">
                  <div>
                    <!-- <p class="product-title">구독형 상품</p> -->
                    <p class="item-title">안드로이드</p>
                  </div>
                </th>
                <th class="p-th">
                  <div>
                    <!-- <p class="product-title">구독형 상품</p> -->
                    <p class="item-title">안드로이드 + iOS</p>
                  </div>
                </th>
              </tr>
              <!-- <tr>
                <th>안드로이드</th>
                <th class="end-r">안드로이드+모바일 웹</th>
                <th>안드로이드</th>
                <th>안드로이드+iOS</th>
              </tr> -->
            </thead>

            <tbody>
              <tr class="feature-line">
                <th class="th">특징</th>
                <!-- <td colspan="2" class="end-r">
                  <div>
                    <p>
                      회사에서, 친구끼리… <span>우리끼리 사용할 앱</span>이
                      필요한 사용자를 위한 전용 상품
                    </p>
                    <p>
                      스토어에 등록하지 않아요! 앱 등록 <span>기간 절약</span>,
                      세팅 후 <span>바로 사용 가능</span>
                    </p>
                    <p>스타터팩구독보다 <span>슬림한 요금제</span></p>
                  </div>
                </td> -->
                <td colspan="2">
                  <div>
                    <!-- <p>
                      스타터팩만 선택하면 <span>스토어 등록 대행</span>까지 한
                      번에!
                    </p> -->
                    <p>
                      고가의 앱 구축 비용없이 <span>월 사용료</span>만 내면
                      <span>전문가가 만든 앱</span>을 사용 가능
                    </p>
                    <p>3개월부터 선택 가능한 <span>부담없는 사용 기간</span></p>
                  </div>
                </td>
              </tr>
              <tr>
                <th class="th">제공 서비스</th>
                <!-- <td>
                  <div>
                    <div class="popover-area pb">
                      <p>안드로이드 APK</p>
                      <button
                        type="button"
                        class="popover-btn"
                        placement="bottom-left"
                        ngbPopover="APK (Android Application Package)이란 안드로이드용 앱 설치 파일로 Android에서만 사용 가능합니다."
                      ></button>
                    </div>
                    <p>관리자 페이지</p>
                    <p>월 유지보수</p>
                  </div>
                </td>
                <td class="end-r">
                  <div>
                    <p>안드로이드 APK</p>
                    <div class="popover-area pt">
                      <p>모바일 웹 호스팅</p>
                      <button
                        type="button"
                        class="popover-btn"
                        placement="bottom-right"
                        popoverClass="popover-right"
                        ngbPopover="모바일 웹이란 별도의 앱 설치 없이 스마트 폰 등 모바일 기기에서 바로 확인 가능하도록 제작된 웹 사이트입니다."
                      ></button>
                    </div>
                    <p>안드로이드 앱 월 구독</p>
                    <p>관리자 페이지</p>
                    <p>월 유지보수</p>
                  </div>
                </td> -->
                <td>
                  <div>
                    <p>안드로이드 앱 월 구독</p>

                    <!-- <p>구글 Play 스토어 앱 등록</p> -->
                    <p>관리자 페이지</p>
                    <p>월 유지보수</p>
                  </div>
                </td>
                <td>
                  <div>
                    <p>안드로이드 + iOS 앱 월 구독</p>
                    <!-- <p>구글 Play 스토어 앱 등록</p> -->
                    <!-- <p>애플 App Store 앱 등록</p> -->
                    <p>관리자 페이지</p>
                    <p>월 유지보수</p>
                  </div>
                </td>
              </tr>

              <tr class="request-line">
                <th class="th">신청 및 사용 방법</th>
                <!-- <td colspan="2" class="way-install end-r">
                  <div>
                    <div class="step-item">
                      <p class="icon"></p>
                      <p class="step-title">상품 선택 및 결제</p>
                    </div>
                    <div class="step-item">
                      <p class="icon"></p>
                      <p class="step-title">앱 세팅</p>
                      <ul>
                        <li>개발자 계정 생성</li>
                        <li>로고 등 앱 세팅<br />필요 자료 준비</li>
                      </ul>
                    </div>
                    <div class="step-item">
                      <p class="icon"></p>
                      <p class="step-title">APK 및 모바일 웹 사용</p>
                      <ul>
                        <li>전달 받은 파일로<br />바로 앱 사용 시작!</li>
                      </ul>
                    </div>
                  </div>
                </td> -->
                <td colspan="2"
                  class="way-store">
                  <div>
                    <div class="step-item">
                      <p class="icon"></p>
                      <p class="step-title">상품 선택 및 결제</p>
                    </div>
                    <div class="step-item">
                      <p class="icon"></p>
                      <p class="step-title">앱 세팅</p>
                      <ul>
                        <li>개발자 계정 생성</li>
                        <li>로고 등 앱 세팅<br />필요 자료 준비</li>
                        <li>스토어별 앱 등록<br />필요 자료 준비</li>
                      </ul>
                    </div>
                    <div class="step-item">
                      <p class="icon"></p>
                      <p class="step-title">APK 및 모바일 웹 사용</p>
                      <ul>
                        <li>전달 받은 파일로<br />바로 앱 사용 시작!</li>
                      </ul>
                    </div>
                    <div class="step-item">
                      <p class="icon"></p>
                      <p class="step-title">스토어 등록</p>
                    </div>

                    <div class="step-item">
                      <p class="icon"></p>
                      <p class="step-title">앱 다운로드 후 사용</p>
                      <ul>
                        <li>등록한 스토어에서<br />앱 다운로드 후 사용</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- 테블릿, 모바일 -->
        <div class="tablet"
          *ngIf="windowWidth <= 1200">
          <!-- <div class="table-one">
            <h3>설치형 상품</h3>
            <div class="table-wrap">
              <table>
                <thead>
                  <tr>
                    <th rowspan="2" class="th">상품명</th>
                    <th colspan="2" class="p-th">
                      <div>
                        <p class="product-title">스타터팩구독</p>
                        <p class="item-title">설치형 상품</p>
                      </div>
                    </th>
                  </tr>
                  <tr class="th-flex">
                    <th>안드로이드</th>
                    <th>안드로이드+모바일 웹</th>
                  </tr>
                </thead>

                <tbody>
                  <tr class="feature-line">
                    <th class="th">특징</th>
                    <td colspan="2">
                      <div>
                        <p>
                          회사에서, 친구끼리… 우리끼리 사용할 앱이 필요한
                          사용자를 위한 전용 상품
                        </p>
                        <p>
                          스토어에 등록하지 않아요! 앱 등록 기간 절약, 세팅 후
                          바로 사용 가능
                        </p>
                        <p>스타터팩구독보다 슬림한 요금제</p>
                      </div>
                    </td>
                  </tr>
                  <tr class="row">
                    <th class="th">제공 서비스</th>
                    <div class="th-flex blk" *ngIf="windowWidth <= 767">
                      <th>안드로이드</th>
                      <th>안드로이드+모바일 웹</th>
                    </div>
                    <td class="td-pa">
                      <div>
                        <div class="popover-area pb">
                          <p>안드로이드 APK</p>
                          <button
                            type="button"
                            class="popover-btn"
                            placement="bottom-left"
                            ngbPopover="APK (Android Application Package)이란 안드로이드용 앱 설치 파일로 Android에서만 사용 가능합니다."
                          ></button>
                        </div>
                        <p>관리자 페이지</p>
                        <p>월 유지보수</p>
                      </div>
                    </td>
                    <td class="td-pa">
                      <div>
                        <p>안드로이드 APK</p>
                        <div class="popover-area pt">
                          <p>모바일 웹 호스팅</p>
                          <button
                            type="button"
                            class="popover-btn"
                            placement="bottom-right"
                            popoverClass="popover-right"
                            ngbPopover="모바일 웹이란 별도의 앱 설치 없이 스마트 폰 등 모바일 기기에서 바로 확인 가능하도록 제작된 웹 사이트입니다."
                          ></button>
                        </div>
                        <p>관리자 페이지</p>
                        <p>월 유지보수</p>
                      </div>
                    </td>
                  </tr>

                  <tr class="request-line">
                    <th class="th">신청 및 사용 방법</th>
                    <td colspan="2" class="way-install">
                      <div>
                        <div class="step-item">
                          <p class="icon"></p>
                          <p class="step-title">상품 선택 및 결제</p>
                        </div>
                        <div class="step-item">
                          <p class="icon"></p>
                          <div>
                            <p class="step-title">앱 세팅</p>
                            <ul>
                              <li>개발자 계정 생성</li>
                              <li>
                                로고 등 앱 세팅<br
                                  *ngIf="windowWidth > 767"
                                />필요 자료 준비
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="step-item">
                          <p class="icon"></p>
                          <div>
                            <p class="step-title">APK 및 모바일 웹 사용</p>
                            <ul>
                              <li>
                                전달 받은 파일로
                                <br *ngIf="windowWidth > 767" />바로 앱 사용
                                시작!
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p class="guide-text">
                - 표시된 가격은 부가세 별도로 선택한 요금제 금액이 한번에
                결제됩니다.
              </p>
            </div>
          </div> -->
          <div class="table-one">
            <h3>스타터팩구독</h3>
            <div class="table-wrap">
              <table>
                <thead>
                  <tr class="thead-row">
                    <th rowspan="2"
                      class="th">상품명</th>

                    <!-- <th colspan="2" class="p-th">
                      <div>
                        <p class="product-title">스타터팩구독</p>
                        <p class="item-title">스타터팩구독</p>
                      </div>
                    </th> -->

                    <th class="p-th">
                      <p class="item-title">안드로이드</p>
                    </th>
                    <th class="p-th">
                      <p class="item-title">안드로이드 + iOS</p>
                    </th>
                  </tr>
                  <!-- <tr class="th-flex">
                    <th>안드로이드</th>
                    <th>안드로이드+iOS</th>
                  </tr> -->
                </thead>

                <tbody>
                  <tr class="feature-line">
                    <th class="th">특징</th>

                    <td colspan="2">
                      <div>
                        <!-- <p>
                          스타터팩만 선택하면 스토어 등록 대행까지 한 번에!
                        </p> -->
                        <p>
                          고가의 앱 구축 비용없이 월 사용료만 내면 전문가가 만든
                          앱을 사용 가능
                        </p>
                        <p>3개월부터 선택 가능한 부담없는 사용 기간</p>
                      </div>
                    </td>
                  </tr>
                  <tr class="row">
                    <th class="th">제공 서비스</th>
                    <div class="th-flex blk"
                      *ngIf="windowWidth <= 767">
                      <th>안드로이드</th>
                      <th>안드로이드+iOS</th>
                    </div>
                    <td class="td-pa">
                      <div>
                        <p>안드로이드 앱 월 구독</p>
                        <!-- <p>구글 Play 스토어 앱 등록</p> -->
                        <p>관리자 페이지</p>
                        <p>월 유지보수</p>
                      </div>
                    </td>
                    <td class="td-pa">
                      <div>
                        <p>안드로이드 + iOS 앱 월 구독</p>
                        <!-- <p>구글 Play 스토어 앱 등록</p> -->
                        <!-- <p>애플 App Store 앱 등록</p> -->
                        <p>관리자 페이지</p>
                        <p>월 유지보수</p>
                      </div>
                    </td>
                  </tr>

                  <tr class="request-line">
                    <th class="th">신청 및 사용 방법</th>

                    <td colspan="2"
                      class="way-store">
                      <div>
                        <div class="step-item">
                          <p class="icon"></p>
                          <p class="step-title">상품 선택 및 결제</p>
                        </div>
                        <div class="step-item">
                          <p class="icon"></p>
                          <div>
                            <p class="step-title">앱 세팅</p>
                            <ul>
                              <li>개발자 계정 생성</li>
                              <li>
                                로고 등 앱 세팅<br *ngIf="windowWidth > 767" />필요 자료 준비
                              </li>
                              <li>
                                스토어별 앱 등록<br *ngIf="windowWidth > 767" />필요 자료 준비
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div class="step-item">
                          <p class="icon"></p>
                          <div>
                            <p class="step-title">APK 및 모바일 웹 사용</p>
                            <ul>
                              <li>
                                전달 받은 파일로
                                <br *ngIf="windowWidth > 767" />바로 앱 사용
                                시작!
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="step-item">
                          <p class="icon"></p>
                          <p class="step-title">스토어 등록</p>
                        </div>
                        <div class="step-item">
                          <p class="icon"></p>
                          <div>
                            <p class="step-title">앱 다운로드 후 사용</p>
                            <ul>
                              <li>
                                등록한 스토어에서<br *ngIf="windowWidth > 767" />앱 다운로드 후 사용
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p class="guide-text">
                - 표시된 가격은 부가세 별도로 선택한 요금제 금액이 한번에
                결제됩니다.
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- 소유형 스타터팩 -->
      <div class="ownership"
        *ngIf="purchaseType === 'ownership'">
        <div class="table-wrap ownership-table">
          <table class="border-none">
            <thead>
              <tr class="thead-row">
                <th class="th">상품명</th>
                <th class="p-th-b">
                  <div>
                    <p class="product-title">스타터팩</p>
                    <p class="item-title">안드로이드</p>
                  </div>
                </th>
                <th class="p-th-b">
                  <div>
                    <p class="product-title">스타터팩</p>
                    <p class="item-title">안드로이드 + iOS</p>
                  </div>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr class="feature-line">
                <th class="th">특징</th>
                <td colspan="2">
                  <div>
                    <p>
                      경험 많은 앱 전문가가 만든 <span>완성도 높은</span> 소스
                      코드
                    </p>
                    <p>
                      <span>짧은 세팅 기간, 저렴한 비용</span>으로 나만의 앱을
                      소유
                    </p>
                    <p>
                      소유한 소스 코드는 <span>자유롭게 수정</span> 가능
                      <span class="just"> (단, 소스 코드 재판매는 불가)</span>
                    </p>
                  </div>
                </td>
              </tr>
              <tr class="row">
                <th class="th">제공 서비스</th>
                <div class="th-flex blk"
                  *ngIf="windowWidth <= 767">
                  <th>안드로이드</th>
                  <th>안드로이드+iOS</th>
                </div>
                <td class="td-pa">
                  <div>
                    <p>안드로이드 앱 소스 코드</p>
                    <p>관리자 페이지 소스 코드</p>
                  </div>
                </td>
                <td class="td-pa">
                  <div>
                    <p>안드로이드 앱 소스 코드</p>
                    <p>iOS 앱 소스 코드</p>
                    <p>관리자 페이지 소스 코드</p>
                  </div>
                </td>
              </tr>

              <tr class="request-line">
                <th class="th">신청 및 사용 방법</th>
                <td colspan="2"
                  class="way-install">
                  <div>
                    <div class="step-item">
                      <p class="icon"></p>
                      <p class="step-title">상품 선택 및 결제</p>
                    </div>
                    <div class="step-item">
                      <p class="icon"></p>
                      <div>
                        <p class="step-title">앱 세팅</p>
                        <ul>
                          <li>개발자 계정 생성</li>
                          <li>
                            로고 등 앱 세팅<br *ngIf="windowWidth <= 1200 && windowWidth > 767" />
                            필요 자료 준비
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="step-item">
                      <p class="icon"></p>
                      <div>
                        <p class="step-title">소스코드 전달</p>
                        <ul>
                          <li>
                            전달 받은 소스코드로<br *ngIf="windowWidth <= 1200 && windowWidth > 767" />
                            앱 사용
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <p class="guide-text"
            *ngIf="windowWidth <= 1200">
            - 표시된 가격은 부가세 별도입니다.
          </p>
        </div>
      </div>

      <div class="btn-wrap">
        <button type="button"
          class="line-btn"
          (click)="pdList()">
          스타터팩제품 보기
        </button>
      </div>
    </section>
  </div>
</main>
<footer-share id="footerWrap"></footer-share>
<footer-share class="mobile-footer"></footer-share>