const templates = [
  {
    id: 'one',
    image: '../../../assets/imgs/detailRenewal/design-sample01.png',
    chapter: '01',
    feature: '깔끔한 / 세련된 / 기본적인',
    desc: `직선과 여백을 활용한 구성으로 깔끔함과 세련됨이 느껴지는 디자인이에요.\n높은 채도의 색상으로 카테고리를 분명히 구분할 수 있어요.\n의류부터 식품까지 다양하게 활용해보세요.`,
  },
  {
    id: 'two',
    image: '../../../assets/imgs/detailRenewal/design-sample02.png',
    chapter: '02',
    feature: '심플한 / 명확한 / 모던한',
    desc: `의류, 리빙, 명품, 액세서리 등 상품 종류에 구애받지 않고\n사용할 수 있는 심플하고 명확한 디자인이에요.\n색상은 적게 사용하되 강한 명도 대비로 중요한 정보를 구분할 수 있어요.`,
  },
  {
    id: 'three',
    image: '../../../assets/imgs/detailRenewal/design-sample03.png',
    chapter: '03',
    feature: '둥근 / 부드러운 / 편안한',
    desc: `깔끔한 구성에 청량한 색상을 사용한 디자인이에요.\n둥근 모서리를 다양하게 활용해 부드러운 느낌까지 더했어요.\n편안하고 부드러운 디자인의 쇼핑몰을 원한다면 선택하세요!`,
  },
  {
    id: 'four',
    image: '../../../assets/imgs/detailRenewal/design-sample04.png',
    chapter: '04',
    feature: '밝은 / 활기찬 / 즐거운',
    desc: `화장품, 영양제, 아동, 반려동물 등에 어울리는 밝고 활기찬 디자인이에요.\n둥글고 부드러운 요소에 간단한 그래픽 포인트로 재미까지 더했어요!`,
  },
];

export { templates };
