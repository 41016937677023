import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import _ from 'lodash';
import { first } from 'rxjs/operators';
import { DbService } from 'src/app/service/db.service';
@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss'],
})
export class PortfolioComponent implements OnInit, OnDestroy {
  // portfolioMain = [
  //   {
  //     title: '한라',
  //     con: ` 우리는 한라 앱에서 제공하는 다양한 서비스를 사용자가 쉽고\n
  //   편하게 사용할 수 있도록 최적화된 플로우와 화면을 구현하고\n
  //   연구 개발 하였습니다.`,
  //     imgs: [
  //       {
  //         img1: '',
  //         time: 0,
  //       },
  //       {
  //         img1: '',
  //         time: 0,
  //       },
  //       {
  //         img1: '',
  //         time: 0,
  //       },
  //     ],
  //   },
  // ];
  mobileSwitch = false;
  count = 0;

  appIcon4 = [{ img: 'assets/imgs/portfolio-imgs/pf4-icon-1.png', time: -3 }];
  allPfList = [];

  AllArr: any;
  mainArray: any;
  pfListArray: any;
  mainIcon: any;

  constructor(
    private db: DbService,
    private router: Router,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  getHeight(list) {
    if (list.length <= 3) {
      return '644px';
    } else if (list.length > 3 && list.length <= 6) {
      return '1030px';
    } else {
      return '1630px';
    }
  }

  getData(): Promise<any> {
    return this.db.collection$(`portfolio`).pipe(first()).toPromise();
  }

  ngOnInit(): void {
    this.getData()
      .then((data) => {
        this.mainArray = data
          .filter((data) => data && data.mainPick == true)
          .map((data) => {
            const time = Math.floor(Math.random() * 350) + 100;

            if (data.title == '테라피플') {
              data.time = '800';
            } else {
              data.time = time;
            }

            return data;
          })
          .sort(function (a, b) {
            return a.mainIndex - b.mainIndex;
          });

        this.mainIcon = [];
        this.mainIcon.push(
          { img: this.mainArray[0].icon, time: -3 },
          { img: this.mainArray[5].icon, time: 4 },
          { img: this.mainArray[6].icon, time: -2 },
          { img: this.mainArray[9].icon, time: 3 },
          { img: this.mainArray[12].icon, time: -3 }
        );

        this.pfListArray = data
          .filter((data) => data && data.mainPick != true)
          .map((data) => {
            const time = Math.floor(Math.random() * 200) + 50;
            data.time = time;

            if (data.type == 'horizontal') {
              data.appImg = data.horizontalImg;
            }
            return data;
          })
          .sort(function (a, b) {
            return b.startDate - a.startDate;
          });

        //배열 9개씩 나누기
        this.allPfList = [];
        for (let i = 0; i < this.pfListArray.length; i += 9)
          this.allPfList.push(this.pfListArray.slice(i, i + 9));

        this.AllArr = this.mainArray.concat(this.pfListArray);
      })
      .then(() => {
        this.showAnim();
      });
    //   let script2 = this._renderer2.createElement('script');
    //   script2.type = 'text/javascript';
    //   script2.text = `var _nasa={};
    //   if (!wcs_add) var wcs_add = {};
    // wcs_add["wa"] = "s_52ea45ec186a";
    // if (!_nasa) var _nasa = {};
    // if (window.wcs) {
    //   wcs.inflow("startapp.co.kr");
    //   wcs_do(_nasa);}
    //   `;
    //   this._renderer2.appendChild(this._document.body, script2);
    let that = this;

    // this.stickyBtnActive();
    // this.stickyBtnRelative();
    //마우스 이벤트
    // document.addEventListener('mousemove', parallax);
    // function parallax(e) {
    //   this.querySelectorAll('.layer').forEach((layer) => {
    //     const speed = layer.getAttribute('data-speed');

    //     const x = (window.innerWidth - e.pageX * speed) / 100;
    //     const y = (window.innerHeight - e.pageY * speed) / 100;

    //     layer.style.transform = `translateX(${x}px) translateY(${y}px)`;
    //   });
    // }
  }

  ngOnDestroy(): void {
    $(window).scroll().off();
  }
  showAnim() {
    $(function () {
      $(window).on('beforeunload', function () {
        $(window).scrollTop(0);
      });

      function pageAni() {
        let windowWidth = $(window).width();

        if (windowWidth > 1023) {
          $('.opacity-on').each((index, item) => {
            const time = item.getAttribute('data-img-speed');

            $(item)
              .stop()
              .delay(time * index)
              .animate({ opacity: 1 }, 400)
              .queue(function () {
                $('.list-item').hover(
                  function () {
                    $(this).find('.hover-box').stop().animate(
                      {
                        opacity: 1,
                      },
                      150
                    );
                  },
                  function () {
                    $(this).find('.hover-box').stop().animate(
                      {
                        opacity: 0,
                      },
                      150
                    );
                  }
                );
              });
          });
        }
        if (windowWidth <= 1023) {
          $('.opacity-on').each((index, item) => {
            const time = item.getAttribute('data-img-speed');
            $(item)
              .stop()
              .delay(time * index)
              .animate({ opacity: 1 }, 400);
          });
        }

        // window.resize(function(){

        // })
      }

      pageAni();

      $(window).resize(() => {
        let windowWidth = $(window).width();
      });
      // $(window).on('resize', _.debounce(pageAni));
      $(window).on(
        'resize',
        _.debounce(function () {
          pageAni();
        }, 100)
      );
    });
  }

  stickyBtnActive() {
    const stickyBtn = document.querySelector<HTMLElement>('sticky-button p');
    window.addEventListener('scroll', function () {
      let scrollY = window.scrollY;
      if (scrollY > 10) {
        stickyBtn.classList.add('text-ac');
      }
    });
  }

  stickyBtnRelative() {
    window.addEventListener('scroll', function () {
      const $stickyBtn = $('sticky-button');
      let $btmLine = $('.all-wrap');
      let $btmLineTop = $btmLine.offset().top;
      let $btmLineHeight = $btmLine.outerHeight();
      let trigger = $btmLineTop + $btmLineHeight;

      let docHeight = window.scrollY + window.innerHeight;

      if (trigger <= docHeight) {
        $stickyBtn.css({
          position: 'absolute',
        });
      } else {
        $stickyBtn.css({
          position: 'fixed',
        });
      }
    });
  }

  goHanra() {
    this.router.navigateByUrl('/portfolio-hanra').then(() => {
      //window.location.reload();
    });
  }

  goPfDetail(id) {
    this.router.navigateByUrl(`/portfolio-detail/${id}`).then(() => {
      //window.location.reload();
    });
  }
}
