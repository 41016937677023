import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-admin-sample-pop',
  templateUrl: './admin-sample-pop.component.html',
  styleUrls: ['./admin-sample-pop.component.scss'],
})
export class AdminSamplePopComponent implements OnInit {
  loading: boolean = true;
  adminUrl: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<AdminSamplePopComponent>
  ) {
    this.adminUrl = data.adminUrl;
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const siteFrame = document.getElementById('site');
    siteFrame.setAttribute('src', this.adminUrl);
  }

  onMyFrameLoad(e) {
    this.loading = false;
  }

  close() {
    this.dialogRef.close();
  }
}
