<!-- <header-share></header-share> -->
<main id="main">

  <section class="container-wrap">
    <article class="store-info left store1"
      (click)="goDetail('shopping')">
      <div class="image-area">
        <div class="img-inner-off">
          <img class="off"
            src="assets/imgs/store-1.png"
            alt="" />
        </div>
        <div class="img-inner-on">
          <img class="on"
            src="assets/imgs/store-1-hover.png"
            alt="" />
        </div>
      </div>
      <div class="txt-area">
        <span class="sub-title">소비자의 지갑을 여는</span>
        <h4 class="article-title">쇼핑몰 APP</h4>
        <span class="price-txt">₩ 3,300,000원 부터 ~</span>
        <p class="article-info">
          다양한 상품들을 한 눈에!<br />
          카테고리별 인기상품 확인, 신속정확한 회원 / 매출 관리
        </p>
      </div>
    </article>
    <article class="store-info rigth store2"
      (click)="goDetail('community')">
      <div class="image-area">
        <div class="img-inner-off light">
          <img class="off"
            src="assets/imgs/store-2.png"
            alt="" />
        </div>
        <div class="img-inner-on">
          <img class="on"
            src="assets/imgs/store-2-hover.png"
            alt="" />
        </div>
      </div>
      <div class="txt-area">
        <span class="sub-title">쉴새 없이 드나드는</span>
        <h4 class="article-title">커뮤니티 APP</h4>
        <span class="price-txt">₩ 1,100,000원 부터 ~</span>
        <p class="article-info">
          필터 기능을 통해 원하는 게시물에 빠르게 접근,<br />귀찮은 페이지 이동
          없이 디테일한 이미지 확인,<br />커뮤니티 이용자들과 다양한 방법으로
          소통
        </p>
      </div>
    </article>
    <article class="store-info left store3"
      (click)="goDetail('matching')">
      <div class="image-area">
        <div class="img-inner-off light">
          <img class="off"
            src="assets/imgs/store-3.png"
            alt="" />
        </div>
        <div class="img-inner-on">
          <img class="on"
            src="assets/imgs/store-3-hover.png"
            alt="" />
        </div>
      </div>
      <div class="txt-area">
        <span class="sub-title">서로의 필요에 의한</span>
        <h4 class="article-title">매칭 APP</h4>
        <span class="price-txt">₩ 5,500,000원 부터 ~</span>
        <p class="article-info">
          고객 / 전문가용으로 나뉘어 활발한 매칭 구조,<br />자유로운 소통 &
          후기, 간편한 결제 기능
        </p>
      </div>
    </article>
    <article class="store-info rigth store4"
      (click)="goDetail('hospital')">
      <div class="image-area">
        <div class="img-inner-off">
          <img class="off"
            src="assets/imgs/store-4.png"
            alt="" />
        </div>
        <div class="img-inner-on">
          <img class="on"
            src="assets/imgs/store-4-hover.png"
            alt="" />
        </div>
      </div>
      <div class="txt-area">
        <span class="sub-title">소비자들이 원하는 병원정보</span>
        <h4 class="article-title">병원 APP</h4>
        <span class="price-txt">₩ 5,500,000원 부터 ~</span>
        <p class="article-info">
          병원 등록과 홍보를 동시에,<br />상세한 병원 정보 등록, 리뷰를 통한
          피드백
        </p>
      </div>
    </article>
    <article class="store-info left store5"
      (click)="goDetail('delivery')">
      <div class="image-area">
        <div class="img-inner-off light">
          <img class="off"
            src="assets/imgs/store-5.png"
            alt="" />
        </div>
        <div class="img-inner-on">
          <img class="on"
            src="assets/imgs/store-5-hover.png"
            alt="" />
        </div>
      </div>
      <div class="txt-area">
        <span class="sub-title">주문이 끊이질 않는</span>
        <h4 class="article-title">배달 APP</h4>
        <span class="price-txt">₩ 11,000,000원 부터 ~</span>
        <p class="article-info">
          주소 등록으로 근처 맛집을 한번에,<br />가맹점과 소비자를 연결하는
          후기,<br />
          손쉽고 빠른 주문 시스템
        </p>
      </div>
    </article>
    <article class="store-info rigth store6"
      (click)="goDetail('education')">
      <div class="image-area">
        <div class="img-inner-off">
          <img class="off"
            src="assets/imgs/store-6.png"
            alt="" />
        </div>
        <div class="img-inner-on">
          <img class="on"
            src="assets/imgs/store-6-hover.png"
            alt="" />
        </div>
      </div>
      <div class="txt-area">
        <span class="sub-title">학부모님들에 사랑받는</span>
        <h4 class="article-title">교육 APP</h4>
        <span class="price-txt">₩ 5,500,000원 부터 ~</span>
        <p class="article-info">
          간편한 수업 신청과 선생님 매칭,<br />자녀의 수업 내용 확인 및
          선생님과의 소통,<br />선생님은 편리한 수업 일정관리
        </p>
      </div>
    </article>
  </section>

  <section class="bottom-line">
    <div class="bottom_box">
      <!-- <h2>스타트앱</h2> -->
      <p>더 다양한 앱들이 여러분을 찾아옵니다.</p>
      <span>Comming soon</span>
    </div>
  </section>
</main>
<footer-share id="footerWrap"></footer-share>
<footer-share class="mobile-footer"></footer-share>