import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-solution-ownership-pc',
  templateUrl: './solution-ownership-pc.component.html',
  styleUrls: ['./solution-ownership-pc.component.scss']
})
export class SolutionOwnershipPcComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
