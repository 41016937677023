<main id="main"
  style="background: #f3f5f9">
  <section id="form-section"
    class="bg-w">
    <div class="w1200 top">
      <h2>
        다양하고 풍부하게<br />원하는 웹을 <span>지금 바로 </span>
        <span>선택하세요!</span>
      </h2>
      <p>다양한 분야의 웹 페이지를 확인해보세요.</p>

      <!-- <div class="search-wrap">
        <input type="search"
          id="search-input"
          [(ngModel)]="term"
          placeholder="검색어를 입력해 주세요." />
        <button type="button"
          id="search-btn"></button>
      </div> -->
    </div>
  </section>
  <!-- <section id="form-section"
    class="sticky">
    <div class="w1200 cate">
      <div class="category"
        [ngClass]="{ open: categoryView }">
        <ng-container *ngIf="dataService.webCategories as webCategories">
          <div class="category-item">
            <p class="cate-tit">웹페이지</p>

            <mat-button-toggle-group name="solution"
              aria-label="solution type"
              [(ngModel)]="solutionType"
              (change)="changeFilter()"
              class="toggle-category">
              <mat-button-toggle value="all">
                <p>전체</p>
              </mat-button-toggle>
              <mat-button-toggle value="{{ item.id }}"
                *ngFor="let item of webCategories">
                <p>{{ item.title }}</p>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div class="category-item">
            <p class="cate-tit">기능</p>

            <mat-button-toggle-group name="filter"
              aria-label="pg type"
              [(ngModel)]="includeFeature"
              (change)="changeFilter()"
              class="toggle-category one">
              <mat-button-toggle [value]="'all'">
                <p>전체</p>
              </mat-button-toggle>
              <mat-button-toggle [value]="'원페이지'">
                <p>원페이지</p>
              </mat-button-toggle>
              <mat-button-toggle [value]="'문의하기'">
                <p>문의하기</p>
              </mat-button-toggle>
              <mat-button-toggle [value]="'결제기능'">
                <p>결제기능</p>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </ng-container>
        <button type="button"
          class="cate-button"
          (click)="viewChange()">
          <div class="img-wrap">
            <img src="./assets/imgs/solution-cate-arrow.png"
              alt="" />
          </div>
        </button>
      </div>
    </div>
  </section> -->
  <section id="form-section category-list"
    class="category-list">
    <div class="w1200"
      *ngIf="selectApps$ | async as apps">
      <div class="product-list">
        <div class="product"
          *ngFor="let item of (apps | filter: term); let i = index">
          <!-- 슬라이드 -->
          <div class="slide-wrap">
            <div class="swiper-container product-swiper{{ i }}">
              <div class="swiper-wrapper">
                <div class="swiper-slide"
                  *ngFor="let image of item.images">
                  <img src="{{ image }}"
                    (click)="pdDetail(item.id)"
                    (load)="listImage.style.opacity = '1'"
                    #listImage
                    class="image"
                    alt="상품이미지" />
                </div>
              </div>
            </div>
          </div>

          <!-- 호버 시 나오는 설명, 버튼 -->
          <div class="hover-before">
            <div class="inner">
              <p class="tit">
                {{ item.title }}
              </p>
              <p class="type">
                <span> #WS-{{item.order}}</span>
                <!-- <span *ngFor="let tag of item.tag"> #{{ tag }}</span> -->
              </p>
            </div>
            <!-- 슬라이드 이동 -->
            <div class="slide-change"
              *ngIf="item.images.length>1">
              <div class="swiper-button-prev button-prev{{ i }}"></div>
              <div class="swiper-button-next button-next{{ i }}"></div>
            </div>
            <!-- 하단 버튼 -->
            <div class="product-btns">
              <button type="button"
                class="button"
                (click)="openweb(item)">
                미리보기
              </button>

              <button type="button"
                class="button"
                (click)="inquiry(item.id)">
                해당 상품 문의하기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <footer-share id="footerWrap"></footer-share>
  <footer-share class="mobile-footer"></footer-share>
</main>