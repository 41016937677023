import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as firebase from 'firebase';
import * as $ from 'jquery';
import { filter, first, take } from 'rxjs/operators';
import { PopUpComponent } from 'src/app/pop-up/pop-up.component';
import { DbService } from 'src/app/service/db.service';
import Swiper, { Autoplay } from 'swiper';
import SwiperCore, { Navigation, Pagination, Mousewheel } from 'swiper/core';
import { InquirySimplePopComponent } from '../inquiry-simple-pop/inquiry-simple-pop.component';
import { TrackingService } from 'src/app/service/tracking.service';
import { DeviceService } from 'src/app/service/device.service';

// configure Swiper to use modules
SwiperCore.use([Navigation, Pagination, Mousewheel, Autoplay]);
@Component({
  selector: 'app-service-info',
  templateUrl: './service-info.component.html',
  styleUrls: ['./service-info.component.scss'],
})
export class ServiceInfoComponent implements OnInit {
  portfolio = [];
  appIcon = [{ img: 'assets/imgs/portfolio-imgs/pf-icon-1.png', time: -3 }];

  lodingSwitch = false;
  showBanner = false;
  myDevice = navigator.platform;
  realMain = false;

  serviceInfo; // 통계 정보
  inquiryNumber = 0; // 프로젝트 문의 수
  projectNumber = 0; // 누적 프로젝트 수
  continueNumber = 0; // 계약 연장 비율
  integerContinueNumber = 0; // 계약 연장 비율 정수

  // 고객 후기
  reviews = [
    {
      title: '프로세스가 다른 곳',
      desc: '스타트앱과 함께하면서 그들의 접근법이 얼마나 다른지 느낄 수 있었습니다. 기획부터 마케팅까지, 모든 과정이 체계적이고 효율적이었어요. 이런 프로세스 덕분에 프로젝트 진행이 무척이나 원활했습니다. 정말로 만족스럽네요!',
      companyName: '통신 서비스',
      name: 'CEO 박승훈',
    },
    {
      title: '유지보수 믿음이 가네요',
      desc: '스타트앱은 유지보수를 정확하고 신뢰성 있게 처리해주더라고요. 이전에는 유지보수가 얼마나 중요한 것인지 몰랐는데, 이제는 그 중요성을 깊이 이해하게 되었습니다. 스타트앱과 함께해서 감사하다는 말씀 드리고 싶네요',
      companyName: '여행 서비스',
      name: '매니저 김유진',
    },
    {
      title: '최저가의 진실을 알게 해준',
      desc: '견적 문제로 골치 아팠던 경험이 있었는데, 스타트앱은 꼼수 없는 합리적인 견적을 제공하더라고요. 데이터를 기반으로 나온 견적이라 더욱 신뢰가 갔습니다',
      companyName: '건설업',
      name: '실장 백정택',
    },
    {
      title: '상담에서의 만족',
      desc: '다른 곳에서는 최저가 상담 후 실망한 적이 많았는데, 스타트앱은 그렇지 않았어요. 견적은 합리적이였고, 서비스는 품질이 높았습니다. 진짜로 만족스러운 경험이었습니다',
      companyName: '부동산 플랫폼',
      name: '기획팀장 조민경',
    },
    {
      title: '너무나 깔끔한 진행',
      desc: '이전에 다른 업체와 일할 때, 개발 기간이나 유지보수, 추가금액적으로 마찰이 생기는 경우가 많았어요. 하지만 스타트앱은 그런 일 없이 깔끔하게 프로젝트를 진행해줬습니다',
      companyName: '의료 서비스',
      name: '담당 김태희',
    },
    {
      title: '데이터 기반의 기획',
      desc: '스타트앱은 저희가 원하는 니즈를 기반으로, 그에 맞는 데이터적 기획을 해주더라고요. 전문성과 경험이 느껴지는 순간이었습니다. 이런 전문적인 접근 방식 덕분에 프로젝트가 성공적으로 마무리될 수 있었어요. 다음에도 또 다른 프로젝트로 함께하면 좋을 거 같아요',
      companyName: '화물 서비스',
      name: '대표이사 장태훈',
    },
    {
      title: '디테일한 관리의 끝판왕',
      desc: '스타트앱과 일하면서 그들이 얼마나 디테일하게 일을 관리하는지 보았습니다. 보통 계약 진행하고 나서는 대충대충 하는 업체들도 많은데 스타트앱은 최초 미팅부터 개발 완료까지, 디테일하게 케어해주고 문제 없이 개발이 완료되었습니다.',
      companyName: '유통 서비스',
      name: '마케팅 이사 유진아',
    },
    {
      title: '최고의 파트너',
      desc: '아이디어만 있지 실제 개발은 꿈도 못 꾸고 있었는데 아이디어의 문제점 파악 및 해결을 비롯해서 저에게 맞는 기능과 스타터팩을 제시해주셔서 문제 없이 사업을 진행하고 있습니다. 감사합니다.',
      companyName: '반려동물 스타트업',
      name: '예비 기업가 이현희',
    },
    {
      title: '신뢰 그 한마디면 끝',
      desc: '미팅을 할 때 느꼈습니다. 아 이곳은 다른 곳과 다르구나 상담 담당자부터 메인 담당자님까지 확실한 경험과 데이터를 기반하여 설명해주고 오히려 비싼 비용보다는 합리적인 형태로 개발을 안내해주시는 거 보고 돈만 밝히는 다른 업체들과는 다르다는 걸 느꼈습니다.',
      companyName: '디자인 서비스',
      name: '운영팀 송중현',
    },
  ];

  constructor(
    private db: DbService,
    private router: Router,
    public dialog: MatDialog,
    private deviceService: DeviceService,
    private _renderer2: Renderer2,
    private httpClient: HttpClient,
    private trackingService: TrackingService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.getData()
      .then((data) => {
        this.portfolio = data
          .filter((data) => data && data.mainPick == true)
          .sort(function (a, b) {
            return a.mainIndex - b.mainIndex;
          })
          .slice(0, 6);
      })
      .then(() => {
        // this.openPopup('agreePopup');
      });
  }

  @ViewChild('myVideoElement') myVideoElement: ElementRef;

  //쿠키 있는지 없는지 활용해서 팝업 열기
  openPopup(winName) {
    var blnCookie = this.getCookie(winName);

    if (!blnCookie) {
      const dialog = this.dialog.open(PopUpComponent, {
        panelClass: 'pop-up-dialog',
        backdropClass: 'pop-up-backdrop',
      });

      dialog.afterClosed().subscribe((data: any) => {
        if (data) {
          var todayDate = new Date();
          todayDate.setDate(todayDate.getDate() + 1);

          document.cookie =
            'agreePopup' +
            '=' +
            escape('expire') +
            '; path=/; expires=' +
            todayDate.toUTCString() +
            ';';
        }
      });
    }
  }

  // 쿠키 가져오기
  getCookie(name) {
    var nameOfCookie = name + '=';

    var x = 0;
    while (x <= document.cookie.length) {
      var y = x + nameOfCookie.length;

      if (document.cookie.substring(x, y) == nameOfCookie) {
        let endOfCookie = document.cookie.indexOf(';', y);

        if (endOfCookie == -1) endOfCookie = document.cookie.length;
        return unescape(document.cookie.substring(y, endOfCookie));
      }
      x = document.cookie.indexOf(' ', x) + 1;

      if (x == 0) break;
    }
    return '';
  }

  getData(): Promise<any> {
    return this.db.collection$(`portfolio`).pipe(first()).toPromise();
  }

  ngOnInit(): void {
    ///스와이퍼부터 작동
    this.swiperOptions();
  }

  // 최상단 배너 슬라이드
  async swiperOptions() {
    const swiperBanner = new Swiper('.home-banner-swiper', {
      slidesPerView: 'auto',
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    });

    setTimeout(() => {
      this.guideSwiper();
    }, 6000);
  }

  ngAfterViewInit(): void {
    // 비디오 음소거로 재생
    this.myVideoElement.nativeElement.currentTime = 0;
    this.myVideoElement.nativeElement.muted = true;
    this.myVideoElement.nativeElement.volume = 0;
    this.myVideoElement.nativeElement.play();

    // 비디오 영역 스크롤시 텍스트 애니메이션
    const io = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          entry.target.classList.remove('show');
          return;
        }
        entry.target.classList.add('show');
      });
    });

    // 비디오 영역 숫자 증가 애니메이션

    // element의 observer가 작동하면 돌아갈 애들
    const scrollIo = new IntersectionObserver((entries, observer) => {
      // observer가 작동할때 entries[0].isIntersecting 이게 true면서 inquiryNumber가 0일때.
      if (entries[0].isIntersecting && this.inquiryNumber == 0) {
      } else {
        // 만약에 entries[0].isIntersecting == false면 벗어난거고, 벗어나자마자 잽싸게 0으로 바꾼다. 그래야
        // 위에 if 에서 조건충족
        this.inquiryNumber = 0;
        this.projectNumber = 0;
        this.continueNumber = 0;
        this.integerContinueNumber = 0;
        return;
      }

      // 처음 수치
      this.inquiryNumber = 0;
      this.projectNumber = 0;
      this.continueNumber = 0;
      // 정수값
      this.integerContinueNumber = 0;

      // 0에서부터 올라가야 할 목표수치
      const targetInquiryNumber = 10000;
      const targetProjectNumber = 400;
      const targetContinueNumber = 928;

      // observe 되었을때 각각의 .number-text 가 증가하는 식을 만들어주는데
      // 목표치 에서 처음 수치 0을 빼준후 각각 일정한 비율로 증가하기위해 정수로 나눠준다
      const animateNumbers = () => {
        const inquiryIncrement = Math.ceil(
          (targetInquiryNumber - this.inquiryNumber) / 20
        );
        const projectIncrement = Math.ceil(
          (targetProjectNumber - this.projectNumber) / 50
        );
        const continueIncrement =
          (targetContinueNumber - this.integerContinueNumber) / 20;

        // 처음 수치가 목표치보다 작을때 처음수치는 처음수치에다 위에서 계산한 증가값을 더한 값이다
        if (this.inquiryNumber < targetInquiryNumber) {
          this.inquiryNumber += inquiryIncrement;
        }

        if (this.projectNumber < targetProjectNumber) {
          this.projectNumber += projectIncrement;
        }

        // 계약연장비율은 처음수치(정수)에 소수점0.1자리까지 계산을 위해 10을 먼저 곱해준다
        if (this.continueNumber * 10 < targetContinueNumber) {
          this.integerContinueNumber += continueIncrement;

          // 정수를 10으로 나누고 소수점 이하 1자리 까지 고정시킨값을 continueNumber에 할당
          this.continueNumber = parseFloat(
            (this.integerContinueNumber / 10).toFixed(1)
          );
        }

        // 각각의 처음 수치들이 목표치와 같지않는게 하나라도 참인경우 animateNumbers를 실행한다
        if (
          this.inquiryNumber != targetInquiryNumber ||
          this.projectNumber != targetProjectNumber ||
          this.continueNumber * 10 != targetContinueNumber
        ) {
          setTimeout(animateNumbers, 30);
        }
      };

      animateNumbers();
    });

    const infoEls = document.querySelectorAll('.ani');
    infoEls.forEach((el) => {
      io.observe(el);
    });
    const datawrap = document.querySelector('.amount-list');

    scrollIo.observe(datawrap);
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    let script = this._renderer2.createElement('script');
    script.type = 'text/javascript';
    script.text = `var _nasa={};if (!wcs_add) var wcs_add = {};
                   wcs_add["wa"] = "s_52ea45ec186a";
                   if (!_nasa) var _nasa = {};
  if (window.wcs) {
    wcs.inflow("startapp.co.kr");
    wcs_do(_nasa);
  }
    `;
    script.onload = () => {};
    this._renderer2.appendChild(this._document.body, script);
    $(function () {
      $('.back-img1').addClass('active');
      $('.back-img2').addClass('active');
      $('.back-img3').addClass('active');
      $('.back-img4').addClass('active');
      $('.back-img5').addClass('active');
      $('.back-img6').addClass('active');

      setTimeout(() => {
        $('.back-img1').removeClass('active');
        $('.back-img2').removeClass('active');
        $('.back-img3').removeClass('active');
        $('.back-img4').removeClass('active');
        $('.back-img5').removeClass('active');
        $('.back-img6').removeClass('active');
      }, 400);
    });

    setTimeout(() => {
      //로딩되자마자 메인 베너
      $('.real-main').animate({ opacity: 1 }, 1000);

      $('.back-img1').addClass('active');
      $('.back-img2').addClass('active');
      $('.back-img3').addClass('active');
      $('.back-img4').addClass('active');
      $('.back-img5').addClass('active');
      $('.back-img6').addClass('active');

      this.showAni();
    }, 1000);
  }

  ngOnDestroy(): void {
    this.dialog.closeAll();
    $(window).scroll().off();
  }

  // 성공을 위한 가이드 스와이퍼
  guideSwiper() {
    const swiper = new Swiper('.swiper-container', {
      loop: true,
      slidesPerView: 'auto',
      mousewheel: false,
      pagination: false,
      slideActiveClass: 'slide_ac',
      navigation: false,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
    });
  }

  showAni() {
    let windowWidth = $(window).width();
    $(function () {
      setTimeout(() => {
        /////제이쿼리

        if (windowWidth < 767) {
          const swiperM = new Swiper('.m-swiper-container', {
            mousewheel: false,
            autoplay: {
              delay: 3000,
              disableOnInteraction: false,
            },

            pagination: {
              el: '.swiper-pagination',
              type: 'bullets',
            },
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
            allowTouchMove: true,
            centeredSlides: true,
            slidesPerView: 'auto',
            loop: true,
            slideActiveClass: 'slide_ac',
          });

          let that = this;
          let filter = 'win16|win32|win64|mac|macintel';

          if (that.myDevice == filter) {
            $(window).resize(function (e) {
              let windowWidth = $(window).width();
              //width 체크
              $('.width').text(windowWidth);

              if (windowWidth < 767) {
                //리사이즈 될때마다 사이즈 체크
                let timerId = setInterval(() => {
                  swiperM.update();
                  swiperM.updateSize();
                }, 150);
                setTimeout(() => {
                  clearInterval(timerId);
                }, 5000);
              } else {
                ////767보다 클때
                $('sticky-button')
                  .stop()
                  .animate({ bottom: 40 }, 800)
                  .queue(function () {
                    $('sticky-button > .sticky_wrap > p').addClass('text-ac');
                  });
                $('.img-box.img1 img').attr('src', 'assets/imgs/img_ux.gif');
                $('.txt-area .txt1')
                  .find('h3')
                  .animate({ opacity: 1, top: 0 }, 1000);

                for (var i = 0; i < txtBox2.length; i++) {
                  for (let index = 0; index < temp2.length; index++) {
                    const element = temp2[index];

                    $('.txt-area .txt-box')
                      .eq(0)
                      .find('div p')
                      .addClass('txt-ani-ac')
                      .removeClass('txt-ani')
                      .animate({ opacity: 1 })
                      .find($(element))
                      .delay(50 * index)
                      .animate(
                        {
                          top: 0,
                          opacity: 1,
                          left: 0,
                        },
                        600
                      )
                      .queue(function () {
                        $('.txt1').find('a').animate({ opacity: 1 }, 400);
                      });
                  }

                  attrSwitch1 = true;
                }

                $('.img-box.img1 img').attr('src', 'assets/imgs/img_ux.gif');
                $('.txt-area .txt1')
                  .find('h3')
                  .animate({ opacity: 1, top: 0 }, 1000);
                for (var i = 0; i < txtBox2.length; i++) {
                  for (let index = 0; index < temp2.length; index++) {
                    const element = temp2[index];
                    $('.txt-area .txt-box')
                      .eq(0)
                      .find('div p')
                      .addClass('txt-ani-ac')
                      .removeClass('txt-ani')
                      .animate({ opacity: 1 })
                      .find($(element))
                      .delay(50 * index)
                      .animate(
                        {
                          top: 0,
                          opacity: 1,
                          left: 0,
                        },
                        600
                      )
                      .queue(function () {
                        $('.txt1').find('a').animate({ opacity: 1 }, 400);
                      });
                  }
                }

                $('.img-box.img2 img').attr('src', 'assets/imgs/img_ui.gif');
                $('.txt-area .txt2')
                  .find('h3')
                  .animate({ opacity: 1, top: 0 }, 1000);
                for (var i = 0; i < txtBox2.length; i++) {
                  for (let index = 0; index < temp2.length; index++) {
                    const element = temp2[index];
                    $('.txt-area .txt-box')
                      .eq(1)
                      .find('div p')
                      .addClass('txt-ani-ac')
                      .removeClass('txt-ani')
                      .animate({ opacity: 1 })
                      .find($(element))
                      .delay(50 * index)
                      .animate(
                        {
                          top: 0,
                          opacity: 1,
                          left: 0,
                        },
                        600
                      )
                      .queue(function () {
                        $('.txt2').find('a').animate({ opacity: 1 }, 400);
                      });
                  }
                  attrSwitch2 = true;
                  attrSwitch1 = true;
                }
                $('.img-box.img1 img').attr('src', 'assets/imgs/img_ux.gif');
                $('.txt-area .txt1')
                  .find('h3')
                  .animate({ opacity: 1, top: 0 }, 1000);

                for (var i = 0; i < txtBox2.length; i++) {
                  for (let index = 0; index < temp2.length; index++) {
                    const element = temp2[index];
                    $('.txt-area .txt-box')
                      .eq(0)
                      .find('div p')
                      .addClass('txt-ani-ac')
                      .removeClass('txt-ani')
                      .animate({ opacity: 1 })
                      .find($(element))
                      .delay(50 * index)
                      .animate(
                        {
                          top: 0,
                          opacity: 1,
                          left: 0,
                        },
                        600
                      )
                      .queue(function () {
                        $('.txt1').find('a').animate({ opacity: 1 }, 400);
                      });
                  }
                }

                $('.img-box.img2 img').attr('src', 'assets/imgs/img_ui.gif');
                $('.txt-area .txt2')
                  .find('h3')
                  .animate({ opacity: 1, top: 0 }, 1000);
                for (var i = 0; i < txtBox2.length; i++) {
                  for (let index = 0; index < temp2.length; index++) {
                    const element = temp2[index];
                    $('.txt-area .txt-box')
                      .eq(1)
                      .find('div p')
                      .addClass('txt-ani-ac')
                      .removeClass('txt-ani')
                      .animate({ opacity: 1 })
                      .find($(element))
                      .delay(50 * index)
                      .animate(
                        {
                          top: 0,
                          opacity: 1,
                          left: 0,
                        },
                        600
                      )
                      .queue(function () {
                        $('.txt2').find('a').animate({ opacity: 1 }, 400);
                      });
                  }
                }
                $('.img-box.img3 img').attr(
                  'src',
                  'assets/imgs/img_develop.gif'
                );
                $('.txt-area .txt3')
                  .find('h3')
                  .animate({ opacity: 1, top: 0 }, 1000);
                for (var i = 0; i < txtBox2.length; i++) {
                  for (let index = 0; index < temp2.length; index++) {
                    const element = temp2[index];
                    $('.txt-area .txt-box')
                      .eq(2)
                      .find('div p')
                      .addClass('txt-ani-ac')
                      .removeClass('txt-ani')
                      .animate({ opacity: 1 })
                      .find($(element))
                      .delay(50 * index)
                      .animate(
                        {
                          top: 0,
                          opacity: 1,
                          left: 0,
                        },
                        600
                      )
                      .queue(function () {
                        $('.txt3').find('a').animate({ opacity: 1 }, 400);
                      });
                  }
                  attrSwitch3 = true;
                  attrSwitch2 = true;
                  attrSwitch1 = true;
                }
                $('.img-box.img1 img').attr('src', 'assets/imgs/img_ux.gif');
                $('.txt-area .txt1')
                  .find('h3')
                  .animate({ opacity: 1, top: 0 }, 1000);

                for (var i = 0; i < txtBox2.length; i++) {
                  for (let index = 0; index < temp2.length; index++) {
                    const element = temp2[index];
                    $('.txt-area .txt-box')
                      .eq(0)
                      .find('div p')
                      .addClass('txt-ani-ac')
                      .removeClass('txt-ani')
                      .animate({ opacity: 1 })
                      .find($(element))
                      .delay(50 * index)
                      .animate(
                        {
                          top: 0,
                          opacity: 1,
                          left: 0,
                        },
                        600
                      )
                      .queue(function () {
                        $('.txt1').find('a').animate({ opacity: 1 }, 400);
                      });
                  }
                }

                $('.img-box.img2 img').attr('src', 'assets/imgs/img_ui.gif');
                $('.txt-area .txt2')
                  .find('h3')
                  .animate({ opacity: 1, top: 0 }, 1000);
                for (var i = 0; i < txtBox2.length; i++) {
                  for (let index = 0; index < temp2.length; index++) {
                    const element = temp2[index];
                    $('.txt-area .txt-box')
                      .eq(1)
                      .find('div p')
                      .addClass('txt-ani-ac')
                      .removeClass('txt-ani')
                      .animate({ opacity: 1 })
                      .find($(element))
                      .delay(50 * index)
                      .animate(
                        {
                          top: 0,
                          opacity: 1,
                          left: 0,
                        },
                        600
                      )
                      .queue(function () {
                        $('.txt2').find('a').animate({ opacity: 1 }, 400);
                      });
                  }
                }
                $('.img-box.img3 img').attr(
                  'src',
                  'assets/imgs/img_develop.gif'
                );
                $('.txt-area .txt3')
                  .find('h3')
                  .animate({ opacity: 1, top: 0 }, 1000);
                for (var i = 0; i < txtBox2.length; i++) {
                  for (let index = 0; index < temp2.length; index++) {
                    const element = temp2[index];
                    $('.txt-area .txt-box')
                      .eq(2)
                      .find('div p')
                      .addClass('txt-ani-ac')
                      .removeClass('txt-ani')
                      .animate({ opacity: 1 })
                      .find($(element))
                      .delay(50 * index)
                      .animate(
                        {
                          top: 0,
                          opacity: 1,
                          left: 0,
                        },
                        600
                      )
                      .queue(function () {
                        $('.txt3').find('a').animate({ opacity: 1 }, 400);
                      });
                  }
                }
                $('.img-box.img4 img').attr(
                  'src',
                  'assets/imgs/img_marketing.gif'
                );
                $('.txt-area .txt4')
                  .find('h3')
                  .animate({ opacity: 1, top: 0 }, 1000);
                for (var i = 0; i < txtBox2.length; i++) {
                  for (let index = 0; index < temp2.length; index++) {
                    const element = temp2[index];
                    $('.txt-area .txt-box')
                      .eq(3)
                      .find('div p')
                      .addClass('txt-ani-ac')
                      .removeClass('txt-ani')
                      .animate({ opacity: 1 })
                      .find($(element))
                      .delay(50 * index)
                      .animate(
                        {
                          top: 0,
                          opacity: 1,
                          left: 0,
                        },
                        600
                      )
                      .queue(function () {
                        $('.txt4').find('a').animate({ opacity: 1 }, 400);
                      });
                  }
                  attrSwitch4 = true;
                  attrSwitch3 = true;
                  attrSwitch2 = true;
                  attrSwitch1 = true;
                  // $(this).off(e);
                }
              }
            });
          }
        }

        //텍스트 애니메이션 함수
        let txtBox = [];
        let temp = [];
        function textAni() {
          let txtLine = [];
          //p 태그에 있는 문장을 한 문장씩 저장
          $('#artiText p').each(function (index, item) {
            let txt = $(item).text();
            let tmp = { index: index, txt: txt };
            txtLine.push(tmp);
          });

          //문장별로 단어를 끊어서 저장
          let splitTxt = [];
          txtLine.forEach((txt) => {
            splitTxt.push(txt.txt.split(' '));
          });

          //단어별로 스펜태그로 감싸서 저장

          $(splitTxt).each(function (index, item) {
            let txt = [];
            item.forEach((e) => {
              txt.push('<span>' + e + '</span>&nbsp;');
            });
            txtBox.push({ index: index, txt: txt });
          });

          //문장별 끊어서 포문을 돌리고
          for (var i = 0; i < txtBox.length; i++) {
            //태그에 span태그로 감싼 단어 다시 넣기
            $('#artiText p').eq(i).html(txtBox[i].txt);
            //만들어진 span 태그 css
            $('#artiText p span').css({
              opacity: 0,
              position: 'relative',
              top: '10px',
              left: '5px',
              lineHeight: '30px',
              display: 'inline-block',
              '-webkit-font-smoothing': 'antialiased',
              '-moz-osx-font-smoothing': 'grayscale',
            });

            //문장별 단어 배열 다시받기
            $('#artiText p')
              .eq(i)
              .find('span')
              .each(function (index, item) {
                temp.push(item);
              });
          }
        }
        //reset
        textAni();

        setTimeout(() => {
          let ani1 = true;
          // 애니메이션 끝날때까지 스크롤 막으려면 false에, 시간 초  2100 정도 다시 주기
          // let ani1 = false;
          if (windowWidth > 767) {
            $('#main').on('mousewheel', function (e) {
              let wheel = e.originalEvent.wheelDelta;
              if (wheel > 0) {
                //스크롤 올릴때
              } else {
                //스크롤  내릴때
                if (ani1 == false) {
                  $('.front-title').animate({ top: '-400px' }, 1000, 'linear');
                  for (var i = 0; i < txtBox.length; i++) {
                    //단어별로 포문 돌리기
                    for (let index = 0; index < temp.length; index++) {
                      const element = temp[index];
                      //이걸 어떻게 단축시킬수 있을까..
                      //각 문단별로 애니메이션 실행
                      if (i == 0) {
                        //특정단어 css
                        if (String($(element).text()) == '시간과') {
                          $(element).css({ fontWeight: '800' });
                        } else if (String($(element).text()) == '돈의') {
                          $(element).css({ fontWeight: '800' });
                        } else if (String($(element).text()) == '가치를') {
                          $(element).css({ fontWeight: '800' });
                        }

                        $('#artiText p')
                          .eq(0)
                          .addClass('txt-ani-ac')
                          .removeClass('txt-ani')
                          .animate({ opacity: 1 })
                          .find($(element))
                          .delay(50 * index)
                          .animate(
                            {
                              top: 0,
                              opacity: 1,
                              left: 0,
                            },
                            500
                          );
                      } else if (i == 1) {
                        $('#artiText p')
                          .eq(1)
                          .addClass('txt-ani-ac')
                          .removeClass('txt-ani')
                          .animate({ opacity: 1 })
                          .find($(element))
                          .delay(30 * txtBox[0].txt.length)
                          .delay(50 * index)
                          .animate(
                            {
                              top: 0,
                              opacity: 1,
                              left: 0,
                            },
                            500
                          );
                      } else if (i == 2) {
                        if (String($(element).text()) == '기획,') {
                          $(element).css({ fontWeight: '800' });
                        } else if (String($(element).text()) == '디자인,') {
                          $(element).css({ fontWeight: '800' });
                        } else if (String($(element).text()) == '개발,') {
                          $(element).css({ fontWeight: '800' });
                        } else if (String($(element).text()) == '마케팅') {
                          $(element).css({ fontWeight: '800' });
                        }

                        $('#artiText p')
                          .eq(2)
                          .addClass('txt-ani-ac')
                          .removeClass('txt-ani')
                          .animate({ opacity: 1 })
                          .find($(element))
                          .delay(
                            30 * (txtBox[0].txt.length + txtBox[1].txt.length)
                          )
                          .delay(50 * index)
                          .animate(
                            {
                              top: 0,
                              opacity: 1,
                              left: 0,
                            },
                            500
                          );
                      }
                    }
                  }

                  setTimeout(() => {
                    ani1 = true;
                  }, 900);
                } else {
                  $('#main').css({ overflowY: 'auto', height: 'auto' });
                  let windowWidth = $(window).width();
                  if (windowWidth > 767) {
                    setTimeout(() => {
                      $('sticky-button > .sticky_wrap > p').addClass('text-ac');
                    }, 300);
                  }
                  $(this).off(e); ///////첫번째 스크롤이벤트 끝
                }
              }
              e.stopPropagation();
              e.preventDefault();
            });
          }
          $(function () {
            ////모바일인지 웹인지 알아보는 코드
            var filter = 'win16|win32|win64|mac|macintel';

            if (navigator.platform) {
              if (filter.indexOf(navigator.platform.toLowerCase()) < 0) {
                //mobile
                if (windowWidth < 767) {
                  $('.front-title').animate({ top: '-400px' }, 1000, 'linear');
                  for (var i = 0; i < txtBox.length; i++) {
                    //단어별로 포문 돌리기
                    for (let index = 0; index < temp.length; index++) {
                      const element = temp[index];
                      //이걸 어떻게 단축시킬수 있을까..
                      //각 문단별로 애니메이션 실행
                      if (i == 0) {
                        //특정단어 css
                        if (String($(element).text()) == '시간과') {
                          $(element).css({ fontWeight: '800' });
                        } else if (String($(element).text()) == '돈의') {
                          $(element).css({ fontWeight: '800' });
                        } else if (String($(element).text()) == '가치를') {
                          $(element).css({ fontWeight: '800' });
                        }

                        $('#artiText p')
                          .eq(0)
                          .addClass('txt-ani-ac')
                          .removeClass('txt-ani')
                          .animate({ opacity: 1 })
                          .find($(element))
                          .delay(50 * index)
                          .animate(
                            {
                              top: 0,
                              opacity: 1,
                              left: 0,
                            },
                            500
                          );
                      } else if (i == 1) {
                        $('#artiText p')
                          .eq(1)
                          .addClass('txt-ani-ac')
                          .removeClass('txt-ani')
                          .animate({ opacity: 1 })
                          .find($(element))
                          .delay(30 * txtBox[0].txt.length)
                          .delay(50 * index)
                          .animate(
                            {
                              top: 0,
                              opacity: 1,
                              left: 0,
                            },
                            500
                          );
                      } else if (i == 2) {
                        if (String($(element).text()) == '기획,') {
                          $(element).css({ fontWeight: '800' });
                        } else if (String($(element).text()) == '디자인,') {
                          $(element).css({ fontWeight: '800' });
                        } else if (String($(element).text()) == '개발,') {
                          $(element).css({ fontWeight: '800' });
                        } else if (String($(element).text()) == '마케팅') {
                          $(element).css({ fontWeight: '800' });
                        }

                        $('#artiText p')
                          .eq(2)
                          .addClass('txt-ani-ac')
                          .removeClass('txt-ani')
                          .animate({ opacity: 1 })
                          .find($(element))
                          .delay(
                            30 * (txtBox[0].txt.length + txtBox[1].txt.length)
                          )
                          .delay(50 * index)
                          .animate(
                            {
                              top: 0,
                              opacity: 1,
                              left: 0,
                            },
                            500
                          );
                      }
                    }
                  }

                  setTimeout(() => {
                    $('#main').css({ overflowY: 'auto', height: 'auto' });
                  }, 900);
                }
              } else {
                $('#main').on('mousewheel', function (e) {
                  let wheel = e.originalEvent.wheelDelta;
                  if (wheel > 0) {
                    //스크롤 올릴때
                  } else {
                    //스크롤  내릴때
                    if (ani1 == false) {
                      $('.front-title').animate(
                        { top: '-400px' },
                        1000,
                        'linear'
                      );
                      for (var i = 0; i < txtBox.length; i++) {
                        //단어별로 포문 돌리기
                        for (let index = 0; index < temp.length; index++) {
                          const element = temp[index];
                          //이걸 어떻게 단축시킬수 있을까..
                          //각 문단별로 애니메이션 실행
                          if (i == 0) {
                            //특정단어 css
                            if (String($(element).text()) == '시간과') {
                              $(element).css({ fontWeight: '800' });
                            } else if (String($(element).text()) == '돈의') {
                              $(element).css({ fontWeight: '800' });
                            } else if (String($(element).text()) == '가치를') {
                              $(element).css({ fontWeight: '800' });
                            }

                            $('#artiText p')
                              .eq(0)
                              .addClass('txt-ani-ac')
                              .removeClass('txt-ani')
                              .animate({ opacity: 1 })
                              .find($(element))
                              .delay(50 * index)
                              .animate(
                                {
                                  top: 0,
                                  opacity: 1,
                                  left: 0,
                                },
                                500
                              );
                          } else if (i == 1) {
                            $('#artiText p')
                              .eq(1)
                              .addClass('txt-ani-ac')
                              .removeClass('txt-ani')
                              .animate({ opacity: 1 })
                              .find($(element))
                              .delay(30 * txtBox[0].txt.length)
                              .delay(50 * index)
                              .animate(
                                {
                                  top: 0,
                                  opacity: 1,
                                  left: 0,
                                },
                                500
                              );
                          } else if (i == 2) {
                            if (String($(element).text()) == '기획,') {
                              $(element).css({ fontWeight: '800' });
                            } else if (String($(element).text()) == '디자인,') {
                              $(element).css({ fontWeight: '800' });
                            } else if (String($(element).text()) == '개발,') {
                              $(element).css({ fontWeight: '800' });
                            } else if (String($(element).text()) == '마케팅') {
                              $(element).css({ fontWeight: '800' });
                            }

                            $('#artiText p')
                              .eq(2)
                              .addClass('txt-ani-ac')
                              .removeClass('txt-ani')
                              .animate({ opacity: 1 })
                              .find($(element))
                              .delay(
                                30 *
                                  (txtBox[0].txt.length + txtBox[1].txt.length)
                              )
                              .delay(50 * index)
                              .animate(
                                {
                                  top: 0,
                                  opacity: 1,
                                  left: 0,
                                },
                                500
                              );
                          }
                        }
                      }

                      setTimeout(() => {
                        ani1 = true;
                      }, 900);
                    } else {
                      $('#main').css({ overflowY: 'auto', height: 'auto' });
                      let windowWidth = $(window).width();
                      if (windowWidth > 767) {
                        setTimeout(() => {
                          $('sticky-button > .sticky_wrap > p').addClass(
                            'text-ac'
                          );
                        }, 300);
                      }
                      $(this).off(e);
                    }
                  }
                });
              }
            }
          });
          // }, 2100);
        }, 0);
      });

      ////메인배너 끝

      let txtBox2 = [];
      let temp2 = [];
      function textAni2() {
        let txtLine2 = [];
        //p 태그에 있는 문장을 한 문장씩 저장
        $('.txt-area .txt-box')
          .find('p')
          .each(function (index, item) {
            let txt = $(item).text();
            let tmp = { index: index, txt: txt };
            txtLine2.push(tmp);
          });

        //문장별로 단어를 끊어서 저장
        let splitTxt2 = [];
        txtLine2.forEach((txt) => {
          splitTxt2.push(txt.txt.split(' '));
        });

        //단어별로 스펜태그로 감싸서 저장

        $(splitTxt2).each(function (index, item) {
          let txt2 = [];
          item.forEach((e) => {
            txt2.push('<span>' + e + '</span>&nbsp;');
          });
          txtBox2.push({ index: index, txt: txt2 });
        });

        //문장별 끊어서 포문을 돌리고
        for (var i = 0; i < txtBox2.length; i++) {
          //태그에 span태그로 감싼 단어 다시 넣기
          $('.txt-area .txt-box div p').eq(i).html(txtBox2[i].txt);
          //만들어진 span 태그 css
          $('.txt-area .txt-box div p span').css({
            opacity: 0,
            position: 'relative',
            top: '10px',
            left: '5px',
            lineHeight: '30px',
            display: 'inline-block',
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
          });

          //문장별 단어 배열 다시받기
          $('.txt-area .txt-box div p')
            .eq(i)
            .find('span')
            .each(function (index, item) {
              temp2.push(item);
            });
        }
      }

      //텍스트 애니메이션 reset
      textAni2();
      let attrSwitch1 = false;
      let attrSwitch2 = false;
      let attrSwitch3 = false;
      let attrSwitch4 = false;

      let mTxtSwitch1 = false;
      let mTxtSwitch2 = false;
      let mTxtSwitch3 = false;
      let mTxtSwitch4 = false;

      let guideSwitch = false;
      // let footerOpen = false;
      let stickyAc = false;

      //두번째 섹션 시작 --

      setTimeout(() => {
        $(window).scroll(function (e) {
          let windowWidth = $(window).width();

          let scroll_bottom =
            $(window).scrollTop() + $(window).height() + 56 - 400;

          //모든게 가능한 이유 섹션

          ///스크롤시 해당 영역에서 잡히게
          if (windowWidth > 767) {
            let img1_bottom =
              $('.txt-area .txt1 div h3')?.offset()?.top +
              $('.txt-area .txt1').outerHeight() +
              56;
            let img2_bottom =
              $('.txt-area .txt2 div h3')?.offset().top +
              $('.txt-area .txt2').outerHeight() +
              56;
            let img3_bottom =
              $('.txt-area .txt3 div h3')?.offset().top +
              $('.txt-area .txt3').outerHeight() +
              56;
            let img4_bottom =
              $('.txt-area .txt4 div h3')?.offset().top +
              $('.txt-area .txt4').outerHeight() +
              56;

            if (scroll_bottom > img1_bottom / 2) {
              if (!attrSwitch1) {
                $('.img-box.img1 img').attr('src', 'assets/imgs/img_ux.gif');
                $('.txt-area .txt1')
                  .find('h3')
                  .animate({ opacity: 1, top: 0 }, 1000);

                for (var i = 0; i < txtBox2.length; i++) {
                  //단어별로 포문 돌리기
                  for (let index = 0; index < temp2.length; index++) {
                    const element = temp2[index];
                    //이걸 어떻게 단축시킬수 있을까..
                    //각 문단별로 애니메이션 실행

                    $('.txt-area .txt-box')
                      .eq(0)
                      .find('div p')
                      .addClass('txt-ani-ac')
                      .removeClass('txt-ani')
                      .animate({ opacity: 1 })
                      .find($(element))
                      .delay(50 * index)
                      .animate(
                        {
                          top: 0,
                          opacity: 1,
                          left: 0,
                        },
                        600
                      )
                      .queue(function () {
                        $('.txt1').find('a').animate({ opacity: 1 }, 400);
                      });
                  }
                }
              }

              // setTimeout(() => {

              // }, 600);

              attrSwitch1 = true;
            }

            if (
              img1_bottom - $(window).height() / 2 < scroll_bottom &&
              scroll_bottom > img2_bottom / 2
            ) {
              if (!attrSwitch2) {
                $('.img-box.img2 img').attr('src', 'assets/imgs/img_ui.gif');
                $('.txt-area .txt2')
                  .find('h3')
                  .animate({ opacity: 1, top: 0 }, 1000);
                for (var i = 0; i < txtBox2.length; i++) {
                  //단어별로 포문 돌리기
                  for (let index = 0; index < temp2.length; index++) {
                    const element = temp2[index];
                    //이걸 어떻게 단축시킬수 있을까..
                    //각 문단별로 애니메이션 실행

                    $('.txt-area .txt-box')
                      .eq(1)
                      .find('div p')
                      .addClass('txt-ani-ac')
                      .removeClass('txt-ani')
                      .animate({ opacity: 1 })
                      .find($(element))
                      .delay(50 * index)
                      .animate(
                        {
                          top: 0,
                          opacity: 1,
                          left: 0,
                        },
                        600
                      )
                      .queue(function () {
                        $('.txt2').find('a').animate({ opacity: 1 }, 400);
                      });
                  }
                }
              }
              attrSwitch2 = true;
            }
            if (
              img2_bottom - $(window).height() / 2 < scroll_bottom &&
              scroll_bottom > img3_bottom / 2
            ) {
              if (!attrSwitch3) {
                $('.img-box.img3 img').attr(
                  'src',
                  'assets/imgs/img_develop.gif'
                );
                $('.txt-area .txt3')
                  .find('h3')
                  .animate({ opacity: 1, top: 0 }, 1000);
                for (var i = 0; i < txtBox2.length; i++) {
                  //단어별로 포문 돌리기
                  for (let index = 0; index < temp2.length; index++) {
                    const element = temp2[index];
                    //이걸 어떻게 단축시킬수 있을까..
                    //각 문단별로 애니메이션 실행

                    $('.txt-area .txt-box')
                      .eq(2)
                      .find('div p')
                      .addClass('txt-ani-ac')
                      .removeClass('txt-ani')
                      .animate({ opacity: 1 })
                      .find($(element))
                      .delay(50 * index)
                      .animate(
                        {
                          top: 0,
                          opacity: 1,
                          left: 0,
                        },
                        600
                      )
                      .queue(function () {
                        $('.txt3').find('a').animate({ opacity: 1 }, 400);
                      });
                  }
                }
              }
              attrSwitch3 = true;
            }
            if (
              img3_bottom - $(window).height() / 2 < scroll_bottom &&
              scroll_bottom > img4_bottom / 2
            ) {
              if (!attrSwitch4) {
                $('.img-box.img4 img').attr(
                  'src',
                  'assets/imgs/img_marketing.gif'
                );
                $('.txt-area .txt4')
                  .find('h3')
                  .animate({ opacity: 1, top: 0 }, 1000);
                for (var i = 0; i < txtBox2.length; i++) {
                  //단어별로 포문 돌리기
                  for (let index = 0; index < temp2.length; index++) {
                    const element = temp2[index];
                    //이걸 어떻게 단축시킬수 있을까..
                    //각 문단별로 애니메이션 실행

                    $('.txt-area .txt-box')
                      .eq(3)
                      .find('div p')
                      .addClass('txt-ani-ac')
                      .removeClass('txt-ani')
                      .animate({ opacity: 1 })
                      .find($(element))
                      .delay(50 * index)
                      .animate(
                        {
                          top: 0,
                          opacity: 1,
                          left: 0,
                        },
                        600
                      )
                      .queue(function () {
                        $('.txt4').find('a').animate({ opacity: 1 }, 400);
                      });
                  }
                }
              }
              attrSwitch4 = true;
              // $(this).off(e);
            }
          } else {
            ///////////////////////////////////모바일일때
            ///////////////////////////////////모바일일때
            ///////////////////////////////////모바일일때
            ///////////////////////////////////모바일일때
            let img1_bottom =
              $('.img-box.img1').offset().top +
              $('.img-box.img1').height() / 2 +
              100;
            let img2_bottom =
              $('.img-box.img2').offset().top +
              +$('.img-box.img2').height() / 2 +
              100;
            let img3_bottom =
              $('.img-box.img3').offset().top +
              +$('.img-box.img3').height() / 2 +
              100;
            let img4_bottom =
              $('.img-box.img4').offset().top +
              +$('.img-box.img4').height() / 2 +
              100;

            let scroll_bottom = $(window).scrollTop() + $(window).height();

            if (scroll_bottom > img1_bottom) {
              if (!attrSwitch1) {
                $('.img-box.img1 img').attr('src', 'assets/imgs/img_ux.gif');
              }

              attrSwitch1 = true;
              if (!mTxtSwitch1) {
                $('.m-txt-area').addClass('txt-area-active');
                $('sticky-button').animate(
                  { bottom: $('.txt-area-active').height() + 30 },
                  500
                );

                // if (scroll_bottom < img1_bottom + 300) {
                //   $('html,body').animate(
                //     {
                //       scrollTop: 1165,
                //     },
                //     500
                //   );
                // }
              }
              mTxtSwitch1 = true;
            } else {
              if (mTxtSwitch1) {
                $('.m-txt-area').removeClass('txt-area-active');
                $('sticky-button').stop().animate({ bottom: 40 }, 500);
                // $('sticky-button > .sticky_wrap > p').removeClass('text-ac');
                // $('.img-box.img1 img').attr('src', 'assets/imgs/img_ux.gif');
              }

              mTxtSwitch1 = false;
            }

            if (img1_bottom < scroll_bottom && scroll_bottom > img2_bottom) {
              if (!attrSwitch2) {
                $('.img-box.img2 img').attr('src', 'assets/imgs/img_ui.gif');
              }
              attrSwitch2 = true;
              if (!mTxtSwitch2) {
                $('.txt1').addClass('m-txt-off');
                $('.txt2').addClass('m-txt-on');
              }
              mTxtSwitch2 = true;
            } else {
              if (mTxtSwitch2) {
                // $('.img-box.img2 img').attr('src', 'assets/imgs/img_ui.gif');
                $('.txt1').removeClass('m-txt-off');
                $('.txt1').addClass('m-txt-on');
                $('.txt2').removeClass('m-txt-on');
              }
              mTxtSwitch2 = false;
            }
            if (img2_bottom < scroll_bottom && scroll_bottom > img3_bottom) {
              if (!attrSwitch3) {
                $('.img-box.img3 img').attr(
                  'src',
                  'assets/imgs/img_develop.gif'
                );
              }
              attrSwitch3 = true;
              if (!mTxtSwitch3) {
                $('.txt2').removeClass('m-txt-on');
                $('.txt2').addClass('m-txt-off');
                $('.txt3').addClass('m-txt-on');
              }
              mTxtSwitch3 = true;
            } else {
              if (mTxtSwitch3) {
                $('.txt2').addClass('m-txt-on');
                $('.txt2').removeClass('m-txt-off');
                $('.txt3').removeClass('m-txt-on');
              }
              mTxtSwitch3 = false;
            }
            if (img3_bottom < scroll_bottom && scroll_bottom > img4_bottom) {
              if (!attrSwitch4) {
                $('.img-box.img4 img').attr(
                  'src',
                  'assets/imgs/img_marketing.gif'
                );
              }
              attrSwitch4 = true;

              if (!mTxtSwitch4) {
                $('.txt3').removeClass('m-txt-on');
                $('.txt3').addClass('m-txt-off');
                $('.txt4').addClass('m-txt-on');
                $('sticky-button')
                  .stop()
                  .animate({ bottom: 40 }, 500)
                  .queue(function () {
                    $('sticky-button > .sticky_wrap > p').addClass('text-ac');
                  });
              }

              if (
                scroll_bottom >
                $('.reason-section').offset().top +
                  $('.reason-section').height()
              ) {
                $('.reason-section').css({
                  position: 'relative',
                });
                $('.m-txt-area').css({
                  position: 'absolute',
                  bottom: 0,
                });
              }

              mTxtSwitch4 = true;
              // $(this).off(e);
            } else {
              if (mTxtSwitch4) {
                // $('.img-box.img4 img').attr('src', 'assets/imgs/img_marketing.gif');
                $('.txt3').addClass('m-txt-on');
                $('.txt3').removeClass('m-txt-off');
                $('.txt4').removeClass('m-txt-on');

                $('.reason-section').css({
                  position: 'unset',
                });
                $('.m-txt-area').css({
                  position: 'fixed',
                  bottom: 0,
                });

                // if ($('.txt3').hasClass('m-txt-on')) {

                // $('sticky-button').animate(
                //   { bottom: $('.txt-area-active').height() + 30 },
                //   800
                // );

                $('sticky-button').stop().animate({ bottom: 300 }, 800);

                $('sticky-button > .sticky_wrap > p').removeClass('text-ac');
                // }
              }
              mTxtSwitch4 = false;
              // $(this).off(e);
            }
          }
          ////두번째 섹션 끝

          ///////두번째 섹션 스크롤 오류
          ///////두번째 섹션 스크롤 오류
          ///////두번째 섹션 스크롤 오류

          ///// 포트폴리오 백영역
          if (scroll_bottom + 400 >= $('.portfolio-box').offset().top + 300) {
            $('#main').addClass('back-ac');
            // $('footer-share').css({ display: 'block' });
          } else {
            $('#main').removeClass('back-ac');
          }

          ///성공을 위한 가이드 스크롤 XXXXXXXX
          let guideTop =
            $('.reason-section').offset().top +
            $('.reason-section').innerHeight();

          let slideAc1 = false;
          let scrollAc = false;

          // if (windowWidth > 767) {
          //   if ($(window).scrollTop() >= guideTop - 300) {
          //     let windowWidth = $(window).width();
          //     if (!slideAc1) {
          //       $('.swiper-container').css({ width: windowWidth });
          //       for (let index = 0; index < 6; index++) {
          //         setTimeout(() => {
          //           if (windowWidth > 1199) {
          //             if (
          //               !$('.swiper-slide').eq(3).hasClass('slide_ac') &&
          //               index == 3
          //             ) {
          //               swiper.slideTo(3, 4000);

          //               // });
          //             }
          //           } else if (windowWidth < 1199 && windowWidth > 1023) {
          //             if (
          //               !$('.swiper-slide').eq(3).hasClass('slide_ac') &&
          //               index == 3
          //             ) {
          //               swiper.slideTo(4, 4000);

          //               // });
          //             }
          //           } else if (windowWidth <= 1023) {
          //             if (
          //               !$('.swiper-slide').eq(3).hasClass('slide_ac') &&
          //               index == 3
          //             ) {
          //               swiper.slideTo(5, 4000);
          //             }
          //           }
          //           $('.swiper-slide').eq(index).addClass('slide_ac');
          //         }, 1000 * index);
          //       }
          //     }
          //     slideAc1 = true;
          //   } else {
          //     slideAc1 = true;
          //   }
          // }

          const store1 = $('.store1').offset().top + 30;
          const store2 = $('.store2').offset().top + 100;
          const store3 = $('.store3').offset().top + 30;
          const store4 = $('.store4').offset().top + 10;
          const store5 = $('.store5').offset().top + 30;
          const store6 = $('.store6').offset().top - 100;
          const store7 = $('.store7').offset().top - 40;

          if (scroll_bottom >= store1) {
            $('.store1').addClass('active');
          } else {
            $('.store1').removeClass('active');
          }
          if (scroll_bottom >= store2) {
            $('.store2').addClass('active');
          } else {
            $('.store2').removeClass('active');
          }
          if (scroll_bottom >= store3) {
            $('.store3').addClass('active');
          } else {
            $('.store3').removeClass('active');
          }
          if (scroll_bottom >= store4) {
            $('.store4').addClass('active');
          } else {
            $('.store4').removeClass('active');
          }
          if (scroll_bottom >= store5) {
            $('.store5').addClass('active');
          } else {
            $('.store5').removeClass('active');
          }
          if (scroll_bottom >= store6) {
            $('.store6').addClass('active');
          } else {
            $('.store6').removeClass('active');
          }
          if (scroll_bottom >= store7) {
            $('.store7').addClass('active');
          } else {
            $('.store7').removeClass('active');
          }

          e.preventDefault();
          e.stopPropagation();
        });
      }, 100);

      // if (windowWidth > 767) {
      //   let mouseAc = false;
      //   $('.swiper-wrapper').mouseover(function (e) {
      //     var $cursor = $('.js-cursor-el');
      //     var $body = $('.guide-section');

      //     var mouseIsDown = false,
      //       lastMousePosition = { x: 0, y: 0 };
      //     lastMousePosition.x = e.clientX;
      //     lastMousePosition.y = e.clientY;

      //     var hoveredEl = document.elementFromPoint(
      //       lastMousePosition.x,
      //       lastMousePosition.y
      //     );

      //     if (
      //       $(hoveredEl).parents('.js-cursor').length ||
      //       $(hoveredEl).hasClass('js-cursor')
      //     ) {
      //       var $whichCursor =
      //         $(hoveredEl).parents('.js-cursor').attr('data-cursor') ||
      //         $(hoveredEl).attr('data-cursor');
      //       $body.addClass('is-cursor-active');
      //       $cursor.addClass('is-active');
      //       $cursor.attr('data-cursor', $whichCursor);
      //     } else {
      //       $body.removeClass('is-cursor-active');
      //       $cursor.removeClass('is-active');
      //     }

      //     // now draw object at lastMousePosition
      //     $cursor.css({
      //       transform: `translate3d(${lastMousePosition.x}px, ${lastMousePosition.y}px, 0)`,
      //     });

      //     function onMouseDown() {
      //       $cursor.addClass('is-mouse-down');
      //       $('.js-cursor').attr('data-cursor', 'cursor__click');
      //       mouseIsDown = true;
      //       requestAnimationFrame(update);
      //     }

      //     function onMouseUp() {
      //       $cursor.removeClass('is-mouse-down');
      //       $('.js-cursor').attr('data-cursor', 'cursor__grab');
      //       mouseIsDown = false;
      //       requestAnimationFrame(update);
      //     }

      //     function onMouseMove(evt) {
      //       // $('.fake_grab').css({ display: 'none' });
      //       lastMousePosition.x = evt.clientX;
      //       lastMousePosition.y = evt.clientY;
      //       requestAnimationFrame(update);
      //     }

      //     function update() {
      //       // Get the element we're hovered on
      //       var hoveredEl = document.elementFromPoint(
      //         lastMousePosition.x,
      //         lastMousePosition.y
      //       );

      //       // Check if the element or any of its parents have a .js-cursor class
      //       if (
      //         $(hoveredEl).parents('.js-cursor').length ||
      //         $(hoveredEl).hasClass('js-cursor')
      //       ) {
      //         var $whichCursor =
      //           $(hoveredEl).parents('.js-cursor').attr('data-cursor') ||
      //           $(hoveredEl).attr('data-cursor');
      //         $body.addClass('is-cursor-active');
      //         $cursor.addClass('is-active');
      //         $cursor.attr('data-cursor', $whichCursor);
      //       } else {
      //         $body.removeClass('is-cursor-active');
      //         $cursor.removeClass('is-active');
      //       }

      //       // now draw object at lastMousePosition
      //       $cursor.css({
      //         transform: `translate3d(${lastMousePosition.x}px, ${lastMousePosition.y}px, 0)`,
      //       });
      //     }

      //     document.addEventListener('mousedown', onMouseDown, false);
      //     document.addEventListener('mouseup', onMouseUp, false);
      //     document.addEventListener('mousemove', onMouseMove, false);
      //     e.stopPropagation();
      //   });

      //   $('.swiper-wrapper').mouseleave(function (e) {
      //     var $cursor = $('.js-cursor-el');
      //     var $body = $('.guide-section');

      //     var mouseIsDown = false,
      //       lastMousePosition = { x: 0, y: 0 };
      //     lastMousePosition.x = e.clientX;
      //     lastMousePosition.y = e.clientY;

      //     var hoveredEl = document.elementFromPoint(
      //       lastMousePosition.x,
      //       lastMousePosition.y
      //     );

      //     if (
      //       $(hoveredEl).parents('.js-cursor').length ||
      //       $(hoveredEl).hasClass('js-cursor')
      //     ) {
      //       var $whichCursor =
      //         $(hoveredEl).parents('.js-cursor').attr('data-cursor') ||
      //         $(hoveredEl).attr('data-cursor');
      //       $body.removeClass('is-cursor-active');
      //       $cursor.removeClass('is-active');
      //       $cursor.attr('data-cursor', $whichCursor);
      //     } else {
      //       $body.addClass('is-cursor-active');
      //       $cursor.addClass('is-active');
      //     }

      //     // now draw object at lastMousePosition
      //     $cursor.css({
      //       transform: `translate3d(${lastMousePosition.x}px, ${lastMousePosition.y}px, 0)`,
      //     });
      //     e.stopPropagation();
      //   });
      // }

      let that = this;
      let filter = 'win16|win32|win64|mac|macintel';

      if (that.myDevice == filter) {
        $(window).resize(function (e) {
          let scroll_bottom =
            $(window).scrollTop() + $(window).height() + 56 - 400;

          if (windowWidth > 767) {
            let img1_bottom =
              $('.txt-area .txt1 div h3').offset().top +
              $('.txt-area .txt1').outerHeight() +
              56;
            let img2_bottom =
              $('.txt-area .txt2 div h3').offset().top +
              $('.txt-area .txt2').outerHeight() +
              56;
            let img3_bottom =
              $('.txt-area .txt3 div h3').offset().top +
              $('.txt-area .txt3').outerHeight() +
              56;
            let img4_bottom =
              $('.txt-area .txt4 div h3').offset().top +
              $('.txt-area .txt4').outerHeight() +
              56;
            let attrSwitch4 = false;
            let attrSwitch3 = false;
            let attrSwitch2 = false;
            let attrSwitch1 = false;

            $('.img-box.img1 img').attr('src', 'assets/imgs/img_ux.gif');
            $('.txt-area .txt1')
              .find('h3')
              .animate({ opacity: 1, top: 0 }, 1000);

            for (var i = 0; i < txtBox2.length; i++) {
              for (let index = 0; index < temp2.length; index++) {
                const element = temp2[index];
                $('.txt-area .txt-box')
                  .eq(0)
                  .find('div p')
                  .addClass('txt-ani-ac')
                  .removeClass('txt-ani')
                  .animate({ opacity: 1 })
                  .find($(element))
                  .delay(50 * index)
                  .animate(
                    {
                      top: 0,
                      opacity: 1,
                      left: 0,
                    },
                    600
                  )
                  .queue(function () {
                    $('.txt1').find('a').animate({ opacity: 1 }, 400);
                  });
              }
              attrSwitch1 = true;
            }

            $('.img-box.img1 img').attr('src', 'assets/imgs/img_ux.gif');
            $('.txt-area .txt1')
              .find('h3')
              .animate({ opacity: 1, top: 0 }, 1000);

            for (var i = 0; i < txtBox2.length; i++) {
              for (let index = 0; index < temp2.length; index++) {
                const element = temp2[index];
                $('.txt-area .txt-box')
                  .eq(0)
                  .find('div p')
                  .addClass('txt-ani-ac')
                  .removeClass('txt-ani')
                  .animate({ opacity: 1 })
                  .find($(element))
                  .delay(50 * index)
                  .animate(
                    {
                      top: 0,
                      opacity: 1,
                      left: 0,
                    },
                    600
                  )
                  .queue(function () {
                    $('.txt1').find('a').animate({ opacity: 1 }, 400);
                  });
              }
            }

            $('.img-box.img2 img').attr('src', 'assets/imgs/img_ui.gif');
            $('.txt-area .txt2')
              .find('h3')
              .animate({ opacity: 1, top: 0 }, 1000);
            for (var i = 0; i < txtBox2.length; i++) {
              for (let index = 0; index < temp2.length; index++) {
                const element = temp2[index];
                $('.txt-area .txt-box')
                  .eq(1)
                  .find('div p')
                  .addClass('txt-ani-ac')
                  .removeClass('txt-ani')
                  .animate({ opacity: 1 })
                  .find($(element))
                  .delay(50 * index)
                  .animate(
                    {
                      top: 0,
                      opacity: 1,
                      left: 0,
                    },
                    600
                  )
                  .queue(function () {
                    $('.txt2').find('a').animate({ opacity: 1 }, 400);
                  });
              }
              attrSwitch2 = true;
              attrSwitch1 = true;
            }
            $('.img-box.img1 img').attr('src', 'assets/imgs/img_ux.gif');
            $('.txt-area .txt1')
              .find('h3')
              .animate({ opacity: 1, top: 0 }, 1000);
            for (var i = 0; i < txtBox2.length; i++) {
              for (let index = 0; index < temp2.length; index++) {
                const element = temp2[index];
                $('.txt-area .txt-box')
                  .eq(0)
                  .find('div p')
                  .addClass('txt-ani-ac')
                  .removeClass('txt-ani')
                  .animate({ opacity: 1 })
                  .find($(element))
                  .delay(50 * index)
                  .animate(
                    {
                      top: 0,
                      opacity: 1,
                      left: 0,
                    },
                    600
                  )
                  .queue(function () {
                    $('.txt1').find('a').animate({ opacity: 1 }, 400);
                  });
              }
            }

            $('.img-box.img2 img').attr('src', 'assets/imgs/img_ui.gif');
            $('.txt-area .txt2')
              .find('h3')
              .animate({ opacity: 1, top: 0 }, 1000);
            for (var i = 0; i < txtBox2.length; i++) {
              for (let index = 0; index < temp2.length; index++) {
                const element = temp2[index];
                $('.txt-area .txt-box')
                  .eq(1)
                  .find('div p')
                  .addClass('txt-ani-ac')
                  .removeClass('txt-ani')
                  .animate({ opacity: 1 })
                  .find($(element))
                  .delay(50 * index)
                  .animate(
                    {
                      top: 0,
                      opacity: 1,
                      left: 0,
                    },
                    600
                  )
                  .queue(function () {
                    $('.txt2').find('a').animate({ opacity: 1 }, 400);
                  });
              }
            }
            $('.img-box.img3 img').attr('src', 'assets/imgs/img_develop.gif');
            $('.txt-area .txt3')
              .find('h3')
              .animate({ opacity: 1, top: 0 }, 1000);
            for (var i = 0; i < txtBox2.length; i++) {
              for (let index = 0; index < temp2.length; index++) {
                const element = temp2[index];
                $('.txt-area .txt-box')
                  .eq(2)
                  .find('div p')
                  .addClass('txt-ani-ac')
                  .removeClass('txt-ani')
                  .animate({ opacity: 1 })
                  .find($(element))
                  .delay(50 * index)
                  .animate(
                    {
                      top: 0,
                      opacity: 1,
                      left: 0,
                    },
                    600
                  )
                  .queue(function () {
                    $('.txt3').find('a').animate({ opacity: 1 }, 400);
                  });
              }
              attrSwitch3 = true;
              attrSwitch2 = true;
              attrSwitch1 = true;
            }
            $('.img-box.img1 img').attr('src', 'assets/imgs/img_ux.gif');
            $('.txt-area .txt1')
              .find('h3')
              .animate({ opacity: 1, top: 0 }, 1000);

            for (var i = 0; i < txtBox2.length; i++) {
              for (let index = 0; index < temp2.length; index++) {
                const element = temp2[index];
                $('.txt-area .txt-box')
                  .eq(0)
                  .find('div p')
                  .addClass('txt-ani-ac')
                  .removeClass('txt-ani')
                  .animate({ opacity: 1 })
                  .find($(element))
                  .delay(50 * index)
                  .animate(
                    {
                      top: 0,
                      opacity: 1,
                      left: 0,
                    },
                    600
                  )
                  .queue(function () {
                    $('.txt1').find('a').animate({ opacity: 1 }, 400);
                  });
              }
            }

            $('.img-box.img2 img').attr('src', 'assets/imgs/img_ui.gif');
            $('.txt-area .txt2')
              .find('h3')
              .animate({ opacity: 1, top: 0 }, 1000);
            for (var i = 0; i < txtBox2.length; i++) {
              for (let index = 0; index < temp2.length; index++) {
                const element = temp2[index];
                $('.txt-area .txt-box')
                  .eq(1)
                  .find('div p')
                  .addClass('txt-ani-ac')
                  .removeClass('txt-ani')
                  .animate({ opacity: 1 })
                  .find($(element))
                  .delay(50 * index)
                  .animate(
                    {
                      top: 0,
                      opacity: 1,
                      left: 0,
                    },
                    600
                  )
                  .queue(function () {
                    $('.txt2').find('a').animate({ opacity: 1 }, 400);
                  });
              }
            }
            $('.img-box.img3 img').attr('src', 'assets/imgs/img_develop.gif');
            $('.txt-area .txt3')
              .find('h3')
              .animate({ opacity: 1, top: 0 }, 1000);
            for (var i = 0; i < txtBox2.length; i++) {
              for (let index = 0; index < temp2.length; index++) {
                const element = temp2[index];
                $('.txt-area .txt-box')
                  .eq(2)
                  .find('div p')
                  .addClass('txt-ani-ac')
                  .removeClass('txt-ani')
                  .animate({ opacity: 1 })
                  .find($(element))
                  .delay(50 * index)
                  .animate(
                    {
                      top: 0,
                      opacity: 1,
                      left: 0,
                    },
                    600
                  )
                  .queue(function () {
                    $('.txt3').find('a').animate({ opacity: 1 }, 400);
                  });
              }
            }
            $('.img-box.img4 img').attr('src', 'assets/imgs/img_marketing.gif');
            $('.txt-area .txt4')
              .find('h3')
              .animate({ opacity: 1, top: 0 }, 1000);
            for (var i = 0; i < txtBox2.length; i++) {
              for (let index = 0; index < temp2.length; index++) {
                const element = temp2[index];
                $('.txt-area .txt-box')
                  .eq(3)
                  .find('div p')
                  .addClass('txt-ani-ac')
                  .removeClass('txt-ani')
                  .animate({ opacity: 1 })
                  .find($(element))
                  .delay(50 * index)
                  .animate(
                    {
                      top: 0,
                      opacity: 1,
                      left: 0,
                    },
                    600
                  )
                  .queue(function () {
                    $('.txt4').find('a').animate({ opacity: 1 }, 400);
                  });
              }
              attrSwitch4 = true;
              attrSwitch3 = true;
              attrSwitch2 = true;
              attrSwitch1 = true;
            }
          } else {
            let img1_bottom =
              $('.img-box.img1').offset().top +
              $('.img-box.img1').height() / 2 +
              100;
            let img2_bottom =
              $('.img-box.img2').offset().top +
              +$('.img-box.img2').height() / 2 +
              100;
            let img3_bottom =
              $('.img-box.img3').offset().top +
              +$('.img-box.img3').height() / 2 +
              100;
            let img4_bottom =
              $('.img-box.img4').offset().top +
              +$('.img-box.img4').height() / 2 +
              100;

            let scroll_bottom = $(window).scrollTop() + $(window).height();

            if (scroll_bottom > img1_bottom) {
              if (!attrSwitch1) {
                $('.img-box.img1 img').attr('src', 'assets/imgs/img_ux.gif');
              }

              attrSwitch1 = true;
              if (!mTxtSwitch1) {
                $('.m-txt-area').addClass('txt-area-active');
                $('sticky-button').animate(
                  { bottom: $('.txt-area-active').height() + 30 },
                  500
                );

                // if (scroll_bottom < img1_bottom + 300) {
                //   $('html,body').animate(
                //     {
                //       scrollTop: 1165,
                //     },
                //     500
                //   );
                // }
              }
              mTxtSwitch1 = true;
            } else {
              if (mTxtSwitch1) {
                $('.m-txt-area').removeClass('txt-area-active');
                $('sticky-button').stop().animate({ bottom: 40 }, 500);
                // $('sticky-button > .sticky_wrap > p').removeClass('text-ac');
                // $('.img-box.img1 img').attr('src', 'assets/imgs/img_ux.gif');
              }

              mTxtSwitch1 = false;
            }

            if (img1_bottom < scroll_bottom && scroll_bottom > img2_bottom) {
              if (!attrSwitch2) {
                $('.img-box.img2 img').attr('src', 'assets/imgs/img_ui.gif');
              }
              attrSwitch2 = true;
              if (!mTxtSwitch2) {
                $('.txt1').addClass('m-txt-off');
                $('.txt2').addClass('m-txt-on');
              }
              mTxtSwitch2 = true;
            } else {
              if (mTxtSwitch2) {
                // $('.img-box.img2 img').attr('src', 'assets/imgs/img_ui.gif');
                $('.txt1').removeClass('m-txt-off');
                $('.txt1').addClass('m-txt-on');
                $('.txt2').removeClass('m-txt-on');
              }
              mTxtSwitch2 = false;
            }
            if (img2_bottom < scroll_bottom && scroll_bottom > img3_bottom) {
              if (!attrSwitch3) {
                $('.img-box.img3 img').attr(
                  'src',
                  'assets/imgs/img_develop.gif'
                );
              }
              attrSwitch3 = true;
              if (!mTxtSwitch3) {
                $('.txt2').removeClass('m-txt-on');
                $('.txt2').addClass('m-txt-off');
                $('.txt3').addClass('m-txt-on');
              }
              mTxtSwitch3 = true;
            } else {
              if (mTxtSwitch3) {
                $('.txt2').addClass('m-txt-on');
                $('.txt2').removeClass('m-txt-off');
                $('.txt3').removeClass('m-txt-on');
              }
              mTxtSwitch3 = false;
            }
            if (img3_bottom < scroll_bottom && scroll_bottom > img4_bottom) {
              if (!attrSwitch4) {
                $('.img-box.img4 img').attr(
                  'src',
                  'assets/imgs/img_marketing.gif'
                );
              }
              attrSwitch4 = true;

              if (!mTxtSwitch4) {
                $('.txt3').removeClass('m-txt-on');
                $('.txt3').addClass('m-txt-off');
                $('.txt4').addClass('m-txt-on');
                $('sticky-button')
                  .stop()
                  .animate({ bottom: 40 }, 500)
                  .queue(function () {
                    $('sticky-button > .sticky_wrap > p').addClass('text-ac');
                  });
              }

              if (
                scroll_bottom >
                $('.reason-section').offset().top +
                  $('.reason-section').height()
              ) {
                $('.reason-section').css({
                  position: 'relative',
                });
                $('.m-txt-area').css({
                  position: 'absolute',
                  bottom: 0,
                });
              }

              mTxtSwitch4 = true;
              // $(this).off(e);
            } else {
              if (mTxtSwitch4) {
                // $('.img-box.img4 img').attr('src', 'assets/imgs/img_marketing.gif');
                $('.txt3').addClass('m-txt-on');
                $('.txt3').removeClass('m-txt-off');
                $('.txt4').removeClass('m-txt-on');

                $('.reason-section').css({
                  position: 'unset',
                });
                $('.m-txt-area').css({
                  position: 'fixed',
                  bottom: 0,
                });

                // if ($('.txt3').hasClass('m-txt-on')) {

                // $('sticky-button').animate(
                //   { bottom: $('.txt-area-active').height() + 30 },
                //   800
                // );

                $('sticky-button').stop().animate({ bottom: 300 }, 800);

                $('sticky-button > .sticky_wrap > p').removeClass('text-ac');
                // }
              }
              mTxtSwitch4 = false;

              // $(this).off(e);
            }
          }
        });
      }

      $(function () {
        // $('footer-share')
        //   .find('.top_btn')
        //   .on('click', function (e) {
        //     $('html, body').animate(
        //       {
        //         scrollTop: 0,
        //       },
        //       600
        //     );
        //     return false;
        //   });
        // $('footer-share')
        //   .find('.to-process')
        //   .on('click', function (e) {
        //     $('html, body').animate(
        //       {
        //         scrollTop: $('#process').offset().top,
        //       },
        //       600
        //     );
        //     return false;
        //   });
        // $('footer-share')
        //   .find('.to-portfolio')
        //   .on('click', function (e) {
        //     $('html, body').animate(
        //       {
        //         scrollTop: $('#portfolioWrap').offset().top,
        //       },
        //       600
        //     );
        //     return false;
        //   });
        // $('footer')
        //   .find($('#process'))
        //   .click(function () {
        //     $(window).animate({ scrollTop: $('#process').offset().top });
        //   });
      });
    }, 400);
  }

  goHome() {
    this.router.navigateByUrl('/').then(() => {
      //window.location.reload();
    });
  }
  goService() {
    this.router.navigateByUrl('/service-menu').then(() => {
      //window.location.reload();
    });
  }
  goPortfolio() {
    this.router.navigateByUrl('/portfolio').then(() => {
      //window.location.reload();
    });
  }

  goInquiry() {
    const isMobile = this.deviceService.isMobileDevice();
    if (isMobile) {
      this.dialog.open(InquirySimplePopComponent, {
        panelClass: 'sample-pop-up',
        backdropClass: 'sample-pop-up-backdrop',
      });
    } else {
      this.router.navigateByUrl('/inquiry');
    }
  }

  goplan() {
    this.router.navigateByUrl('/plan').then(() => {
      //window.location.reload();
    });
  }
  godesign() {
    this.router.navigateByUrl('/design').then(() => {
      //window.location.reload();
    });
  }
  godevelopment() {
    this.router.navigateByUrl('/development').then(() => {
      //window.location.reload();
    });
  }
  gomarketing() {
    this.router.navigateByUrl('/marketing').then(() => {
      //window.location.reload();
    });
  }
  goHanra() {
    this.router.navigateByUrl('/portfolio-hanra').then(() => {
      //window.location.reload();
    });
  }

  goPfDetail(id) {
    this.router.navigateByUrl(`/portfolio-detail/${id}`).then(() => {
      //window.location.reload();
    });
  }

  goStore() {
    this.router.navigateByUrl(`/s-tore`).then(() => {
      //window.location.reload();
    });
  }

  goSolutionHome() {
    this.router.navigateByUrl(`/solution-home`).then(() => {});
  }

  // 배너닫기
  close() {
    this.showBanner = !this.showBanner;
  }
  // 배너 링크 연결
  goLink() {}

  // fab 문의하기
  inqPopup() {
    this.dialog.open(InquirySimplePopComponent, {
      panelClass: 'sample-pop-up',
      backdropClass: 'sample-pop-up-backdrop',
    });
  }

  async call() {
    window.open(`tel:16445786`, '_self');
    const trackingDataId = await this.db.createId();
    await this.trackingService.saveTrackingData(trackingDataId, 'tryCall');
    let script1 = this._renderer2.createElement('script');
    script1.type = 'text/javascript';
    script1.charset = `UTF-8`;
    script1.src = `//t1.daumcdn.net/kas/static/kp.js`;
    this._renderer2.appendChild(this._document.body, script1);
    let script2 = this._renderer2.createElement('script');
    script2.type = 'text/javascript';
    script2.text = `kakaoPixel('901381571553151974').pageView();
    kakaoPixel('901381571553151974').participation();`;
    this._renderer2.appendChild(this._document.body, script2);
    let script3 = this._renderer2.createElement('script');
    script3.type = 'text/javascript';
    script3.text = `var _nasa={};
    if (window.wcs) _nasa["cnv"] = wcs.cnv("5","${1000}");
    if (!wcs_add) var wcs_add = {};
  wcs_add["wa"] = "s_52ea45ec186a";
  if (!_nasa) var _nasa = {};
  if (window.wcs) {
    wcs.inflow("startapp.co.kr");
    wcs_do(_nasa);
  }
    `;
    this._renderer2.appendChild(this._document.body, script3);
  }
}

/*스크롤이 아랫방향일때 main의가 아니라 slide_inner의 offsetleft가 400 > ++  한 스크롤마다 면 되지않나*/
/*스크롤 스무더는 쓰면 좋겟다*/
