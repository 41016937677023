import { Component, Inject, Renderer2 } from '@angular/core';
import {
  ActivatedRoute,
  ActivationStart,
  NavigationEnd,
  NavigationStart,
  Router,
  RoutesRecognized,
} from '@angular/router';
import * as $ from 'jquery';
import { filter, map } from 'rxjs/operators';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { DOCUMENT } from '@angular/common';
import { TrackingService } from './service/tracking.service';
import { DbService } from './service/db.service';
import { IpService } from './service/ip.service';
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  path;
  //기본값

  footer;
  footerHeight;
  footerWrap;
  count = 0;
  footerShow = true;
  constructor(
    private router: Router,
    analytics: AngularFireAnalytics,
    private _renderer2: Renderer2,
    private route: ActivatedRoute,
    private db: DbService,
    private ipService: IpService,
    private trackingService: TrackingService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    let windowWidth = window.innerWidth;

    //width에 맞게 푸터 바꿔주기
    if (windowWidth > 768) {
      this.footerShow = true;
    } else {
      this.footerShow = false;
    }

    this.router.events
      .pipe(
        map((data) => {
          if (data instanceof NavigationStart) {
            if (data.navigationTrigger == 'popstate') {
              window.location.reload();
            }
          }
          return data;
        })
      )
      .subscribe((e) => {
        if (e instanceof NavigationEnd) {
          gtag('config', 'G-CD3RSNS2BJ', {
            debug_mode: true,
            page_path: e.urlAfterRedirects,
          });

          if (e.url == '/inquiry-complete') {
            let scriptGoogle = this._renderer2.createElement('script');
            scriptGoogle.type = `text/javascript`;
            scriptGoogle.text = `

            gtag('event', 'conversion', { 'send_to': 'AW-795587901/40kTCKOSiIMYEL3qrvsC', 'value': 1.0, 'currency': 'KRW' });
            `;

            this._renderer2.appendChild(this._document.head, scriptGoogle);
          }
        }

        if (e instanceof RoutesRecognized) {
          $(window).scroll().off();
        }
        if (e instanceof ActivationStart) {
          //요청하신 페이지를 찾을 수 없습니다. 떴을때
          let notfound = e.snapshot.routeConfig.path;
          if (notfound == '**') {
            $('header-share').css({ display: 'none' });
            $('#footerWrap').css({ display: 'none' });
          }

          // if (this.footerShow) {
          //   let footerWrap = document.getElementById('footerWrap');
          //   footerWrap.classList.remove('footer-open');
          //   footerWrap.classList.remove('none-footer');
          // }
        }

        if (e instanceof NavigationEnd) {
          const split = e.url.split('?');
          this.path = split[0];
          let path = split[0];

          $(function () {
            let mainScrollPosition = $('#main').scrollTop() || 0;

            if (path == '/notfound' || path == '/web-preview') {
              $('header-share').css({ display: 'none' });
            }

            if (path != '/development' && path != '/portfolio-hanra') {
              $(window).on('scroll', function (e) {
                //스크롤 방향 알아내기
                let mainScrollY = $(window).scrollTop();
                let mainDirection =
                  mainScrollY - mainScrollPosition >= 0 ? 1 : -1;
                mainScrollPosition = mainScrollY; // Update scrollY
                let mainScrollTop = $(window).scrollTop();
                if (mainDirection == -1) {
                  // $('#header').addClass('on');
                  // $('#header').removeClass('off');
                }
                if (mainDirection == 1 && mainScrollTop >= $(window).height()) {
                  // $('#header').removeClass('on');
                  // $('#header').addClass('off');
                }
              });
            } else {
              $('#main').on('scroll', function (e) {
                //스크롤 방향 알아내기
                let mainScrollY = $('#main').scrollTop();
                let mainDirection =
                  mainScrollY - mainScrollPosition >= 0 ? 1 : -1;
                mainScrollPosition = mainScrollY; // Update scrollY
                let mainScrollTop = $('#main').scrollTop();
                if (mainDirection == -1) {
                  // $('#header').addClass('on');
                  // $('#header').removeClass('off');
                }
                if (mainDirection == 1 && mainScrollTop >= $(window).height()) {
                  // $('#header').removeClass('on');
                  // $('#header').addClass('off');
                }
              });
            }
          });

          if (this.footerShow) {
            if (
              this.path == '/development' ||
              this.path == '/portfolio-hanra' ||
              this.path == '/service-menu' ||
              this.path == '/inquiry-complete' ||
              this.path == '/order-complete' ||
              this.path == '/solution-complete' ||
              this.path == '/notfound'
            ) {
              let footerWrap = document.getElementById('footerWrap');
              // footerWrap.style.display = 'none';
              // footerWrap.style.position = 'absolute';
              // footerWrap.style.left = '-9999px';
              // footerWrap.style.top = '-9999px';

              // footerWrap.classList.add('none-footer');
            } else {
              // setTimeout(() => {
              //   let beforePosition = document.documentElement.scrollTop;
              //   window.onscroll = function (ev) {
              //     //
              //     let count = 0;
              //     let afterPosition = document.documentElement.scrollTop;
              //     if (afterPosition > 50) {
              //       if (beforePosition < afterPosition) {
              //         let main = document.getElementById('main');
              //         if (count == 0) {
              //           if (
              //             window.innerHeight + window.scrollY >=
              //             main.offsetHeight
              //           ) {
              //             let footerWrap =
              //               document.getElementById('footerWrap');
              //             footerWrap.classList.add('footer-open');
              //             if (
              //               document
              //                 .getElementById('footerWrap')
              //                 .classList.contains('footer-open')
              //             ) {
              //               setTimeout(() => {
              //                 const footer = <HTMLElement>(
              //                   document.querySelector('footer')
              //                 );
              //                 footer.style.zIndex = '999';
              //               }, 1000);
              //             }
              //             // if (window.innerHeight + window.scrollY > main.offsetHeight) {
              //             window.scrollTo({
              //               top: main.offsetHeight,
              //             });
              //             setTimeout(() => {
              //               count++;
              //             }, 1000);
              //             // }
              //           } else {
              //             ev.preventDefault();
              //             ev.stopPropagation();
              //             return false;
              //           }
              //         }
              //       } else {
              //         // 스크롤 아래로
              //         ev.preventDefault();
              //         ev.stopPropagation();
              //         return false;
              //       }
              //     } else {
              //       // 평상 시
              //       //
              //     }
              //     beforePosition = afterPosition;
              //   };
              // }, 500);
            }
          }
        }
      });
  }

  async ngOnInit(): Promise<void> {
    this.setUpAnalytics();
    const id = await this.db.createId();
    this.trackingService.saveTrackingData(id, 'visit');
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
  }

  scrollEvent() {}

  setUpAnalytics() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        gtag('config', 'AW-795587901', {
          page_path: event.urlAfterRedirects,
        });
      });
  }
}
