import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss'],
})
export class StoreComponent implements OnInit {
  constructor(
    private router: Router,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  ngOnInit(): void {
    //   let script2 = this._renderer2.createElement('script');
    //   script2.type = 'text/javascript';
    //   script2.text = `var _nasa={};
    //   if (!wcs_add) var wcs_add = {};
    // wcs_add["wa"] = "s_52ea45ec186a";
    // if (!_nasa) var _nasa = {};
    // if (window.wcs) {
    //   wcs.inflow("startapp.co.kr");
    //   wcs_do(_nasa);}ㅏ
    //   `;
    //   this._renderer2.appendChild(this._document.body, script2);
    $(function () {
      let txtBox = [];
      let temp = [];

      function textAni() {
        let txtLine = [];
        $('.main-text p').each(function (index, item) {
          let txt = $(item).text();
          let tmp = { index: index, txt: txt };
          txtLine.push(tmp);
        });

        let splitTxt = [];
        txtLine.forEach((txt) => {
          splitTxt.push(txt.txt.split(' '));
        });

        $(splitTxt).each(function (index, item) {
          let txt = [];
          item.forEach((e) => {
            txt.push('<span>' + e + '</span>&nbsp;');
          });
          txtBox.push({ index: index, txt: txt });
        });
        for (var i = 0; i < txtBox.length; i++) {
          $('.main-text p').eq(i).html(txtBox[i].txt);
          $('.main-text p span').css({
            display: 'inline-block',
            opacity: 0,
            position: 'relative',
            top: '10px',
            left: '5px',
            fontSize: '1.17rem',
            lineHeight: '1.722rem',
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
          });
          $('.main-text p')
            .eq(i)
            .find('span')
            .each(function (i, item) {
              temp.push(item);
            });
        }
      }
      textAni();

      // 실행
      setTimeout(() => {
        for (let i = 0; i < txtBox.length; i++) {
          for (let index = 0; index < temp.length; index++) {
            const ele = temp[index];
            $('.main-text p')
              .eq(i)
              .animate({ opacity: 1 })
              .find($(ele))
              .delay(50 * index)
              .animate({ top: 0, opacity: 1, left: 0 }, 500);
          }
        }
      }, 200);
    });
  }

  goDetail(id) {
    this.router.navigateByUrl(`/store-detail/${id}`).then(() => {
      // //window.location.reload();
    });
  }
}
