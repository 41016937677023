<main id="main">
  <div class="wrap">
    <section class="complete w1180 cf"
      *ngIf="checkFooter()">
      <h2 class="title">
        스타터팩서비스
        <p>
          스타터팩을 서비스를 신청해주셔서 감사합니다.
          <span class="block">담당자가 확인 후 바로
            <span class="bold">전화로 연락</span> 드리도록
            하겠습니다.</span>
        </p>
      </h2>
      <div class="next-process">
        <p class="how">다음은 어떻게 진행 되나요?</p>
        <ul>
          <li class="text">
            <h3>신청내역확인</h3>
            <p>
              전문상담팀이 요청하신 신청 내용을 확인합니다.
            </p>
          </li>
          <li class="icon">
            <img src="assets/imgs/inquiry_next_icon.png"
              alt="화살표아이콘" />
          </li>
          <li class="text deep-bg">
            <h3>전화상담 및 준비</h3>
            <p>담당자가 필요한 사항을 전달드리고 고객님의 앱을 준비해드립니다.</p>
          </li>
          <li class="icon">
            <img src="assets/imgs/inquiry_next_icon.png"
              alt="화살표아이콘" />
          </li>
          <li class="text">
            <h3>앱 등록</h3>
            <p>
              신청하신 앱을 등록해드립니다.
              <span class="block">본격적인 앱 비즈니스를 시작하세요.</span>
            </p>
          </li>
        </ul>
      </div>
      <button class="go-home">
        <a [routerLink]="['/home']">홈으로 돌아가기</a>
      </button>
    </section>
  </div>
  <footer-share [ngClass]="{ 'not-active': !checkFooter() }"></footer-share>
</main>
