import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { DeviceService } from 'src/app/service/device.service';
import { InquirySimplePopComponent } from '../../inquiry-simple-pop/inquiry-simple-pop.component';

@Component({
  selector: 'header-share',
  templateUrl: './header-share.component.html',
  styleUrls: ['./header-share.component.scss'],
})
export class HeaderShareComponent implements OnInit {
  menuSwitch = false;
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private deviceService: DeviceService
  ) {}

  ngOnInit(): void {
    let that = this;
    $(function () {
      $('.easy-quot').hover(
        function () {
          $(this)
            .find('a')
            .css({
              // 'text-indent': '-9999px',
              // background:
              //   'url(../../../../assets/imgs/home-last-ac.png) no-repeat center / cover',
              // opacity: 0.6,
              background: '#fff',
              color: 'rgba(0,0,0,0.6)',
              opacity: 0.6,
            })
            .animate({ opacity: 1 }, 400);
        },
        function () {
          $(this)
            .find('a')
            .css({ background: 'transparent', color: '#fff', opacity: 0.6 })
            .animate({ opacity: 1 }, 400);
        }
      );

      let toggleSwitch = false;
      $('#mHeader #gnb').on('click', function () {
        if (!toggleSwitch) {
          $('#mHeader').addClass('openmenu');
          $('#mHeader').on('scroll touchmove mousewheel', function (e) {
            e.preventDefault();
            e.stopPropagation();

            // if ($('#mHeader').hasClass('openmenu') &&) {
            //   $('#mHeader').off(e);
            // }

            return false;
          });

          toggleSwitch = true;

          // $('#main').scroll(function (e) {

          // });
          // $('#main').on('mousewheel', function (e) {
          //   $('#main').off(e);

          // });
          // $(window).scroll(function (e) {

          //   $(window).off(e);
          //   $('#main').off(e);
          // });
        } else {
          $('#mHeader').removeClass('openmenu');

          toggleSwitch = false;
        }
      });

      let openSwitch = false;

      $('.m-service').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        let mainHeight = $(window).innerHeight();

        if (mainHeight > 765) {
          if (!openSwitch) {
            $('.work-list').addClass('open-work');
            $('.m-service').addClass('rotate-btn');
            $('.service-list').addClass('open-service');

            openSwitch = true;
          } else {
            $('.service-list').removeClass('open-service');
            $('.work-list').removeClass('open-work');
            $('.m-service').removeClass('rotate-btn');

            openSwitch = false;
          }
        } else {
          that.goService();
        }
      });
    });
  }

  goHome() {
    this.router.navigateByUrl('/').then(() => {
      // //window.location.reload();
    });
  }
  goSolution() {
    this.router.navigateByUrl('/solution-product-category');
  }
  goService() {
    this.router.navigateByUrl('/service-menu').then(() => {
      // //window.location.reload();
    });
  }
  goPortfolio() {
    this.router.navigateByUrl('/portfolio').then(() => {
      // //window.location.reload();
    });
  }
  goStore() {
    this.router.navigateByUrl('/s-tore').then(() => {
      // //window.location.reload();
    });
  }

  goInquiry() {
    const isMobile = this.deviceService.isMobileDevice();
    if (isMobile) {
      this.dialog.open(InquirySimplePopComponent, {
        panelClass: 'sample-pop-up',
        backdropClass: 'sample-pop-up-backdrop',
      });
    } else {
      this.router.navigateByUrl('/inquiry');
    }
  }

  goplan() {
    this.router.navigateByUrl('/plan').then(() => {
      // //window.location.reload();
    });
  }
  godesign() {
    this.router.navigateByUrl('/design').then(() => {
      // //window.location.reload();
    });
  }
  godevelopment() {
    this.router.navigateByUrl('/development').then(() => {
      // //window.location.reload();
    });
  }
  gomarketing() {
    this.router.navigateByUrl('/marketing').then(() => {
      // //window.location.reload();
    });
  }

  goSolutionHome() {
    this.router.navigateByUrl('/solution-home').then(() => {
      // //window.location.reload();
    });
  }

  goWebSolution() {
    this.router.navigateByUrl('/solution-web-category');
  }
}
