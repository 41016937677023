const solutionRenewalData = [
  {
    id: 'community-chat',
    mainType: 'community',
    isUsedPg: false,
    mainColor: '#8558ff',
    topArea: {
      mainImgPc: 'assets/imgs/detailRenewal/community-chat/main-img-pc.png',
      mainImgMb: 'assets/imgs/detailRenewal/community-chat/main-img-mb.png',
      title:
        '따뜻함이 배가 되는 대화. 커뮤니티 공간에서 채팅도 하며 일상을 공유해요.',
      detail: `동네 모임, 팬덤, 일상, 공부, 운동, 취업 등 어떤 분야라도 손 쉽게 적용할 수 있는 커뮤니티. 평범한 일상에 당신만의 특별한 공간을 더해보세요. 앱 개발 전문가 스타트앱이 도와드릴게요.`,
      previewUrl: '',
      startUrl: '',
    },
    detailImages: {
      pc: 'assets/imgs/detailRenewal/community-chat/community-detail-img-pc.png',
      tablet:
        'assets/imgs/detailRenewal/community-chat/community-detail-img-tb.png',
      mobile:
        'assets/imgs/detailRenewal/community-chat/community-detail-img-mb.png',
    },
    adminSlides: [
      {
        image: 'assets/imgs/detailRenewal/community-chat/admin-sample01.png',
        title: '직관적인 대시보드',
        desc: '회원 수, 매출 정보, 최신 주문 정보를 한 눈에 파악할 수 있어요.',
      },
      {
        image: 'assets/imgs/detailRenewal/community-chat/admin-sample01.png',
        title: '직관적인 대시보드 2',
        desc: '회원 수, 매출 정보, 최신 주문 정보를 한 눈에 파악할 수 있어요.',
      },
      {
        image: 'assets/imgs/detailRenewal/community-chat/admin-sample01.png',
        title: '직관적인 대시보드 3',
        desc: '회원 수, 매출 정보, 최신 주문 정보를 한 눈에 파악할 수 있어요.',
      },
    ],
    adminHomeSlides: [
      {
        image: './assets/imgs/homeAdmin/admin_1.png',
      },
      // {
      //   image: './assets/imgs/homeAdmin/admin_1-1.png',
      // },
      {
        image: './assets/imgs/homeAdmin/_admin_2.png',
      },
      // {
      //   image: './assets/imgs/homeAdmin/admin_2-1.png',
      // },
      {
        image: './assets/imgs/homeAdmin/admin_3.png',
      },
      {
        image: './assets/imgs/homeAdmin/_admin_3-1.png',
      },
    ],
  },
  {
    id: 'mall-chat-community',
    mainType: 'mall',
    isUsedPg: true,
    mainColor: '#1285ff',
    topArea: {
      mainImgPc: 'assets/imgs/detailRenewal/mall-chat-community/main-img.png',
      mainImgMb: 'assets/imgs/detailRenewal/mall-chat-community/main-img.png',
      title:
        '회원간 소통부터 상품 구매까지 하나로 OK 이 모든것이 가능한 신개념 앱',
      detail: `앱 개발 전문가 스타트앱이 만든 쇼핑몰 스타터팩을 이용하면 개발자, 디자이너 없이 혼자서도 앱을 운영할 수 있어요.`,
      previewUrl: '',
      startUrl: '',
    },
    detailImages: {
      pc: 'assets/imgs/detailRenewal/mall-chat-community/detail-img-pc.png',
      tablet: 'assets/imgs/detailRenewal/mall-chat-community/detail-img-tb.png',
      mobile: 'assets/imgs/detailRenewal/mall-chat-community/detail-img-mb.png',
    },
    adminSlides: [
      {
        image:
          'assets/imgs/detailRenewal/mall-chat-community/admin-sample01.png',
        title: '필요한것만 담은 직관적인 대시보드',
        desc: '회원 수, 매출 정보, 최신 주문 정보를 한 눈에 파악할 수 있어요.',
      },
      {
        image:
          'assets/imgs/detailRenewal/mall-chat-community/admin-sample02.png',
        title: '당신의 이야기, 타인의 공감',
        desc: '우리 커뮤니티에 맞게 이야기 카테고리를 다양하게 설정해보세요.\n사람들은 그 안에서 소통하고 공감할거에요.',
      },
      {
        image:
          'assets/imgs/detailRenewal/mall-chat-community/admin-sample03.png',
        title: '고객 대응을 바로바로. 정말 편리한 CS',
        desc: '고객의 목소리를 바로 듣고 바로 대응이 가능한 문의 서비스.\n앱과 연동된 관리자 페이지로 편리하게 사용해보세요.',
      },
    ],
  },
];

const functionListData = [
  {
    title: '사용자 앱',
    functionList: [
      {
        title: '로그인',
        desc: '이메일 주소, 비밀번호',
      },
      {
        title: '회원가입',
        desc: '이메일 주소, 비밀번호, 이름, 약관 동의, 이메일 인증',
      },
      {
        title: '비밀번호 찾기',
        desc: '이메일 주소로 재설정 링크 메일 전송',
      },
      {
        title: '광고 배너',
        desc: '슬라이드 배너',
      },
      {
        title: '상품 정렬',
        desc: '최신순, 낮은 가격순, 높은 가격순, 판매량순 등으로 상품 리스트 정렬',
      },
      {
        title: '인기 상품',
        desc: '인기 상품 선택 시 상품 상세 페이지로 이동',
      },
      {
        title: '상품 상세 페이지',
        desc: '상품 이미지, 상품명, 가격, 할인율, 상품정보, 리뷰, 문의, 찜하기, 구매하기 ',
      },
      {
        title: '기획전',
        desc: '기획전 상세 화면 : 기획전 관련 정보 및 상품 리스트 표시',
      },
      {
        title: '찜 (좋아요)',
        desc: '상품 찜하기',
      },
      {
        title: '상품 옵션',
        desc: '상품별 옵션 선택 기능',
      },
      {
        title: '장바구니',
        desc: '장바구니 담기 및 상품 삭제',
      },
      {
        title: '검색',
        desc: '상품 검색 기능, 추천 검색어 기능, 최근 검색어 기능',
      },
      {
        title: 'PG결제',
        desc: '상품 결제',
      },
    ],
  },
  {
    title: '강사용 앱',
    functionList: [
      {
        title: '1',
        desc: '1',
      },
    ],
  },
  {
    title: '관리자 페이지',
    functionList: [
      {
        title: '1',
        desc: '1',
      },
    ],
  },
  {
    title: '앱 기본사양 및 작동환경',
    functionList: [
      {
        title: '1',
        desc: '1',
      },
    ],
  },
];

// 구독형 및 소유형 상품 정보
const productTypesData = [
  {
    id: 'subscribe',
    products: [
      {
        id: 'type-install',
        desc: '실속있는 선택, 스토어에 등록하지 않고 우리끼리 사용하는 ',
        title: '설치형 상품',
        items: [
          {
            id: 'quarter-year',
            month: 3,
            kinds: [
              {
                id: 'single',
                discountRate: 0,
                monthPrice: 40000,
                totalPrice: 120000,
              },
              {
                id: 'both',
                discountRate: 0,
                monthPrice: 80000,
                totalPrice: 240000,
              },
            ],
          },
          {
            id: 'half-year',
            month: 6,
            kinds: [
              {
                id: 'single',
                discountRate: 25,
                monthPrice: 30000,
                totalPrice: 180000,
              },
              {
                id: 'both',
                discountRate: 31,
                monthPrice: 55000,
                totalPrice: 330000,
              },
            ],
          },
          {
            id: 'one-year',
            month: 12,
            kinds: [
              {
                id: 'single',
                discountRate: 50,
                monthPrice: 20000,
                totalPrice: 240000,
              },
              {
                id: 'both',
                discountRate: 56,
                monthPrice: 35000,
                totalPrice: 420000,
              },
            ],
          },
        ],
      },
      {
        id: 'type-store-register',
        desc: '월 사용료만 내고 사용해요.',
        title: '스타터팩구독',
        items: [
          {
            id: 'quarter-year',
            month: 3,
            kinds: [
              {
                id: 'single',
                discountRate: 0,
                monthPrice: 90000,
                totalPrice: 270000,
              },
              {
                id: 'both',
                discountRate: 0,
                monthPrice: 170000,
                totalPrice: 510000,
              },
            ],
          },
          {
            id: 'half-year',
            month: 6,
            kinds: [
              {
                id: 'single',
                discountRate: 28,
                monthPrice: 65000,
                totalPrice: 390000,
              },
              {
                id: 'both',
                discountRate: 29,
                monthPrice: 120000,
                totalPrice: 720000,
              },
            ],
          },
          {
            id: 'one-year',
            month: 12,
            kinds: [
              {
                id: 'single',
                discountRate: 50,
                monthPrice: 45000,
                totalPrice: 540000,
              },
              {
                id: 'both',
                discountRate: 53,
                monthPrice: 80000,
                totalPrice: 960000,
              },
            ],
          },
        ],
      },
    ],
  },

  {
    id: 'ownership',
    products: [
      {
        id: 'type-ownership',
        desc: '짧은 세팅 기간, 저렴한 비용으로 바로 앱 개발을 완료할 수 있어요.',
        title: '스타터팩 선택',
        items: [
          {
            id: 'single',
            discountRate: 0,
            totalPrice: 3000000,
          },
          {
            id: 'both',
            discountRate: 0,
            totalPrice: 5000000,
          },
        ],
      },
    ],
  },
];

// 고객 후기
const reviewData = [
  {
    avatar: '',
    name: '고*정 대표님',
    appName: '반려동물 용품 쇼핑몰',
    content: `제작부터 판매까지 모두 혼자 하는 1인 기업이라 쇼핑몰 앱 사용은 꿈도 못
          꿨어요. SNS를 통해 물건을 판매하다 저희 제품을 좋아해 주는 분들이 점점
          생겨나 전문적인 관리를 위해 쇼핑몰 앱을 사용하기 시작했습니다. 일일이
          문자로 연락할 필요 없이 푸시 알림을 보내고 공지사항도 보낼 수 있어
          번거로운 일을 덜었어요. 사용 방법도 쉽고 브랜딩에 더욱 집중할 수 있어서
          대만족입니다!`,
  },
  {
    // avatar: 'assets/imgs/detailRenewal/avatar-sample01.png',
    avatar: '',
    name: '최*우 대표님',
    appName: '아파트 커뮤니티',
    content: `다들 사용하는 채팅앱이 아닌 내부에서만 사용할 채팅앱이 있으면 좋겠다고 생각했는데 스타트앱 채팅 스타터팩이 정답이었네요! 앱
          제작은 비용이 너무 많이 들어 고민이었는데 구축비용 없이 월
          사용료만 내면 된다니 너무 획기적이었습니다. 구축한 앱과 전혀
          다를 바 없어 너무 만족합니다.`,
  },
  {
    avatar: '',
    name: '이*우 대표님',
    appName: '촬영 스튜디오',
    content: `스튜디오를 찾는 고객이 늘어나서 예약 관리를 할 수 있는 시스템이
          필요했습니다. 스타트앱의 예약 스타터팩은 예약이 되면 바로 알림이 오고
          캘린더에서 예약 상황을 한 눈에 볼 수 있어 정말 편리합니다. 고객 대신 예약을 해 줄 수 있어 고객 만족도도 크고 앱에 채팅 기능이 있어 통신비 부담 없이
          고객과 소통할 수 있는 점도 참 좋아요. 부담 없는 월 사용료에 유지 보수 걱정도 없으니 최고의 선택이라고 생각합니다.`,
  },
  {
    avatar: '',
    name: '장*서님',
    appName: '채팅앱 사용자',
    content: ` 다들 사용하는 채팅앱이 아닌 내부에서만 사용할 채팅앱이 있으면 좋겠다고 생각했는데 스타트앱 채팅 스타터팩이 정답이었네요! 앱
          제작은 비용이 너무 많이 들어 고민이었는데 구축비용 없이 월
          사용료만 내면 된다니 너무 획기적이었습니다. 구축한 앱과 전혀
          다를 바 없어 너무 만족합니다.`,
  },
  {
    avatar: '',
    name: '김*지 대표님',
    appName: '취미 생활 공유 커뮤니티',
    content: `같은 취미를 가진 사람들을 만나고 취향을 공유하고 싶어 취미 생활 공유
          커뮤니티를 만들었어요. 스타트앱을 통해 앱을 런칭하면서 기업이나 단체가
          아닌 저 같은 개인이 이렇게 쉽게 앱을 가질 수 있다는 게 신기했어요! 유지,
          보수도 따로 신경 쓸 일 없어 정말 편해요.`,
  },
  {
    avatar: '',
    name: '박*형님',
    appName: '밀키트 쇼핑몰',
    content: `지방에 있는 부모님 가게가 맛집으로 소문이 나면서 온라인 밀키트 판매를 시작했습니다. 스타트앱 쇼핑몰 스타터팩은 상품 등록
          방법이 간편하고 관리자 페이지도 사용이 쉬워 부모님께서도
          무리없이 사용하세요. 스타트앱 쇼핑몰앱, 정말 잘한
          선택이었어요!`,
  },
];

// 자주 묻는 질문
const qnaData = [
  {
    question: '스타트앱 스타터팩이 무엇인가요?',
    answer: `스타트앱 스타터팩은 '누구나 쉽고 간편하게 앱을 런칭할 수 없을까?'하는 스타트앱의 의문에서 시작된 신개념 앱 서비스입니다. 기존에 앱을 런칭하려면 앱 개발 후 앱 등록까지 수 개월, 수 천만원이 필요했습니다. 부담스러운 기간과 비용에 아무나 앱 런칭을 할 수 없었죠. 그래서 스타트앱은 다양한 앱 제작 경험을 바탕으로 앱 종류별로 필요한 기능을 모두 담은 레디메이드 완성형 앱을 만들었어요.기간, 비용, 기능 고민할 것 없이 원하는 스타터팩을 선택하고 나만의 앱을 런칭해보세요! `,
  },

  {
    question: '스타터팩구독형과 소유형은 어떻게 다른가요?',
    answer: `스타터팩구독형은 고가의 앱 구축 비용을 지불할 필요 없이
    선택한 사용기간 동안 월 요금만 지불하고 원하는 스타터팩을 사용할 수 있는 간편하고 스마트한 서비스입니다.

    스타터팩소유형은 앱 전문가 스타트앱이 만든 완성형 스타터팩을
    합리적인 가격에 구입할 수 있는 서비스입니다.

    필요에 맞게 상품을 선택하세요!`,
  },
  {
    question: '스타터팩구독형의 설치형 상품과 스타터팩구독은 어떻게 다른가요?',
    answer: `월 사용료만 내면 앱을 사용할 수 있는 스타터팩구독형은
    설치형 상품과 스타터팩구독으로 나뉩니다.

    설치형 상품은 스토어에 앱 등록이 필요 없는 경우 추천 드리며
    안드로이드 APK를 휴대폰에 설치하거나 모바일 웹 링크를 이용하여 스타터팩을 이용할 수 있습니다.

    스타터팩구독은 안드로이드나 애플 스토어에 앱을 등록하면
    해당 앱을 다운 받아 누구나 사용할 수 있습니다.`,
  },
  {
    question: '스타터팩신청 및 사용 방법을 알려주세요.',
    answer: `선택하신 스타터팩상품에 따라 신청 및 사용 방법이 달라집니다.

    스타트앱의 스타터팩상품 선택 및 결제 후
    개발자 계정과 로고 등 앱 세팅에 필요한 자료를 전달 주시면 앱 세팅이 완료됩니다.

    스타터팩구독의 설치형 상품은
    전달 드린 안드로이드 APK 나 모바일 웹 링크를 통해 바로 서비스 이용이 가능하며

    스타터팩구독의 스타터팩구독은
    앱 등록 후 스토어에서 다운 받아 앱을 이용하실 수 있습니다.

    스타터팩은
    앱 세팅 후 소스코드를 전달 드리며 스토어 등록은 추가 사항으로 30만원 별도 비용이 발생합니다.`,
  },
  {
    question: '앱 등록을 위해 무엇을 준비하나요?',
    answer: `안드로이드 앱만 사용하시는 경우,
    앱에 사용하실 로고와
    개발을 위해 구글 계정, 구글 개발자 계정, Firebase 요금제 업그레이드가 필요합니다.
    앱 세팅 완료 시, 스타트앱의 안내에 따라 앱 등록을 위한 앱 소개 이미지와 앱 등록 자료도 준비해주세요.

    iOS 앱도 사용하시는 경우,
    iOS 개발자 계정이 추가로 필요합니다.`,
  },
  {
    question: '앱 등록에는 얼마나 시간이 걸리나요?',
    answer: `앱 등록을 위한 필요 자료를 모두 준비해주시면 스타트앱에서 앱 등록 대행을 시작합니다.
    앱 등록 거절 사유가 없는 경우, 앱 등록까지는 통상 2~3일 정도의 시간이 소요됩니다.`,
  },
  {
    question: '월 결제금은 언제부터 발생하나요?',
    answer: `계약을 체결하는 시점부터 스타트앱은 고객님에 스타터팩을 사용하실 수 있도록 준비를 시작합니다.
    따라서 계약 시점부터 월 결제금이 발생합니다.`,
  },
  {
    question: '추가나 유지 비용은 얼마나 드나요?',
    answer: `구글 파이어베이스의 서버 사용량에 따른 서버 이용료가 과금될 수 있습니다.
    일별 무료 구간 내 사용 시 이용료는 발생하지 않으며
    일별 무료 구간을 초과할 경우 초과분에 대해서만 이용료가 발생합니다.

    애플 스토어(앱스토어)에 앱을 게시 하신다면
    연 1회 미화 99달러의 개발자 계정 갱신 비용이 발생합니다.

    스타터팩소유형을 선택한 경우, 앱 등록을 원하실 경우 30만원의 앱 등록 대행 비용이 발생합니다.`,
  },
];

export {
  solutionRenewalData,
  productTypesData,
  functionListData,
  reviewData,
  qnaData,
};
