// src/app/ip.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IpService {
  private functionUrl =
    'https://asia-northeast3-startapp-new.cloudfunctions.net/getIP'; // Firebase Function URL

  constructor(private http: HttpClient) {}

  getIp(): Promise<any> {
    return this.http
      .get<{ ip: string }>(this.functionUrl)
      .pipe(take(1))
      .toPromise();
  }
}
