<main id="main">
  <h1>우리 쇼핑몰에 어울리는 디자인을<br />선택하세요.</h1>
  <!-- ngFor 사용 안 한 html 구조 -->
  <!-- <section id="design-one">
    <div class="container-inner -one">
    </div>
  </section>
  <section id="design-two">
    <div class="container-inner -two">
    </div>
  </section>
  <section id="design-three">
    <div class="container-inner -three">
    </div>
  </section>
  <section id="design-four">
    <div class="container-inner -four">
    </div>
  </section> -->

  <section *ngFor="let item of templates" [id]="'design-' + item.id">
    <div [class]="'container-inner -' + item.id">
      <div class="text-area">
        <p class="chapter">[디자인 {{ item.chapter }}]</p>
        <p class="feature">{{ item.feature }}</p>
        <p class="desc">{{ item.desc }}</p>
      </div>
    </div>
  </section>

  <footer-share id="footerWrap"></footer-share>
  <footer-share class="mobile-footer"></footer-share>
</main>

<!-- <div class="text-area">
     <p class="chapter">[디자인 01]</p>
     <p class="feature">깔끔한 / 세련된 / 기본적인</p>
     <p class="desc">직선과 여백을 활용한 구성으로 깔끔함과 세련됨이 느껴지는 디자인이에요.
          높은 채도의 색상으로 카테고리를 분명히 구분할 수 있어요. 의류부터 식품까지 다양하게 활용해보세요.</p>
</div> -->
