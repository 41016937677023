<div class="mobile-table">
  <h2><span>스타터팩</span> 구독</h2>

  <div id="table-wrap">
    <!-- <div class="table-group">
      <h3><span>설치형</span> 상품</h3>

      <table class="product-table">
        <caption>
          상품명
        </caption>
        <colgroup>
          <col style="width: 50%" />
          <col style="width: 50%" />
        </colgroup>
        <thead>
          <tr>
            <th colspan="2">
              <div>
                <p class="product-title">스타터팩구독</p>
                <p class="item-title">설치형 상품</p>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>안드로이드</td>
            <td>안드로이드+모바일 웹</td>
          </tr>
        </tbody>
      </table>

      <table class="feature-table">
        <caption>
          특징
        </caption>
        <tbody>
          <tr class="feature-line">
            <td>
              <div>
                <p>
                  회사에서, 친구끼리… <span>우리끼리 사용할 앱</span>이 필요한
                  사용자를 위한 전용 상품
                </p>
                <p>
                  스토어에 등록하지 않아요! 앱 등록 <span>기간 절약</span>, 세팅
                  후
                  <span>바로 사용 가능</span>
                </p>
                <p>스타터팩구독보다 <span>슬림한 요금제</span></p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="service-table">
        <caption>
          제공 서비스
        </caption>
        <thead>
          <tr>
            <th>안드로이드</th>
            <th>안드로이드+모바일 웹</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div>
                <div class="popover-area">
                  <span> 안드로이드 APK </span>
                  <button
                    type="button"
                    class="popover-btn"
                    placement="bottom"
                    popoverClass="popover-center"
                    ngbPopover="APK (Android Application Package)이란 안드로이드용 앱 설치 파일로 Android에서만 사용 가능합니다."
                  ></button>
                </div>
                <p>관리자 페이지</p>
                <p>월 유지보수</p>
              </div>
            </td>
            <td>
              <div>
                <p>안드로이드 APK</p>
                <div class="popover-area">
                  <span> 모바일 웹 호스팅 </span>
                  <button
                    type="button"
                    class="popover-btn"
                    placement="bottom-right"
                    popoverClass="popover-right"
                    ngbPopover="모바일 웹이란 별도의 앱 설치 없이 스마트 폰 등 모바일 기기에서 바로 확인 가능하도록 제작된 웹 사이트입니다."
                  ></button>
                </div>
                <p>관리자 페이지</p>
                <p>월 유지보수</p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="price-table">
        <caption>
          요금
        </caption>
        <colgroup>
          <col style="width: 50px" />
          <col style="width: 120px" />
          <col style="width: 120px" />
        </colgroup>
        <thead>
          <tr>
            <th></th>
            <th>안드로이드</th>
            <th>안드로이드+모바일 웹</th>
          </tr>
        </thead>
        <tbody>
          <tr class="price-line quarter-year">
            <td class="th">3개월 요금</td>
            <td>
              <div>
                <p>40,000원<span>/월</span></p>
                <p>120,000원</p>
              </div>
            </td>
            <td>
              <div>
                <p>80,000원<span>/월</span></p>
                <p>240,000원</p>
              </div>
            </td>
          </tr>
          <tr class="price-line half-year">
            <td class="th">6개월 요금</td>
            <td>
              <p class="badge">25%</p>
              <div>
                <p>30,000원<span>/월</span></p>
                <p>180,000원</p>
              </div>
            </td>
            <td>
              <p class="badge">31%</p>
              <div>
                <p>55,000원<span>/월</span></p>
                <p>330,000원</p>
              </div>
            </td>
          </tr>
          <tr class="price-line one-year">
            <td class="th">12개월 요금</td>
            <td>
              <p class="badge">50%</p>
              <div>
                <p>20,000원<span>/월</span></p>
                <p>240,000원</p>
              </div>
            </td>

            <td>
              <p class="badge">56%</p>
              <div>
                <p>35,000원<span>/월</span></p>
                <p>420,000원</p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <p class="guide-text">
        - 표시된 가격은 부가세 별도로 선택한 요금제 금액이 한번에 결제됩니다.
      </p>

      <table>
        <caption>
          신청 및 사용 방법
        </caption>
        <tbody>
          <tr class="request-line">
            <td class="way-common way-install">
              <div>
                <div class="step-item">
                  <p class="icon"></p>
                  <p class="step-title">상품 선택 및 결제</p>
                </div>
                <div class="step-item">
                  <p class="icon"></p>
                  <div>
                    <p class="step-title">앱 세팅</p>
                    <ul>
                      <li>개발자 계정 생성</li>
                      <li>로고 등 앱 세팅 필요 자료 준비</li>
                    </ul>
                  </div>
                </div>
                <div class="step-item">
                  <p class="icon"></p>
                  <div>
                    <p class="step-title">APK 및 모바일 웹 사용</p>
                    <ul>
                      <li>전달 받은 파일로 바로 앱 사용 시작!</li>
                    </ul>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div> -->

    <!-- 스타터팩구독 -->
    <div class="table-group">
      <!-- <h3><span>스토어 등록</span> 상품</h3> -->
      <h3><span>구독형</span> 상품</h3>

      <table class="product-table">
        <caption>
          상품명
        </caption>
        <colgroup>
          <col style="width: 50%" />
          <col style="width: 50%" />
        </colgroup>
        <!-- <thead>
          <tr>
            <th colspan="2">
              <div>
                <p class="product-title">스타터팩구독</p>
                <p class="item-title">스타터팩구독</p>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>안드로이드</td>
            <td>안드로이드+iOS</td>
          </tr>
        </tbody> -->
        <thead>
          <th>
            <div style="padding: 19px 20px 20px">
              <!-- <p class="product-title">구독형 상품</p> -->
              <p class="item-title">안드로이드</p>
            </div>
          </th>
          <th>
            <div style="padding: 19px 20px 20px">
              <!-- <p class="product-title">구독형 상품</p> -->
              <p class="item-title">안드로이드 + iOS</p>
            </div>
          </th>
        </thead>
      </table>

      <table class="feature-table">
        <caption>
          특징
        </caption>
        <tbody>
          <tr class="feature-line">
            <td>
              <div>
                <!-- <p>
                  스타터팩만 선택하면 <span>스토어 등록 대행까지</span> 한
                  번에!
                </p> -->
                <p>
                  고가의 앱 구축 비용없이 <span>월 사용료</span>만 내면
                  <span>전문가가 만든 앱</span>을 사용 가능
                </p>
                <p>3개월부터 선택 가능한 <span>부담없는 사용 기간</span></p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="service-table">
        <caption>
          제공 서비스
        </caption>
        <thead>
          <tr>
            <th>안드로이드</th>
            <th>안드로이드+iOS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div>
                <p>안드로이드 앱 월 구독</p>
                <!-- <p>구글 Play 스토어 앱 등록</p> -->
                <p>관리자 페이지</p>
                <p>월 유지보수</p>
              </div>
            </td>
            <td>
              <div>
                <p>안드로이드 + iOS 앱 월 구독</p>
                <!-- <p>구글 Play 스토어 앱 등록</p> -->
                <!-- <p>애플 App Store 앱 등록</p> -->
                <p>관리자 페이지</p>
                <p>월 유지보수</p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="price-table">
        <caption>
          요금
        </caption>
        <colgroup>
          <col style="width: 50px" />
          <col style="width: 120px" />
          <col style="width: 120px" />
        </colgroup>
        <thead>
          <tr>
            <th></th>
            <th>안드로이드</th>
            <th>안드로이드+iOS</th>
          </tr>
        </thead>
        <tbody>
          <tr class="price-line quarter-year">
            <td class="th">3개월 요금</td>
            <td>
              <div>
                <p>90,000원<span>/월</span></p>
                <p>270,000원</p>
              </div>
            </td>
            <td>
              <div>
                <p>170,000원<span>/월</span></p>
                <p>510,000원</p>
              </div>
            </td>
          </tr>
          <tr class="price-line half-year">
            <td class="th">6개월 요금</td>
            <td>
              <p class="badge">28%</p>
              <div>
                <p>65,000원<span>/월</span></p>
                <p>390,000원</p>
              </div>
            </td>
            <td>
              <p class="badge">29%</p>
              <div>
                <p>120,000원<span>/월</span></p>
                <p>720,000원</p>
              </div>
            </td>
          </tr>
          <tr class="price-line one-year">
            <td class="th">12개월 요금</td>
            <td>
              <p class="badge">50%</p>
              <div>
                <p>45,000원<span>/월</span></p>
                <p>540,000원</p>
              </div>
            </td>

            <td>
              <p class="badge">53%</p>
              <div>
                <p>80,000원<span>/월</span></p>
                <p>960,000원</p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <p class="guide-text">
        - 표시된 가격은 부가세 별도로 선택한 요금제 금액이 한번에 결제됩니다.
      </p>

      <table>
        <caption>
          신청 및 사용 방법
        </caption>
        <tbody>
          <tr class="request-line">
            <td class="way-common way-store">
              <div>
                <div class="step-item">
                  <p class="icon"></p>
                  <p class="step-title">상품 선택 및 결제</p>
                </div>
                <div class="step-item">
                  <p class="icon"></p>
                  <div>
                    <p class="step-title">앱 세팅</p>
                    <ul>
                      <li>개발자 계정 생성</li>
                      <li>로고 등 앱 세팅 필요 자료 준비</li>
                      <li>스토어별 앱 등록 필요 자료 준비</li>
                    </ul>
                  </div>
                </div>
                <div class="step-item">
                  <p class="icon"></p>
                  <div>
                    <p class="step-title">APK 및 모바일 웹 사용</p>
                    <ul>
                      <li>전달 받은 파일로<br />바로 앱 사용 시작!</li>
                    </ul>
                  </div>
                </div>
                <div class="step-item">
                  <p class="icon"></p>
                  <p class="step-title">스토어 등록</p>
                </div>
                <div class="step-item">
                  <p class="icon"></p>
                  <div>
                    <p class="step-title">앱 다운로드 후 사용</p>
                    <ul>
                      <li>등록한 스토어에서 앱 다운로드 후 사용</li>
                    </ul>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <mat-accordion class="guidelines">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> <span>추가 </span>비용 </mat-panel-title>
        </mat-expansion-panel-header>
        <ul>
          <li>개발자 계정 생성 비용과 및 서버 이용료는 별도입니다.</li>
          <li>
            개발자 계정 생성 비용
            <p>Google(안드로이드) : 미화 25달러(최초 1회)</p>
            <p>Apple(iOS) : 미화 99달러(연 1회)</p>
          </li>
          <li>
            구글 파이어 베이스 서버 이용료
            <p>일별 무료 구간 내 사용 시 : 이용료 없음</p>
            <p>일별 무료 구간 초과 사용 시 : 초과분에만 이용료 발생</p>
          </li>
          <li>
            앱 등록 대행 비용
            <p>Google(안드로이드) : 20만원(부가세 별도)</p>
            <p>Google(안드로이드) + Apple(iOS) : 40만원(부가세 별도)</p>
            <p>단, 소스 코드 변경한 경우 앱 등록 대행 불가</p>
          </li>
        </ul>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion class="guidelines attention-note">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> <span>유의</span> 사항 </mat-panel-title>
        </mat-expansion-panel-header>
        <ul>
          <li>
            결제 후 앱 세팅이 시작되면 담당자가 안내 메일을 발송합니다. 앱 세팅
            시작 후에는 환불이 불가능합니다.
          </li>
          <li>
            소프트웨어의 제작 등, 전자 데이터화된 상품을 제조하는 자의 책임은
            제조물 책임법의 적용 대상에서 제외됩니다.
          </li>
          <li>
            파이어베이스에 연동된 gmail 계정 삭제 시, DB가 삭제되어 원상복구가
            불가능하며 스타터팩을 새로 신청하셔야 합니다.
          </li>
        </ul>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
