/** @format */

import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { AppSolutionUser } from '../models/appSolutionUser.models';
import { SolutionApp } from '../models/solutionApp.models';
import { SolutionAppCategory } from '../models/solutionAppCategory.models';
import { SolutionOrder } from '../models/solutionOrder.model';
import {
  OptionPrice,
  SolutionOption,
  SolutionPrice,
} from '../models/solutionPrice.models';
import { SolutionQna } from '../models/solutionQna.models';
import { SolutionReview } from '../models/solutionReview.models';

import { DbService } from './db.service';
import { AppPortFolio } from '../models/appPortFolio.model';
import { AppCategory } from '../models/appCategory.model';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  solutionPrice: SolutionPrice[];
  solutionOptionPrice: OptionPrice[];
  reviewData: SolutionReview[];
  qnaData: SolutionQna[];
  solutionAppCategories: SolutionAppCategory[];
  appSolutionUserData: AppSolutionUser;
  orderSolutionData: SolutionOrder;
  webCategories = [];
  appCategories: AppCategory[] = [];
  constructor(public db: DbService) {}

  //** 데이터 서비스 init **//
  async inIt() {
    return new Promise(async (resolve) => {
      this.appSolutionUserData = null;
      this.orderSolutionData = null;
      if (this.solutionAppCategories) {
        resolve('success');
      } else {
        const data = await Promise.all([
          this.getSolutionPrice(),
          this.getSolutionQna(),
          this.getSolutionReview(),
          this.getAllSolutionOptionPrice(),
          this.getSolutionAppCategory(),
          this.getWebCategory(),
          this.getAllProjectAppCategories(),
        ]);
        this.solutionPrice = data[0];
        this.qnaData = data[1];
        this.reviewData = data[2];
        this.solutionOptionPrice = data[3];
        this.solutionAppCategories = data[4];
        this.webCategories = data[5];
        this.appCategories = data[6];
        resolve('success');
      }
    });
  }

  async getSolutionPrice(): Promise<any> {
    const SolutionPrice = this.db
      .collection$(`solutionPrice`, (ref) => ref.orderBy('order', 'asc'))
      .pipe(first())
      .toPromise();

    return SolutionPrice;
  }

  async getSolutionAppCategory(): Promise<any> {
    const SolutionAppCategories = this.db
      .collection$(`solutionAppCategories`, (ref) =>
        ref.orderBy('order', 'asc')
      )
      .pipe(first())
      .toPromise();

    return SolutionAppCategories;
  }

  async getWebCategory(): Promise<any> {
    const webCategories = this.db
      .collection$(`webCategories`, (ref) => ref.orderBy('order', 'asc'))
      .pipe(first())
      .toPromise();

    return webCategories;
  }

  async getAllSolutionOptionPrice(): Promise<any> {
    const SolutionPrice = this.db
      .collection$(`solutionOptionPrice`, (ref) => ref.orderBy('order', 'asc'))
      .pipe(first())
      .toPromise();

    return SolutionPrice;
  }

  setSolutionPrice(app: SolutionApp) {
    const allPrice = this.solutionPrice.map((item) => {
      const options = item.options.map((option) => {
        const items = this.solutionOptionPrice.filter(
          (price) =>
            price.priceLevel === app.priceLevel &&
            price.optionType === option.optionType &&
            price.solutionPriceId === item.id
        );
        //

        return { ...option, items };
      });
      //

      return { ...item, options };
    });
    return allPrice;
  }

  async getSolutionQna(): Promise<any> {
    const SolutionQna = this.db
      .collection$(`solutionQna`, (ref) =>
        ref.where('displaySwitch', '==', true).orderBy('order', 'asc')
      )
      .pipe(first())
      .toPromise();

    return SolutionQna;
  }

  async getAllProjectAppCategories() {
    const webCategories = this.db
      .collection$(`appCategories`, (ref) => ref.orderBy('order', 'asc'))
      .pipe(first())
      .toPromise();
    return webCategories;
  }

  async getSolutionReview(): Promise<any> {
    const SolutionReview = this.db
      .collection$(`solutionReview`, (ref) =>
        ref.where('displaySwitch', '==', true).orderBy('order', 'asc')
      )
      .pipe(first())
      .toPromise();

    return SolutionReview;
  }

  async getSolutionAppById(appId: string): Promise<any> {
    const SolutionReview = this.db
      .doc$(`solutionApps/${appId}`)
      .pipe(first())
      .toPromise();

    return SolutionReview;
  }

  async getPortfoliopById(portfolioId: string): Promise<AppPortFolio> {
    const portfolio = this.db
      .doc$(`portfolios/${portfolioId}`)
      .pipe(first())
      .toPromise() as unknown as AppPortFolio;

    return portfolio as AppPortFolio;
  }

  async getWebById(appId: string): Promise<any> {
    const solutionWeb = this.db
      .doc$(`solutionWebs/${appId}`)
      .pipe(first())
      .toPromise();
    return solutionWeb;
  }

  async getStaterPackById(appId: string): Promise<any> {
    const SolutionReview = this.db
      .doc$(`solutionApps/${appId}`)
      .pipe(first())
      .toPromise();

    return SolutionReview;
  }

  async getSolutionAppFunctionListByAppId(appId: string): Promise<any> {
    const SolutionReview = this.db
      .collection$(`solutionAppFunctionList`, (ref) =>
        ref.where('solutionAppId', '==', appId).orderBy('order', 'asc')
      )
      .pipe(first())
      .toPromise();

    return SolutionReview;
  }

  getCategoryTitleById(categoryId: string) {
    return this.solutionAppCategories.find((item) => item.id == categoryId)
      ?.title;
  }

  getWebCategoryTitleById(categoryId: string) {
    return this.webCategories.find((item) => item.id == categoryId)?.title;
  }
}
