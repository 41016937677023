import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import Swiper from 'swiper';
import * as $ from 'jquery';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-marketing',
  templateUrl: './marketing.component.html',
  styleUrls: ['./marketing.component.scss'],
})
export class MarketingComponent implements OnInit, OnDestroy {
  startDate;
  inputSwitch;

  myDevice = navigator.platform;

  constructor(
    private router: Router,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  ngOnInit(): void {
    // 최하단 박스...
    document.addEventListener('DOMContentLoaded', () => {
      const io = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            entry.target.classList.remove('active');
            console.log('active 클래스가 제거되었습니다.');
            return;
          } else {
            entry.target.classList.add('active');
            console.log('active 클래스가 추가 되었습니다. 근데 사라짐');
          }
        });
      });

      const infoEls = document.querySelectorAll('.cube');
      infoEls.forEach((el) => {
        io.observe(el);
      });

      // 스크롤 초기화
      window.scrollTo(0, 0);
    });

    //   let script2 = this._renderer2.createElement('script');
    //   script2.type = 'text/javascript';
    //   script2.text = `var _nasa={};
    //   if (!wcs_add) var wcs_add = {};
    // wcs_add["wa"] = "s_52ea45ec186a";
    // if (!_nasa) var _nasa = {};
    // if (window.wcs) {
    //   wcs.inflow("startapp.co.kr");
    //   wcs_do(_nasa);}
    //   `;
    //   this._renderer2.appendChild(this._document.body, script2);
    const swiper = new Swiper('.swiper-container', {
      autoplay: true,
      spaceBetween: 20,
      allowTouchMove: false,
      loop: true,
      slidesPerView: 10,
      slidesPerGroup: 2,
      slidesPerColumn: 1,
      breakpoints: {
        1024: {
          slidesPerView: 10,
          slidesPerGroup: 2,
          slidesPerColumn: 1,
        },
        768: {
          slidesPerView: 8,
        },
        479: {
          slidesPerView: 6,
        },
      },
    });

    // 새로고침 시 스크롤 초기화
    // window.onload = function () {
    //   setTimeout(function () {
    //     scrollTo(0, 0);
    //   }, 10);
    // };

    let that = this;
    let filter = 'win16|win32|win64|mac|macintel';

    $(function () {
      // main height(100%) resize
      let winHeight = $(window).height();

      if (that.myDevice == filter) {
        $(window).trigger('resize');
        // 창크기 조절 시 자동 (새로고침)resize 발생
        window.onresize = function () {
          location.reload();
        };
      }

      // 텍스트애니메이션01 : main video <p>
      let txtBox = [];
      let temp = [];
      function textAni() {
        let txtLine = [];
        $('.main-text p').each(function (index, item) {
          let txt = $(item).text();
          let tmp = { index: index, txt: txt };
          txtLine.push(tmp);
        });

        let splitTxt = [];
        txtLine.forEach((txt) => {
          splitTxt.push(txt.txt.split(' '));
        });

        $(splitTxt).each(function (index, item) {
          let txt = [];
          item.forEach((e) => {
            txt.push('<span>' + e + '</span>&nbsp;');
          });
          txtBox.push({ index: index, txt: txt });
        });

        for (var i = 0; i < txtBox.length; i++) {
          $('.main-text p').eq(i).html(txtBox[i].txt);
          $('.main-text p span').css({
            display: 'inline-block',
            opacity: 0,
            position: 'relative',
            top: '10px',
            left: '5px',
            fontSize: '1.17rem',
            lineHeight: '1.722rem',
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
          });
          $('.main-text p')
            .eq(i)
            .find('span')
            .each(function (i, item) {
              temp.push(item);
            });
        }
      }
      textAni();

      setTimeout(() => {
        for (let index = 0; index < temp.length; index++) {
          const ele = temp[index];
          $('.main-text p')
            .eq(0)
            .animate({ opacity: 1 })
            .find($(ele))
            .delay(50 * index)
            .animate({ top: 0, opacity: 1, left: 0 }, 500);
        }

        //240105버전 마케팅 하단 애니메이션
        // const io = new IntersectionObserver((entries, observer) => {
        //   entries.forEach((entry) => {
        //     if (!entry.isIntersecting) {
        //       entry.target.classList.remove('active');
        //       console.log('active 클래스가 제거되었습니다.');
        //       return;
        //     } else {
        //       entry.target.classList.add('active');
        //       console.log('active 클래스가 추가 되었습니다. 근데 사라짐');
        //     }
        //   });
        // });

        // const infoEls = document.querySelectorAll('.box');
        // infoEls.forEach((el) => {
        //   io.observe(el);
        // });
      }, 200);

      // 텍스트애니메이션02 : section-title <p>
      let txtBox2 = [];
      let temp2 = [];
      function textAni2() {
        let txtLine2 = [];
        $('.section-title .animation').each(function (index, item) {
          let txt = $(item).text();
          let tmp = { index: index, txt: txt };
          txtLine2.push(tmp);
        });
        let splitTxt2 = [];
        txtLine2.forEach((txt) => {
          splitTxt2.push(txt.txt.split(' '));
        });
        $(splitTxt2).each(function (index, item) {
          let txt2 = [];
          item.forEach((e) => {
            txt2.push('<span>' + e + '</span>&nbsp;');
          });
          txtBox2.push({ index: index, txt: txt2 });
        });

        for (var i = 0; i < txtBox2.length; i++) {
          $('.section-title .animation').eq(i).html(txtBox2[i].txt);
          $('.section-title .animation span').css({
            opacity: 0,
            position: 'relative',
            top: '10px',
            left: '5px',
            fontSize: '1.166rem',
            lineHeight: '1.778rem',
            wordBreak: 'keep-all',
            display: 'inline-block',
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
          });
          $('.section-title .animation')
            .eq(i)
            .find('span')
            .each(function (i, item) {
              temp2.push(item);
            });
        }
      }
      textAni2();

      // 스크롤 이벤트 시작
      $(window).scroll(function () {
        let sTop = $(window).scrollTop();
        let sBottom = sTop + $(window).height();
        let winWidth = $(window).width();
        let winHeight = $(window).height();
        // cube animation
        // 가영님 버전
        // $('.start-marketing').each(function (e) {
        //   let sectionTop = $(this).offset().top;

        //   if (sTop + winHeight > sectionTop) {
        //     setTimeout(() => {
        //       console.log('액티브');

        //       $('.box').addClass('active');
        //     }, 1000);
        //   } else {
        //     console.log('사라짐');
        //     $('.box').removeClass('active');
        //   }
        // });

        // textAni2 animation
        let slideTop = $('.first-section').offset().top;
        let processTop = $('.process-wrap').offset().top;

        for (var i = 0; i < txtBox2.length; i++) {
          for (let index = 0; index < temp2.length; index++) {
            const ele = temp2[index];

            if (i == 0 && sBottom - 150 > slideTop) {
              // 첫번째 섹션 타이틀 애니메이션
              $('.section-title .animation')
                .eq(0)
                .find($(ele))
                .delay(50 * index)
                .animate({ opacity: 1, top: 0, left: 0 }, 500);
            } else if (i == 1 && sBottom + 300 > processTop) {
              // 세번재 섹션 타이틀 애니메이션(프로세스)
              $('.section-title .animation')
                .eq(1)
                .find($(ele))
                .delay(30 * index)
                .animate({ opacity: 1, top: 0, left: 0 }, 500);
            }
          }
        }

        // 두번째 섹션 리스트 애니메이션
        let $list = $('.content-wrap .list');
        let $text = $list.find('.text'),
          $img = $list.find('.img');
        $('.best-effect .content-wrap').each(function (e) {
          for (let i = 0; i < $list.length; i++) {
            let listTop = $list.eq(i).offset().top;
            let listBot = listTop + $list.eq(i).outerHeight();
            if (winWidth > 767) {
              if (sTop > listTop - 1000 && sTop < listBot) {
                $text.eq(i).addClass('active');
                $img.eq(i).addClass('active');
              }
            } else {
              if (sTop > listTop - 1400 && sTop < listBot) {
                $text.eq(i).addClass('active');
                $img.eq(i).addClass('active');
              }
            }
          }
        });
      });
      $(window).trigger('scroll');

      // scrollTop btn
      // $('footer-share')
      //   .find('.top_btn')
      //   .on('click', function (e) {
      //     $('html,body').animate({ scrollTop: 0 }, 500);
      //     return false;
      //   });
    }); // jQuery end
  }
  ngOnDestroy(): void {
    $(window).scroll().off();
  }

  stickyBtnActive() {
    const stickyBtn = document.querySelector<HTMLElement>('sticky-button p');
    window.addEventListener('scroll', function () {
      let scrollY = window.scrollY;
      if (scrollY > 10) {
        stickyBtn.classList.add('text-ac');
      }
    });
  }

  stickyBtnRelative() {
    $(window).scroll(function () {
      const stickyBtn = $('sticky-button');
      let btmLineTop = $('.bottom-line').offset().top;
      let btmLineHeight = $('.bottom-line').outerHeight();
      let trigger = btmLineTop + btmLineHeight;
      let docHeight = window.scrollY + window.innerHeight;

      if (trigger <= docHeight) {
        stickyBtn.css({
          position: 'absolute',
          zIndex: '100',
        });
      } else {
        stickyBtn.css({
          position: 'fixed',
        });
      }
    });
  }

  // footerOpen() {
  //   const footer = document.querySelector<HTMLElement>('footer-share footer');
  //   const marketing = document.querySelector<HTMLElement>('#footer-marketing');
  //   const footerInner = document.querySelector<HTMLElement>('.footer-inner');

  //   footer.style.position = 'static';
  //   let marketingHeight = marketing.clientHeight;
  //   marketing.style.marginTop = `${-marketingHeight}px`;

  //   window.addEventListener('scroll', () => {
  //     let $btmLine = $('.bottom-line');
  //     let $btmLineTop = $btmLine.offset().top;
  //     let $btmLineHeight = $btmLine.outerHeight();
  //     let point = $btmLineTop + $btmLineHeight;
  //     let trigger = window.scrollY + window.innerHeight;

  //     if (point <= trigger + 10) {
  //       setTimeout(() => {
  //         marketing.classList.add('active');
  //         marketing.style.marginTop = '0';
  //       }, 1800);
  //       setTimeout(() => {
  //         footerInner.style.zIndex = '100';
  //       }, 2700);
  //     }
  //   });
  // }

  goService() {
    this.router.navigateByUrl('/service-menu').then(() => {
      //window.location.reload();
    });
  }
}
