<div class="header">
  <header class="header_wrap"
    id="header">
    <h1><a (click)="goHome()">스타트앱</a></h1>
    <nav class="gnb_box"
      id="gnb">
      <button class="easy-quot">
        <a (click)="inquiry()">해당 상품 무료 견적 신청</a>
      </button>
    </nav>
  </header>
</div>

<div class="wrap">
  <iframe id="site"
    *ngIf="webSolution"
    [src]="iframUrl | safe"
    frameborder="0"
    (load)="onMyFrameLoad(this)"
    (error)="handleError()"></iframe>
</div>