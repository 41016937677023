import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AppFunctionList,
  SolutionApp,
} from 'src/app/models/solutionApp.models';
import { SolutionPrice } from 'src/app/models/solutionPrice.models';
import { DataService } from 'src/app/service/data.service';
import { DbService } from 'src/app/service/db.service';
import { TermsModalComponent } from '../terms-modal/terms-modal.component';
import Swal from 'sweetalert2';
import { SolutionOrder } from 'src/app/models/solutionOrder.model';
import { AppSolutionUser } from 'src/app/models/appSolutionUser.models';
import * as moment from 'moment';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-solution-payment',
  templateUrl: './solution-payment.component.html',
  styleUrls: ['./solution-payment.component.scss'],
})
export class SolutionPaymentComponent implements OnInit {
  // 쇼핑몰 디자인 선택
  designSelect = '쇼핑몰 디자인을 선택해주세요.';
  designList = [
    '[디자인 01] 깔끔한 / 세련된 / 기본적인',
    '[디자인 02] 심플한 / 명확한 / 모던한',
    '[디자인 03] 둥근 / 부드러운 / 편안한',
    '[디자인 04] 밝은 / 활기찬 / 즐거운',
  ];
  monthleyType = '스타터팩구독';
  // 요금 선택
  priceSelect = '상품 요금을 선택해주세요.';
  addSelect = '부가서비스를 선택해주세요.';
  selectTitle = '';
  selectPrice = 0;
  priceList = [];
  addList = [
    { type: '선택안함', totalPrice: 0 },
    { type: '안드로이드 등록 대행', totalPrice: 200000 },
    { type: '안드로이드+iOS 등록 대행', totalPrice: 400000 },
  ];
  addPrice = 0;
  isSaleAdd = false;
  storeRegistration = '';
  tryNumber = 0;
  vat = 0;
  addView: boolean;
  paymentForm = new FormGroup({
    pg: new FormControl('html5_inicis'),
    pay_method: new FormControl('card'),
    card_quota: new FormControl(),
    vbank_due: new FormControl('', [Validators.required]),
    biz_num: new FormControl(),
    digital: new FormControl(false),
    escrow: new FormControl(false),
    name: new FormControl('아임포트 결제 데이터 분석'),
    amount: new FormControl(30000),
    merchant_uid: new FormControl('min_' + new Date().getTime()),
    buyer_name: new FormControl('홍길동'),
    buyer_tel: new FormControl('01012341234'),
    buyer_email: new FormControl('example@example.com'),
  });

  // 이메일 선택
  emailSelect = '선택하기';
  emailList = [
    'naver.com',
    'gmail.com',
    'daum.net',
    'hanmail.net',
    'nate.com',
    '직접입력',
  ];
  solutionAppData: SolutionApp;
  solutionPrice: SolutionPrice[];
  solutionType = '스타터팩';
  selectPriceType = '';
  priceType;
  email1;
  email2;
  selectType = '';
  applyForm: FormGroup;
  submit: boolean;
  policyAgreement1: boolean;
  policyAgreement2: boolean;
  totalPrice = 0;
  selectMonth = 0;
  selectDesignType = '';
  isTest = false;
  testPaydata = {
    apply_num: 'test',
    bank_name: 'test',
    buyer_addr: 'test',
    buyer_email: 'test',
    buyer_name: 'test',
    buyer_postcode: 'test',
    buyer_tel: 'test',
    card_name: 'test',
    card_number: 'test',
    card_quota: 0,
    currency: 'KRW',
    custom_data: 'test',
    id: '',
    imp_uid: 'test',
    merchant_uid: 'test',
    name: 'test',
    paid_amount: 'test',
    paid_at: 'test',
    pay_method: 'card',
    pg_provider: 'html5_inicis',
    pg_tid: 'test',
    pg_type: 'test',
    receipt_url: 'test',
    status: 'test',
    success: 'test',
    type: 'solution',
  };
  payPrice = [];
  solutionTypePrice = {
    items: [
      {
        id: 'LYCQa777OymJ7pPtp4a6',
        isMonthlyPrice: false,
        monthPeriod: 0,
        price: [
          {
            discountRate: 0,
            monthPrice: 0,
            order: 1,
            totalPrice: 3000000,
            type: '안드로이드',
          },
          {
            discountRate: 0,
            monthPrice: 0,
            order: 2,
            totalPrice: 5000000,
            type: '안드로이드+iOS',
          },
        ],
        optionType: '소유형스타터팩',
        priceLevel: 1,
        solutionPriceId: 'LcxQyUQIGiMC4EMMZxUC',
        title: '소유형스타터팩',
      },
    ],
    optionType: '소유형스타터팩',
    order: 1,
    title: '소유형 스타터팩',
  };
  constructor(
    private renderer: Renderer2,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private db: DbService,
    private dataService: DataService,
    public formBuilder: FormBuilder,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.route.queryParams.subscribe({
      next: async ({ id }) => {
        try {
          if (id) {
            await this.dataService.inIt();
            this.solutionAppData = await this.dataService.getSolutionAppById(
              id
            );
            this.solutionPrice = this.dataService.setSolutionPrice(
              this.solutionAppData
            );
            this.getPriceType();
          }
        } catch (error) {
          console.error('Error handling the categoryId:', error);
          // Handle any errors here
        }
      },
      error: (err) => {
        // Error handling here
        console.error('Error in subscription:', err);
      },
      complete: () => {
        // Handle completion here
        console.log('Completed');
      },
    });
  }

  ngOnInit(): void {
    //   let script2 = this._renderer2.createElement('script');
    //   script2.type = 'text/javascript';
    //   script2.text = `var _nasa={};
    //   if (!wcs_add) var wcs_add = {};
    // wcs_add["wa"] = "s_52ea45ec186a";
    // if (!_nasa) var _nasa = {};
    // if (window.wcs) {
    //   wcs.inflow("startapp.co.kr");
    //   wcs_do(_nasa);}
    //   `;
    //   this._renderer2.appendChild(this._document.body, script2);
    this.applyForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      phone: [null, [Validators.required]],
      email: ['', [Validators.required]],
      request: [''],
    });
    this.submit = false;
  }

  resetPrice() {
    this.totalPrice = 0;
    this.vat = 0;
    this.selectPrice = 0;
    this.addPrice = 0;
    this.priceSelect = '상품 요금을 선택해주세요.';
    this.addSelect = '부가서비스를 선택해주세요.';
    this.selectPriceType = null;
  }

  getPriceType() {
    this.resetPrice();
    setTimeout(() => {
      let TypePrice;
      let solutionTypePrice;
      if (this.solutionPrice) {
        if (this.solutionType == '스타터팩') {
          this.selectType = ' 스타터팩';
          TypePrice = this.solutionPrice[1];

          solutionTypePrice = this.solutionTypePrice;
        } else {
          TypePrice = this.solutionPrice[0];
          this.selectType = '구독형 스타터팩 - 스타터팩구독';
          solutionTypePrice = TypePrice.options[1];
        }
      }
      this.priceList = solutionTypePrice.items
        .map((item) => {
          return item.price.map((price) => {
            return {
              title: item.title,
              ...price,
              monthPeriod: item.monthPeriod,
            };
          });
        })
        .flat();
    }, 500);
  }

  hoverView = false;
  priceView = false;
  emailView = false;

  // 쇼핑몰 디자인 선택
  clickView() {
    this.hoverView = !this.hoverView;
    this.priceView = false;
    this.emailView = false;
  }

  // 요금 선택
  priceClickView() {
    this.priceView = !this.priceView;
    this.hoverView = false;
    this.emailView = false;
  }

  addClickView() {
    this.addView = !this.addView;
    this.hoverView = false;
    this.emailView = false;
  }

  // 이메일
  emailClickView() {
    this.emailView = !this.emailView;
    this.hoverView = false;
    this.priceView = false;
  }

  // 디자인 보러가기 > (쇼핑몰 앱-디자인 상세로 이동)
  designGuide() {
    this.router.navigateByUrl('/solution-detail-renewal');
  }

  // 스타터팩알아보기 > (스타터팩안내로 이동)
  appSolutionInfo() {
    this.router.navigateByUrl('/solution-guide');
  }

  // 쇼핑몰 디자인 선택
  designValueChange(design) {
    this.designSelect = design;
    this.selectDesignType = design;
  }

  // 요금 선택
  addValueChange(price) {
    let totalPrice = 0;

    this.addSelect =
      price.type + ' ' + price.totalPrice.toLocaleString() + '원';
    this.storeRegistration = price.type;
    this.addPrice = price.totalPrice;
    this.setPrice();
    this.scrolltoElement('section4');
  }

  priceValueChange(price) {
    let totalPrice = 0;
    this.priceSelect =
      price.title != '소유형스타터팩'
        ? price.type +
          ' ' +
          price.totalPrice.toLocaleString() +
          '원 / ' +
          price.title
        : price.type + ' ' + price.totalPrice.toLocaleString() + '원';
    this.selectTitle =
      price.title != '소유형스타터팩'
        ? `${price.title} - ${price.type}`
        : price.type;
    this.selectPrice = price.totalPrice;
    this.selectMonth = price.monthPeriod;
    if (this.solutionType == '스타터팩구독') {
      this.monthleyType = '스타터팩구독';
      this.selectPriceType = `${price.type} ${price.title}`;
    } else {
      this.monthleyType = '';
      this.selectPriceType = `${price.type}`;
    }
    this.setPrice();
    this.scrolltoElement('section4');
  }

  setPrice() {
    if (this.solutionType == '스타터팩구독') {
      this.monthleyType = '스타터팩구독';

      this.totalPrice =
        this.selectMonth == 12
          ? this.selectPrice
          : this.selectPrice + this.addPrice;
      this.vat = this.totalPrice * 0.1;
      this.totalPrice = this.totalPrice + this.vat;
    } else {
      this.monthleyType = '';
      this.totalPrice = this.selectPrice + this.addPrice;
      this.vat = this.totalPrice * 0.1;
      this.totalPrice = this.totalPrice + this.vat;
    }
  }

  // 이메일 선택
  emailValueChange(email) {
    this.emailSelect = email;
    this.setEmail();
  }

  // 오른쪽 컨텐츠
  @ViewChild('rightCont') rightCont: ElementRef;
  @HostListener('window:scroll', ['$event'])
  scroll() {
    const rightCont = this.rightCont.nativeElement;
    if (document.documentElement.scrollTop <= 267) {
      this.renderer.setStyle(rightCont, 'position', 'absolute');
      this.renderer.setStyle(rightCont, 'top', '0px');
    } else if (document.documentElement.scrollTop > 270) {
      this.renderer.setStyle(rightCont, 'position', 'fixed');
      this.renderer.setStyle(rightCont, 'top', '76px');
    }
  }

  totalView = false;

  totalViewChange() {
    this.totalView = !this.totalView;
  }

  // 이용약관 개인정보보호정책
  terms(type) {
    this.dialog.open(TermsModalComponent, {
      panelClass: 'terms-modal',
      data: {
        type,
      },
    });
  }

  // 결제하기(완료페이지로 이동)
  payment() {
    const check = this.checkInput();
    if (!check) {
      return false;
    }
    if (this.isTest) {
      this.purchase(this.testPaydata, 'card');
    } else {
      this.paymentPg();
    }
    // this.router.navigateByUrl('/solution-payment-completed').then(() => {});
  }

  scrolltoElement(el) {
    setTimeout(() => {
      document.getElementById(el).scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'end',
      });
    }, 200);
  }

  checkInput() {
    if (!this.solutionType) {
      this.warningMessage('스타터팩 상품을 선택해주세요.', 'section');
      return false;
    }

    if (this.priceSelect == '상품 요금을 선택해주세요.') {
      this.warningMessage('상품 요금을 선택해주세요', 'section3');
      return false;
    }

    if (this.addSelect == '부가서비스를 선택해주세요.') {
      this.warningMessage('부가서비스룰 선택해주세요', 'section3');
      return false;
    }

    if (this.applyForm.invalid) {
      this.warningMessage('구매자 정보를 입력해주세요.', 'section4');
      return false;
    }

    if (!this.policyAgreement1 || !this.policyAgreement2) {
      this.warningMessage('약관에 동의해 주세요.', 'section6');
      return false;
    }
    return true;
  }

  setEmail() {
    let email = '';
    if (this.emailSelect == '직접입력') {
      email = this.email1 + '@' + this.email2;
    } else {
      email = this.email1 + '@' + this.emailSelect;
    }

    this.applyForm.controls.email.setValue(email);
  }

  warningMessage(text, elementId) {
    Swal.fire({
      title: '',
      text,
      icon: 'warning',
      confirmButtonText: '확인',
      confirmButtonColor: '#586bff',
    }).then((result) => {
      if (elementId == 'section' || elementId == 'section6') {
        setTimeout(() => {
          document.getElementById(elementId).scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start',
          });
        }, 300);
      } else {
        setTimeout(() => {
          this.scrolltoElement(elementId);
        }, 100);
      }
    });
  }

  paymentPg(): void {
    const infor = this.applyForm.value;
    /* 가맹점 식별코드 */
    const userCode = 'imp33174574';
    const formValues = this.paymentForm.getRawValue();
    const {
      pg,
      pay_method,
      merchant_uid,
      name,
      amount,
      buyer_name,
      buyer_tel,
      buyer_email,
      escrow,
      card_quota,
      biz_num,
      vbank_due,
      digital,
    } = formValues;

    const requestData: Record<string, any> = {
      pg: 'html5_inicis',
      pay_method: 'card',
      merchant_uid: 'min_' + new Date().getTime(),
      name: this.solutionAppData?.title + this.selectType,
      amount: this.totalPrice,
      buyer_name: infor.name,
      buyer_tel: infor.phone,
      buyer_email: infor.email,
      escrow: false,
      vbank_due: undefined,
      biz_num: undefined,
      digital: undefined,
    };

    if (pay_method === 'vbank') {
      requestData.vbank_due = vbank_due;
      if (pg === 'danal_tpay') {
        requestData.biz_num = biz_num;
      }
    }
    if (pay_method === 'card') {
      if (card_quota !== 0) {
        requestData.digital = {
          card_quota: card_quota === 1 ? [] : card_quota,
        };
      }
    }
    if (pay_method === 'phone') {
      requestData.digital = digital;
    }

    // @ts-ignore
    const { IMP } = window;
    IMP.init(userCode);
    IMP.request_pay(requestData, async (response: object) => {
      if (response['success'] == true) {
        this.purchase(response, pay_method);
      } else {
        if (this.tryNumber == 3) {
          this.showWarningAlert(
            '결제실패',
            '결제에 실패하였습니다. 잠시 후에 다시 시도해 주시길 바랍니다.'
          );
          return;
        }
        this.tryNumber++;
        this.showWarningAlert(
          '결제실패',
          '결제에 실패하였습니다. 다시 한번 시도해 주세요.'
        );
        return;
      }
    });
  }

  async purchase(response, method) {
    const infor = this.applyForm.value;
    const purchaseId = await this.db.createId();
    const paymentId = await this.db.createId();
    const userId = await this.db.createId();
    const newOrder: SolutionOrder = {
      id: purchaseId,
      dateCreated: new Date().toISOString(),
      date: new Date(),
      status: '결제완료',
      memo: '',
      request: infor.request,
      isCheck: false,
      isPaied: true,
      userId: userId,
      solutionAppId: this.solutionAppData.id,
      solutionApp: this.solutionAppData,
      inflow: '',
      designType: this.selectDesignType,
      solutionType: this.solutionType,
      monthleyType: '',
      priceType: this.selectPriceType,
      price: this.totalPrice,
      storeRegistration: this.storeRegistration,
      paymethod: method,
      periodOfMonth: this.selectMonth,
    };

    const expiredDate = moment()
      .add(this.selectMonth, 'month')
      .add(-1, 'day')
      .format('YYYY-MM-DD');
    const appSolutionUser: AppSolutionUser = {
      name: infor.name,
      email: infor.email,
      phone: infor.phone,
      expiredDate,
      uid: userId,
      dateCreated: new Date().toISOString(),
    };
    const paymentData = { id: paymentId, type: 'solution', ...response };
    await this.db.updateAt(`appSolutionUser/${userId}`, appSolutionUser);
    await this.db.updateAt(`payments/${paymentId}`, paymentData);
    await this.db.updateAt(`orderSolution/${purchaseId}`, newOrder);
    this.dataService.appSolutionUserData = appSolutionUser;
    this.dataService.orderSolutionData = newOrder;
    this.router.navigate(['/solution-payment-completed']);
  }

  showWarningAlert(title: string, text: string) {
    Swal.fire({
      title,
      text,
      icon: 'warning',
      confirmButtonText: '확인',
    });
  }

  getVat(price: number) {
    return price * 0.1;
  }

  goDesignTemplate() {
    this.router.navigate(['design-template']);
  }
}
