import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  Renderer2,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SolutionApp } from 'src/app/models/solutionApp.models';
import { DataService } from 'src/app/service/data.service';
import { DbService } from 'src/app/service/db.service';
import Swiper from 'swiper';
import { StoreAppPopComponent } from '../store-app-pop/store-app-pop.component';
import { DOCUMENT } from '@angular/common';
import { AppPortFolio } from 'src/app/models/appPortFolio.model';
import { AppCategory } from 'src/app/models/appCategory.model';

@Component({
  selector: 'app-app-portfolio-list',
  templateUrl: './app-portfolio-list.component.html',
  styleUrls: ['./app-portfolio-list.component.scss'],
})
export class AppPortfolioListComponent implements OnInit {
  storeId: string = '';
  category = 'all';
  includePG = 'all';
  categoryView = false;
  selectApps$: Observable<AppPortFolio[]>;
  appCategories: AppCategory[] = [];
  filter = new BehaviorSubject({ categoryId: 'all' });
  constructor(
    private router: Router,
    public dialog: MatDialog,
    public dataService: DataService,
    public db: DbService,
    private route: ActivatedRoute,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.route.queryParams.subscribe(async ({ categoryId }) => {
      if (categoryId) {
        this.category = categoryId;
        this.filter.next({ categoryId });
      }
      await this.dataService.inIt();
      this.appCategories = this.dataService.appCategories;
      this.initData();

      // setTimeout(() => this.setSwiperOption(), 500);
    });
  }

  ngAfterViewInit(): void {}

  async ngOnInit(): Promise<void> {
    //   let script2 = this._renderer2.createElement('script');
    //   script2.type = 'text/javascript';
    //   script2.text = `var _nasa={};
    //   if (!wcs_add) var wcs_add = {};
    // wcs_add["wa"] = "s_52ea45ec186a";
    // if (!_nasa) var _nasa = {};
    // if (window.wcs) {
    //   wcs.inflow("startapp.co.kr");
    //   wcs_do(_nasa);}
    //   `;
    //   this._renderer2.appendChild(this._document.body, script2);
  }

  initData() {
    this.selectApps$ = this.filter.pipe(
      switchMap((filter: any) => {
        if (filter.categoryId == 'all') {
          return this.db.collection$(`portfolios`, (ref) =>
            ref.where('displaySwitch', '==', true).orderBy('order', 'asc')
          );
        } else {
          return this.db.collection$(`portfolios`, (ref) =>
            ref
              .where('displaySwitch', '==', true)
              .where('categoryId', '==', filter.categoryId)
              .orderBy('order', 'asc')
          );
        }
      })
    );
  }

  setSwiperOption(index: number) {
    const swiper = new Swiper(`.product-swiper${index}`, {
      slidesPerView: 1,
      centeredSlides: true, //선택된 슬라이드를 중심으로
      spaceBetween: 0, //슬라이드 여백
      loop: false, // 반복
      navigation: {
        nextEl: `.button-next${index}`,
        prevEl: `.button-prev${index}`,
      },
    });
    setTimeout(() => {
      swiper?.update();
    }, 4000);
  }

  getCategoryTitleById(categoryId: string) {
    const find = this.appCategories?.find((item) => item.id === categoryId);
    if (find) {
      return find.title;
    }
  }

  viewChange() {
    this.categoryView = !this.categoryView;
  }

  changeFilter() {
    this.filter.next({
      categoryId: this.category,
    });
  }

  pdDetail(id: string) {
    this.router.navigate(['/app-portfolio-detail'], {
      queryParams: { id },
    });
  }
}
