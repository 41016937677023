import { Injectable } from '@angular/core';
import { Inquiry } from '../models/inquiry.models';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class InquiryService {
  private _inquiry: Inquiry;

  inquiryData: Inquiry = {
    inquiryId: '', // 문의 Id,
    isCheck: false, //확인여부
    username: '', // 이름
    phone: '', // 전화번호
    email: '', // 이메일
    service: '', //서비스 종류
    company: '', //회사명
    project: '', //프로젝트 명
    budget: 0, // 예산
    content: '', // 상세내용
    dateCreated: '', // 문의일
    browser: '', //접속정보
    updateFiles: [], // 무슨 브라우저로 들어왔는지,
    memoList: [], //메모리스트,
    date: '',
    status: '접수',
    inflow: '스타트앱 문의',
    visitRoute: [],
    createdBy: '',
  };

  public serviceList = [
    { id: 1, title: '앱 개발', checked: false },
    { id: 2, title: '웹 개발', checked: false },
    { id: 3, title: '디자인', checked: false },
    { id: 4, title: '마케팅', checked: false },
    { id: 5, title: '컨설팅', checked: false },
    { id: 6, title: 'UI/UX 기획', checked: false },
  ];

  public routeList = [
    {
      id: 11,
      title: '네이버',
      checked: false,
    },
    {
      id: 12,
      title: '구글',
      checked: false,
    },
    {
      id: 13,
      title: '블로그',
      checked: false,
    },
    {
      id: 14,
      title: '언론매체',
      checked: false,
    },

    {
      id: 15,
      title: '유튜브',
      checked: false,
    },
    {
      id: 16,
      title: '지인소개',
      checked: false,
    },
    {
      id: 17,
      title: '기존고객',
      checked: false,
    },
    {
      id: 18,
      title: '기타',
      checked: false,
    },
  ];

  constructor() {
    this.clear();
  }

  generateFilename(): string {
    var length = 20;
    var text = '';
    var possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  get inquiry(): Inquiry {
    return this._inquiry;
  }

  set inquiry(inquiry: Inquiry) {
    this._inquiry = inquiry;
  }

  alert(title): void {
    Swal.fire({
      customClass: 'alert-custom notext ok-btn',
      title,
      icon: 'info',
      text: '',
      showCancelButton: false,
      confirmButtonText: '확인',
    });
  }

  success(title, html): void {
    Swal.fire({
      customClass: 'alert-custom notext ok-btn',
      title,
      icon: 'success',
      html,
      showCancelButton: false,
      confirmButtonText: '확인',
    });
  }

  confirmAlert(title): Promise<boolean> {
    return new Promise<any>(async (resolve) => {
      const result = await Swal.fire({
        customClass: 'alert-custom notext ok-btn',
        title,
        icon: 'info',
        text: '',
        showCancelButton: true,
        cancelButtonText: '취소',
        confirmButtonText: '확인',
      });

      resolve(result.isConfirmed);
    });
  }

  clear(): void {
    this._inquiry = { ...this.inquiryData };
    this.serviceList.forEach((element) => {
      element.checked = false;
    });
    this.routeList.forEach((element) => {
      element.checked = false;
    });
  }
}
