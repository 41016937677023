import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import * as $ from 'jquery';
import { TermsModalComponent } from '../../terms-modal/terms-modal.component';
import { HttpClient } from '@angular/common/http';
import * as firebase from 'firebase';
import { InquirySimplePopComponent } from '../../inquiry-simple-pop/inquiry-simple-pop.component';
import { DeviceService } from 'src/app/service/device.service';
@Component({
  selector: 'footer-share',
  templateUrl: './footer-share.component.html',
  styleUrls: ['./footer-share.component.scss'],
})
export class FooterShareComponent implements OnInit {
  @Input() urlPath = '';
  path;

  planSwitch = false;
  designSwitch = false;
  developSwitch = false;
  marketingSwitch = false;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private httpClient: HttpClient,
    private deviceService: DeviceService
  ) {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.planSwitch = false;
        this.designSwitch = false;
        this.developSwitch = false;
        this.marketingSwitch = false;

        let url = e.url;
        let splitUrl = url.split('/');
        const tempPath = '/' + splitUrl[1];
        const split = tempPath.split('?');
        this.path = split[0];

        if (this.path.includes('portfolio-detail')) {
          this.path = '/portfolio-detail';
        }

        let footer = document.getElementById('footer');
        switch (this.path) {
          case '/service-menu':
            footer?.classList.add('opacity');
            break;
          case '/plan':
            footer?.classList.remove('opacity');
            this.planSwitch = true;
            break;
          case '/design':
            footer?.classList.remove('opacity');
            this.designSwitch = true;
            break;
          case '/development':
            footer?.classList.remove('opacity');
            this.developSwitch = true;
            break;
          case '/marketing':
            footer?.classList.remove('opacity');
            this.marketingSwitch = true;
            break;
          case '/portfolio':
            footer?.classList.add('white');
            break;
          case '/portfolio-hanra':
            footer?.classList.add('white');
            break;
          case '/portfolio-detail':
            footer?.classList.add('white');
            break;
          case '/solution-product-category':
            footer?.classList.add('white');
            break;
          case '/solution-product-category/:id':
            footer?.classList.add('white');
            break;

          case '/simple-estimate':
            footer?.classList.add('white');
            break;
          case '/inquiry':
            footer?.classList.add('white');

            break;
          case '/inquiry-complete':
            setTimeout(() => {
              if (footer && footer.classList) {
                footer.classList.add('white');
              }
            }, 0);

            break;
          case '/order-complete':
            setTimeout(() => {
              footer?.classList.add('white');
            }, 0);

            break;
          case '/solution-complete':
            setTimeout(() => {
              footer?.classList.add('white');
            }, 0);

            break;

          default:
            break;
        }
      }
    });
  }

  ngOnInit(): void {
    $(function () {
      $('.go-top').on('click', function (e) {
        $('html,body').animate({ scrollTop: 0 }, 500);
        return false;
      });
    });
  }

  goHome() {
    this.router.navigateByUrl('/').then(() => {
      // //window.location.reload();
    });
  }
  goService() {
    this.router.navigateByUrl('/service-menu').then(() => {
      // //window.location.reload();
    });
  }
  goPortfolio() {
    this.router.navigateByUrl('/portfolio').then(() => {
      // //window.location.reload();
    });
  }
  goInquiry() {
    const isMobile = this.deviceService.isMobileDevice();
    if (isMobile) {
      this.dialog.open(InquirySimplePopComponent, {
        panelClass: 'sample-pop-up',
        backdropClass: 'sample-pop-up-backdrop',
      });
    } else {
      this.router.navigateByUrl('/inquiry');
    }
  }
  goplan() {
    this.router.navigateByUrl('/plan').then(() => {
      // //window.location.reload();
    });
  }
  godesign() {
    this.router.navigateByUrl('/design').then(() => {
      // //window.location.reload();
    });
  }
  godevelopment() {
    this.router.navigateByUrl('/development').then(() => {
      // //window.location.reload();
    });
  }
  gomarketing() {
    this.router.navigateByUrl('/marketing').then(() => {
      // //window.location.reload();
    });
  }

  // 회사소개서 다운로드
  goDown() {}

  terms(type) {
    this.dialog.open(TermsModalComponent, {
      panelClass: 'terms-modal',
      data: {
        type,
      },
    });
  }
  save() {
    const url =
      'https://storage.googleapis.com/startapp-new.appspot.com/files/스타트앱_회사소개서.pdf';
    const storage = firebase.default.storage();
    const gsReference = storage.refFromURL(url);
    gsReference
      .getDownloadURL()
      .then((url) => {
        this.httpClient
          .get(url, { responseType: 'blob' as 'json' })
          .subscribe((res: any) => {
            const file = new Blob([res], { type: res.type });
            const navi: any = window.navigator;
            // IE
            if (navi && navi.msSaveOrOpenBlob) {
              navi.msSaveOrOpenBlob(file);
              return;
            }

            const blob = window.URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = blob;
            link.download = '스타트앱_회사소개서.pdf';
            link.dispatchEvent(
              new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window,
              })
            );

            setTimeout(() => {
              window.URL.revokeObjectURL(blob);
              link.remove();
            }, 100);
          });
      })
      .catch((error) => {
        switch (error.code) {
          case 'storage/object-not-found':
            break;
          case 'storage/unauthorized':
            break;
          case 'storage/canceled':

          case 'storage/unknown':
            break;
        }
      });
  }
}
