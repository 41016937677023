import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import * as $ from 'jquery';
import _ from 'lodash';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss'],
})
export class PlanComponent implements OnInit, OnDestroy {
  constructor(
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  ngOnInit(): void {
    //   let script2 = this._renderer2.createElement('script');
    //   script2.type = 'text/javascript';
    //   script2.text = `var _nasa={};
    //   if (!wcs_add) var wcs_add = {};
    // wcs_add["wa"] = "s_52ea45ec186a";
    // if (!_nasa) var _nasa = {};
    // if (window.wcs) {
    //   wcs.inflow("startapp.co.kr");
    //   wcs_do(_nasa);}
    //   `;
    //   this._renderer2.appendChild(this._document.body, script2);
    window.onload = function () {
      setTimeout(function () {
        // document.documentElement.scrollIntoView({ behavior: 'smooth' });
        scrollTo(0, 0);
      }, 50);
    };

    //메인 텍스트 애니매이션
    $(function () {
      let txtBox = [];
      let temp = [];
      function textAni() {
        let txtLine = [];
        $('.top-text p').each(function (index, item) {
          let txt = $(item).text();
          let tmp = { index: index, txt: txt };
          txtLine.push(tmp);
        });
        let splitTxt = [];
        txtLine.forEach((txt) => {
          splitTxt.push(txt.txt.split(' '));
        });
        $(splitTxt).each(function (index, item) {
          let txt = [];
          item.forEach((e) => {
            txt.push('<span>' + e + '</span>&nbsp;');
          });
          txtBox.push({ index: index, txt: txt });
        });
        for (var i = 0; i < txtBox.length; i++) {
          $('.top-text p').eq(i).html(txtBox[i].txt);
          $('.top-text p span').css({
            display: 'inline-block',
            opacity: 0,
            position: 'relative',
            top: '10px',
            left: '5px',
            fontSize: '1.17rem',
            lineHeight: '1.722rem',
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
          });
          $('.top-text p')
            .eq(i)
            .find('span')
            .each(function (i, item) {
              temp.push(item);
            });
        }
      }
      textAni();

      // 텍스트애니메이션01 실행
      setTimeout(() => {
        for (let index = 0; index < temp.length; index++) {
          const ele = temp[index];
          $('.top-text p')
            .eq(0)
            .animate({ opacity: 1 })
            .find($(ele))
            .delay(50 * index)
            .animate({ top: 0, opacity: 1, left: 0 }, 500);
        }
      }, 200);
    });

    // $(function () {
    let windowWidth = $(window).width();
    // });

    if (windowWidth > 767) {
      // this.listItemHover();
    } else {
      this.listItemActive();
      this.listItemClose();
    }

    // this.rotateBanner();
    // this.rotateMax();
    this.resizeWidth();
    // this.bannerActive();

    // this.stickyBtnActive();
    // this.stickyBtnRelative();
    this.scrollEvt();

    // this.footerRelative();

    // this.goToTop();

    // resize Event
    window.addEventListener('resize', function () {
      let windowWidth = window.innerWidth;
      // media max-width: 1024
      if (windowWidth >= 1024) {
      }
    });

    window.addEventListener('scroll', this.scrollEvt, true);
  }

  ngOnDestroy(): void {
    $(window).scroll().off();
    window.removeEventListener('scroll', this.scrollEvt, true);
  }

  scrollEvt() {
    const contBanner = document.querySelector<HTMLElement>('.cont-banner');
    const $cont = $('.cont-banner');
    const $banner = $('.banner-inner');
    // let contHeight = contBanner.offsetHeight;
    let contRect = contBanner.getBoundingClientRect();
    let contTop = contRect.top;

    // let $contTop = $cont.offset().top;
    let trigger = window.scrollY + window.innerHeight;

    if (contTop <= trigger) {
      contBanner.classList.add('active');
    }

    const $item = $('.last-cont-list .cont-item');
    const $service = $('.last-cont-list .-service');

    let $serviceTop = $service.offset().top;
    // let $itemH = $item.height();
    let docHeight = window.scrollY + window.innerHeight;

    if ($serviceTop <= docHeight) {
      $item.removeClass('active');
      $service.addClass('active');

      if ($service.hasClass('active')) {
        $service.find($('.img-box')).css({
          backgroundImage: 'url(../../../assets/imgs/plan_01.gif)',
          transition: 'background ease-in 0.2s',
        });
      }
    } else {
      $service.removeClass('active');
    }

    const $item1 = $('.last-cont-list .cont-item');
    const $design = $('.last-cont-list .-design');

    let $designTop = $design.offset().top;
    let $itemH = $item.height();
    let docHeight1 = window.scrollY + window.innerHeight - $itemH;

    if ($designTop <= docHeight1) {
      $item1.removeClass('active');
      $design.addClass('active');

      if ($design.hasClass('active')) {
        $design.find($('.img-box')).css({
          backgroundImage: 'url(../../../assets/imgs/plan_02.gif)',
          transition: 'background ease-in 0.2s',
        });
      }
    }

    const $item2 = $('.last-cont-list .cont-item');
    const $third = $('.last-cont-list .-function');

    let $thirdTop = $third.offset().top;
    let $itemH2 = $item.height();
    let docHeight2 = window.scrollY + window.innerHeight - $itemH2;

    if ($thirdTop <= docHeight2) {
      $item2.removeClass('active');
      $third.addClass('active');
      if ($third.hasClass('active')) {
        $third.find($('.img-box')).css({
          backgroundImage: 'url(../../../assets/imgs/plan_03.gif)',
          transition: 'background ease-in 0.2s',
        });
      }
    }

    const $item3 = $('.last-cont-list .cont-item');
    const $support = $('.last-cont-list .-support');

    let $supportTop = $support.offset().top;
    let $itemH3 = $item.height();
    let docHeight3 = window.scrollY + window.innerHeight - $itemH3;

    if ($supportTop <= docHeight3) {
      $item3.removeClass('active');
      $support.addClass('active');
      if ($support.hasClass('active')) {
        $support.find($('.img-box')).not($('.back-img')).css({
          backgroundImage: 'url(../../../assets/imgs/plan_04.gif)',
          transition: 'background ease-in 0.2s',
        });
      }
    }
  }

  removeEvent() {
    // window.removeEventListener('scroll', function scrollE() {
    //   const contBanner = document.querySelector<HTMLElement>('.cont-banner');
    //   const $cont = $('.cont-banner');
    //   const $banner = $('.banner-inner');
    //   let contHeight = contBanner.offsetHeight;
    //   let contRect = contBanner.getBoundingClientRect();
    //   let contTop = contRect.top;
    //   // let $contTop = $cont.offset().top;
    //   let trigger = window.scrollY + window.innerHeight;
    //   if (contTop <= trigger) {
    //     contBanner.classList.add('active');
    //   }
    //   const $item = $('.last-cont-list .cont-item');
    //   const $service = $('.last-cont-list .-service');
    //   let $serviceTop = $service.offset().top;
    //   // let $itemH = $item.height();
    //   let docHeight = window.scrollY + window.innerHeight;
    //   if ($serviceTop <= docHeight) {
    //     $item.removeClass('active');
    //     $service.addClass('active');
    //     if ($service.hasClass('active')) {
    //       $service.find($('.img-box')).css({
    //         backgroundImage: 'url(../../../assets/imgs/plan_01.gif)',
    //       });
    //     }
    //   } else {
    //     $service.removeClass('active');
    //   }
    //   const $item1 = $('.last-cont-list .cont-item');
    //   const $design = $('.last-cont-list .-design');
    //   let $designTop = $design.offset().top;
    //   let $itemH = $item.height();
    //   let docHeight1 = window.scrollY + window.innerHeight - $itemH;
    //   if ($designTop <= docHeight1) {
    //     $item1.removeClass('active');
    //     $design.addClass('active');
    //     if ($design.hasClass('active')) {
    //       $design.find($('.img-box')).css({
    //         backgroundImage: 'url(../../../assets/imgs/plan_02.gif)',
    //       });
    //     }
    //   }
    //   const $item2 = $('.last-cont-list .cont-item');
    //   const $third = $('.last-cont-list .-function');
    //   let $thirdTop = $third.offset().top;
    //   let $itemH2 = $item.height();
    //   let docHeight2 = window.scrollY + window.innerHeight - $itemH2;
    //   if ($thirdTop <= docHeight2) {
    //     $item2.removeClass('active');
    //     $third.addClass('active');
    //     if ($third.hasClass('active')) {
    //       $third.find($('.img-box')).css({
    //         backgroundImage: 'url(../../../assets/imgs/plan_03.gif)',
    //       });
    //     }
    //   }
    //   const $item3 = $('.last-cont-list .cont-item');
    //   const $support = $('.last-cont-list .-support');
    //   let $supportTop = $support.offset().top;
    //   let $itemH3 = $item.height();
    //   let docHeight3 = window.scrollY + window.innerHeight - $itemH3;
    //   if ($supportTop <= docHeight3) {
    //     $item3.removeClass('active');
    //     $support.addClass('active');
    //     if ($support.hasClass('active')) {
    //       $support.find($('.img-box')).not($('.back-img')).css({
    //         backgroundImage: 'url(../../../assets/imgs/plan_04.gif)',
    //       });
    //     }
    //   }
    // });
  }

  // rotateBanner() {
  //   const contBanner = document.querySelector<HTMLElement>('.banner-wrap');
  //   const bannerInner = document.querySelector<HTMLElement>('.banner-inner');
  //   const contWrap = document.querySelector<HTMLElement>('.cont-wrap');

  //   window.addEventListener('scroll', () => {
  //     let scroll = window.scrollY;
  //     let contHeight = contBanner.offsetHeight;
  //     let contRect = contBanner.getBoundingClientRect();
  //     let contTop = contRect.top;

  //     let trigger = scroll + window.innerHeight;

  //     if (contTop <= trigger) {
  //       bannerInner.style.transform = `rotateX(${
  //         ((scroll - 150) / (contHeight + contTop)) * 50 - 50
  //       }deg)`;
  //       contWrap.style.opacity = `${scroll / (contHeight + contTop)}`;
  //
  //
  //
  //       //
  //       // } else {
  //       //   bannerInner.style.transform = 'rotateX(80deg)';
  //     }
  //   });
  // }

  // rotateMax() {
  //   window.addEventListener('scroll', () => {
  //     const $banner = $('.banner-inner');
  //     const jsBanner = document.querySelector<HTMLElement>('.banner-inner');

  //     let $bannerTop = $banner.offset().top * 1.6;
  //     let scroll = window.scrollY;
  //     let docHeight = scroll + window.innerHeight;

  //     if ($bannerTop <= docHeight) {
  //       jsBanner.style.transform = 'rotateX(0)';
  //     }
  //   });
  // }

  // bannerActive() {
  //   window.addEventListener('scroll', () => {});
  // }

  listItemActive() {
    const $listItem = $('.list-item');
    $listItem.click(function (e) {
      $(this).addClass('active');
    });
  }

  listItemClose() {
    const $closeBtn = $('.close-btn');
    $closeBtn.click(function (e) {
      $(this).parents().removeClass('active');
      e.stopPropagation();
    });
  }

  resizeWidth() {
    let width = $(window).width();

    if (width < 768) {
      _.debounce(() => {
        location.reload();
      });

      const $listItem = $('.list-item');
      $listItem.click(function (e) {
        $(this).addClass('active');
      });
      const $closeBtn = $('.close-btn');
      $closeBtn.click(function (e) {
        $(this).parents().removeClass('active');
        e.stopPropagation();
      });
    }
  }

  // stickyBtnActive() {
  //   const stickyBtn = document.querySelector<HTMLElement>('sticky-button p');
  //   window.addEventListener('scroll', function () {
  //     let scrollY = window.scrollY;
  //     if (scrollY > 10) {
  //       stickyBtn.classList.add('text-ac');
  //     }
  //   });
  // }

  // stickyBtnRelative() {
  //   window.addEventListener('scroll', function () {
  //     const $stickyBtn = $('sticky-button');
  //     let $btmLine = $('.bottom-line');
  //     let $btmLineTop = $btmLine.offset().top;
  //     let $btmLineHeight = $btmLine.outerHeight();
  //     let trigger = $btmLineTop + $btmLineHeight;

  //     let docHeight = window.scrollY + window.innerHeight;

  //     if (trigger <= docHeight) {
  //       $stickyBtn.css({
  //         position: 'absolute',
  //       });
  //     } else {
  //       $stickyBtn.css({
  //         position: 'fixed',
  //       });
  //     }
  //   });
  // }

  // serviceItemActive() {
  //   window.addEventListener('scroll', () => {});
  // }

  // designItemActive() {
  //   window.addEventListener('scroll', () => {});
  // }

  // thirdItemActive() {
  //   window.addEventListener('scroll', () => {});
  // }

  // supportItemActive() {
  //   window.addEventListener('scroll', () => {});
  // }

  // footerRelative() {
  //   const footer = document.querySelector<HTMLElement>('footer-share footer');
  //   const plan = document.querySelector<HTMLElement>('#footer-plan');
  //   const footerInner = document.querySelector<HTMLElement>('.footer-inner');

  //   footer.style.position = 'static';
  //   let planHeight = plan.clientHeight;
  //   plan.style.marginTop = `${-planHeight}px`;

  //   window.addEventListener('scroll', () => {
  //     let $btmLine = $('.bottom-line');
  //     let $btmLineTop = $btmLine.offset().top;
  //     let $btmLineHeight = $btmLine.outerHeight();
  //     let point = $btmLineTop + $btmLineHeight;

  //     let trigger = window.scrollY + window.innerHeight;

  //     if (point <= trigger) {
  //       plan.classList.add('active');
  //       plan.style.marginTop = '0';

  //       setTimeout(() => {
  //         footerInner.style.zIndex = '0';
  //       }, 900);
  //     }
  //   });
  // }

  // goToTop() {
  //   const main = document.querySelector<HTMLElement>('main');
  //   const topBtn = document.querySelector<HTMLElement>('.top_btn');
  //   topBtn.addEventListener('click', () => {
  //     main.scrollIntoView({ behavior: 'smooth' });
  //   });
  // }
}
