<!-- <header-share></header-share> -->
<!-- id="main" -->
<main id="main"
  class="desgn-main">
  <div class="active-top">
    <section class="main-video">
      <video autoplay
        muted
        loop
        width="100%"
        height="100%"
        poster="assets/imgs/service-m02.png">
        <source src="assets/video/header_bg_design.mp4"
          type="video/mp4" />
      </video>
      <div class="main-text">
        <h2>UI 디자인</h2>
        <p>완벽한 디자인으로 디지털 스타터팩에 의미와 무게를 더합니다.</p>
      </div>
      <div class="scroll-icon">
        <p>SCROLL</p>
      </div>
    </section>

    <section class="uxui w1180 section">
      <div class="section-title">
        <h2>가치 있는 경험을 디자인하다.</h2>
        <p>
          사용자 습관, 경험, 체험으로 발전하는 UX가 있다면
          <span class="bolder">
            사용자가 보다 쉽게 이해하고 사용할수 있게 돕는 UI가 있습니다.
          </span>
        </p>
      </div>
      <div class="circle-wrap">
        <div class="ux-circle">
          <span class="inner-txt">UX</span>
          <p class="ui-circle">
            <span class="inner-txt">UI</span>
          </p>
        </div>
        <p class="ux-txt">
          <span class="bolder">User Experience</span>
          사용자 경험
        </p>
        <p class="ui-txt">
          <span class="bolder">User Interface</span>
          사용자 인터페이스
        </p>
      </div>
      <div class="m-circle">
        <img src="assets/imgs/m_design_uxui_circle.png"
          alt="uxui도형"
          class="mobile" />
      </div>
    </section>

    <section class="business w1180 section">
      <div class="section-title">
        <h2>
          <span class="block">좋은 UI란,</span> 비즈니스 목적을 달성 하는 것
        </h2>
        <p>
          디자인은 단순히 예쁘다고만 해서 설득력을 가지는 것은 아닙니다. <br />
          <span class="bolder">
            비즈니스 목표를 정의하고, 사용자의 요구를 이해하고, 사용자가
            수용할수 있는 의미있는 환경을
          </span>
          만드는 것부터 시작을 합니다.
        </p>
      </div>
      <ul>
        <li class="even">
          <h3>01<span class="bold">직관성</span></h3>
          <p>
            누구나 쉽게 <br />
            이해할 수 있게
          </p>
        </li>
        <li class="odd">
          <h3>02<span class="bold">유효성</span></h3>
          <p>사용자의 목적을 정확하게 달성할 수 있게</p>
        </li>
        <li class="even m-bg">
          <h3>03<span class="bold">학습성</span></h3>
          <p>
            누구나 쉽게 <br />
            배우고 익힐 수 있게
          </p>
        </li>
        <li class="odd m-bg">
          <h3>04<span class="bold">유연성</span></h3>
          <p>요구사항을 수용하며 오류가 최소화 될 수 있게</p>
        </li>
      </ul>
    </section>

    <section id="scroll"
      class="strategy section">
      <div class="section-title w1180">
        <h2>
          일관된 경험을 제공하는 우리의
          <span class="blue">디자인 시스템 전략 <span class="num"></span></span>
        </h2>
        <p>
          우리의 시스템은 <span class="bolder">원칙, 지침, 표준</span> 세 가지를
          목표로 합니다. 크고 작은 디자인 의사결정의 기준이 되며,
          <span class="block">효율적으로 빠르게 디자인할 수 있도록 돕고, 일관성을 확보할 수
            있도록 합니다.</span>
        </p>
        <div class="progress-bar">
          <div id="progress"
            class="inner-bar"></div>
        </div>
      </div>
      <div class="content-wrap cf">
        <div class="text-wrap">
          <ul>
            <li class="first text">
              <h4 class="bolder"><span class="num">01</span>시스템 정의</h4>
              <p>
                사용자가 원하는 것이 무엇인지, <br />
                시스템의 목적이 무엇인지 명확히 정의 합니다.
              </p>
            </li>
            <li class="text">
              <h4 class="bolder"><span class="num">02</span>시스템 설계</h4>
              <p>
                흐름에 따른 정보 배치로 사용자가 쉽고, <br />
                편리하게 원하는 정보를 얻도록 설계 합니다.
              </p>
            </li>
            <li class="last text">
              <h4 class="bolder"><span class="num">03</span>시스템 테스트</h4>
              <p>
                사용자가 경험하는 것을 테스트 하고, <br />
                반복적인 테스트를 진행 하며 검증 합니다.
              </p>
            </li>
          </ul>
        </div>

        <!-- 텍스트영역 끝 -->
        <div class="img-wrap">
          <!-- 01 시스템 정의 -->
          <div class="definition content scroll">
            <img src="assets/imgs/design_definition_img.png"
              alt="사용자요구 이미지" />
          </div>

          <!-- 02 시스템 설계 -->
          <ul class="system-design content">
            <li class="first box-wrap scroll">
              <img src="assets/imgs/design_board_img.png"
                alt="무드보드만들기 이미지" />
              <h5 class="bolder">무드 보드 만들기</h5>
              <p>
                목적에 맞는 시각적인 요소들을 모으는 작업을 진행
                합니다.프로젝트에 대한 디자인 컨셉을 확고히 알 수 있으며,
                서비스의 따른 컨셉에 다양함을 생각할 수 있습니다.
              </p>
            </li>
            <li class="second box-wrap scroll">
              <img src="assets/imgs/design_guide_img.png"
                alt="UI Style guide 이미지" />
              <h5 class="bolder">UI 스타일 가이드 제작</h5>
              <p>
                실제 목업 설계 전 UI요소들을 정의 하고, 쉽게 이해할 수 있도록
                컬러 계획 타이포 그래피등 서비스와 어떻게 어울릴지에 대한 단계를
                진행 합니다.
              </p>
            </li>
            <li class="box-wrap mb0 scroll">
              <div class="device-wrap">
                <div class="android">
                  <a href="https://material.io/design"
                    target="_blank"></a>
                  <img src="assets/imgs/design_android_img.png"
                    alt="android 이미지" />
                  <div class="device-txt">
                    <p>
                      <span class="bold">Google</span>
                      Android Material Design
                    </p>
                    <button class="more"></button>
                  </div>
                </div>
                <div class="ios">
                  <a href="https://developer.apple.com/design/human-interface-guidelines/ios/overview/themes/"
                    target="_blank"></a>
                  <img src="assets/imgs/design_ios_img.png"
                    alt="ios 이미지" />
                  <div class="device-txt">
                    <p>
                      <span class="bold">Apple</span>
                      iOS Human Interface
                    </p>
                    <button class="more"></button>
                  </div>
                </div>
              </div>
              <h5 class="bolder">Android, iOS 기반 환경 디자인</h5>
              <p>
                <span class="bolder">
                  “ 안드로이드는 자유롭고 , iOS 명확하다”
                </span>
                우리는 사용자가 단말을 자연스럽게 사용하고, 중요한 콘텐츠에
                집중하는 것을 도와주기 위한 디자인 원칙을 따르고 있습니다.
                가이드 라인에 따르게 되면 새로운 앱도 친숙하게 사용할 수 있으며,
                해당 디바이스에 최적화된 경험을 제공 할 수 있습니다.
              </p>
            </li>
          </ul>

          <!-- 03 시스템 테스트 -->
          <div class="system-test content scroll">
            <img src="assets/imgs/design_test_img.png"
              alt="시스템테스트 이미지" />
            <p class="mb20">
              구현 되기 전, 시스템에 맞게 테스트 시 전체의 성능을 향상 시키는
              역할을 할 수 있습니다.
            </p>
            <ul class="check-list">
              <li>사용자를 위한 다양한 접근 경로를 제공하고 있는가?</li>
              <li>
                사용자의 시선 및 작업의 흐름에 따라 진행 되도록 배치 되어
                있는가?
              </li>
              <li>UX설계에 맞는 명확한 정보 구조를 이루고 있는가?</li>
              <li>자연스러운 페이지 이동을 유도하고 있는가?</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
  <section class="bottom-line">
    <div class="bottom-box">
      <h2>스타트앱</h2>
      <p>좋은 UI디자인이란 사용자의 만족에 있습니다.</p>
    </div>
    <!-- <sticky-button></sticky-button> -->
  </section>
</main>
<footer-share id="footerWrap"></footer-share>
<footer-share class="mobile-footer"></footer-share>

<!-- <div id="footer-design">
  <div class="footer-inner">
    <div class="btn-wrap">
      <p>더 많은 프로세스를 만나보세요.</p>
      <div class="btn-inner cf">
        <button class="prev"><a href="/plan">UX기획&전략</a></button>
        <button class="next">
          <a href="/development">Android/iOS 개발</a>
        </button>
      </div>
    </div>

    <footer-share></footer-share>
  </div>
</div> -->
