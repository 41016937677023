<div id="inquiry-simple-pop">
  <div class="pop-head">
    <div class="tit">견적문의</div>
    <button class="dialog-close"
      type="button"
      (click)="modalClose()"></button>
  </div>
  <div class="pop-con">
    <div class="input-wrap">
      <label class="pop-label">이름</label>
      <input type="text"
        inputmode="text"
        [(ngModel)]="inquiry.username"
        placeholder="이름을 입력해 주세요." />
    </div>
    <div class="input-wrap">
      <label class="pop-label">연락처</label>
      <input type="tel"
        inputmode="tel"
        [(ngModel)]="inquiry.phone"
        placeholder="휴대전화를 입력해 주세요." />
    </div>

    <div class="pop-check-box">
      <input type="checkbox"
        name="terms"
        [(ngModel)]="privacy"
        id="termschk1" />
      <label class="check-label"
        for="termschk1">개인정보 수집 동의</label>
    </div>

    <button class="full-btn"
      (click)="goComplete()"
      type="button">견적 문의하기</button>
  </div>
</div>