import { MbscModule } from '@mobiscroll/angular';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { HeaderShareComponent } from './pages/share/header-share/header-share.component';
import { FooterShareComponent } from './pages/share/footer-share/footer-share.component';
import { SwiperModule } from 'swiper/angular';
import { StickyButtonComponent } from './pages/share/sticky-button/sticky-button.component';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import firebase from 'firebase';
firebase.initializeApp(environment.firebaseConfig);
import { PlanComponent } from './pages/plan/plan.component';
import { DesignComponent } from './pages/design/design.component';
import { MarketingComponent } from './pages/marketing/marketing.component';
import { ServiceMenuComponent } from './pages/service-menu/service-menu.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { SimpleEstimateComponent } from './pages/simple-estimate/simple-estimate.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { InquiryComponent } from './pages/inquiry/inquiry.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InquiryCompleteComponent } from './pages/inquiry-complete/inquiry-complete.component';
import { PortfolioDetailComponent } from './pages/portfolio-detail/portfolio-detail.component';
import { HttpClientModule } from '@angular/common/http';
import { PortfolioMainComponent } from './pages/portfolio-main/portfolio-main.component';
import { DevelopmentComponent } from './pages/development/development.component';
import { PortfolioComponent } from './pages/portfolio/portfolio.component';
import { EstimatePopComponent } from './pages/estimate-pop/estimate-pop.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { StoreComponent } from './pages/store/store.component';
import { StoreAppPopComponent } from './pages/store-app-pop/store-app-pop.component';
import { OrderCompleteComponent } from './pages/order-complete/order-complete.component';
import { TermsModalComponent } from './pages/terms-modal/terms-modal.component';
import { PopUpComponent } from './pop-up/pop-up.component';
import { ServiceInfoComponent } from './pages/service-info/service-info.component';
import { SolutionCompleteComponent } from './pages/solution-complete/solution-complete.component';
import { SolutionDetailRenewalComponent } from './pages/solution-detail-renewal/solution-detail-renewal.component';
import { SolutionDetailRenewalPopupComponent } from './pages/solution-detail-renewal-popup/solution-detail-renewal-popup.component';
import { SolutionSubscribeComponent } from './pages/solution-popup-content/solution-subscribe/solution-subscribe.component';
import { SolutionOwnershipComponent } from './pages/solution-popup-content/solution-ownership/solution-ownership.component';
import { SolutionSubscribePcComponent } from './pages/solution-popup-content/solution-subscribe-pc/solution-subscribe-pc.component';
import { SolutionSubscribeTabletComponent } from './pages/solution-popup-content/solution-subscribe-tablet/solution-subscribe-tablet.component';
import { SolutionSubscribeMobileComponent } from './pages/solution-popup-content/solution-subscribe-mobile/solution-subscribe-mobile.component';
import { SolutionOwnershipPcComponent } from './pages/solution-popup-content/solution-ownership-pc/solution-ownership-pc.component';
import { SolutionOwnershipTabletComponent } from './pages/solution-popup-content/solution-ownership-tablet/solution-ownership-tablet.component';
import { SolutionOwnershipMobileComponent } from './pages/solution-popup-content/solution-ownership-mobile/solution-ownership-mobile.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SolutionGuideComponent } from './pages/solution-guide/solution-guide.component';
import { SolutionPaymentComponent } from './pages/solution-payment/solution-payment.component';
import { SolutionPaymentCompletedComponent } from './pages/solution-payment-completed/solution-payment-completed.component';
import { SolutionProductCategoryComponent } from './pages/solution-product-category/solution-product-category.component';
import { SolutionDetailRenewalImagePopupComponent } from './pages/solution-detail-renewal-image-popup/solution-detail-renewal-image-popup.component';
import { SolutionDetailRenewalDesignTemplateComponent } from './pages/solution-detail-renewal-design-template/solution-detail-renewal-design-template.component';
import { SolutionHomeComponent } from './pages/solution-home/solution-home.component';
import { AdminSamplePopComponent } from './pages/admin-sample-pop/admin-sample-pop.component';
import { SolutionWebCategoryComponent } from './pages/solution-web-category/solution-web-category.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { WebPreviewComponent } from './pages/web-preview/web-preview.component';
import { SafePipe } from './pipes/safe.pipe';
import { InquiryPageComponent } from './pages/inquiry-page/inquiry-page.component';
import { InquiryListPopComponent } from './pages/inquiry-list-pop/inquiry-list-pop.component';
import { DndDirective } from './dnd.directive';
import { BytesPipe } from './pipes/bytes.pipe';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { AppPortfolioListComponent } from './pages/app-portfolio-list/app-portfolio-list.component';
import { AppPortfolioDetailComponent } from './pages/app-portfolio-detail/app-portfolio-detail.component';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { InquirySimplePopComponent } from './pages/inquiry-simple-pop/inquiry-simple-pop.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderShareComponent,
    FooterShareComponent,
    StickyButtonComponent,
    PlanComponent,
    DesignComponent,
    MarketingComponent,
    ServiceMenuComponent,
    SimpleEstimateComponent,
    NotFoundComponent,
    InquiryComponent,
    InquiryCompleteComponent,
    PortfolioDetailComponent,
    PortfolioMainComponent,
    DevelopmentComponent,
    PortfolioComponent,
    EstimatePopComponent,
    StoreComponent,
    StoreAppPopComponent,
    OrderCompleteComponent,
    TermsModalComponent,
    PopUpComponent,
    ServiceInfoComponent,

    SolutionCompleteComponent,
    SolutionDetailRenewalComponent,
    SolutionDetailRenewalPopupComponent,
    SolutionSubscribeComponent,
    SolutionOwnershipComponent,
    SolutionSubscribePcComponent,
    SolutionSubscribeTabletComponent,
    SolutionSubscribeMobileComponent,
    SolutionOwnershipPcComponent,
    SolutionOwnershipTabletComponent,
    SolutionOwnershipMobileComponent,
    SolutionGuideComponent,
    SolutionPaymentComponent,
    SolutionPaymentCompletedComponent,
    SolutionProductCategoryComponent,
    SolutionWebCategoryComponent,
    SolutionDetailRenewalImagePopupComponent,
    SolutionDetailRenewalDesignTemplateComponent,
    SolutionHomeComponent,
    AdminSamplePopComponent,
    WebPreviewComponent,
    SafePipe,
    InquiryPageComponent,
    InquiryListPopComponent,
    DndDirective,
    BytesPipe,
    AppPortfolioListComponent,
    AppPortfolioDetailComponent,
    InquirySimplePopComponent,
  ],
  imports: [
    MbscModule,
    BrowserModule,
    AppRoutingModule,
    SwiperModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    NgxGoogleAnalyticsModule.forRoot('G-CD3RSNS2BJ'),
    AngularFireStorageModule,
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    AngularFireAnalyticsModule,
    BrowserAnimationsModule,
    MatButtonToggleModule,
    MatCardModule,
    FormsModule,
    HttpClientModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    ReactiveFormsModule,
    NgbModule,
    Ng2SearchPipeModule,
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    Meta,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
