import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { SolutionWeb } from 'src/app/models/solutionWeb.models';
import { DataService } from 'src/app/service/data.service';
import { DbService } from 'src/app/service/db.service';

@Component({
  selector: 'app-web-preview',
  templateUrl: './web-preview.component.html',
  styleUrls: ['./web-preview.component.scss'],
})
export class WebPreviewComponent implements OnInit {
  iframUrl;
  loading: boolean = true;
  webSolution: SolutionWeb;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public db: DbService
  ) {}

  ngOnInit(): void {
    this.setData();
  }

  async setData() {
    const id = this.activatedRoute.snapshot.queryParams.id;
    if (id) {
      this.webSolution = await this.db
        .doc$(`solutionWebs/${id}`)
        .pipe(first())
        .toPromise();
      this.iframUrl = this.webSolution.sampleUrl;
    }
  }

  onMyFrameLoad(e) {
    this.loading = false;
  }

  // 헤더
  // 로고
  goHome() {
    this.router.navigateByUrl('/').then(() => {
      window.location.reload();
    });
  }

  // 해당 상품 문의하기
  inquiry() {
    this.router.navigate(['/inquiry'], {
      queryParams: { id: this.webSolution.id },
    });
  }

  handleError() {}
}
