<div class="mobile-table ownership-mobile">
  <h2><span>소유형 앱</span> 스타터팩</h2>

  <div id="table-wrap"
    class="ownership-table">
    <div class="table-group">
      <!-- <h3><span>설치형</span> 상품</h3> -->

      <table class="product-table">
        <caption>
          상품명
        </caption>
        <colgroup>
          <col style="width: 50%" />
          <col style="width: 50%" />
        </colgroup>
        <thead>
          <tr>
            <th>
              <div>
                <!-- <p class="product-title">소스코드 구매</p> -->
                <p class="item-title">안드로이드</p>
              </div>
            </th>
            <th>
              <div>
                <!-- <p class="product-title">소스코드 구매</p> -->
                <p class="item-title">안드로이드 + iOS</p>
              </div>
            </th>
          </tr>
        </thead>
      </table>

      <table class="feature-table">
        <caption>
          특징
        </caption>
        <tbody>
          <tr class="feature-line">
            <td>
              <div>
                <p>
                  경험 많은 앱 전문가가 만든 <span>완성도 높은</span> 소스 코드
                </p>
                <p>
                  <span>짧은 세팅 기간, 저렴한 비용</span>으로 나만의 앱을 소유
                </p>
                <p>
                  소유한 소스 코드는 <span>자유롭게 수정</span> 가능 <br /><span class="just">
                    (단, 소스 코드 재판매는 불가)</span>
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="service-table">
        <caption>
          제공 서비스
        </caption>
        <thead>
          <tr>
            <th>안드로이드</th>
            <th>안드로이드+iOS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div>
                <p>안드로이드 앱<br />소스 코드</p>
                <p>관리자 페이지<br />소스 코드</p>
              </div>
            </td>
            <td>
              <div>
                <p>안드로이드 앱<br />소스 코드</p>
                <p>iOS 앱<br />소스 코드</p>
                <p>관리자 페이지<br />소스 코드</p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="price-table">
        <caption>
          가격
        </caption>
        <colgroup>
          <col style="width: 50%" />
          <col style="width: 50%" />
        </colgroup>
        <thead>
          <tr>
            <th>안드로이드</th>
            <th>안드로이드+iOS</th>
          </tr>
        </thead>
        <tbody>
          <tr class="price-line">
            <td>
              <p>3,000,000원</p>
            </td>
            <td>
              <p>5,000,000원</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p class="guide-text">- 표시된 가격은 부가세 별도입니다.</p>

      <table>
        <caption>
          신청 및 사용 방법
        </caption>
        <tbody>
          <tr class="request-line">
            <td class="way-common way-install">
              <div>
                <div class="step-item">
                  <p class="icon"></p>
                  <p class="step-title">상품 선택 및 결제</p>
                </div>
                <div class="step-item">
                  <p class="icon"></p>
                  <div>
                    <p class="step-title">앱 세팅</p>
                    <ul>
                      <li>개발자 계정 생성</li>
                      <li>로고 등 앱 세팅 필요 자료 준비</li>
                    </ul>
                  </div>
                </div>
                <div class="step-item">
                  <p class="icon"></p>
                  <div>
                    <p class="step-title">소스코드 전달</p>
                    <ul>
                      <li>전달 받은 소스코드로 앱 사용</li>
                    </ul>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <mat-accordion class="guidelines">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> <span>추가</span>&nbsp;비용 </mat-panel-title>
        </mat-expansion-panel-header>
        <ul>
          <li>개발자 계정 생성 비용과 및 서버 이용료는 별도입니다.</li>
          <li>
            개발자 계정 생성 비용
            <p>Google(안드로이드) : 미화 25달러(최초 1회)</p>
            <p>Apple(iOS) : 미화 99달러(연 1회)</p>
          </li>
          <li>
            구글 파이어 베이스 서버 이용료
            <p>일별 무료 구간 내 사용 시 : 이용료 없음</p>
            <p>일별 무료 구간 초과 사용 시 : 초과분에만 이용료 발생</p>
          </li>
          <li>
            앱 등록 대행 비용
            <p>Google(안드로이드) : 20만원(부가세 별도)</p>
            <p>Google(안드로이드) + Apple(iOS) : 40만원(부가세 별도)</p>
            <p>단, 소스 코드 변경한 경우 앱 등록 대행 불가</p>
          </li>
        </ul>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion class="guidelines attention-note">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> <span>유의</span>&nbsp;사항 </mat-panel-title>
        </mat-expansion-panel-header>
        <ul>
          <li>
            결제 후 앱 세팅이 시작되면 담당자가 안내 메일을 발송합니다. 앱 세팅
            시작 후에는 환불이 불가능합니다.
          </li>
          <li>
            프로그램 특성상 소스 코드 전달 후에는 내부 코드가 공개되므로 환불이
            불가능합니다.
          </li>
          <li>
            소프트웨어의 제작 등, 전자 데이터화된 상품을 제조하는 자의 책임은
            제조물 책임법의 적용 대상에서 제외됩니다.
          </li>
          <li>
            전달 받으신 소스코드는 수정 가능하나 재판매 및 유통하실 수 없습니다.
          </li>
          <li>소스 코드를 수정하신 경우 앱 등록 대행 불가합니다.</li>
          <li>
            파이어베이스에 연동된 gmail 계정 삭제 시, DB가 삭제되어 원상복구가
            불가능하며 스타터팩을 새로 신청하셔야 합니다.
          </li>
        </ul>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>