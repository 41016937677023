<div class="container-wrap">
  <div class="title-wrap">
    <h2>문의 내역</h2>

    <button class="close-btn" type="button" (click)="modalClose()">
      <img src="assets/imgs/close-btn.png" alt="" />
    </button>
  </div>

  <div class="table-wrap mb-72">
    <p class="title"><span>문의자 </span>정보</p>

    <div class="table">
      <div class="row">
        <div class="tit">성함</div>
        <div class="con">{{ inquiryService.inquiry?.username }}</div>
      </div>
      <div class="row">
        <div class="tit">연락처</div>
        <div class="con">{{ displayPhone(inquiryService.inquiry?.phone) }}</div>
      </div>
      <div class="row">
        <div class="tit">이메일 주소</div>
        <div class="con">{{ inquiryService.inquiry?.email }}</div>
      </div>
      <div class="row">
        <div class="tit">회사 이름</div>
        <div class="con">
          {{
            inquiryService.inquiry?.company
              ? inquiryService.inquiry?.company
              : "-"
          }}
        </div>
      </div>
    </div>
  </div>

  <div class="table-wrap">
    <p class="title"><span>프로젝트 </span>정보</p>

    <div class="table">
      <div class="row">
        <div class="tit">프로젝트 종류</div>
        <div class="con">{{ inquiryService.inquiry?.service.join(", ") }}</div>
      </div>
      <div class="row">
        <div class="tit">프로젝트<br />세부 내용</div>
        <div class="con">
          {{ inquiryService.inquiry?.content }}
        </div>
      </div>
      <div class="row">
        <div class="tit">예산</div>
        <div class="con cost">
          {{ inquiryService.inquiry?.budget | number }} 만 원
        </div>
      </div>
      <div class="row">
        <div class="tit mb-12">참고 문서</div>
        <div class="con" *ngFor="let file of fileUploadService.fileArr">
          <div class="file-upload">
            <div class="icon">
              <img
                class="after-icon"
                src="assets/imgs/upload-icon.png"
                alt=""
              />
            </div>

            <div class="file">
              <p class="name">{{ file.name }}</p>
              <p class="k-bite">({{ file.totalBytes | bytes }})</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="tit">방문 경로</div>
        <div class="con">{{ inquiryService.inquiry?.visitRoute }}</div>
      </div>
    </div>
  </div>

  <div class="btn-wrap">
    <button type="button" class="ok-btn" (click)="modalClose()">확인</button>
  </div>
</div>
