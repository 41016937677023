import {
  Component,
  OnInit,
  HostListener,
  Renderer2,
  Inject,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import Swiper from 'swiper';
import { SolutionDetailRenewalImagePopupComponent } from '../solution-detail-renewal-image-popup/solution-detail-renewal-image-popup.component';
import { SolutionDetailRenewalPopupComponent } from '../solution-detail-renewal-popup/solution-detail-renewal-popup.component';
import {
  productTypesData,
  reviewData,
  solutionRenewalData,
} from '../../data/solutionRenewalData';
import { DataService } from 'src/app/service/data.service';
import { AdminSamplePopComponent } from '../admin-sample-pop/admin-sample-pop.component';
import { SolutionQna } from 'src/app/models/solutionQna.models';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-solution-home',
  templateUrl: './solution-home.component.html',
  styleUrls: ['./solution-home.component.scss'],
})
export class SolutionHomeComponent implements OnInit {
  windowWidth: number = window.innerWidth;
  @HostListener('window:resize', ['$event'])
  getWindowWidth(e) {
    const width = e.target.innerWidth;
    this.windowWidth = width;
  }
  purchaseType = 'subscribe';
  renewalData: any;
  qnaData: SolutionQna[];
  reviewData: any;
  productTypesData: any;
  activeIndex = 0;
  step04Swiper: Swiper;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.reviewData = reviewData;
    this.productTypesData = productTypesData;
    this.route.queryParams.subscribe({
      next: async ({ id }) => {
        try {
          if (id) {
            const data = solutionRenewalData.find((item) => item.id === id);
            // 전달된 id와 일치하는 데이터 있는 경우
            if (data) {
              this.renewalData = data;
            } else {
              // 온전히 일치하는 데이터 없고 전달된 id에 mall이 포함되어 있는 경우
              if (id.includes('mall')) {
                this.renewalData = solutionRenewalData.find(
                  ({ id }) => id === 'mall-chat-community'
                );
                // 온전히 일치하는 데이터 없고 전달된 id에 community가 포함되어 있는 경우
              } else if (id.includes('community')) {
                this.renewalData = solutionRenewalData.find(
                  ({ id }) => id === 'community-chat'
                );
              }
            }
          } else {
            // 전달된 id가 없는 경우
            this.renewalData = solutionRenewalData[0];
          }
        } catch (error) {
          console.error('Error handling the categoryId:', error);
          // Handle any errors here
        }
      },
      error: (err) => {
        // Error handling here
        console.error('Error in subscription:', err);
      },
      complete: () => {
        // Handle completion here
        console.log('Completed');
      },
    });
  }

  async ngOnInit(): Promise<void> {
    //   let script2 = this._renderer2.createElement('script');
    //   script2.type = 'text/javascript';
    //   script2.text = `var _nasa={};
    //   if (!wcs_add) var wcs_add = {};
    // wcs_add["wa"] = "s_52ea45ec186a";
    // if (!_nasa) var _nasa = {};
    // if (window.wcs) {
    //   wcs.inflow("startapp.co.kr");
    //   wcs_do(_nasa);}
    //   `;
    //   this._renderer2.appendChild(this._document.body, script2);
    await this.dataService.inIt();
    this.qnaData = this.dataService.qnaData;
  }

  ngAfterViewInit(): void {
    const mainSwiper = new Swiper('.main-swiper', {
      slidesPerView: 1,
      autoplay: {
        delay: 3000,
      },

      loop: true,
      loopAdditionalSlides: 1,
      speed: 300,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });

    this.step04Swiper = new Swiper('.step04-swiper', {
      slidesPerView: 2,
      autoplay: {
        delay: 5000,
      },
      spaceBetween: 20,
      loop: true,
      speed: 400,
      pagination: false,
      breakpoints: {
        1200: {
          direction: 'horizontal',
          slidesPerView: 2,
          autoplay: {
            delay: 5000,
          },
          loop: true,
          initialSlide: 0,
          allowTouchMove: true,
        },
        768: {
          direction: 'horizontal',
          slidesPerView: 3,
          autoplay: false,
          loop: false,
          initialSlide: 0,
          allowTouchMove: false,
        },
        280: {
          initialSlide: 0,
          slidesPerView: 3,
          autoplay: false,
          loop: false,
          direction: 'vertical',
          allowTouchMove: false,
        },
      },
      on: {
        afterInit: (e) => {
          setTimeout(() => {
            this.resizeCheck();
          }, 200);
        },
      },
    });

    const swiper = new Swiper('.admin-swiper', {
      slidesPerView: 1,
      autoplay: {
        delay: 5000,
      },
      loop: true,
      speed: 400,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        1201: {
          allowTouchMove: false,
        },
        280: {
          allowTouchMove: true,
        },
      },
      on: {
        slideChange: (e) => {
          this.activeIndex = e.realIndex;
        },
      },
    });

    const swiper2 = new Swiper('.reivew-swiper', {
      autoplay: {
        delay: 0,
      },
      allowTouchMove: false,
      centeredSlides: true,
      loop: true,
      breakpoints: {
        280: {
          direction: 'horizontal',
          slidesPerView: 1.1,
          speed: 8000,
          spaceBetween: 20,
          autoplay: {
            delay: 0,
          },
          loop: true,
        },
        768: {
          direction: 'horizontal',
          slidesPerView: 1.5,
          speed: 7000,
          spaceBetween: 24,
          autoplay: {
            delay: 0,
          },
          loop: true,
        },
        1201: {
          direction: 'vertical',
          slidesPerView: 2,
          speed: 5000,
          spaceBetween: 100,
          autoplay: {
            delay: 0,
          },
          loop: true,
        },
      },
      on: {
        init: (e) => {},
        breakpoint: (e) => {
          e.autoplay.start();
        },
      },
    });

    setTimeout(() => {
      mainSwiper.update();
      this.step04Swiper.update();
      swiper.update();
      swiper2.update();
    }, 200);
  }

  openPopup(popupType) {
    this.dialog.open(SolutionDetailRenewalPopupComponent, {
      panelClass: 'solution-popup',
      data: {
        type: popupType,
      },
    });
  }

  // 관리자페이지 디테일 이미지 모달
  imgDetailPopup(i: number) {
    const dialog = this.dialog.open(SolutionDetailRenewalImagePopupComponent, {
      panelClass: 'solution-image-popup',
      data: {
        list: this.renewalData.adminHomeSlides,
        i,
      },
    });
  }

  // 예약 스타터팩
  reservationApp() {
    this.router.navigateByUrl('/solution-detail-renewal').then(() => {});
  }
  // 커뮤니티 스타터팩
  comunityApp() {
    this.router.navigateByUrl('/solution-detail-renewal').then(() => {});
  }
  // 쇼핑몰 스타터팩
  shoppingApp() {
    this.router.navigateByUrl('/solution-detail-renewal').then(() => {});
  }
  // 채팅 스타터팩
  chattingApp() {
    this.router.navigateByUrl('/solution-detail-renewal').then(() => {});
  }

  // 스타터팩제품 보기
  appSolution(categoryId?) {
    this.router
      .navigate(['/solution-product-category'], {
        queryParams: { categoryId },
      })
      .then(() => {});
  }

  goHanra() {
    this.router.navigateByUrl('/portfolio-hanra').then(() => {});
  }

  goTherapeople() {
    this.router.navigateByUrl('/portfolio-detail/therapeople').then(() => {});
  }

  goBuoost() {
    this.router.navigateByUrl('/portfolio-detail/buoost').then(() => {});
  }

  goVanilla() {
    this.router
      .navigateByUrl('/portfolio-detail/vanillaDentist')
      .then(() => {});
  }
  goGanpyeonganpan() {
    this.router
      .navigateByUrl('/portfolio-detail/ganpyeonganpan')
      .then(() => {});
  }

  goDoor() {
    this.router.navigateByUrl('/portfolio-detail/door').then(() => {});
  }

  goHeartFriend() {
    this.router.navigateByUrl('/portfolio-detail/heartFriend').then(() => {});
  }

  goCarryIn() {
    this.router.navigateByUrl('/portfolio-detail/carryIn').then(() => {});
  }

  goMobilityF() {
    this.router.navigateByUrl('/portfolio-detail/mobilityF').then(() => {});
  }
  goPortfolio() {
    this.router.navigateByUrl('/portfolio').then(() => {});
  }

  resizeCheck() {
    this.step04Swiper.on('breakpoint', (e) => {
      setTimeout(() => {
        if (e.currentBreakpoint < 1200) {
          this.step04Swiper.autoplay.stop();
          this.step04Swiper.slideTo(0);
          // this.step04Swiper.params.loop = false;

          setTimeout(() => {
            // this.step04Swiper.changeDirection('vertical');
            // this.step04Swiper.params.loop = false;

            this.step04Swiper.slideReset(0);
            this.step04Swiper.update();
          }, 500);
        } else {
          this.step04Swiper.autoplay.start();
        }
      }, 200);
    });
  }

  openAdmin() {
    this.dialog.open(AdminSamplePopComponent, {
      panelClass: 'admin-sample-modal',
      data: {
        // storeId: this.storeId,
        adminUrl: 'https://startapp-anidar6-master.web.app',
      },
    });
  }
}
