import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-store-app-pop',
  templateUrl: './store-app-pop.component.html',
  styleUrls: ['./store-app-pop.component.scss'],
})
export class StoreAppPopComponent implements OnInit {
  storeId: string;
  siteUrl: string = '';
  loading: boolean = true;
  appUrl: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<StoreAppPopComponent>
  ) {
    this.appUrl = data.appUrl;
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const siteFrame = document.getElementById('site');

    siteFrame.setAttribute('src', this.appUrl);
  }

  onMyFrameLoad(e) {
    this.loading = false;
  }

  goSite() {
    switch (this.storeId) {
      case 'shopping':
        return 'https://startapp-store-mall.web.app';
      case 'community':
        return 'https://startapp-store-community.web.app';
      case 'matching':
        return 'https://startapp-store-matching.web.app';
      case 'hospital':
        return 'https://startapp-store-hospital-cus.web.app';
      case 'delivery':
        return 'https://startapp-store-delivery.web.app';
      case 'education':
        return 'https://startapp-store-education.web.app';
      default:
        return 'https://startapp-store-mall.web.app';
    }
  }

  close() {
    this.dialogRef.close();
  }
}
