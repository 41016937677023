<main id="main">
  <div class="all-wrap"
    class="w1200">
    <section id="form-section"
      class="w609">
      <div class="top">
        <div class="complete-icon">
          <img src="./assets/imgs/solution-t-icon.png"
            alt="결제완료" />
        </div>
        <h3><span>결제</span> 완료</h3>
      </div>
    </section>
    <section id="form-section"
      class="w641">
      <div class="complete-table">
        <table>
          <tr>
            <th>성함</th>
            <td>{{ dataService.appSolutionUserData?.name }}</td>
          </tr>
          <tr>
            <th>연락처</th>
            <td>{{ dataService.appSolutionUserData?.phone }}</td>
          </tr>
          <tr>
            <th>이메일</th>
            <td>{{ dataService.appSolutionUserData?.email }}</td>
          </tr>
          <tr class="end">
            <th>요청사항</th>
            <td>
              {{ dataService.orderSolutionData?.request }}
            </td>
          </tr>
          <tr>
            <th>상품명</th>
            <td>{{ dataService.orderSolutionData?.solutionApp.title }}</td>
          </tr>
          <tr *ngIf="dataService.orderSolutionData?.designType">
            <th>쇼핑몰 디자인</th>
            <td>{{ dataService.orderSolutionData?.designType }}</td>
          </tr>
          <tr>
            <th>스타터팩</th>
            <td>{{ dataService.orderSolutionData?.solutionType }}</td>
          </tr>

          <tr>
            <th>요금</th>
            <td>{{ dataService.orderSolutionData?.priceType }}</td>
          </tr>
          <tr class="end"
            *ngIf="dataService.orderSolutionData?.storeRegistration">
            <th>부가 서비스</th>
            <td>앱 등록 대행 서비스- {{dataService.orderSolutionData?.storeRegistration}}</td>
          </tr>
          <tr class="total-tr">
            <th>결제 금액</th>
            <td class="total-td">
              <span>{{ dataService.orderSolutionData?.price | number }}</span>
              원
            </td>
          </tr>
        </table>
      </div>
      <div class="btn-wrap">
        <button class="line-btn"
          (click)="completed()">홈으로 가기</button>
      </div>
    </section>
  </div>
</main>
<footer-share id="footerWrap"></footer-share>
<footer-share class="mobile-footer"></footer-share>
