import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  Renderer2,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SolutionApp } from 'src/app/models/solutionApp.models';
import { DataService } from 'src/app/service/data.service';
import { DbService } from 'src/app/service/db.service';
import Swiper from 'swiper';
import { StoreAppPopComponent } from '../store-app-pop/store-app-pop.component';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-solution-product-category',
  templateUrl: './solution-product-category.component.html',
  styleUrls: ['./solution-product-category.component.scss'],
})
export class SolutionProductCategoryComponent implements OnInit, AfterViewInit {
  storeId: string = '';
  solutionType = 'all';
  includePG = 'all';
  categoryView = false;
  selectApps$: Observable<SolutionApp[]>;
  filter = new BehaviorSubject({ categoryId: 'all', includePG: 'all' });
  constructor(
    private router: Router,
    public dialog: MatDialog,
    public dataService: DataService,
    public db: DbService,
    private route: ActivatedRoute,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.route.queryParams.subscribe({
      next: async ({ categoryId }) => {
        try {
          if (categoryId) {
            this.solutionType = categoryId;
            this.filter.next({ categoryId, includePG: 'all' });
          }
          await this.dataService.inIt();
          this.selectApps$ = await this.initData();
        } catch (error) {
          console.error('Error handling the categoryId:', error);
          // Handle any errors here
        }
      },
      error: (err) => {
        // Error handling here
        console.error('Error in subscription:', err);
      },
      complete: () => {
        // Handle completion here
        console.log('Completed');
      },
    });
  }

  ngAfterViewInit(): void {}

  async ngOnInit(): Promise<void> {
    //   let script2 = this._renderer2.createElement('script');
    //   script2.type = 'text/javascript';
    //   script2.text = `var _nasa={};
    //   if (!wcs_add) var wcs_add = {};
    // wcs_add["wa"] = "s_52ea45ec186a";
    // if (!_nasa) var _nasa = {};
    // if (window.wcs) {
    //   wcs.inflow("startapp.co.kr");
    //   wcs_do(_nasa);}
    //   `;
    //   this._renderer2.appendChild(this._document.body, script2);
  }

  initData(): Promise<Observable<any>> {
    return new Promise((resolve) => {
      const datas = this.filter.pipe(
        switchMap((filter: any) => {
          if (filter.categoryId == 'all') {
            if (filter.includePG == 'all') {
              return this.db.collection$(`solutionApps`, (ref) =>
                ref.where('displaySwitch', '==', true).orderBy('order', 'asc')
              );
            }
            return this.db.collection$(`solutionApps`, (ref) =>
              ref
                .where(
                  'includePG',
                  '==',
                  filter.includePG == 'pg' ? true : false
                )
                .where('displaySwitch', '==', true)
                .orderBy('order', 'asc')
            );
          }
          if (filter.includePG == 'all') {
            return this.db.collection$(`solutionApps`, (ref) =>
              ref
                .where('appCategoryId', '==', filter.categoryId)
                .where('displaySwitch', '==', true)
                .orderBy('order', 'asc')
            );
          }
          return this.db.collection$(`solutionApps`, (ref) =>
            ref
              .where('appCategoryId', '==', filter.categoryId)
              .where('includePG', '==', filter.includePG == 'pg' ? true : false)
              .where('displaySwitch', '==', true)
              .orderBy('order', 'asc')
          );
        }),
        map((datas) => {
          setTimeout(() => {
            for (const [index, value] of datas.entries()) {
              this.setSwiperOption(index);
            }
          }, 500);
          return datas;
        })
      );
      resolve(datas);
    });
  }

  setSwiperOption(index: number) {
    const swiper = new Swiper(`.product-swiper${index}`, {
      slidesPerView: 1,
      centeredSlides: true, //선택된 슬라이드를 중심으로
      spaceBetween: 0, //슬라이드 여백
      loop: false, // 반복
      navigation: {
        nextEl: `.button-next${index}`,
        prevEl: `.button-prev${index}`,
      },
    });
    setTimeout(() => {
      swiper?.update();
    }, 4000);
  }

  viewChange() {
    this.categoryView = !this.categoryView;
  }

  changeFilter() {
    this.filter.next({
      categoryId: this.solutionType,
      includePG: this.includePG,
    });
  }

  pdDetail(id: string) {
    this.router.navigate(['/solution-detail-renewal'], {
      queryParams: { id },
    });
  }

  openApp(solutionApp: SolutionApp) {
    this.dialog.open(StoreAppPopComponent, {
      panelClass: 'app-pop-modal',
      data: {
        appUrl: solutionApp.appPreviewUrl,
      },
    });
  }
}
