<main id="main">
  <section class="top-box">
    <section id="form-section"
      class="w1180">
      <h2>스타터팩<span>결제하기</span></h2>
    </section>
  </section>

  <div class="all-wrap">
    <section id="form-section"
      class="w1200 flex-between-start">
      <div class="left-contents">
        <div class="content-btm-pd"
          id="section">
          <h2 class="title"><span>상품</span> 정보 입력</h2>

          <!-- 상품명 입력 불가! 해당 <재품-상셰>의 제품명을 노출  -->
          <div class="item-wrap product-name"
            *ngIf="solutionAppData">
            <label for="product">상품명</label>
            <input type="text"
              id="product"
              name="product"
              inputmode="text"
              placeholder="상품명을 입력해주세요."
              [(ngModel)]="solutionAppData.title"
              disabled />
          </div>

          <!-- 쇼핑몰 디자인 선택 -->
          <div class="item-wrap"
            id="section1"
            *ngIf="
              solutionAppData &&
              solutionAppData.appCategoryId == 'lQgvG6lz6sT9zuSwraUR'
            ">
            <div class="flex-between-center">
              <label>쇼핑몰 디자인 선택</label>
              <button class="right-txt"
                (click)="goDesignTemplate()"
                type="button">
                디자인 보러가기 >
              </button>
            </div>
            <div class="sol-select"
              (click)="clickView()"
              [ngClass]="{ hover: hoverView }">
              <button type="button">
                <!-- 선택 전 텍스트(쇼핑몰 디자인을 선택해주세요.), 선택 전 class 추가 placeholder -->
                <span class="text"
                  [ngClass]="{
                    placeholder:
                      designSelect === '쇼핑몰 디자인을 선택해주세요.'
                  }">
                  {{ designSelect }}
                </span>
                <span class="icon"><img src="assets/imgs/solution_select_icon.png"
                    alt="셀렉트아이콘" /></span>
              </button>
              <!-- 아이템이 하나일 떄 ul에 class="item-one" 추가 -->
              <ul class="select-ul">
                <li *ngFor="let design of designList"
                  (click)="designValueChange(design)">
                  {{ design }}
                </li>
              </ul>
            </div>
          </div>

          <!-- 스타터팩선택 01 -->
          <div class="item-wrap"
            id="section2">
            <!-- <div class="flex-between-center">
              <label>상품 선택</label>
              <button class="right-txt"
                (click)="appSolutionInfo()"
                type="button">
                스타터팩알아보기 >
              </button>
            </div> -->

            <!-- <div class="radio-list">
              <div class="radio-item">
                <input type="radio"
                  [(ngModel)]="solutionType"
                  id="solutionType01"
                  name="solutionType"
                  value="스타터팩구독"
                  [checked]="false"
                  (click)="scrolltoElement('section2'); getPriceType()"
                  [disabled]="false" />

                <label for="solutionType01">
                  <div class="s-top">
                    <i class="radio-icon"></i>
                    <span class="tit"><span>스타터팩</span> 구독</span>
                  </div>

                  <div class="wh-txt">

                    <ul>
                      <li>
                        최저 월 <span>45,000원 &#42;의 월 구독료</span>로
                        나만의 앱을 시작
                      </li>
                      <li>
                        <span>안드로이드 APK</span>를 휴대폰에 설치하거나
                        <span>앱 등록 후 </span>스토어에서 다운 받아 앱 사용
                      </li>
                    </ul>
                  </div>
                </label>
                <p class="radioinfo">
                  &#42;안드로이드 기준, 12개월 계약 시
                </p>
              </div>
              <div class="radio-item">
                <input type="radio"
                  id="solutionType02"
                  name="solutionType"
                  value="스타터팩"
                  [(ngModel)]="solutionType"
                  [checked]="false"
                  (click)="scrolltoElement('section2'); getPriceType();monthleyType"
                  [disabled]="false" />

                <label for="solutionType02">
                  <div class="s-top">
                    <i class="radio-icon"></i>
                    <span class="tit"><span>소스코드</span> 구매</span>
                  </div>

                  <div class="wh-txt">
                    <ul>
                      <li>
                        짧은 세팅 기간, 저렴한 비용으로 앱
                        <span>전문가가 만든 소스코드</span>를 소유
                      </li>

                    </ul>

                  </div>
                </label>
              </div>
            </div> -->
          </div>

          <!-- 스타터팩선택 02 -->


          <!-- 요금 선택 -->
          <div class="item-wrap"
            id="section4"
            *ngIf="
              (solutionType == '스타터팩구독' && monthleyType) ||
              solutionType == '스타터팩'
            ">
            <label>요금 선택</label>
            <div class="sol-select"
              (click)="priceClickView()"
              [ngClass]="{ hover: priceView }">
              <button type="button">
                <!-- 선택 전 텍스트(상품 요금을 선택해주세요.), 선택 전 class 추가 placeholder -->
                <span class="text"
                  [ngClass]="{
                    placeholder: priceSelect === '상품 요금을 선택해주세요.'
                  }">
                  {{ priceSelect }}
                </span>

                <span class="icon"><img src="assets/imgs/solution_select_icon.png"
                    alt="셀렉트아이콘" /></span>
              </button>

              <ul class="select-ul">
                <li *ngFor="let price of priceList"
                  (click)="priceValueChange(price)">
                  <div class="flex-between-center">
                    <p>
                      {{ price.type }}
                      <span class="li-price">{{
                        price.totalPrice | number
                        }}</span>원<span class="month"
                        *ngIf="price.title != '소유형스타터팩'">
                        / {{ price.title }}</span>
                    </p>

                    <!-- 6개월일 때 "line", 12개월일 때 "full" 클래스 추가 -->
                    <!-- 6개월일 때 "line" -->
                    <div class="chip line"
                      *ngIf="
                        (price.discountRate != 0 && price.title == '6개월') ||
                        (price.discountRate != 0 && price.title == '6개월')
                      ">
                      {{ price.discountRate }}%
                    </div>
                    <!-- 12개월일 때 "full" -->
                    <div class="chip full"
                      *ngIf="
                        (price.discountRate != 0 && price.title == '12개월') ||
                        (price.discountRate != 0 && price.title == '12개월')
                      ">
                      {{ price.discountRate }}%
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="item-wrap"
            id="section4"
            *ngIf="
              (solutionType == '스타터팩구독' && monthleyType) ||
              solutionType == '스타터팩'
            ">
            <label>부가서비스 [선택]</label>
            <div class="sol-select"
              (click)="addClickView()"
              [ngClass]="{ hover: addView }">
              <button type="button">
                <!-- 선택 전 텍스트(상품 요금을 선택해주세요.), 선택 전 class 추가 placeholder -->
                <span class="text"
                  [ngClass]="{
                    placeholder: addSelect === '부가서비스를 선택해주세요.'
                  }">
                  {{ addSelect }}
                </span>

                <span class="icon"><img src="assets/imgs/solution_select_icon.png"
                    alt="셀렉트아이콘" /></span>
              </button>

              <ul class="select-ul">
                <li *ngFor="let price of addList"
                  (click)="addValueChange(price)">
                  <div class="flex-between-center">
                    <p>
                      {{ price.type }}
                      <span class="li-price">{{
                        price.totalPrice | number
                        }}</span>원
                    </p>

                    <!-- 6개월일 때 "line", 12개월일 때 "full" 클래스 추가 -->
                    <!-- 6개월일 때 "line" -->
                    <!-- <div class="chip line"
                      *ngIf="
                        (price.discountRate != 0 && price.title == '6개월') ||
                        (price.discountRate != 0 && price.title == '6개월')
                      "> F
                      {{ price.discountRate }}%
                    </div> -->
                    <!-- 12개월일 때 "full" -->
                    <!-- <div class="chip full"
                      *ngIf="
                        (price.discountRate != 0 && price.title == '12개월') ||
                        (price.discountRate != 0 && price.title == '12개월')
                      ">
                      {{ price.discountRate }}%
                    </div> -->
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="content-btm-pd"
          id="section5"
          [ngClass]="{ pd: totalView }">
          <form [formGroup]="applyForm">
            <h2 class="title"><span>구매자</span> 정보 입력</h2>

            <!-- 성함 -->
            <div class="item-wrap">
              <label for="name">성함</label>
              <input type="text"
                id="name"
                formControlName="name"
                name="name"
                inputmode="text"
                placeholder="성함을 입력해주세요." />
            </div>

            <!-- 연락처 -->
            <div class="item-wrap">
              <label for="phone">연락처</label>
              <input type="text"
                id="phone"
                formControlName="phone"
                name="phone"
                inputmode="text"
                placeholder="연락처를 입력해주세요." />

              <p class="input-info">
                연락처를 남겨주시면 전문 상담사가 연락 드립니다.
              </p>
            </div>

            <!-- 이메일 -->
            <div class="item-wrap email">
              <label for="email">이메일</label>
              <div class="flex-between-start">
                <input type="text"
                  id="email"
                  name="email"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="email1"
                  (change)="setEmail()"
                  placeholder="이메일을 입력해주세요." />
                <span class="mark">@</span>
                <div class="flex-col-start">
                  <div class="sol-select"
                    (click)="emailClickView()"
                    [ngClass]="{ hover: emailView }">
                    <button type="button">
                      <span class="text"
                        [ngClass]="{
                          placeholder: emailSelect === '선택하기'
                        }">{{ emailSelect }}</span>
                      <span class="icon"><img src="assets/imgs/solution_select_icon.png"
                          alt="셀렉트아이콘" /></span>
                    </button>
                    <ul class="select-ul">
                      <li *ngFor="let email of emailList"
                        (click)="emailValueChange(email)">
                        {{ email }}
                      </li>
                    </ul>
                  </div>
                  <div class="direct"
                    [ngClass]="{ active: emailSelect === '직접입력' }">
                    <input type="text"
                      name="direct"
                      id="direct"
                      [(ngModel)]="email2"
                      (change)="setEmail()"
                      [ngModelOptions]="{ standalone: true }"
                      placeholder="직접입력" />
                  </div>
                </div>
              </div>
            </div>

            <!-- 요청사항 [선택] -->
            <div class="item-wrap detail-info">
              <label for="detail">요청사항 <span>[선택]</span></label>
              <textarea name="detail"
                id="detail"
                rows="10"
                formControlName="request"
                placeholder="요청사항을 입력해주세요."></textarea>
            </div>

            <div class="item-wrap"
              id="section6">
              <label>약관 동의</label>

              <div class="check-list">
                <div class="check-item">
                  <input type="checkbox"
                    id="agree01"
                    [(ngModel)]="policyAgreement1"
                    [ngModelOptions]="{ standalone: true }"
                    [checked]="false"
                    [disabled]="false" />

                  <label for="agree01">
                    <div class="s-top">
                      <i class="check-icon"></i>
                      <span class="tit">
                        계약 후 앱 세팅이 시작되면 담당자가 안내 메일을 발송하며
                        앱 세팅 시작 후에는 환불이 불가능함을 인지하고
                        동의합니다.
                      </span>
                    </div>
                  </label>
                </div>
                <div class="check-item">
                  <input type="checkbox"
                    id="agree02"
                    [(ngModel)]="policyAgreement2"
                    [ngModelOptions]="{ standalone: true }"
                    [checked]="false"
                    [disabled]="false" />

                  <label for="agree02">
                    <div class="s-top">
                      <i class="check-icon"></i>
                      <span class="tit">
                        아래 내용에 모두 동의합니다. 스타트앱
                        <button type="button"
                          class="text-btn"
                          (click)="$event.stopPropagation(); terms('service')">
                          이용약관
                        </button>
                        및
                        <button type="button"
                          class="text-btn"
                          (click)="$event.stopPropagation(); terms('privacy')">
                          개인정보 취급방침
                        </button>
                      </span>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="right-contents"
        #rightCont>
        <div class="follow-box"
          [ngClass]="{ none: !totalView }">
          <button type="button"
            class="open"
            [ngClass]="{ viewon: totalView }"
            (click)="totalViewChange()">
            <div class="img-wrap">
              <img src="./assets/imgs/solution_select_icon.png"
                alt="{{ totalView ? '숨기기' : '더보기' }}" />
            </div>
          </button>

          <div class="pd-list">
            <div class="pd">
              <p class="pd-tit">상품명</p>
              <p class="pd-select">{{ solutionAppData?.title }}</p>
              <p class="pd-select"
                *ngIf="selectType">{{ selectType }}</p>
            </div>
            <div class="pd">
              <p class="pd-tit">
                <!-- 쇼핑몰 디자인 -->
                상품 금액
              </p>
              <div class="flex-between-center">
                <!-- 선택해주세요. class="not" -->
                <!-- <p class="pd-select not">선택해주세요.</p> -->

                <!-- 단독일 경우 하나만 -->
                <!-- <p class="pd-select">[디자인 01] 깔끔한 / 세련된 / 기본적인</p> -->

                <!-- 가격 표시의 경우 -->
                <p class="pd-select">{{ selectTitle }}</p>

                <p class="pd-price">{{ selectPrice | number }}원</p>
              </div>
            </div>
            <div class="pd">
              <p class="pd-tit">
                <!-- 스타터팩금액 -->
                부가 서비스 금액
              </p>
              <div class="flex-between-start"
                *ngIf="storeRegistration">
                <!-- 선택해주세요. class="not" -->
                <!-- <p class="pd-select not">선택해주세요.</p> -->

                <!-- 단독일 경우 하나만 -->
                <!-- <p class="pd-select">안드로이드 + iOS 12개월</p> -->

                <!-- 가격 표시의 경우 -->
                <p class="pd-select">앱 등록 대행서비스</p>
                <!-- 할인 전 -->
                <p class="pd-price"
                  *ngIf="storeRegistration && storeRegistration!=='선택안함' && selectMonth !== 12">{{addPrice | number}}원
                </p>
                <!-- 할인 후 -->
                <p class="pd-price sale"
                  *ngIf="storeRegistration && storeRegistration!=='선택안함' && selectMonth == 12">
                  <span>{{addPrice | number}}원</span><br />
                  0원 (이벤트 할인)
                </p>
              </div>
              <div class="flex-between-center">
                <!-- 선택해주세요. class="not" -->
                <!-- <p class="pd-select not">선택해주세요.</p> -->

                <!-- 단독일 경우 하나만 -->
                <!-- <p class="pd-select">안드로이드 + iOS 12개월</p> -->

                <!-- 가격 표시의 경우 -->
                <p class="pd-price"
                  *ngIf="storeRegistration=='선택안함'">선택 안함</p>
              </div>
            </div>
          </div>
          <div class="gray-total">
            <div class="total">
              <p class="tit">부가 가치세</p>
              <div class="price">{{ vat | number }}원</div>
            </div>

            <div class="total">
              <div class="tit">합계</div>
              <div class="price blue">{{ totalPrice | number }}원</div>
            </div>
          </div>
        </div>

        <div class="btn-wrap">
          <button type="button"
            class="full-btn"
            (click)="payment()"
            [disabled]="false">
            결제하기
          </button>
        </div>
      </div>
    </section>
  </div>
</main>
<!-- <footer-share id="footerWrap"></footer-share>
<footer-share class="mobile-footer"></footer-share> -->