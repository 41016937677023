<div id="common-container">
  <!-- <div class="progress-bar">
    <div class="bar"></div>
  </div> -->

  <div class="swiper mySwiper">
    <div class="swiper-wrapper">
      <!-- 첫번째 페이지 -->
      <div class="swiper-slide page01">
        <div class="main-container">
          <div class="content">
            <p class="num"><span>01</span> /07</p>

            <div class="text-wrap">
              <div class="imoz">
                <p>만나뵙게 되어 반갑습니다.</p>
                <img src="assets/imgs/imoz.png" alt="" />
              </div>

              <p class="bold"><span>성함과 소속 회사</span>를 알려주세요.</p>

              <p class="small-p">
                소속된 회사가 없을 경우 넘어가셔도 좋습니다.
              </p>

              <div class="input-box">
                <form [formGroup]="inquiryForm">
                  <div class="form-inner">
                    <div class="input-wrap">
                      <label for="name">성함</label>
                      <input
                        type="text"
                        placeholder="성함을 입력해주세요."
                        formControlName="username"
                      />
                    </div>

                    <div class="input-wrap">
                      <label for="password">소속회사</label>
                      <input
                        type="text"
                        placeholder="소속회사명을 입력해주세요."
                        formControlName="company"
                      />
                    </div>
                  </div>
                </form>

                <div class="flex-end">
                  <button type="button" class="swiper-button-next">
                    다음
                    <div class="icon"></div>
                  </button>
                  <p>Enter키를 눌러서 넘어가기</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-slide page02">
        <div class="main-container">
          <div class="content">
            <p class="num"><span>02</span> /07</p>

            <div class="text-wrap">
              <p class="bold">
                안녕하세요 {{ inquiryService.inquiry?.username }}님!
              </p>

              <p class="bold">
                <span>연락 받으실 전화번호</span>를 알려주세요.
              </p>

              <p class="small-p">
                알려주신 전화번호로 담당자가 연락을<br />
                드려요.
              </p>

              <div class="input-box">
                <form [formGroup]="inquiryForm">
                  <div class="form-inner">
                    <div class="input-wrap">
                      <label for="name">전화번호</label>
                      <input
                        type="text"
                        placeholder="‘-‘ 포함 입력"
                        formControlName="phone"
                      />
                      <!-- <p class="error">유효하지 않은 전화번호입니다.</p> -->
                    </div>
                  </div>
                </form>

                <div class="flex-end">
                  <div class="wrap">
                    <button type="button" class="swiper-button-prev">
                      <div class="icon"></div>
                      이전
                    </button>
                    <button type="button" class="swiper-button-next">
                      다음
                      <div class="icon"></div>
                    </button>
                  </div>
                  <p>Enter키를 눌러서 넘어가기</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-slide page03">
        <div class="main-container">
          <div class="content">
            <p class="num"><span>03</span> /07</p>

            <div class="text-wrap">
              <p class="bold">
                견적서를 받으실<br />
                <span>이메일 주소</span>를 알려주세요.
              </p>

              <p class="small-p">
                상담 진행 후 알려주신 이메일 주소로 견적서를 보내드릴게요.
              </p>

              <!-- TODO 반응형 디자인 없음 요청해야함 -->
              <div class="input-box">
                <form [formGroup]="inquiryForm">
                  <div class="form-inner">
                    <div class="input-wrap">
                      <label for="name">이메일</label>
                      <input
                        type="text"
                        placeholder="예) email@email.com"
                        formControlName="email"
                      />
                      <!-- <p class="error">이메일 형식에 맞게 입력해주세요.</p> -->
                    </div>
                  </div>
                </form>

                <div class="flex-end">
                  <div class="wrap">
                    <button type="button" class="swiper-button-prev">
                      <div class="icon"></div>
                      이전
                    </button>
                    <button type="button" class="swiper-button-next">
                      다음
                      <div class="icon"></div>
                    </button>
                  </div>
                  <p>Enter키를 눌러서 넘어가기</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-slide page04">
        <div class="main-container">
          <div class="content">
            <p class="num"><span>04</span> /07</p>

            <div class="text-wrap">
              <div class="flex">
                <p class="bold">
                  <span>어떤 프로젝트 종류</span>를<br />원하시는지 알려주세요.
                </p>
                <img class="imoz" src="assets/imgs/imoz02.png" alt="" />
              </div>

              <p class="small-p">예산에 맞게 방법을 제안해드릴게요.</p>

              <div class="input-box">
                <div class="checkbox-bind-wrap">
                  <div class="row">
                    <div
                      (click)="inputClick(i, service.checked)"
                      *ngFor="
                        let service of inquiryService.serviceList;
                        let i = index
                      "
                    >
                      <label [for]="service.id">
                        <input
                          disabled
                          type="checkbox"
                          [id]="service.id"
                          name="check01"
                          [(ngModel)]="service.checked"
                        />
                        {{ service.title }}
                      </label>
                    </div>
                  </div>
                </div>

                <div class="flex-end">
                  <div class="wrap">
                    <button type="button" class="swiper-button-prev">
                      <div class="icon"></div>
                      이전
                    </button>
                    <button type="button" class="swiper-button-next">
                      다음
                      <div class="icon"></div>
                    </button>
                  </div>

                  <p>Enter키를 눌러서 넘어가기</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-slide page05">
        <div class="main-container">
          <div class="content">
            <p class="num"><span>05</span> /07</p>

            <div class="text-wrap">
              <p class="bold">
                <span>개발 예산</span>이<br />이 어떻게 되세요?
              </p>

              <p class="small-p">예산에 맞게 방법을 제안해드릴게요.</p>

              <div class="input-box">
                <form [formGroup]="inquiryForm">
                  <div class="form-inner">
                    <div class="input-wrap">
                      <label for="name">예산 금액</label>
                      <div class="budget-input">
                        <input
                          type="tel"
                          name="budget"
                          id="budget"
                          placeholder="예산 금액을 입력해주세요."
                          formControlName="budget"
                          (input)="setFormat($event)"
                        />
                        <span class="unit">만원</span>
                      </div>
                    </div>
                  </div>
                </form>

                <div class="flex-end">
                  <div class="wrap">
                    <button type="button" class="swiper-button-prev">
                      <div class="icon"></div>
                      이전
                    </button>
                    <button type="button" class="swiper-button-next">
                      다음
                      <div class="icon"></div>
                    </button>
                  </div>

                  <p>Enter키를 눌러서 넘어가기</p>
                </div>
              </div>
            </div>

            <div class="floating">
              <img src="assets/imgs/floating-img.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-slide page06">
        <div class="main-container">
          <div class="content">
            <p class="num"><span>06</span> /07</p>

            <div class="text-wrap">
              <p class="bold">
                원하시는<br /><span>상담 내용</span>을 말씀해주세요.
              </p>

              <p class="small-p">
                간단하게라도 작성해주시면<br />
                상담 시 큰 도움이 됩니다.
              </p>

              <div class="input-box">
                <form [formGroup]="inquiryForm">
                  <div class="form-inner">
                    <h4>원하시는 상담 내용</h4>

                    <textarea
                      class="textarea"
                      placeholder="예) 쇼핑몰인데 커뮤니티 기능도 포함된 형태의 앱 개발"
                      formControlName="content"
                    ></textarea>
                  </div>
                </form>

                <div class="file-wrap">
                  <!-- 파일 선택화면 -->
                  <div class="drag-drop" appDnd>
                    <input
                      type="file"
                      name="reference"
                      id="reference"
                      style="display: none"
                      (change)="selectFile($event)"
                      (click)="reference = ''"
                      [(ngModel)]="reference"
                    />
                    <img src="assets/imgs/drag-icon.png" alt="" />
                    <p><span>Drag & Drop</span> 또는</p>
                    <label class="button_label" for="reference">
                      파일 선택
                    </label>
                  </div>

                  <!-- 파일 업로드 후  -->
                  <div class="file-list">
                    <!-- 업로드 완료 후 after 클래스 추가 -->
                    <div
                      class="file-upload"
                      [ngClass]="{ after: file.progress == 100 }"
                      *ngFor="
                        let file of fileUploadService.fileArr;
                        let i = index
                      "
                    >
                      <div class="icon">
                        <img
                          class="before-icon"
                          src="assets/imgs/before-icon.png"
                          alt=""
                        />
                        <img
                          class="after-icon"
                          src="assets/imgs/after-icon.png"
                          alt=""
                        />
                      </div>

                      <div class="file">
                        <div class="up">
                          <div class="flex">
                            <div class="left">
                              <p class="name">{{ file.name }}</p>
                            </div>
                            <p class="k-bite">
                              ({{ file.totalBytes | bytes }})
                            </p>
                          </div>

                          <button type="button" (click)="delFile(i)"></button>
                        </div>
                        <!-- 프로그래스 바  -->
                        <mbsc-form
                          [options]="formSettings"
                          class="progress-upload"
                          *ngIf="file.progress !== 100"
                        >
                          <mbsc-form-group>
                            <mbsc-progress
                              max="100"
                              [value]="file.progress"
                              val="right"
                            ></mbsc-progress>
                          </mbsc-form-group>
                        </mbsc-form>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flex-end">
                  <p class="mb-32">최대 10MB (선택사항)</p>
                  <div class="wrap">
                    <button type="button" class="swiper-button-prev">
                      <div class="icon"></div>
                      이전
                    </button>
                    <button type="button" class="swiper-button-next">
                      다음
                      <div class="icon"></div>
                    </button>
                  </div>

                  <p>Enter키를 눌러서 넘어가기</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-slide page07">
        <div class="main-container">
          <div class="content">
            <p class="num"><span>07</span> /07</p>

            <div class="text-wrap">
              <p class="bold"><span>방문 경로</span>가<br />어떻게 되세요?</p>

              <p class="small-p">
                저희 스타트앱에서 더 나은 서비스를 제공해<br />드리기 위해 좋은
                자료로 활용하겠습니다.
              </p>

              <div class="input-box">
                <div class="radio-bind-wrap">
                  <div class="row">
                    <div
                      *ngFor="
                        let route of inquiryService.routeList;
                        let i = index
                      "
                      (click)="inputRadio(route)"
                    >
                      <label attr.for="route{{ i }}">
                        <input
                          disabled
                          type="radio"
                          id="route{{ i }}"
                          [name]="route01"
                          [checked]="route.checked"
                        />
                        {{ route.title }}
                      </label>
                    </div>
                  </div>
                </div>

                <div class="check-box-wrap">
                  <label>
                    <input
                      class="check-box"
                      type="checkbox"
                      [(ngModel)]="agree"
                    />
                    상담 서비스를 제공하기 위한<br />
                    <span (click)="goDetailPrivacy()"> 개인정보 처리방침</span>
                    에 동의합니다.
                  </label>
                </div>

                <div class="flex-end">
                  <div class="wrap">
                    <button type="button" class="swiper-button-prev">
                      <div class="icon"></div>
                      이전
                    </button>
                    <button
                      type="button"
                      class="next-btn"
                      (click)="goComplete()"
                    >
                      제출하기
                    </button>
                  </div>

                  <p>Enter키를 눌러서 넘어가기</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="swiper-pagination progress-bar"></div>
  </div>
</div>
