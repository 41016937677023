<!-- <header-share></header-share> -->
<main id="main">
  <section class="main-video">
    <video
      autoplay
      muted
      loop
      width="100%"
      height="100%"
      poster="assets/imgs/service-m04.png"
    >
      <source src="assets/video/bg_marketing.mp4" type="video/mp4" />
    </video>
    <div class="main-text">
      <h2>마케팅</h2>
      <p>시작과 마무리 단계까지 최고의 효과를 만듭니다.</p>
    </div>
    <div class="scroll-icon">
      <p>SCROLL</p>
    </div>
  </section>
  <section class="first-section w1180">
    <div class="section-title">
      <h2>
        정말 앱 개발만 완료 했다고 해서,
        <span class="block">단 한번에 성공할 수 있을까요?</span>
      </h2>
      <p class="animation">
        대부분 수많은 앱들은 이용자들에게 알리지 못해 수익 창출은 물론,
        업데이트도 이루어지지 않습니다.
      </p>
    </div>
    <div class="slide-wrap">
      <div class="swiper-container icon-wrap">
        <div class="swiper-wrapper">
          <div class="swiper-slide icon01"></div>
          <div class="swiper-slide icon02"></div>
          <div class="swiper-slide icon03"></div>
          <div class="swiper-slide icon04"></div>
          <div class="swiper-slide icon05"></div>
          <div class="swiper-slide icon06"></div>
          <div class="swiper-slide icon07"></div>
          <div class="swiper-slide icon08"></div>
          <div class="swiper-slide icon09"></div>
          <div class="swiper-slide icon10"></div>
          <div class="swiper-slide icon11"></div>
          <div class="swiper-slide icon12"></div>
          <div class="swiper-slide icon13"></div>
          <div class="swiper-slide icon14"></div>
          <div class="swiper-slide icon15"></div>
          <div class="swiper-slide icon16"></div>
          <div class="swiper-slide icon17"></div>
          <div class="swiper-slide icon18"></div>
          <div class="swiper-slide icon19"></div>
          <div class="swiper-slide icon20"></div>
        </div>
        <!-- <div class="opacity40"></div>
      <div class="opacity60"></div> -->
      </div>
    </div>
  </section>
  <section class="best-effect w1180">
    <div class="section-title">
      <h2>스타트업을 위한, 손쉽게 최고의 효과를 만들 수 있는 방법</h2>
    </div>
    <div class="content-wrap">
      <!-- 01 -->
      <div class="list cf">
        <div class="img fl to-left">
          <img
            src="assets/imgs/marketing_effect_img_01.png"
            alt="최고의 효과 만드는 방법 이미지01"
          />
        </div>
        <div class="text fr">
          <h3>
            <span class="num">01</span>
            사용자를 찾고
          </h3>
          <p class="regular">
            우리는 사용자의 행동 패턴 분석을 통해 서비스를 이용하고자 하는 잠재
            고객을 찾습니다.
          </p>
          <p class="bold">SNS(인스타그램,페이스북)타게팅 / DB광고</p>
        </div>
      </div>
      <!-- 02 -->
      <div class="list left cf">
        <div class="text fl">
          <h3>
            <span class="num">02</span>
            호감을 느끼게 하며
          </h3>
          <p class="regular">
            타겟의 맞춘 관련 있는 주제로 콘텐츠를 만들어 흥미와 궁금증을
            유발하여 관심을 끌어냅니다.
          </p>
          <p class="bold">
            앱 홍보 랜딩 페이지 제작 / 쉽게 읽히는 카피라이팅 / 사용자를 고려한
            디자인
          </p>
        </div>
        <div class="img fr to-right">
          <img
            src="assets/imgs/marketing_effect_img_02.png"
            alt="최고의 효과 만드는 방법 이미지02"
          />
        </div>
      </div>
      <!-- 03 -->
      <div class="list cf">
        <div class="img fl to-left">
          <img
            src="assets/imgs/marketing_effect_img_03.png"
            alt="최고의 효과 만드는 방법 이미지03"
          />
        </div>
        <div class="text fr">
          <h3>
            <span class="num">03</span>
            확신을 만들어
          </h3>
          <p class="regular">
            고객은 확신이 들기 위해 더 많은 정보를 얻으려 자료를 모색 합니다. 그
            과정에서 우리는 더 확신을 줄 수 있게 방문을 유도합니다.
          </p>
          <p class="bold">
            키워드, 콘텐츠 최적화 / 앱스토어 최적화 / 실제 사용자 리뷰 / 평점
            관리
          </p>
        </div>
      </div>
      <!-- 04 -->
      <div class="list left cf">
        <div class="text fl">
          <h3>
            <span class="num">04</span>
            지속적으로 이용할 수 있게 만드는 우리의 전략
          </h3>
          <p class="regular">
            신규 고객을 유입했다면, 지속적인 노출을 통해 서비스의 사용률을
            높힙니다.
          </p>
          <p class="bold">
            사용자 피드백 채널 운영 / 지속적인 푸시 알림 / 이메일을 통한 홍보 /
            UX&UI 개선
          </p>
        </div>
        <div class="img fr to-right">
          <img
            src="assets/imgs/marketing_effect_img_04.png"
            alt="최고의 효과 만드는 방법 이미지04"
          />
        </div>
      </div>
    </div>
  </section>
  <section class="process-wrap w1180">
    <div class="section-title">
      <h2>
        그렇다면 앱 마케팅은
        <span class="block">언제부터 시작해야 하는 것일까요?</span>
      </h2>
      <p class="animation">
        앱 아이디어를 개발하고자 하는 의사 결정을 하는 순간부터 비즈니스의
        시작이 됨을 인지하고 세부적인 전략을 계획하고 실행해야 합니다.
      </p>
    </div>
    <div class="process cf m">
      <ul class="cf">
        <li>
          <div class="li-wrap">
            <img src="assets/imgs/marketing_plan_icon.png" alt="기획 아이콘" />
            <p>기획</p>
          </div>
        </li>
        <li class="even cf">
          <div class="li-wrap">
            <img
              src="assets/imgs/marketing_design_icon.png"
              alt="디자인 아이콘"
            />
            <p>디자인</p>
          </div>
        </li>
        <li>
          <div class="li-wrap">
            <img
              src="assets/imgs/marketing_development_icon.png"
              alt="개발 아이콘"
            />
            <p>개발</p>
          </div>
        </li>
        <li class="even cf">
          <div class="li-wrap">
            <img
              src="assets/imgs/marketing_launch_icon.png"
              alt="출시 아이콘"
            />
            <p>출시</p>
          </div>
        </li>
      </ul>
      <p class="line pc">
        <img
          src="assets/imgs/marketing_processline_pc.png"
          alt="pc 프로세스 라인"
        />
      </p>
      <p class="line mobile">
        <img
          src="assets/imgs/marketing_processline_m.png"
          alt="mobile 프로세스 라인"
        />
      </p>
      <button class="go-process">
        <a (click)="goService()">우리의 프로세스 알아보기 ></a>
      </button>
    </div>
  </section>
  <section class="start-marketing w1180">
    <div class="img-content">
      <!-- <div class="box-test">
        <div class="cube">
          <div class="front">
            <img
              class="pc"
              src="assets/imgs/marketing_custom_off.png"
              alt="고객정보부족"
            />
          </div>
          <div class="back">
            <img
              class="pc"
              src="assets/imgs/marketing_custom_on.png"
              alt="고객사전조사"
            />
          </div>
        </div>
      </div>
      <div class="box-test">
        <div class="cube">
          <div class="front">
            <img
              class="pc"
              src="assets/imgs/marketing_custom_off.png"
              alt="고객정보부족"
            />
          </div>
          <div class="back">
            <img
              class="pc"
              src="assets/imgs/marketing_custom_on.png"
              alt="고객사전조사"
            />
          </div>
        </div>
      </div>
      <div class="box-test">
        <div class="cube">
          <div class="front">
            <img
              class="pc"
              src="assets/imgs/marketing_custom_off.png"
              alt="고객정보부족"
            />
          </div>
          <div class="back">
            <img
              class="pc"
              src="assets/imgs/marketing_custom_on.png"
              alt="고객사전조사"
            />
          </div>
        </div>
      </div> -->

      <div class="box">
        <div class="custom cube m">
          <!-- <div class="side-bottom"></div> -->
          <!-- <div class="side-top"></div> -->
          <!-- <div class="side-back">
            <img
              class="pc"
              src="assets/imgs/marketing_custom_off.png"
              alt="고객정보부족"
            />
            <img
              class="mobile"
              src="assets/imgs/marketing_custom_off_m.png"
              alt="고객정보부족"
            />
          </div> -->
          <!-- <div class="side-right">
            <img
              class="pc"
              src="assets/imgs/marketing_custom_on.png"
              alt="고객사전조사"
            />
            <img
              class="mobile"
              src="assets/imgs/marketing_custom_on_m.png"
              alt="고객사전조사"
            />
          </div> -->
          <div class="side-left">
            <img
              class="pc"
              src="assets/imgs/marketing_custom_on.png"
              alt="고객사전조사"
            />
            <img
              class="mobile"
              src="assets/imgs/marketing_custom_on_m.png"
              alt="고객사전조사"
            />
          </div>
          <div class="side-front">
            <img
              class="pc"
              src="assets/imgs/marketing_custom_off.png"
              alt="고객정보부족"
            />
            <img
              class="mobile"
              src="assets/imgs/marketing_custom_off_m.png"
              alt="고객정보부족"
            />
          </div>
        </div>
      </div>
      <div class="box">
        <div class="time cube m">
          <!-- <div class="side-bottom"></div>
          <div class="side-top"></div>
          <div class="side-back">
            <img
              class="pc"
              src="assets/imgs/marketing_time_off.png"
              alt="시간부족"
            />
            <img
              class="mobile"
              src="assets/imgs/marketing_time_off_m.png"
              alt="시간부족"
            />
          </div>
          <div class="side-right">
            <img
              class="pc"
              src="assets/imgs/marketing_time_on.png"
              alt="시간절약"
            />
            <img
              class="mobile"
              src="assets/imgs/marketing_time_on_m.png"
              alt="시간절약"
            />
          </div> -->
          <div class="side-left">
            <img
              class="pc"
              src="assets/imgs/marketing_time_on.png"
              alt="시간절약"
            />
            <img
              class="mobile"
              src="assets/imgs/marketing_time_on_m.png"
              alt="시간절약"
            />
          </div>
          <div class="side-front">
            <img
              class="pc"
              src="assets/imgs/marketing_time_off.png"
              alt="시간부족"
            />
            <img
              class="mobile"
              src="assets/imgs/marketing_time_off_m.png"
              alt="시간부족"
            />
          </div>
        </div>
      </div>
      <div class="box">
        <div class="cost cube m">
          <!-- <div class="side-bottom"></div>
          <div class="side-top"></div>
          <div class="side-back">
            <img
              class="pc"
              src="assets/imgs/marketing_cost_off.png"
              alt="추가적인비용"
            />
            <img
              class="mobile"
              src="assets/imgs/marketing_cost_off_m.png"
              alt="추가적인비용"
            />
          </div>
          <div class="side-right">
            <img
              class="pc"
              src="assets/imgs/marketing_cost_on.png"
              alt="적절한비용"
            />
            <img
              class="mobile"
              src="assets/imgs/marketing_cost_on_m.png"
              alt="적절한비용"
            />
          </div> -->
          <div class="side-left">
            <img
              class="pc"
              src="assets/imgs/marketing_cost_on.png"
              alt="적절한비용"
            />
            <img
              class="mobile"
              src="assets/imgs/marketing_cost_on_m.png"
              alt="적절한비용"
            />
          </div>
          <div class="side-front">
            <img
              class="pc"
              src="assets/imgs/marketing_cost_off.png"
              alt="추가적인비용"
            />
            <img
              class="mobile"
              src="assets/imgs/marketing_cost_off_m.png"
              alt="추가적인비용"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- text area -->
    <div class="text-content">
      <h3>시작 단계부터 마케팅을 진행하세요</h3>
      <p class="mb10">
        앱 개발이 마무리 된 후 마케팅이 진행된다면 고객에 대한 정보도 없이
        고객의 니즈를 찾지 못한 앱으로 남아있을 것 입니다.
      </p>
      <p>
        <span class="bold"
          >시작 단계부터 마케팅을 진행한다면 비용과 시간에 적절한 균형을 맞추어
          앱이 출시되는 시점부터 잠재고객 유입까지</span
        >
        적절한 타이밍으로 실행될 수 있을 것 입니다.
      </p>
    </div>
  </section>

  <section class="bottom-line">
    <div class="bottom-box">
      <h2>스타트앱</h2>
      <p>이제 당신의 서비스는 '모두가 찾는 앱'이 될 것입니다.</p>
    </div>
    <!-- <sticky-button></sticky-button> -->
  </section>
  <footer-share id="footerWrap"></footer-share>
  <footer-share class="mobile-footer"></footer-share>
</main>

<!-- <div id="footer-marketing">
  <div class="footer-inner">
    <div class="btn-wrap">
      <p>더 많은 프로세스를 만나보세요.</p>
      <div class="btn-inner cf">
        <button class="prev">
          <a href="/development">Android/iOS 개발</a>
        </button>
        <button class="next"><a href="/plan">UX기획&전략</a></button>
      </div>
    </div>

    <footer-share></footer-share>
  </div>
</div> -->
