import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Inquiry } from 'src/app/models/inquiry.models';
import { DbService } from 'src/app/service/db.service';
import { InquiryService } from 'src/app/service/inquiry.service';
import { TrackingService } from 'src/app/service/tracking.service';

@Component({
  selector: 'app-inquiry-simple-pop',
  templateUrl: './inquiry-simple-pop.component.html',
  styleUrls: ['./inquiry-simple-pop.component.scss'],
})
export class InquirySimplePopComponent implements OnInit {
  inquiry: Inquiry = {
    inquiryId: '', // 문의 Id,
    isCheck: false, //확인여부
    username: '', // 이름
    phone: '', // 전화번호
    email: '', // 이메일
    service: '', //서비스 종류
    company: '', //회사명
    project: '', //프로젝트 명
    budget: 0, // 예산
    content: '', // 상세내용
    dateCreated: '', // 문의일
    browser: '', //접속정보
    updateFiles: [], // 무슨 브라우저로 들어왔는지,
    memoList: [], //메모리스트,
    date: '',
    status: '접수',
    inflow: '스타트앱 문의',
    visitRoute: [],
    ipAddress: '',
    createdBy: '',
    trackingDataId: '',
  };
  loading = false;
  privacy = false;
  constructor(
    private trackingService: TrackingService,
    private deviceService: DeviceDetectorService,
    public inquiryService: InquiryService,
    private db: DbService,
    public dialogRef: MatDialogRef<InquirySimplePopComponent>,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  ngOnInit(): void {}

  modalClose() {
    this.dialogRef.close();
  }

  async goComplete(): Promise<void> {
    if (!this.inquiry.username || !this.inquiry.phone) {
      this.inquiryService.alert('이름과 연락처 모두 입력해주세요.');
      return;
    }
    if (!this.privacy) {
      this.inquiryService.alert('개인정보 수집 동의를 해주세요.');
      return;
    }
    this.inquiry.id = await this.db.createId();

    this.inquiry.browser = navigator.userAgent;
    this.inquiry.dateCreated = new Date().toISOString();
    this.inquiry.date = new Date();
    this.inquiry.trackingDataId = await this.db.createId();
    this.inquiryService.inquiry = this.inquiry;

    this.loading = true;

    await this.trackingService.saveTrackingData(
      this.inquiry.trackingDataId,
      'inquiry-mobile',
      this.inquiry.id
    );
    await this.db.updateAt(`inquiries/${this.inquiry.id}`, this.inquiry);
    this.loading = false;
    let script1 = this._renderer2.createElement('script');
    script1.type = 'text/javascript';
    script1.charset = `UTF-8`;
    script1.src = `//t1.daumcdn.net/kas/static/kp.js`;
    this._renderer2.appendChild(this._document.body, script1);
    let script2 = this._renderer2.createElement('script');
    script2.type = 'text/javascript';
    script2.text = `kakaoPixel('901381571553151974').pageView();
    kakaoPixel('901381571553151974').participation();`;
    this._renderer2.appendChild(this._document.body, script2);
    let script3 = this._renderer2.createElement('script');
    script3.type = 'text/javascript';
    script3.text = `var _nasa={};
    if (window.wcs) _nasa["cnv"] = wcs.cnv("5","${1000}");
    if (!wcs_add) var wcs_add = {};
  wcs_add["wa"] = "s_52ea45ec186a";
  if (!_nasa) var _nasa = {};
  if (window.wcs) {
    wcs.inflow("startapp.co.kr");
    wcs_do(_nasa);
  }
    `;
    this.inquiryService.success(
      '접수완료',
      `문의 주셔서 감사합니다.<br/>전문 상담사가 확인 후<br/>빠르게 연락드리도록 하겠습니다.`
    );
    this.dialogRef.close();
  }
}
