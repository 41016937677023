import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { Inquiry } from 'src/app/models/inquiry.models';
import { InquiryService } from 'src/app/service/inquiry.service';
import { InquiryListPopComponent } from '../inquiry-list-pop/inquiry-list-pop.component';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-inquiry-complete',
  templateUrl: './inquiry-complete.component.html',
  styleUrls: ['./inquiry-complete.component.scss'],
})
export class InquiryCompleteComponent implements OnInit {
  inquiry: Inquiry;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private inquiryService: InquiryService,
    protected $gaService: GoogleAnalyticsService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  ngOnInit(): void {
    let script1 = this._renderer2.createElement('script');
    script1.type = 'text/javascript';
    script1.charset = `UTF-8`;
    script1.src = `//t1.daumcdn.net/kas/static/kp.js`;

    this._renderer2.appendChild(this._document.body, script1);
    let script2 = this._renderer2.createElement('script');
    script2.type = 'text/javascript';
    script2.text = `kakaoPixel('901381571553151974').pageView();
    kakaoPixel('901381571553151974').participation();`;
    this._renderer2.appendChild(this._document.body, script2);
    let script3 = this._renderer2.createElement('script');
    script3.type = 'text/javascript';
    script3.text = `var _nasa={};
    if (window.wcs) _nasa["cnv"] = wcs.cnv("5","${1000}");
    if (!wcs_add) var wcs_add = {};
  wcs_add["wa"] = "s_52ea45ec186a";
  if (!_nasa) var _nasa = {};
  if (window.wcs) {
    wcs.inflow("startapp.co.kr");
    wcs_do(_nasa);
  }
    `;
    this._renderer2.appendChild(this._document.body, script3);
    this.inquiry = this.inquiryService.inquiry;

    if (this.inquiry.dateCreated) {
      this.$gaService.event('submit', 'inquiry', 'Complete');
    } else {
      this.goHome();
    }
  }

  goHome() {
    this.router.navigateByUrl('/home');
  }

  goList() {
    this.dialog.open(InquiryListPopComponent, {
      panelClass: 'inquiry-popup',
    });
  }
}
