import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-solution-detail-renewal-popup',
  templateUrl: './solution-detail-renewal-popup.component.html',
  styleUrls: ['./solution-detail-renewal-popup.component.scss'],
})
export class SolutionDetailRenewalPopupComponent implements OnInit {
  type = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data, private dialog: MatDialog) {
    this.type = data.type;
  }

  ngOnInit(): void {}

  closeDialog() {
    this.dialog.closeAll();
  }
}
