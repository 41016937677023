import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/service/data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-solution-payment-completed',
  templateUrl: './solution-payment-completed.component.html',
  styleUrls: ['./solution-payment-completed.component.scss'],
})
export class SolutionPaymentCompletedComponent implements OnInit {
  constructor(private router: Router, public dataService: DataService) {}

  ngOnInit(): void {
    if (!this.dataService.orderSolutionData) {
      this.goHome();
    }
  }
  // 홈으로 가기
  completed() {
    return new Promise<any>((resolve, reject) => {
      Swal.fire({
        text: '소중한 구매 감사드립니다. 결제 내역은 입력하신 이메일 주소로 발송될 예정입니다.',
        showCancelButton: true,
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        confirmButtonColor: '#586bff',
      }).then((result) => {
        if (result.isConfirmed) {
          resolve('OK');
          this.goHome();
        } else {
          reject('cancel');
        }
      });
    });
  }
  goHome() {
    this.router.navigateByUrl('/').then(() => {
      // //window.location.reload();
    });
  }
}
