import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  LOCALE_ID,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
import { EstimateInfoService } from 'src/app/service/estimate-info.service';
import { jsPDF } from 'jspdf';
import { PdfFontService } from 'src/app/service/pdf-font.service';
import { DbService } from 'src/app/service/db.service';
import * as firebase from 'firebase';
import autoTable from 'jspdf-autotable';
import {
  AngularFireStorage,
  AngularFireUploadTask,
} from '@angular/fire/storage';
import Swal from 'sweetalert2';
import { Inquiry } from 'src/app/models/inquiry.models';
import { DataService } from 'src/app/service/data.service';
import { TermsModalComponent } from '../terms-modal/terms-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';
import { InquiryService } from 'src/app/service/inquiry.service';
import { FileUploadService } from 'src/app/service/file-upload.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { SolutionApp } from 'src/app/models/solutionApp.models';
import { TrackingService } from 'src/app/service/tracking.service';

declare const Email: any;

@Component({
  selector: 'app-inquiry',
  templateUrl: './inquiry.component.html',
  styleUrls: ['./inquiry.component.scss'],
})
export class InquiryComponent implements OnInit, OnDestroy {
  inquiry: Inquiry = {
    inquiryId: '', // 문의 Id,
    isCheck: false, //확인여부
    username: '', // 이름
    phone: '', // 전화번호
    email: '', // 이메일
    service: '', //서비스 종류
    company: '', //회사명
    project: '', //프로젝트 명
    budget: 0, // 예산
    content: '', // 상세내용
    dateCreated: '', // 문의일
    browser: '', //접속정보
    updateFiles: [], // 무슨 브라우저로 들어왔는지,
    memoList: [], //메모리스트,
    date: '',
    status: '접수',
    inflow: '스타트앱 문의',
    visitRoute: [],
    ipAddress: '',
    createdBy: '',
    trackingDataId: '',
  };
  task: AngularFireUploadTask;
  email: string = '';
  emailAdd: string = '';
  //개인정보 처리방침
  privacy = false;
  deviceInfo;
  ip;
  getEstimeate = false;
  url;
  file;
  blobUrl;
  requiredArr;
  additional;
  reference;
  fileArr = [];
  estimateId;
  pdfUrl;
  pdf;
  saveDate;
  type;
  loading = false;
  uploadFiles = [];
  isSubmitted = false;
  selectWeb;
  selectApp: SolutionApp;

  marketing;
  serviceList = [
    {
      id: 1,
      title: '컨설팅',
      checked: false,
    },
    {
      id: 2,
      title: '웹 개발',
      checked: false,
    },
    {
      id: 3,
      title: '앱 개발',
      checked: false,
    },
    {
      id: 4,
      title: '마케팅',
      checked: false,
    },
  ];
  routeList = [
    {
      id: 1,
      title: '네이버',
      checked: false,
    },
    {
      id: 2,
      title: '구글',
      checked: false,
    },
    {
      id: 3,
      title: '블로그',
      checked: false,
    },
    {
      id: 4,
      title: '언론매체',
      checked: false,
    },

    {
      id: 5,
      title: '유튜브',
      checked: false,
    },
    {
      id: 6,
      title: '지인소개',
      checked: false,
    },
    {
      id: 7,
      title: '기존고객',
      checked: false,
    },
    {
      id: 8,
      title: '기타',
      checked: false,
    },
  ];
  ipAddress = '';
  constructor(
    private activatedRouter: ActivatedRoute,
    private deviceService: DeviceDetectorService,
    private http: HttpClient,
    private router: Router,
    private estimateInfo: EstimateInfoService,
    private pdfFont: PdfFontService,
    public fileUploadService: FileUploadService,
    private db: DbService,
    private storage: AngularFireStorage,
    private dataService: DataService,
    public dialog: MatDialog,
    protected $gaService: GoogleAnalyticsService,
    private _renderer2: Renderer2,
    public inquiryService: InquiryService,
    private trackingService: TrackingService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    if (this.activatedRouter.snapshot.params.id) {
      this.activatedRouter.queryParams
        .pipe(take(1))
        .subscribe(async (params) => {
          this.type = params.type ? params.type : '';
          this.selectApp = await this.dataService.getStaterPackById(
            this.activatedRouter.snapshot.params.id
          );
          this.inquiry.service = 'staterpack';
          this.inquiry.selectApp = this.activatedRouter.snapshot.params.id;
          this.estimateId = this.activatedRouter.snapshot.params.id;
        });
    }
  }

  generateFilename() {
    var length = 20;
    var text = '';
    var possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  //파일 가져오기
  selectFile(event) {
    let file = event.target.files[0];

    let fileName = file.name;
    let dataUrl;

    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      dataUrl = reader.result;

      this.makeFileArr(file, fileName, dataUrl);
    };
  }

  showErrorAlert(title: string, text: string) {
    Swal.fire({
      title,
      text,
      icon: 'error',
      confirmButtonText: '확인',
    });
  }

  showWarningAlert(title: string, text: string) {
    Swal.fire({
      title,
      text,
      icon: 'warning',
      confirmButtonText: '확인',
    });
  }

  showSuccessAlert(title: string, text: string) {
    Swal.fire({
      title,
      text,
      icon: 'success',
      confirmButtonText: '확인',
    });
  }

  showConfirmAlert(title: string) {
    return new Promise<any>((resolve, reject) => {
      Swal.fire({
        title,
        showCancelButton: true,
        confirmButtonText: '확인',
        cancelButtonText: '취소',
      }).then((result) => {
        if (result.isConfirmed) {
          resolve('OK');
        } else {
          reject('cancel');
        }
      });
    });
  }

  ///첨부파일 만들기
  makeFileArr(file, fileName, dataUrl) {
    let tmp = { name: fileName, data: dataUrl };

    if (this.fileArr.length == 0) {
      this.fileArr.push(tmp);
      this.uploadFiles.push(file);
    } else if (!this.getEstimeate && this.fileArr?.length < 5) {
      let index = this.fileArr.findIndex((e) => e.name == tmp.name);

      // let tmpIndex = this.myFollingList.findIndex(e => e == user.id);

      if (index == -1) {
        this.fileArr.push(tmp);
        this.uploadFiles.push(file);
      } else {
        this.showWarningAlert('알림', '이미 추가한 파일입니다.');
      }
    } else if (this.getEstimeate && this.fileArr?.length < 4) {
      let index = this.fileArr.findIndex((e) => e.name == tmp.name);

      // let tmpIndex = this.myFollingList.findIndex(e => e == user.id);

      if (index == -1) {
        this.fileArr.push(tmp);
        this.uploadFiles.push(file);
      } else {
        this.showWarningAlert('알림', '이미 추가한 파일입니다.');
      }
    } else {
      this.showWarningAlert(
        '알림',
        '참고문서는 간편 견적서를 포함해 5개 이하로 첨부해주세요.'
      );
    }
    tmp = { name: '', data: '' };
  }

  delete(item) {
    this.fileArr.forEach((e) => {
      let index = this.fileArr.indexOf(e);
      this.fileArr.splice(index, 1);
    });
  }

  async ngOnInit(): Promise<void> {
    this.$gaService.event('inquiry-start', 'inquiry', 'writing');
    this.deviceInfo = this.deviceService.getDeviceInfo();
    // this.getIPAddress();
    this.uploadFiles = [];
    if (this.requiredArr) {
      this.requiredArr[0].osDetail.forEach((item) => {
        if (item.check == true) {
          this.getEstimeate = true;
        }
      });
    }

    if (this.getEstimeate) {
    }

    // this.getIPAddress().subscribe((data: any) => {
    //   this.ip = data.ip;
    // });

    // this.footerOpen();

    // 새로고침 시 스크롤 초기화
    // window.ready = function () {
    //   $(window).animate({ scrollTop: 0 }, 100);
    //   // setTimeout(function () {
    //   //   scrollTo(0, 0);
    //   // }, 10);
    // };

    $(document).ready(function () {
      $('html,body').animate({ scrollTop: 0 }, 100); //100ms for example
    });

    $(function () {
      let winHeight = $(window).height();
      // $(window).resize(function () {
      //   $('.main-video').height(winHeight);
      // });
      // $(window).trigger('resize');

      // 창크기 조절 시 자동 (새로고침)resize 발생
      // window.onresize = function () {
      //   location.reload();
      // };

      // ******************** 텍스트애니메이션 ******************** //
      let txtBox = [];
      let temp = [];
      function textAni() {
        let txtLine = [];
        $('.main-text p').each(function (index, item) {
          let txt = $(item).text();
          let tmp = { index: index, txt: txt };
          txtLine.push(tmp);
        });

        let splitTxt = [];
        txtLine.forEach((txt) => {
          splitTxt.push(txt.txt.split(' '));
        });

        $(splitTxt).each(function (index, item) {
          let txt = [];
          item.forEach((e) => {
            txt.push('<span>' + e + '</span>&nbsp;');
          });
          txtBox.push({ index: index, txt: txt });
        });
        for (var i = 0; i < txtBox.length; i++) {
          $('.main-text p').eq(i).html(txtBox[i].txt);
          $('.main-text p span').css({
            display: 'inline-block',
            opacity: 0,
            position: 'relative',
            top: '10px',
            left: '5px',
            fontSize: '1.17rem',
            lineHeight: '1.722rem',
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
          });
          $('.main-text p')
            .eq(i)
            .find('span')
            .each(function (i, item) {
              temp.push(item);
            });
        }
      }
      textAni();

      // 실행
      setTimeout(() => {
        for (let i = 0; i < txtBox.length; i++) {
          for (let index = 0; index < temp.length; index++) {
            const ele = temp[index];
            $('.main-text p')
              .eq(i)
              .animate({ opacity: 1 })
              .find($(ele))
              .delay(50 * index)
              .animate({ top: 0, opacity: 1, left: 0 }, 500);
          }
        }
      }, 200);
      // ******************** 텍스트애니메이션 끝 ******************** //

      // 이메일 주소 선택
      var select = $('.email-address');
      // 선택한 텍스트 입력
      $('.email-address li').click(function () {
        var text = $(this).text();
        $(this).parent().prev().find('.text').html(text).css({ color: '#000' });
        $(this).parent().stop().slideUp('fast');
        // '직접입력' 선택 시 input창 생성
        if (text == '직접입력') {
          $(this).parent().stop().slideUp('fast');
          setTimeout(() => {
            $('.direct').addClass('active');
          }, 100);
        } else {
          $('.direct').removeClass('active');
        }
      });
      // 리스트 업다운 & 아이콘 회전
      $(select)
        .find('button')
        .click(function () {
          $(this).next().stop().slideToggle('fast');
          $(this).find('.icon').toggleClass('active');
        });
      // 마우스 포커스 떠나면 리스트 올라가고 아이콘 원래대로
      $(select).mouseleave(function () {
        $(this).find('ul').slideUp('fast');
        $(this).find('.icon').removeClass('active');
      });
      // 웹접근성 tab & shift key
      // $(select)
      //   .find('li:last')
      //   .keydown(function (e) {
      //     if (e.keyCode == 9) {
      //       if (!e.shiftKey) {
      //         $(select).find('button').trigger('click');
      //       }
      //     }
      //   });
      // $(select)
      //   .find('button')
      //   .keydown(function (e) {
      //     if (e.keyCode == 9) {
      //       if (e.shiftKey) {
      //         $(this).next().stop().slideUp('fast');
      //         $(this).find('.icon').removeClass('active');
      //       }
      //     }
      //   });
      // // 이메일 주소 선택 jquery 끝

      // scrollTop btn
      // $('footer-share')
      //   .find('.top_btn')
      //   .on('click', function (e) {
      //     $('html,body').animate({ scrollTop: 0 }, 500);
      //     return false;
      //   });
    }); //jquery 끝
  }

  ngOnDestroy(): void {
    $(window).scroll().off();
  }

  //사용 브라우저 이름
  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();

    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  checkEmail(value) {
    if (value != '직접입력') {
      this.emailAdd = value;
    } else {
      this.emailAdd = this.emailAdd;
    }
    this.setEmail();
  }

  async submit() {
    this.goComplete();
  }

  async goComplete(): Promise<void> {
    this.inquiry.id = await this.db.createId();
    this.inquiry.browser = navigator.userAgent;
    this.inquiry.dateCreated = new Date().toISOString();
    this.inquiry.date = new Date();
    this.inquiry.email = this.email + '@' + this.emailAdd;
    this.inquiry.deviceInfo = this.deviceInfo ? this.deviceInfo : '';
    this.inquiry.ipAddress = this.ipAddress ? this.ipAddress : '';
    this.inquiry.trackingDataId = await this.db.createId();
    this.inquiryService.inquiry = this.inquiry;
    const ok = await this.inquiryService.confirmAlert(
      '입력하신 내용으로 문의를 제출하시겠어요?'
    );
    if (ok) {
      this.loading = true;
      const updateFile = this.uploadFiles.map(async (item) => {
        const res = await this.updateFile(item);
        this.inquiry.updateFiles.push(res);
      });
      await Promise.all(updateFile);
      await this.trackingService.saveTrackingData(
        this.inquiry.trackingDataId,
        'inquiry',
        this.inquiry.id
      );
      await this.db.updateAt(`inquiries/${this.inquiry.id}`, this.inquiry);
      this.loading = false;
      this.router.navigateByUrl('/inquiry-complete');
    } else {
      this.loading = false;
    }
  }

  setEmail() {
    this.inquiry.email = this.email + '@' + this.emailAdd;
  }

  async submitProcess(url?) {
    this.inquiry.browser = navigator.userAgent;
    let tmp = ``;
    this.pdfUrl = encodeURI(url);

    this.inquiry.email = this.email + '@' + this.emailAdd;
    let update = await this.updateDb();
    if (update) {
      //   Email.send({
      //     Host: 'smtp.elasticemail.com',
      //     Username: 'startappfirebase@gmail.com',
      //     Password: '454153EE68A1CC33F3700F526F929CBDD3C6',
      //     To: email,
      //     // To: 'kycha@kncuration.com',
      //     // From: this.inquiry.email + '@' + this.inquiry.emailAdd,
      //     From: 'startappfirebase@gmail.com',
      //     Subject: `[스타트앱] ${this.inquiry.username}님 문의`,
      //     Attachments: this.fileArr,
      //     Body: `<html>
      //   <h4>문의자: ${this.inquiry.username}</h4>
      //   <h4>연락처: ${this.inquiry.phone}</h4>
      //   <h4>이메일: ${this.inquiry.email}</h4>
      //   <h4>회사명: ${this.inquiry.company}</h4>
      //   <h4>프로젝트명: ${this.inquiry.project}</h4>
      //   <h4>서비스 종류: ${this.inquiry.service}</h4>
      //   <h4>전화번호: ${this.inquiry.phone}</h4>
      //   <h4>예산: ${formatNumber(this.inquiry.budget, this.locale)} 만원</h4>
      //   <h4>상세내용: ${this.inquiry.content}</h4>
      //   <p>접속정보 - ip: ${this.ip} | 브라우져: ${
      //       this.deviceInfo.browser
      //     } | 운영체제: ${this.deviceInfo.os} | 디바이스: ${
      //       this.deviceInfo.device
      //     }</p>
      //   </html>`,
      //   }).then((success) => {
      // if (success == 'OK' && this.loading) {
      //
      this.loading = false;
      setTimeout(() => {
        this.inquiry.username = '';
        this.inquiry.phone = '';
        this.inquiry.email = '';
        this.inquiry.company = '';
        this.inquiry.project = '';
        this.inquiry.service = '';
        this.inquiry.budget = 0;
        this.inquiry.content = '';
        this.inquiry.browser = '';
        this.email = '';
        this.emailAdd = '';
        this.privacy = false;
        this.getEstimeate = false;
        this.fileArr = [];
        tmp = ``;
        this.router.navigateByUrl('/inquiry-complete').then(() => {
          //window.location.reload();
        });
        // window.location.replace('/inquiry');
        //추후에 페이지 연결 해주세요
      }, 300);
      // } else {
      //
      //   this.loading = false;
      //   alert(
      //     '문의하기 오류 참고문서가 있을시 참고문서 파일 크기를 확인해주세요!'
      //   );
      //   this.router.navigateByUrl('/inquiry').then(() => {
      //     //window.location.reload();
      //   });
      // }
      // });
    } else {
      this.loading = false;
      this.showErrorAlert(
        '알림',
        '문제가 생겨 문의 등록이 실패하였습니다. 잠시후에 다시 시도해주시길 바랍니다.'
      );
    }
  }

  async updateDb() {
    this.inquiry.dateCreated = new Date().toISOString();
    this.inquiry.date = new Date();
    for await (const file of this.uploadFiles) {
      const res = await this.updateFile(file);
      this.inquiry.updateFiles.push(res);
    }
    return this.db.updateAt('inquiries', this.inquiry);
  }

  updateFile(file) {
    return new Promise<any>((resolve) => {
      const path = `fileStorage/${new Date().getTime()}_${file.name}`;
      const customMetadata = { app: 'uploadFile' };
      this.task = this.storage.upload(path, file, { customMetadata });
      this.task
        .then((v) => {
          let url = `https://storage.googleapis.com/${v.metadata.bucket}/${v.metadata.fullPath}`;
          resolve(url);
        })
        .catch((error) => {});
    });
  }

  makePdf() {
    return new Promise<any>((resolve) => {
      let dataurl;
      //pdf 세팅
      // this.saveDate = new Date();
      var doc = new jsPDF('p', 'px', 'a4'); //jspdf객체 생성
      //한국어 깨짐 방지
      var _fonts = this.pdfFont.setFont();

      //한국어 깨짐 방지 폰트 셋팅
      doc.addFileToVFS('NanumSquare_acR.ttf', _fonts); //_fonts 변수는 Base64 형태로 변환된 내용입니다.
      doc.addFont('NanumSquare_acR.ttf', 'NanumSquare', 'normal');
      doc.setFont('NanumSquare');

      doc
        .html(document.getElementById('pdfBody'), {
          // margin: [32, 0, 700, 0],
          html2canvas: {
            scale: 0.635,
          },
        })
        .then((result) => {
          autoTable(doc, {
            html: '#my-table',
            theme: 'grid',
            margin: [24, 0, 24, 7],
            tableWidth: 700 * 0.635 - 14,
            tableLineWidth: 1.5,
            tableLineColor: '#000',
            headStyles: {
              lineColor: '#000',
              lineWidth: 0.5,
              fillColor: '#f1f1f1',
              textColor: '#000',
            },
            styles: {
              font: 'NanumSquare',
              fillColor: '#fff',
              textColor: '#000',
              lineColor: '#000',
              valign: 'middle',
              halign: 'center',
              minCellHeight: 54 * 0.635,
              fontSize: 12,
            },
            startY: document.getElementById('pdfBody').clientHeight * 0.635,
          });
          dataurl = doc.output('datauristring');
          this.pdfUrl = dataurl;
        })
        .then(() => {
          this.updatePdf(dataurl).then((result) => {
            this.db.updateAt(`estimate/${this.estimateId}`, {
              pdf: result,
            });

            let tmp = { name: '스타트앱 간편 견적서.pdf', data: dataurl };
            this.fileArr.push(tmp);
            resolve(true);
          });

          doc.save('스타트앱 간편 견적서.pdf');
        })
        .then(() => {});
    });
  }
  updatePdf(dataurl) {
    return new Promise<any>((resolve) => {
      const name = this.generateFilename();

      firebase.default
        .storage()
        .ref(`/pdf/` + name)
        .putString(dataurl, 'data_url')
        .then((v) => {
          let url = `https://storage.googleapis.com/${v.metadata.bucket}/${v.metadata.fullPath}`;
          resolve(url);
        })
        .catch((error) => {});
    });
  }

  check() {
    var emailRule =
      /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i; //이메일 정규식

    if (
      !this.inquiry.username ||
      !this.inquiry.phone ||
      !this.email ||
      !this.emailAdd ||
      !this.inquiry.service ||
      !this.inquiry.company ||
      !this.inquiry.project ||
      !this.inquiry.budget ||
      !this.privacy ||
      this.checkEmailValidate()
    ) {
      return true;
    }
    return false;
  }

  checkEmailValidate() {
    var emailRule =
      /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    if (!emailRule.test(this.email + '@' + this.emailAdd)) {
      //경고
      return true;
    }

    return false;
  }

  //견적 내보기
  goSimpleEstimate() {
    if (
      !this.inquiry.username ||
      !this.inquiry.company ||
      !this.inquiry.project
    ) {
      //pdf 필수정보 없을때
      alert('필수 정보를 입력하여 주세요.');
    } else {
      let tmp = {
        name: this.inquiry.username,
        company: this.inquiry.company,
        project: this.inquiry.project,
        phone: this.inquiry.phone,
        email: this.inquiry.email,
        service: this.inquiry.service,
        budget: this.inquiry.budget,
        required: [],
        additional: [],
        fileArr: this.fileArr,
        dateCreated: '',
        id: '',
        content: this.inquiry.content,
        selectApp: this.selectWeb.id,
      };

      tmp.id = this.generateFilename();

      this.db.updateAt(`estimate/${tmp.id}`, tmp).then(() => {
        // this.router.navigateByUrl(`simple-estimate/${tmp.id}`);
        this.router.navigate([`simple-estimate/${tmp.id}`], {
          queryParams: { type: 'inquiry' },
        });
      });
    }
  }

  checkValidation() {
    this.isSubmitted = true;
  }
  goDetailPrivacy() {
    this.dialog.open(TermsModalComponent, {
      panelClass: 'terms-modal',
      data: {
        type: 'privacy',
      },
    });
  }

  chagneServieList() {
    this.inquiry.service = this.serviceList
      .filter((item) => item.checked)
      .map((item) => item.title);
  }

  checkNumber(event) {
    this.inquiry.phone = this.inquiry.phone
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*)\./g, '$1');
  }
}
