import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FileUploadService } from 'src/app/service/file-upload.service';
import { InquiryService } from 'src/app/service/inquiry.service';

@Component({
  selector: 'app-inquiry-list-pop',
  templateUrl: './inquiry-list-pop.component.html',
  styleUrls: ['./inquiry-list-pop.component.scss'],
})
export class InquiryListPopComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<InquiryListPopComponent>,
    public inquiryService: InquiryService,
    public fileUploadService: FileUploadService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  ngOnInit(): void {
    //   let script2 = this._renderer2.createElement('script');
    //   script2.type = 'text/javascript';
    //   script2.text = `var _nasa={};
    //   if (!wcs_add) var wcs_add = {};
    // wcs_add["wa"] = "s_52ea45ec186a";
    // if (!_nasa) var _nasa = {};
    // if (window.wcs) {
    //   wcs.inflow("startapp.co.kr");
    //   wcs_do(_nasa);}
    //   `;
    //   this._renderer2.appendChild(this._document.body, script2);
  }

  displayPhone(phone) {
    let newStr = '';
    newStr = phone.replace(
      /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
      '$1-$2-$3'
    );

    return newStr;
  }

  modalClose() {
    this.dialogRef.close();
  }
}
