<!-- <header-share></header-share> -->
<main id="main">
  <section class="detail-header"
    *ngIf="pickPortfolio && loadingSwitch">
    <h1>포트폴리오</h1>
    <p>
      우리의 포트폴리오는 고객을 위해 구현한 설계 및 개발 스타터팩을 설명합니다.
    </p>
    <p>
      웹 및 모바일 개발 마케팅까지 접근하는 방법에 대한 정확한 통찰력으로
      가득합니다.
    </p>
  </section>

  <div class="all-wrap"
    *ngIf="portfolioList && pickPortfolio">
    <section class="project-wrap w1180">
      <div class="appintro-wrap"
        [ngStyle]="{
          'padding-bottom':
            pickPortfolio.introImg?.length !== 0 || pickPortfolio.info
              ? '200px'
              : 0
        }">
        <div class="top">
          <div class="icon"
            *ngIf="pickPortfolio.icon">
            <img [src]="pickPortfolio.icon"
              alt="{{ pickPortfolio.title }} 앱 아이콘" />
          </div>
          <div class="txt-wrap">
            <h3 *ngIf="pickPortfolio.title">{{ pickPortfolio.title }}</h3>
            <p>{{ pickPortfolio.subInfo }}</p>
            <p class="small"
              *ngIf="pickPortfolio.startDate || pickPortfolio.endDate">
              <span class="bolder">기간</span>{{ pickPortfolio.startDate ? pickPortfolio.startDate : "" }} ~
              {{ pickPortfolio.endDate ? pickPortfolio.endDate : "" }}
            </p>
            <p class="small"
              *ngIf="pickPortfolio.platform">
              <span class="bolder">플랫폼</span>{{ pickPortfolio.platform }}
            </p>
          </div>
        </div>

        <!-- 앱소개이미지 -->
        <div *ngIf="pickPortfolio.introImg?.length !== 0">
          <div class="swiper-container"
            [ngStyle]="{
              'max-width':
                pickPortfolio.introImg?.length > 0 &&
                pickPortfolio.introImg?.length < 4
                  ? '880px'
                  : '1200px'
            }">
            <div class="swiper-wrapper">
              <div *ngFor="let mainImg of pickPortfolio.introImg"
                class="swiper-slide">
                <img draggable="false"
                  [src]="mainImg"
                  alt="{{ pickPortfolio.title }} 앱 소개이미지" />
              </div>
            </div>
            <div class="btn-area big-area"
              *ngIf="pickPortfolio.introImg?.length > 4">
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>
            <div class="btn-area middel-area"
              *ngIf="pickPortfolio.introImg?.length >= 4">
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>
          </div>
        </div>
        <div class="explanation-txt"
          *ngIf="pickPortfolio.info">
          <h4>서비스 설명</h4>
          <p>
            {{ pickPortfolio.info ? pickPortfolio.info : "" }}
          </p>
        </div>
      </div>
      <!-- appintro-wrap end -->
      <!-- 목업이미지 4개인 경우 four-layout class 추가 -->
      <div class="mockup-wrap"
        [ngClass]="{
          'four-layout': pickPortfolio.appImg?.length == 4,
          'h-layout': pickPortfolio.type == 'horizontal'
        }"
        [ngStyle]="{
          height: pickPortfolio.title == '다이아토닉 피아노' ? '745px' : 'auto'
        }">
        <div class="mockup"
          *ngFor="let detail of pickPortfolio.appImg">
          <div>
            <img [src]="detail"
              alt="{{ pickPortfolio.title }} 앱 이미지" />
          </div>
          <img class="frame"
            src="assets/imgs/img_mockup.png"
            alt="목업프레임" />
        </div>
      </div>
    </section>

    <section class="bottom-btn">
      <!-- <div class="left" *ngIf="pickPortfolio.prev == '한라'">
        <button class="prev">
          <a href="/portfolio-hanra">
            <span>한라</span>
            <img src="assets/imgs/prev_icon.png" alt="이전버튼" />
          </a>
        </button>
      </div>
      <div class="left" *ngIf="pickPortfolio.prev !== '한라'">
        <button class="prev">
          <a href="/portfolio-detail/{{ pickPortfolio.prev }}">
            <span>{{ pickPortfolio.prev }}</span>
            <img src="assets/imgs/prev_icon.png" alt="이전버튼" />
          </a>
        </button>
      </div> -->
      <!--
      <div class="right" *ngIf="pickPortfolio.next == '한라'">
        <button class="next">
          <a href="/portfolio-hanra">
            <span>한라</span>
            <img src="assets/imgs/next_icon.png" alt="다음버튼" />
          </a>
        </button>
      </div>

      <div
        class="right"
        *ngIf="pickPortfolio.next !== '한라'"
        [replaceUrl]="true"
      >
        <button class="next">
          <a href="/portfolio-detail/{{ pickPortfolio.next }}">
            <span>{{ pickPortfolio.next }}</span>
            <img src="assets/imgs/next_icon.png" alt="다음버튼" />
          </a>
        </button>
      </div> -->

      <div class="left"
        [ngStyle]="{
          'background-image':
            itemIndex == 0 ? 'url(../../../assets/imgs/hanra_bg.png)' : false
        }">
        <button class="prev">
          <a (click)="prevBtn()">
            <span>{{
              itemIndex != 0 ? portfolioList[itemIndex - 1].title : "한라"
              }}</span>
            <img src="assets/imgs/prev_icon.png"
              alt="이전버튼" />
          </a>
        </button>
        <div class="bg"
          *ngIf="
            itemIndex != 0 && portfolioList[itemIndex - 1].introImg?.length >= 2
          ">
          <img [src]="portfolioList[itemIndex - 1].introImg[0]"
            alt="" />
          <img [src]="portfolioList[itemIndex - 1].introImg[1]"
            alt="" />
        </div>
        <div class="bg"
          *ngIf="
            itemIndex != 0 &&
            portfolioList[itemIndex - 1].introImg?.length < 2 &&
            itemIndex != 0 &&
            portfolioList[itemIndex - 1].appImg?.length >= 2
          ">
          <img [src]="portfolioList[itemIndex - 1].appImg[0]"
            alt="" />
          <img [src]="portfolioList[itemIndex - 1].appImg[1]"
            alt="" />
        </div>
      </div>

      <div class="right"
        [ngStyle]="{
          'background-image':
            itemIndex == portfolioList.length - 1
              ? 'url(../../../assets/imgs/hanra_bg.png)'
              : false
        }">
        <button class="next">
          <a (click)="nextBtn()">
            <span>{{
              itemIndex != portfolioList.length - 1
              ? portfolioList[itemIndex + 1].title
              : "한라"
              }}</span>
            <img src="assets/imgs/next_icon.png"
              alt="다음버튼" />
          </a>
        </button>
        <div class="bg"
          *ngIf="
            itemIndex != portfolioList.length - 1 &&
            portfolioList[itemIndex + 1].introImg?.length >= 2
          ">
          <img [src]="portfolioList[itemIndex + 1].introImg[0]"
            alt="" />
          <img [src]="portfolioList[itemIndex + 1].introImg[1]"
            alt="" />
        </div>
        <div class="bg"
          *ngIf="
            itemIndex != portfolioList.length - 1 &&
            portfolioList[itemIndex + 1].introImg?.length < 2 &&
            portfolioList[itemIndex + 1].appImg?.length >= 2
          ">
          <img [src]="portfolioList[itemIndex + 1].appImg[0]"
            alt="" />
          <img [src]="portfolioList[itemIndex + 1].appImg[1]"
            alt="" />
        </div>
      </div>

      <div class="opacity"></div>
    </section>
  </div>
  <!-- <sticky-button></sticky-button> -->
  <p class="bottom-line"></p>
</main>
<footer-share id="footerWrap"></footer-share>
<footer-share class="mobile-footer"></footer-share>
<!-- <footer-share id="mainFooter"></footer-share> -->

<!-- <div id="pfdetail-footer">
  <div class="footer-inner">
    <footer-share></footer-share>
  </div>
</div> -->
