import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Inquiry } from 'src/app/models/inquiry.models';
import { FileUploadService } from 'src/app/service/file-upload.service';
import { InquiryService } from 'src/app/service/inquiry.service';
import {
  default as Swiper,
  default as SwiperCore,
  Pagination,
  Scrollbar,
} from 'swiper';
import { TermsModalComponent } from '../terms-modal/terms-modal.component';
import { DbService } from 'src/app/service/db.service';
import { MbscFormOptions } from '@mobiscroll/angular';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-inquiry-page',
  templateUrl: './inquiry-page.component.html',
  styleUrls: ['./inquiry-page.component.scss'],
})
export class InquiryPageComponent implements OnInit {
  DECIMAL_SEPARATOR = '.';
  GROUP_SEPARATOR = ',';

  swiperRef;
  pageNumber: number = 0;

  reference;
  agree: boolean = false;

  inquiryForm: FormGroup;

  constructor(
    public inquiryService: InquiryService,
    private router: Router,
    public formBuilder: FormBuilder,
    public fileUploadService: FileUploadService,
    public dialog: MatDialog,
    private db: DbService,
    protected $gaService: GoogleAnalyticsService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  ngOnInit(): void {
    //   let script2 = this._renderer2.createElement('script');
    //   script2.type = 'text/javascript';
    //   script2.text = `var _nasa={};
    //   if (!wcs_add) var wcs_add = {};
    // wcs_add["wa"] = "s_52ea45ec186a";
    // if (!_nasa) var _nasa = {};
    // if (window.wcs) {
    //   wcs.inflow("startapp.co.kr");
    //   wcs_do(_nasa);}
    //   `;
    //   this._renderer2.appendChild(this._document.body, script2);
    this.$gaService.event('inquiry-start', 'inquiry', 'writing');
    this.inquiryService.clear();
    this.fileUploadService.fileArr = [];
    this.inquiryForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      company: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required]],
      budget: ['', [Validators.required]],
      content: ['', [Validators.required]],
    });

    this.swiperOptions();

    let keyPress: boolean = false;
    let inquiryPage = document.getElementById('common-container');
    inquiryPage.addEventListener('keydown', (event) => {
      if (event.key === 'Enter') {
        if (!keyPress) {
          keyPress = true;
          setTimeout(() => {
            if (this.pageNumber < 6) {
              this.swiperRef.slideNext();
              keyPress = false;
            } else {
              this.goComplete();
            }
          }, 50);
        }
      }
    });
  }

  // 슬라이드 옵션을 Set합니다.
  swiperOptions() {
    this.swiperRef = new Swiper('.mySwiper', {
      loop: false,
      mousewheel: false,
      touchRatio: 0,
      pagination: {
        el: '.swiper-pagination',
        type: 'progressbar',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      on: {
        slideChange: async (data) => {
          // 왜 0->1갈때만?
          if (data.realIndex > this.pageNumber) {
            if (this.pageNumber === 0 && !this.inquiryForm.value.username) {
              this.inquiryService.alert('성함을 입력해주세요.');
              data.slidePrev();
              return;
            }

            if (this.pageNumber === 1 && !this.inquiryForm.value.phone) {
              this.inquiryService.alert('전화번호를 입력해주세요.');
              data.slidePrev();
              return;
            }

            if (this.pageNumber === 2 && !this.inquiryForm.value.email) {
              this.inquiryService.alert('이메일주소를 입력해주세요.');
              data.slidePrev();
              return;
            }

            if (
              this.pageNumber === 3 &&
              !this.inquiryService.inquiry.service.length
            ) {
              this.inquiryService.alert(
                '원하시는 프로젝트 종류를 1개 이상 선택해주세요.'
              );
              data.slidePrev();
              return;
            }

            if (this.pageNumber === 4 && !this.inquiryForm.value.budget) {
              this.inquiryService.alert('개발 예산을 입력해주세요.');
              data.slidePrev();
              return;
            }

            if (this.pageNumber === 5 && !this.inquiryForm.value.content) {
              this.inquiryService.alert('원하시는 상담 내용을 입력해주세요.');
              data.slidePrev();
              return;
            }

            let inquiry: Inquiry = this.inquiryService.inquiry;

            inquiry.username = this.inquiryForm.value.username;
            inquiry.company = this.inquiryForm.value.company;
            inquiry.phone = this.inquiryForm.value.phone;
            inquiry.email = this.inquiryForm.value.email;
            inquiry.content = this.inquiryForm.value.content;
            inquiry.budget = Number(
              this.inquiryForm.value.budget.toString().replace(/,/g, '')
            );

            this.inquiryService.inquiry = inquiry;
          }

          this.pageNumber = data.realIndex;
        },
      },
    });
  }

  //파일 가져오기
  selectFile(event) {
    let file = event.target.files[0];
    this.fileUploadService.selectFile(file);
  }

  goDetailPrivacy() {
    this.dialog.open(TermsModalComponent, {
      panelClass: 'terms-modal',
      data: {
        type: 'privacy',
      },
    });
  }

  async goComplete(): Promise<void> {
    const visitRoute = this.inquiryService.routeList.find((v) => v.checked);

    if (!visitRoute) {
      this.inquiryService.alert('방문 경로를 선택해주세요.');
      return;
    }
    if (!this.agree) {
      this.inquiryService.alert('개인정보 처리방침에 동의해주세요.');
      return;
    }

    let inquiry: Inquiry = this.inquiryService.inquiry;
    inquiry.visitRoute = visitRoute.title;

    inquiry.browser = navigator.userAgent;
    inquiry.dateCreated = new Date().toISOString();
    inquiry.date = new Date();
    inquiry.updateFiles = this.fileUploadService.fileArr.map((e) => e.url);

    this.inquiryService.inquiry = inquiry;
    const ok = await this.inquiryService.confirmAlert(
      '입력하신 내용으로 문의를 제출하시겠어요?'
    );

    if (ok) {
      this.fileUploadService.fileArr = [];
      await this.db.updateAt('inquiries', this.inquiryService.inquiry);
      this.router.navigateByUrl('/inquiry-complete');
    }
  }

  delFile(index) {
    this.fileUploadService.fileArr.splice(index, 1);
  }

  setFormat(event) {
    let budget = this.format(event.target.value);
    this.inquiryForm.controls.budget.setValue(budget ? budget : '');
  }

  format(valString: string) {
    if (!valString) {
      return '';
    }
    let val = valString.toString();
    const parts = this.unFormat(val).split(this.DECIMAL_SEPARATOR);
    return parts[0]
      ? parts[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, this.GROUP_SEPARATOR)
      : '';
  }

  unFormat(val: string) {
    if (!val) {
      return '';
    }
    val = val.replace(/^0+/, '').replace(/\D/g, '');
    if (this.GROUP_SEPARATOR === ',') {
      return val.replace(/,/g, '');
    } else {
      return val.replace(/\./g, '');
    }
  }

  //모비스크롤 프로그레스바
  progress = 0;

  formSettings: MbscFormOptions = {
    theme: 'ios',
    themeVariant: 'light',
  };

  inputClick(index, value) {
    this.inquiryService.serviceList[index].checked = !value;

    this.inquiryService.inquiry.service = this.inquiryService.serviceList
      .filter((item) => item.checked)
      .map((item) => item.title);
  }

  inputRadio(route) {
    for (const element of this.inquiryService.routeList) {
      element.checked = false;
    }
    route.checked = !route.checked;
  }
}
