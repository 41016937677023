import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  constructor() {}

  isMobileDevice(): boolean {
    const userAgent = window.navigator.userAgent;
    return /iPhone|iPad|iPod|Android/i.test(userAgent);
  }
}
