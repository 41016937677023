import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-solution-subscribe-pc',
  templateUrl: './solution-subscribe-pc.component.html',
  styleUrls: ['./solution-subscribe-pc.component.scss']
})
export class SolutionSubscribePcComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
