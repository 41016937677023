import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-solution-subscribe',
  templateUrl: './solution-subscribe.component.html',
  styleUrls: ['./solution-subscribe.component.scss'],
})
export class SolutionSubscribeComponent implements OnInit {
  windowWidth: number = window.innerWidth;

  @HostListener('window:resize', ['$event'])
  getWindowWidth(e) {
    const width = e.target.innerWidth;
    this.windowWidth = width;
  }

  constructor() {}

  ngOnInit(): void {}
}
